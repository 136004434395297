import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { $t } from "~/i18n.js";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Empty, Input, Switch, Tag } from "antd";

import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { SearchTags } from "~/components/searchTag/searchTag.jsx";
import { getMaterialTab } from "~/redux/materiaal/materiaal.actions.js";
import { MaintenanceStatusTag } from "../../materialTags.jsx";
import { MatChapterCard } from "./sections/matChapterCard.jsx";
import { MatChapterModal } from "./sections/matChapterModal.jsx";

export const MaterialDocuments = ({ currentMaterial, chapters, readOnly }) => {
  // setup search for kisten you are allowed access to
  const [search, setSearch] = useState("");
  const [searchTag, setSearchTag] = useState([]);

  const [showArchive, setShowArchive] = useState(false);
  const hasArchive = chapters?.some((c) => c.is_archived);

  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [openChapter, setOpenChapter] = useState(null);

  // reduce unique cluster names in tabs
  let allTags = {};
  chapters?.forEach((c) => (allTags[c.tag] = { name: c.tag }));
  const allTagsList = Object.keys(allTags);
  allTags = allTagsList.map((v) => allTags[v]);

  const dispatch = useDispatch();

  const saveChapter = async (data) => {
    const payload = { action: "chapter", ...data };

    return await dispatch(
      getMaterialTab({
        method: data.method,
        activeTab: "documents",
        materialID: currentMaterial.id,
        payload,
        as_json: false,
      })
    );
  };

  return (
    <div className="column" style={{ gap: 24, alignItems: "flex-start" }}>
      {openChapter && (
        <MatChapterModal
          allTags={allTagsList}
          closeModal={() => setOpenChapter(null)}
          chapter={openChapter}
          saveChapter={saveChapter}
        />
      )}

      <div className="row" style={{ gap: 12, flexWrap: "wrap", width: "100%" }}>
        <Input.Search
          style={{ maxWidth: 250 }}
          value={search}
          allowClear
          size="small"
          placeholder={$t("Zoek op naam")}
          onChange={(event) => setSearch(event.target.value.toLowerCase())}
        />

        <SearchTags
          items={allTags}
          searchTags={searchTag}
          setSearchTags={setSearchTag}
        />

        {(showArchive || hasArchive) && (
          <Tag
            className="clickable"
            color="red"
            onClick={() => setShowArchive(!showArchive)}
            style={{ opacity: !showArchive ? 0.3 : 1 }}
          >
            {$t("archief")}
          </Tag>
        )}

        {!readOnly && (
          <p className="row" style={{ width: 120 }}>
            {$t("Wijzig")}:
            <Switch
              size="small"
              style={{ marginLeft: 4 }}
              onChange={(checked) => setReadOnlyMode(!checked)}
              checked={!readOnlyMode}
            />
          </p>
        )}

        {!readOnlyMode && (
          <Button
            onClick={() =>
              setOpenChapter({
                material_id: currentMaterial.id,
                is_public: false,
                order: chapters.length + 1,
              })
            }
            size="small"
            icon={<PlusOutlined />}
          >
            {$t("Voeg toe")}
          </Button>
        )}
      </div>

      {currentMaterial?.document_status && (
        <div className="row" style={{ gap: 12 }}>
          <p className="label">
            {$t("Status")}{" "}
            <HelpBubble content={$t("Status word elke nacht bijgewerkt")} />
          </p>
          <MaintenanceStatusTag
            maintenance_status={currentMaterial.document_status}
            default_value="nvt"
          />
        </div>
      )}

      <div
        className="row"
        style={{ flexWrap: "wrap", alignItems: "flex-start" }}
      >
        {chapters?.length > 0 ? (
          chapters?.map((chapter) => {
            // see if any documents are in chapter, otherwise filter out
            let matched_documents = chapter.documents;

            // filter out if archive
            if (chapter.is_archived != showArchive) return null;

            if (searchTag?.length > 0 && !searchTag.includes(chapter.tag))
              return null;

            if (search) {
              matched_documents = matched_documents?.filter(
                (f) => f.name.toLowerCase().indexOf(search) > -1
              );
              if (matched_documents?.length == 0) return null;
            }

            return (
              <MatChapterCard
                key={chapter.id}
                chapter={chapter}
                documents={matched_documents}
                readOnly={readOnlyMode}
                setOpenChapter={setOpenChapter}
                allChapters={chapters}
                materialID={currentMaterial.id}
              />
            );
          })
        ) : (
          <Empty description="Geen documenten" />
        )}
      </div>
    </div>
  );
};
