import { Checkbox, Tag } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const SelectProgramList = ({
  selectedPrograms,
  setSelectedPrograms,
  maintenancePrograms
}) => {
  
  const handleCheckboxChange = (programId) => {
    setSelectedPrograms((prevSelected) =>
      prevSelected.includes(programId)
        ? prevSelected.filter((id) => id !== programId)
        : [...prevSelected, programId]
    );
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Checkbox
          indeterminate={
            selectedPrograms.length > 0 &&
            selectedPrograms.length < maintenancePrograms.length
          }
          checked={selectedPrograms.length === maintenancePrograms.length}
          onChange={() => {
            const allIds = maintenancePrograms.map((p) => p.id);
            setSelectedPrograms(
              selectedPrograms.length === maintenancePrograms.length
                ? []
                : allIds
            );
          }}
        >
          {$t("Selecteer alles")}
        </Checkbox>
      </div>

      {maintenancePrograms.map((program) => (
        <div key={program.id}>
          <Checkbox
            checked={selectedPrograms.includes(program.id)}
            onChange={() => handleCheckboxChange(program.id)}
          >
            {program.part_name && <Tag>{program.part_name}</Tag>} {program.name}
          </Checkbox>
        </div>
      ))}
    </div>
  );
};
