import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { $t } from '~/i18n.js'

import { PrinterOutlined } from '@ant-design/icons'
import { Button, Input, Select, Switch, Table } from 'antd'

import { MainLayout } from '~/components/layout/layout.jsx'
import { UserModal } from '~/components/profile/userModal.jsx'
import { api } from '~/lib/api.js'
import { daysActions, flightsActions } from '~/redux/actions.js'
import {
  DTOlijst,
  InstructeurLijst,
  clusterColumn,
  ledenLijst
} from './table.columns.jsx'

import { toLocaleDate } from '~/lib/localize.js'
import { EmailComponent } from './email.component.jsx'

import { BreadcrumbHeader } from '~/components/layout/breadcrumbHeader.jsx'
import { defaultTableConfig } from '~/components/layout/table.jsx'
import { selectElementByID } from '~/lib/helpers.js'
import { RefreshBaseData } from '~/components/buttons/RefreshBaseData.jsx'
import { useMedia } from 'use-media'

const LedenLijstOverzicht = ({
  persist,
  flights,
  getBaseData,
  history,
  days,
  getAllDays,
  title,
  overzicht,
  match
}) => {
  const [vliegers, setVliegers] = useState([])
  useEffect(() => setVliegers(flights.vliegers.filter(filterOutPassengiers)), [flights.vliegers])

  const [DTOvliegers, setDTOvliegers] = useState({});
  const [search, setSearch] = useState("");

  const isWide = useMedia({ minWidth: "450px" }, false);

  const filterOutPassengiers = (item) => {
    return item.id > 0;
  };

  const searchVlieger = (searchText, newdtoVliegers) => {
    let vliegers = flights.vliegers.filter(filterOutPassengiers);

    if (overzicht == "dto") {
      const selectedDTOvliegers = newdtoVliegers || DTOvliegers;
      vliegers = vliegers.filter((vlieger) => {
        return selectedDTOvliegers[vlieger.id] || false;
      });
    }

    if (!searchText) {
      // if no search, remove vligers with <0 id (passengier)
      setVliegers(vliegers);
    } else {
      // search for name of vlieger
      searchText = searchText.toLowerCase();
      vliegers = vliegers.filter((vlieger) => {
        return vlieger.lowercase.indexOf(searchText) > -1;
      });
      setVliegers(vliegers);
    }
  };

  const [behaaldeBevoegdheden, setBevoegdheden] = useState({});
  const [activeDtoTrainingen, setActiveDtoTrainingen] = useState({});
  const [deactivatedLeden, SetDeactivatedLeden] = useState(null);
  const [notities, setNotities] = useState({});
  const [latestBehaaldeBevoegdheden, setLatestBehaaldeBevoegdheden] = useState(
    {}
  );
  const [tableColumns, setStateColumns] = useState();

  const [loadingFile, setFileLoading] = useState(false);

  // using router to navigate opened pilot
  const [openedPilot, setOpenPilot] = useState(null);
  useEffect(() => {
    setOpenPilot({
      id: match.params.id,
      ...selectElementByID(
        flights.vliegers,
        "id",
        parseInt(match.params.id || 0)
      ),
    });
  }, [match.params.id]);

  const setColumns = (
    newNotities,
    newBevoegdheden,
    newDTOtrainingen,
    showInstructorColumns,
    newlatestBehaaldeBevoegdheden
  ) => {
    const selectedNotities = newNotities || notities;
    const selectedBehaaldeBevoegdheden =
      newBevoegdheden || behaaldeBevoegdheden;
    const selectedActiveDtoTrainingen = newDTOtrainingen || activeDtoTrainingen;
    const groups_filter = persist.club && persist.club.groups_filter;
    const group_name_lookup = flights?.group_name_lookup || {};

    // set column to render table
    let columns = [];

    if (overzicht === "dto") {
      columns = DTOlijst(
        groups_filter,
        group_name_lookup,
        newlatestBehaaldeBevoegdheden || latestBehaaldeBevoegdheden,
        history
      );

      // iterate over bevoegdheden columns
      flights.bevoegdheden.forEach(
        (cluster) =>
          cluster.is_dto &&
          columns.push(
            clusterColumn(
              cluster,
              selectedBehaaldeBevoegdheden,
              selectedActiveDtoTrainingen
            )
          )
      );
    } else if (showInstructorColumns) {
      columns = InstructeurLijst(
        selectedNotities,
        groups_filter,
        group_name_lookup,
        history
      );
    } else {
      columns = ledenLijst(groups_filter, group_name_lookup, history);
    }

    // we push the columns to a state to update the table
    setStateColumns(columns);
  };

  const [showDTOdata, setShowDTOdata] = useState(false);
  const [DTOloading, setDTOloading] = useState(false);
  const getDTOdata = async () => {
    setShowDTOdata(true);
    setDTOloading(true);
    const res = await api.get("instructie/dto_data.json");

    if (res) {
      setNotities(res.notities);
      setBevoegdheden(res.behaalde_bevoegdheden);
      setActiveDtoTrainingen(res.active_dto_trainingen);
      SetDeactivatedLeden(res.deactivated_leden);
      setLatestBehaaldeBevoegdheden(res.latest_behaalde_bevoegdheden || {});

      // set all started vliegers in list to filter
      const dtoVliegers = {};
      Object.keys(res.active_dto_trainingen).forEach(function (key) {
        if (res.active_dto_trainingen[key] === "started") {
          dtoVliegers[parseInt(key.split("_")[0])] = 1;
        }
      });
      setDTOvliegers(dtoVliegers);

      // set columns initially
      setColumns(
        res.notities,
        res.behaalde_bevoegdheden,
        res.active_dto_trainingen,
        true,
        res.latest_behaalde_bevoegdheden
      );

      setDTOloading(false);

      // set first empty search
      console.log("setting vliegers", dtoVliegers);
      searchVlieger("", dtoVliegers);
    }
  };
  const turnDTOoff = () => {
    setShowDTOdata(false)
    setColumns()
  }

  // loading in all data, flights, days and dto information
  const [awaitingData, setAwaitingData] = useState(-1)
  useEffect(() => {
    const loadData = async () => {

      // load in flights if needed
      if (!flights.lastUpdatedBaseData) await getBaseData();

      // load in days if needed
      if (!days.lastUpdated) await getAllDays();

      if (overzicht === 'dto' && persist.profile.ziet_dto) {
        getDTOdata()
      } else {
        // for regular leden, or normal overview, show cap columns directly
        setColumns();
      }

      // finally, show data
      setAwaitingData(1)
    }

    // when opening
    setShowDTOdata(false)
    loadData()
  }, [flights.lastUpdatedBaseData, days.lastUpdated, overzicht])

  return (
    <>
      <UserModal
        link={window.location.href}
        visible={openedPilot && openedPilot.id}
        onClose={() => history.replace('/' + overzicht)}
        pilot={openedPilot}
        newNotities={(_new) => {
          notities[openedPilot.id] = _new
          setNotities(notities)
          setColumns()
        }}
      />

      <MainLayout history={history} isLoading={awaitingData < 1}>
        <BreadcrumbHeader
          breadcrumbs={[title]}
          buttons={[
            <RefreshBaseData key='1' size='medium' style={{marginRight: 8}}/>,

            isWide && persist.profile.ziet_emaillijst && overzicht === 'members' && (
              <EmailComponent />
            ),
            isWide && persist.profile.wijzig_dto && (
              <Button
                icon={<PrinterOutlined />}
                loading={loadingFile}
                onClick={async () => {
                  setFileLoading(true)
                  await api.open_file(
                    `bevoegdheid/${overzicht}/export.json`,
                    `bevoegheden ${overzicht} ${toLocaleDate(new Date())}.xlsx`
                  )
                  setFileLoading(false)
                }}
              >
                {$t('Exporteer')}
              </Button>
            )
          ]}
        />

        <div className='column' style={{ alignItems: 'flex-start' }}>
          <Input.Search
            style={{ maxWidth: 250 }}
            value={search}
            allowClear
            size='small'
            placeholder={$t('Zoek op naam')}
            onChange={(event) => {
              searchVlieger(event.target.value)
              setSearch(event.target.value)
            }}
          />

          {overzicht === 'dto' &&
            deactivatedLeden &&
            deactivatedLeden.length > 0 && (
              <Select
                mode='single'
                showSearch
                style={{ marginTop: 12, width: 200 }}
                size='small'
                onSelect={(vliegerID) =>
                  history.push(`/${overzicht}/${vliegerID}`)}
                filterOption={(input, option) =>
                  option &&
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0}
                placeholder='Open deactief lid'
              >
                {deactivatedLeden.map(
                  (vlieger, key) =>
                    vlieger.id > 0 && (
                      <Select.Option value={vlieger.id} key={key}>
                        {vlieger.name}
                      </Select.Option>
                    )
                )}
              </Select>
          )}

          {persist.profile.ziet_dto && overzicht !== 'dto' && (
            <p className='spacer'>
              {$t('Bekijk instructie informatie:')}
              <Switch
                size='small'
                loading={DTOloading}
                style={{ marginLeft: 4 }}
                onChange={(checked) => (checked ? getDTOdata() : turnDTOoff())}
                checked={showDTOdata}
              />
            </p>
          )}

          <Table
            style={{
              marginTop: 12,
              width: '95vw',
              overflowX: 'auto',
              cursor: 'pointer'
            }}
            pagination={{ ...defaultTableConfig, defaultPageSize: 100 }}
            size='small'
            rowKey='id'
            dataSource={vliegers}
            columns={tableColumns}
          />
        </div>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state) => ({
  persist: state.persist,
  flights: state.flights,
  days: state.days
})

const mapDispatchToProps = (dispatch) => {
  return {
    getBaseData: (d) => dispatch(flightsActions.getBaseData(d)),
    getAllDays: (d) => dispatch(daysActions.getAllDays(d))
  }
}

export const LedenLijstOverzichtPagina = connect(
  mapStateToProps,
  mapDispatchToProps
)(LedenLijstOverzicht)
