import { useDispatch, useSelector } from "react-redux";
import { updateIfNeeded } from "~/lib/helpers.js";
import { flightsActions } from "~/redux/actions.js";
import { getBaseData } from "~/redux/flights/flights.actions.js";
import { getVliegerData, profileAction } from "~/redux/persist/persist.actions.js";

export function useSyncData() {
    const dispatch = useDispatch();
    const flights = useSelector((state) => state.flights);

    return {
        async sync() {

            // hard sync of all data
            await dispatch(getBaseData());
            await dispatch(profileAction());
            await dispatch(getVliegerData());
            await dispatch(dispatch({ type: 'RESET_MATERIAAL_STATE' }))
        },

        async updateBaseIfRequired() {
            updateIfNeeded(flights.lastUpdatedBaseData, () =>
                dispatch(getBaseData())
            );
        }
    }
}

export function usePermission() {
    const persist = useSelector((state) => state.persist);

    return {
        can(role) {
            return persist.profile.app_permissions.includes(role);
        }
    }
}