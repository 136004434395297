import React from "react";
import { $t } from "~/i18n.js";
import "./RecencyBaro.css";

export const calculateRelativeCurrency = (starts, hours) => {
  // returns relative Currency, which can be used to multiply against plot width to plot on chart
  const maxHours = 30;
  const maxStarts = 42;

  // to plot, maximize the points to max hours
  // you can't compensate low starts with much hours, and visa versa
  const calcHours = Math.min(hours, maxHours);
  const calcStarts = Math.min(starts, maxStarts);

  // plot both values on a relative axis from 0 to max
  const relHours = calcHours / maxHours;
  const relStarts = calcStarts / maxStarts;

  // currency is the average of these two points, on a scale between 0 and 1
  const relCurrency = (relHours + relStarts) / 2;

  return { relHours, relStarts, relCurrency, maxHours, maxStarts };
};

const currencyToColorLookup = {
  groen: "#79DE79",
  geel: "#FCFC99",
  rood: "#FB6962",
  orange: "#EAB256",
};

const barColorLookup = {
  groen: "#79DE79",
  geel: "#FCFC99",
  rood: "#FB6962",
  orange: "#EAB256",
};

export function RecencyBaro({ starts = 0, hours = 0, currency = "" } = {}) {
  // add labels on max for starts/hours
  const hourLabels = [10, 20, 30];
  const startLabels = [15, 30, 40];

  const { relHours, relStarts, relCurrency, maxHours, maxStarts } =
    calculateRelativeCurrency(starts, hours);

  // transformations to the width of the chart
  const maxWidth = 300; // pixels of the chart
  const height = 30;
  const plottedHours = relHours * maxWidth;
  const plottedStarts = relStarts * maxWidth;
  const plottedCurrency = relCurrency * maxWidth;

  // for the label, clamp the value to ensure that the label does not drop of the right side
  const clamp = (num = 0, min = 0, max = 100) =>
    Math.min(Math.max(num, min), max);

  const color = currencyToColorLookup[currency] || "black";

  return (
    <svg viewBox={`0 0 ${maxWidth + 20} ${height + 60}`} width="200">
      <g transform="translate(10 30)">
        <rect
          x="50"
          width="200"
          height={height}
          fill={barColorLookup.geel}
          rx="5"
          className="RecencyBaro__Bar RecencyBaro__Bar--caution"
        />
        <rect
          x="200"
          width="100"
          height={height}
          fill={barColorLookup.groen}
          rx="5"
          className="RecencyBaro__Bar RecencyBaro__Bar--good"
        />
        <rect
          x="0"
          width="100"
          height={height}
          fill={barColorLookup.rood}
          rx="5"
          className="RecencyBaro__Bar RecencyBaro__Bar--rusty"
        />

        <line
          x1={plottedHours}
          y1="0"
          x2={plottedStarts}
          y2={height}
          stroke="black"
        />

        <circle
          cx={plottedCurrency}
          cy={height / 2}
          r="7"
          stroke="black"
          fill={color}
        />
        <circle cx={plottedHours} cy="0" r="2" fill="black" />
        <circle cx={plottedStarts} cy={height} r="2" fill="black" />
      </g>

      <g transform="translate(10 25)">
        <text
          x={clamp(plottedHours, 20, 280)}
          y="-10"
          textAnchor="middle"
          className="RecencyBaro__Value"
        >
          {$t("${hours} uren", { hours })}
        </text>
        <text x={0} textAnchor="middle" className="RecencyBaro__Labels">
          0
        </text>

        {hourLabels.map((val, idx) => (
          <text
            key={idx}
            x={(val / maxHours) * maxWidth}
            textAnchor="middle"
            className="RecencyBaro__Labels"
          >
            {val}
          </text>
        ))}
      </g>
      <g transform={`translate(10 ${height + 40})`}>
        <text
          x={clamp(plottedStarts, 20, 280)}
          y="15"
          textAnchor="middle"
          className="RecencyBaro__Value"
        >
          {$t("${starts} starts", { starts })}
        </text>
        <text x={0} textAnchor="middle" className="RecencyBaro__Labels">
          0
        </text>

        {startLabels.map((val, idx) => (
          <text
            key={idx}
            x={(val / maxStarts) * maxWidth}
            textAnchor="middle"
            className="RecencyBaro__Labels"
          >
            {val}
          </text>
        ))}
      </g>
    </svg>
  );
}
