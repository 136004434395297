import React, { useState } from 'react'
import { Button } from 'antd'
import { QuestionOutlined } from '@ant-design/icons'
import { HelpDrawer } from "./helpDrawer.jsx";
import './help.css'

export const HelpButton = ({ title, subtitle, helpDocs }) => {
  const [showHelp, setShowHelp] = useState(false)

  return (
    <div>

      <HelpDrawer
        title={title}
        subtitle={subtitle}
        setShowHelp={setShowHelp}
        showHelp={showHelp}
        helpDocs={helpDocs}
      />

      <Button
        className='clickable' style={{ marginLeft: 6 }}
        shape='circle'
        onClick={() => setShowHelp(true)}
      >
        <QuestionOutlined />
      </Button>
    </div>
  )
}
