import { Alert } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

// Add in comment so that code is run during translation code scan
export const permissionDescription = {
  // $t('app_vliegt')
  app_vliegt: [
    "De basis permissie van een actief lid die vliegt", // $t('De basis permissie van een actief lid die vliegt'),
    "Actieve gebruikers met deze permissie vallen in het prijsplan", // $t('Actieve gebruikers met deze permissie vallen in het prijsplan'),
  ],
  // $t('app_zichzelf_aanmelden')
  app_zichzelf_aanmelden: [
    "Kan zichzelf voor vliegdagen aanmelden", // $t('Kan zichzelf voor vliegdagen aanmelden'),
  ],
  // $t('app_iedereen_aanmelden')
  app_iedereen_aanmelden: [
    "Kan alle leden aanmelden voor vliegdagen", // $t('Kan alle leden aanmelden voor vliegdagen'),
  ],
  // $t('app_wijzig_reservering')
  app_wijzig_reservering: [
    "Mag reserveringen voor vliegtuigen toekennen en afwijzen", // $t('Mag reserveringen voor vliegtuigen toekennen en afwijzen'),
  ],
  // $t('app_wijzig_vluchten')
  app_wijzig_vluchten: [
    "Mag vluchten wijzigen nadat deze gesloten zijn", // $t('Mag vluchten wijzigen nadat deze gesloten zijn'),
  ],
  // $t('app_wijzig_start')
  app_wijzig_start: [
    "Kan tijdens de vliegdag de startadministratie voeren, en hoort doorgaans alleen bij de startwagen account", // $t('Kan tijdens de vliegdag de startadministratie voeren, en hoort doorgaans alleen bij de startwagen account'),
    "Let op, zorg ervoor dat niet teveel leden tegelijkertijd de administratie doen tijdens de dag", // $t('Let op, zorg ervoor dat niet teveel leden tegelijkertijd de administratie doen tijdens de dag'),
  ],
  // $t('app_wijzig_sleep')
  app_wijzig_sleep: [
    "Kan de sleepadministratie voeren tijdens de vluchtdag", // $t('Kan de sleepadministratie voeren tijdens de vluchtdag'),
  ],

  // $t('app_wijzig_rooster')
  app_wijzig_rooster: [
    "Kan roosters aanmaken en aanpassen", // $t('Kan roosters aanmaken en aanpassen'),
  ],

  // $t('app_wijzig_dagen')
  app_wijzig_dagen: [
    "Kan dagen aanmaken en wijzigen", // $t('Kan dagen aanmaken en wijzigen'),
  ],

  // $t('app_wijzig_melding')
  app_wijzig_melding: [
    "Kan alle technische meldingen (defecten en klachten) wijzigen en verwijderen", // $t('Kan alle technische meldingen (defecten en klachten) wijzigen en verwijderen'),
  ],
  // $t('app_wijzig_melding_vliegend')
  app_wijzig_melding_vliegend: [
    "Kan technische meldingen voor vliegtuigen wijzigingen en verwijderen", // $t('Kan technische meldingen voor vliegtuigen wijzigingen en verwijderen'),
  ],
  // $t('app_wijzig_melding_rollend')
  app_wijzig_melding_rollend: [
    "Kan technische meldingen voor rollend materiaal wijzigen en verwijderen", // $t('Kan technische meldingen voor rollend materiaal wijzigen en verwijderen'),
  ],
  // $t('app_goedkeuren_uren')
  app_goedkeuren_uren: [
    "Mag winteruren goedkeuren van leden", // $t('Mag winteruren goedkeuren van leden'),
  ],
  // $t('app_wijzig_winteruren')
  app_wijzig_winteruren: [
    "Mag de winteruren aanpassen, zoals periodes aanmaken", // $t('Mag de winteruren aanpassen, zoals periodes aanmaken'),
    "Mag winteruren goedkeuren van leden", // $t('Mag winteruren goedkeuren van leden'),
  ],
  // $t('app_wijzig_material')
  app_wijzig_material: [
    "Kan materiaal toevoegen, wijzigen en verwijderen", // $t('Kan materiaal toevoegen, wijzigen en verwijderen'),
  ],
  // $t('app_wijzig_documenten')
  app_wijzig_documenten: [
    "Mag documenten aanpassen en wijzigen, alleen voor de groepen waar hij zelf lid van is", // $t('Mag documenten aanpassen en wijzigen, alleen voor de groepen waar hij zelf lid van is'),
  ],

  // $t('app_ziet_dto')
  app_ziet_dto: [
    "Kan alle DTO informatie van een lid zien, zoals de notities en DTO gegevens", // $t('Kan alle DTO informatie van een lid zien, zoals de notities en DTO gegevens'),
  ],
  // $t('app_ziet_alle_data')
  app_ziet_alle_data: [
    "Ziet alle data van gebruikers die dit hebben afgeschermd, de prive settings", // $t('Ziet alle data van gebruikers die dit hebben afgeschermd, de prive settings'),
  ],
  // $t('app_uitgifte_bevoegheid')
  app_uitgifte_bevoegheid: [
    "Mag bevoegdheden uitgeven", // $t('Mag bevoegdheden uitgeven'),
  ],

  // $t('app_ziet_staff')
  app_ziet_staff: [
    "Ziet de admin pagina en kan de settings van de club beheren", // $t('Ziet de admin pagina en kan de settings van de club beheren'),
  ],
  // $t('app_wijzig_leden')
  app_wijzig_leden: [
    "Mag de leden administratie doen in de club admin, zoals toevoegen en verwijderen van leden", // $t('Mag de leden administratie doen in de club admin, zoals toevoegen en verwijderen van leden'),
  ],
  // $t('app_wijzig_dto')
  app_wijzig_dto: [
    "Mag de DTO administratie doen in de club admin, zoals toevoegen en verwijderen van DTO programmas", // $t('Mag de DTO administratie doen in de club admin, zoals toevoegen en verwijderen van DTO programmas'),
    "Dit is een permissie die de Head of Training nodig heeft", // $t('Dit is een permissie die de Head of Training nodig heeft'),
  ],
  // $t('app_ziet_emaillijst')
  app_ziet_emaillijst: [
    "Kan email mailings sturen naar leden", // $t('Kan email mailings sturen naar leden'),
  ],
  // $t('app_wijzig_findings')
  app_wijzig_findings: [
    "Kan bij de bevindingen module en deze toevoegen, aanpassen en verwijderen", // $t('Kan bij de bevindingen module en deze toevoegen, aanpassen en verwijderen'),
  ],

  // $t('app_wijzig_news')
  app_wijzig_news: [
    "Kan newsbulletins maken en publiceren", // $t('Kan newsbulletins maken en publiceren'),
  ],
};

// export all keys
export const permissions = Object.keys(permissionDescription);

// Translate on the fly
export const translateUserPermission = (permission) => $t(permission);

export const PermissionComponent = ({ permission, showHelp = false }) => (
  <div>
    <p>{translateUserPermission(permission)}</p>
    {showHelp && (
      <Alert
        style={{ marginBottom: 12 }}
        info="info"
        message={
          <div>
            {permissionDescription[permission]?.map((b) => (
              <p key={b}>- {$t(b)} </p>
            ))}
          </div>
        }
      ></Alert>
    )}
  </div>
);
