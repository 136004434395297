import { CheckCircleFilled } from "@ant-design/icons";
import { Checkbox, Input, Modal, Radio, Timeline } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { $t } from "~/i18n.js";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";
import { colors } from "~/theme/colors.js";

export const ModalCompleteWorkorder = ({
  visible,
  workorder,
  currentMaterial,
  closeModal,
}) => {
  const dispatch = useDispatch();
  
  const [signoff_remaining_tasks, set_signoff_remaining_tasks] = useState(true);
  const [limitations, setLimitations] = useState("");
  const [meldingenOK, setMeldingen] = useState(false);
  const [maintenanceOK, setMaintenance] = useState(false);

  const hasMeldingen = workorder?.list_meldingen?.length > 0;
  const hasMaintenance =
    workorder.category !== "arc" && workorder?.list_maintenance?.length > 0;

  // tasks completed can be set on workorder, or found in currentMaterial if tasks are completed inbetween
  const deferredTasks =
    workorder.n_tasks -
    (currentMaterial.n_tasks_completed || workorder.n_tasks_completed);
  const limitationMissing = limitations === "" && deferredTasks > 0;

  const disabled =
    (hasMeldingen && !meldingenOK) ||
    (hasMaintenance && !maintenanceOK) ||
    (deferredTasks > 0 && signoff_remaining_tasks === false && !limitations);

  const [saving, setSaving] = useState(false);
  const triggerCompleteWorkorder = async () => {
    setSaving(true);
    const payload = {
      id: workorder.id,
      action: "complete",
      limitations,
      signoff_remaining_tasks,
    };
    const res = await dispatch(
      saveMaterialTab({
        activeTab: "workorders",
        materialID: currentMaterial.id,
        payload,
      })
    );
    if (res) {
      closeModal();
      Modal.success({
        title: $t("Afgerond"),
        content: $t("De workorder is afgerond."),
      });
    }
    setSaving(false);
  };

  if (!visible) return null;
  return (
    <Modal
      title={$t("Rond workorder af")}
      confirmLoading={saving}
      open={visible}
      onCancel={closeModal}
      okButtonProps={{ disabled }}
      onOk={triggerCompleteWorkorder}
    >
      <Timeline>
        {deferredTasks > 0 && (
          <Timeline.Item
            color={
              signoff_remaining_tasks || !limitationMissing ? "green" : "blue"
            }
          >
            <p className="subtitle">
              {$t("Er zijn ${deferredTasks} onvoltooide taken", {
                deferredTasks,
              })}
            </p>

            <Radio.Group
              onChange={(e) => set_signoff_remaining_tasks(e.target.value)}
              value={signoff_remaining_tasks}
              className="column"
              style={{ alignItems: "flex-start" }}
            >
              <Radio value>
                <span className="bold">{$t("Voltooi")}</span>{" "}
                {$t("openstaande taken")}{" "}
                <HelpBubble
                  content={$t(
                    "Alle openstaande taken zullen door jou worden afgetekend, en dit is zichtbaar op de CRS."
                  )}
                />
              </Radio>
              <Radio value={false}>
                <span className="bold">Defer</span> {$t("openstaande taken")}{" "}
                <HelpBubble
                  content={$t(
                    'Alle openstaande kunnen worden "deferred", wat betekend dat ze niet uitgevoerd zijn. Dit wordt expliciet op de CRS vermeld.'
                  )}
                />
              </Radio>
            </Radio.Group>

            {!signoff_remaining_tasks && (
              <>
                <p className="spacer">
                  {$t("Voeg (mogelijke) operationele limitaties toe")}{" "}
                  <HelpBubble
                    content={$t(
                      "Wanneer onderhoud niet kan worden opgeleverd, mag een CRS alsnog worden uitgegeven binnen de toegestaande vliegtuig limieten. Mogelijk dat dit invloed heeft op de operationele limitaties. Deze kan je hier toevoegen."
                    )}
                  />
                  :
                </p>
                <Input.TextArea
                  rows={3}
                  style={{ width: 300 }}
                  value={limitations}
                  status={limitationMissing && "error"}
                  onChange={(event) => setLimitations(event.target.value)}
                />
                <p className="alert" style={{ minHeight: 22 }}>
                  {limitationMissing
                    ? "Voeg limitaties toe toe of vermeld dat er geen limitaties zijn"
                    : " "}
                </p>
              </>
            )}
          </Timeline.Item>
        )}

        {hasMaintenance && (
          <Timeline.Item color={maintenanceOK ? "green" : "blue"}>
            <p className="bold subtitle">{$t("Maintenance")}</p>
            <Checkbox
              checked={maintenanceOK}
              onChange={(e) => setMaintenance(e.target.checked)}
            >
              <span>
                {" "}
                {$t(
                  "De volgende programmas zullen een nieuw termijn ontvangen."
                )}
              </span>
            </Checkbox>

            <div style={{ marginLeft: 24 }}>
              {workorder?.list_maintenance?.map((m, key) => (
                <p key={key}>
                  <CheckCircleFilled style={{ color: colors.green }} /> {m.name}
                </p>
              ))}
            </div>
          </Timeline.Item>
        )}

        {hasMeldingen && (
          <Timeline.Item color={meldingenOK ? "green" : "blue"}>
            <p className="subtitle bold">{$t("Meldingen")}</p>
            <Checkbox
              checked={meldingenOK}
              onChange={(e) => setMeldingen(e.target.checked)}
            >
              <span>
                {" "}
                {$t("De volgende meldingen zullen worden afgesloten.")}
              </span>
            </Checkbox>

            <div style={{ marginLeft: 24 }}>
              {workorder?.list_meldingen?.map((m, key) => (
                <p key={key}>
                  <CheckCircleFilled style={{ color: colors.green }} /> {m.name}
                </p>
              ))}
            </div>
          </Timeline.Item>
        )}
      </Timeline>
    </Modal>
  );
};
