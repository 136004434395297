import dayjs from "dayjs";
import React, { useState } from "react";
import useMedia from "use-media";
import { $t } from "~/i18n.js";

import {
  AppstoreAddOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Switch, Table, Tag, Tooltip } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint.js";
import { useDispatch, useSelector } from "react-redux";
import { api } from "~/lib/api.js";
import { generate_uuid } from "~/lib/helpers.js";
import { _parseDate, parseMinutes, toLocaleDate } from "~/lib/localize.js";
import { BulkImport } from "~/pages/profile/bulkimport.jsx";
import { flightsActions, persistActions } from "~/redux/actions.js";
import { emptyFlight } from "~/redux/flights/flights.state.js";
import { FlightPrivateModal } from "../flight/FlightPrivateModal.jsx";
import { FlightViewModal } from "../flight/FlightViewModal.jsx";

import {
  flightStatsAggregatedColumns,
  flightStatsColumns,
} from "./flightStats.columns.jsx";

export const FlightTable = ({ loadNewFlights, loading, filters }) => {
  const persist = useSelector((state) => state.persist);
  const flights = useSelector((state) => state.flights);
  const dispatch = useDispatch();

  const updateFlight = (data) => dispatch(flightsActions.updateFlight(data));
  const setPreferences = (data) =>
    dispatch(persistActions.setPreferences(data));

  const setPage = (newPage) => {
    dispatch({
      type: "SET_PAGE",
      page: newPage,
    });
    loadNewFlights(newPage);
  };
  const currentPage = flights.page || 1;
  const count = flights.totals?.count;

  const isWide = useMedia({ minWidth: "1350px" }, true);

  const [flight, openFlight] = useState(null);
  const [privateFlight, setPrivateFlight] = useState(null);
  const [openImportModal, setOpenImportModal] = useState(false);

  const [datum, setDatum] = useState();

  const [fileLoading, setFileLoading] = useState("");
  const downloadFile = async (format) => {
    if (fileLoading !== "") return;

    const query_string = new URLSearchParams();
    Object.entries(filters).map(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        query_string.append(key, value);
      }
    });

    setFileLoading(format);
    await api.open_file(
      "flightstats/export_history.json?" +
        query_string.toString() +
        "&personal=True&format=" +
        format,
      `${$t("vluchten")} ${persist.profile.name} - ${toLocaleDate(
        new Date()
      )}.${format}`
    );
    setFileLoading("");
  };

  // Facilitate filter on day and aggregation on day level
  let flights_data = flights.allFlights;
  if (datum) {
    flights_data = flights_data.filter((f) => f.datum === datum);
  } else if (persist.aggregate_starts) {
    // perhaps we need to aggregate on day level
    const reduced = flights_data.reduce((all, flight) => {
      const key =
        flight.datum +
        flight.registratie +
        flight.start_methode +
        flight.vertrek_vliegveld;
      if (!all[key]) {
        all[key] = {
          uuid: flight.uuid,
          datum: flight.datum,
          vertrek_vliegveld: flight.vertrek_vliegveld,
          callsign: flight.callsign,
          registratie: flight.registratie,
          type: flight.type,
          start_methode: flight.start_methode,
          vluchtduur: 0,
          starts: 0,
        };
      }
      all[key].vluchtduur += flight.vluchtduur;
      all[key].starts += flight.starts;
      return all;
    }, {});
    // then we return the dictionary to an array
    flights_data = Object.keys(reduced).reduce((all, key) => {
      all.push(reduced[key]);
      return all;
    }, []);
  }

  const columns =
    persist.aggregate_starts && !datum
      ? flightStatsAggregatedColumns()
      : flightStatsColumns(persist.profile);

  const screens = useBreakpoint();
  const summary = () => {
    const responsive_columns = columns.filter((item) => item.responsive).length;
    const added_cells = 4; // Amount of cells below that have colSpan=1
    const colSpan =
      columns.length - added_cells - (screens.md ? 0 : responsive_columns);
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={colSpan}>
            <p>{$t("Totalen over alle pagina's")}</p>
          </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={1}>
            <p style={{ whiteSpace: "noWrap" }}>
              {flights?.totals?.total_pic_starts}
            </p>
          </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={1}>
            <p style={{ whiteSpace: "noWrap" }}>
              + {flights?.totals?.total_dbo_starts}
            </p>
          </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={1}>=</Table.Summary.Cell>
          <Table.Summary.Cell colSpan={1}>
            <p style={{ whiteSpace: "noWrap" }}>
              {flights?.totals?.total_starts}
            </p>{" "}
            {$t("starts")}
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={colSpan} />
          <Table.Summary.Cell colSpan={1}>
            <p style={{ whiteSpace: "noWrap" }}>
              {parseMinutes(flights?.totals?.total_pic_time) ||
                flights?.totals?.total_pic_time}
            </p>
          </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={1}>
            <p style={{ whiteSpace: "noWrap" }}>
              +{" "}
              {parseMinutes(flights?.totals?.total_dbo_time) ||
                flights?.totals?.total_dbo_time}
            </p>
          </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={1}>=</Table.Summary.Cell>
          <Table.Summary.Cell colSpan={1}>
            <p style={{ whiteSpace: "noWrap" }}>
              {parseMinutes(flights?.totals?.total_time)}
            </p>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <>
      <BulkImport
        profile={persist.profile}
        showModal={openImportModal}
        closeModal={() => setOpenImportModal(false)}
      />

      <FlightPrivateModal
        pilot={persist.profile}
        flight={privateFlight}
        onClose={() => setPrivateFlight(null)}
        onUpdateFlight={() => setPage(1)}
      />

      <FlightViewModal
        onClose={() => openFlight(null)}
        flight={flight}
        currentUser={persist.profile}
        updateFlight={updateFlight}
        onUpdateFlight={() => setPage(1)}
      />

      <div
        className="row"
        style={{ alignContent: "center", flexWrap: "wrap", gap: 12 }}
      >
        <Tooltip
          placement="top"
          title={$t("Voeg vlucht toe buiten clubverband")}
        >
          <Button
            icon={<PlusOutlined />}
            size="small"
            onClick={() =>
              setPrivateFlight({
                ...emptyFlight,
                datum: dayjs(),
                volg_nummer: 1,
                uuid: generate_uuid(),
                rol: "gezagvoerder",
                gezagvoerder_id: persist.profile.id,
                gezagvoerder_naam: persist.profile.name,
                is_prive: true,
              })
            }
          >
            {$t("Voeg vlucht toe")}
          </Button>
        </Tooltip>

        <Button
          icon={<ImportOutlined />}
          size="small"
          onClick={() => setOpenImportModal(true)}
        >
          {$t("Importeer")}
        </Button>

        <Button
          icon={<FileTextOutlined />}
          size="small"
          loading={fileLoading === "csv"}
          onClick={() => downloadFile("csv")}
        >
          CSV
        </Button>

        <Button
          icon={<FileExcelOutlined />}
          size="small"
          loading={fileLoading === "xlsx"}
          onClick={() => downloadFile("xlsx")}
        >
          Excel
        </Button>

        {persist?.club?.schema_name === "acvz" && (
          <Button
            size="small"
            loading={loading}
            icon={<AppstoreAddOutlined />}
            href={`https://zweefstats.nl/load/zweefapp?club=${
              persist?.club?.schema_name
            }&token=${api.getToken()}`}
            target="_blank"
          >
            {$t("ZweefStats")}
          </Button>
        )}

        <p>
          {$t("Per dag")}:
          <Switch
            style={{ marginLeft: 6 }}
            checked={persist.aggregate_starts}
            onChange={(newValue) =>
              setPreferences({ aggregate_starts: newValue })
            }
          />
        </p>

        {datum && (
          <Tag closable onClose={() => setDatum(null)}>
            {_parseDate(datum)}
          </Tag>
        )}
      </div>

      <Table
        style={{
          marginTop: 12,
          width: "97vw",
          overflowX: isWide ? null : "auto",
          cursor: "pointer",
        }}
        pagination={{
          position: ["bottomLeft"],
          pageSize: 50,
          total: count,
          current: currentPage,
          onChange: setPage,
          hideOnSinglePage: false,
        }}
        size="small"
        rowKey="uuid"
        dataSource={flights_data}
        columns={columns}
        summary={persist.aggregate_starts && !datum ? null : summary}
        loading={loading}
        onRow={(flight) => {
          return {
            onClick: () => {
              if (persist.aggregate_starts && !datum) {
                setDatum(flight.datum);
                return;
              }

              if (flight.is_prive) {
                setPrivateFlight({
                  ...flight,
                  rol:
                    flight.gezagvoerder_id === persist.profile.id
                      ? "gezagvoerder"
                      : "tweede",
                  datum: dayjs(flight.datum, "YYYY-MM-DD"),
                });
              } else {
                openFlight(flight);
              }
            },
          };
        }}
      />
    </>
  );
};
