import { Button, List } from "antd";
import React, { useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { AddButton } from "~/components/buttons/AddButton.jsx";
import { $t } from "~/i18n.js";
import { DocumentTitle } from "./documentList.jsx";
import { DocumentSelectModal } from "./documentSelectModal.jsx";
import { MatDocumentModal } from "./sections/matDocumentModal.jsx";

export const DocumentUsedList = ({ documents, setDocuments }) => {
  const [openDocument, setOpenDocument] = useState(null);

  const [openCascader, setOpenCascader] = useState(false);

  const removeDocument = (document) => {
    setDocuments(documents.filter((doc) => doc.id !== document.id));
  };

  const addDocument = (document) => {
    setDocuments((documents || []).concat(document));
  };

  return (
    <>
      {openDocument && (
        <MatDocumentModal
          document={openDocument}
          closeModal={() => setOpenDocument(null)}
          readOnly={true}
        />
      )}

      {openCascader && (
        <DocumentSelectModal
          visible={openCascader}
          addDocument={addDocument}
          title={$t("Selecteer document")}
          onClose={() => setOpenCascader(false)}
          documents={documents}
        />
      )}

      <List
        header={
          <div className="row" style={{ justifyContent: "space-between" }}>
            <p className="bold label">{$t("Verbonden documenten")}</p>
            <AddButton onClick={() => setOpenCascader(true)} />
          </div>
        }
        style={{ maxWidth: 400 }}
        className="documentList"
        itemLayout="horizontal"
        dataSource={documents}
        size="small"
        locale={{ emptyText: $t("geen bestanden") }}
        renderItem={(document) => (
          <List.Item>
            <div
              className="row"
              style={{
                justifyContent: "space-between",
                minWidth: 200,
                width: "100%",
              }}
            >
              <div onClick={() => setOpenDocument(document)}>
                <DocumentTitle document={document} />
              </div>

              <Button
                danger
                icon={<CloseOutlined />}
                size="small"
                shape="circle"
                onClick={() => removeDocument(document)}
              />
            </div>
          </List.Item>
        )}
      />
    </>
  );
};
