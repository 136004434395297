export const checkFlightRecencyWarnings = (pic, flight) => {
  const { recency = {} } = pic || {};

  if (!recency) return false;

  const isNotValid =
    recency.spl === "invalid" ||
    recency.medical ||
    recency.starts < 15 ||
    recency.vliegduur < 300 ||
    recency.checks < 2 ||
    (recency.lieraantekening === "invalid" && flight.start_methode == "lier") ||
    (recency.sleepaantekening === "invalid" &&
      flight.start_methode == "sleep") ||
    (recency.zelfstartaantekening === "invalid" &&
      flight.start_methode == "zelf") ||
    (recency.tmgaantekening === "invalid" && flight.start_methode == "tmg") ||
    (recency["tmg-a-aantekening"] === "invalid" &&
      flight.start_methode == "tmg-a") ||
    (recency.bungeeaantekening === "invalid" &&
      flight.start_methode == "bungee") ||
    (recency.caraantekening === "invalid" && flight.start_methode == "car") ||
    (recency.fis === "invalid" && flight.is_fis);
  return isNotValid;
};
