import { Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Loader } from "~/components/loader/loader.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { parseMinutes } from "~/lib/localize.js";
import "./overviewFlights.css";

export const TotalTypes = ({ user_id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetchData = async (days) => {
    try {
      setLoading(true);
      const response = await api.get(
        `gegevens/${user_id}/flight_summary.json?days=${days}`
      );
      setData(response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching flight summary:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(183);
  }, [user_id]);

  return (
    <div style={{paddingBottom: 12}}>
      <div className="row" style={{ gap: 12 }}>
        <p className="subtitle" style={{ marginBottom: 0 }}>
          {$t("Overzicht")}
        </p>
        <Select
          defaultValue="183"
          size="small"
          disabled={loading}
          style={{ width: 150 }}
          onChange={(e) => fetchData(e)}
        >
          <Select.Option value="183">{$t("6 maanden")}</Select.Option>
          <Select.Option value="365">{$t("1 jaar")}</Select.Option>
          <Select.Option value="730">{$t("2 jaar")}</Select.Option>
          <Select.Option value="-1">{$t("Alles")}</Select.Option>
        </Select>
      </div>
      {loading && <Loader />}

      {!loading && (
        <div style={{ maxHeight: 300, overflowY: "auto"}}>
          <TotalTypeTable
            data={data.gezagvoerder}
            title={$t("Als gezagvoerder")}
          />
          <TotalTypeTable
            data={data.tweede}
            title={$t("Als tweede inzittende")}
          />
        </div>
      )}
    </div>
  );
};

export const TotalTypeTable = ({ data, title }) => {
  if (!data) return null;

  // generate a lookup for the types and methods, so that we can create a
  // cross table with types per row, and date/methods per start methode
  const type_lookup = {};
  const method_lookup = {};

  data.forEach((row) => {
    // fill out lookups with blank dates
    const desc = row.type || $t("Anders");

    const date = dayjs(row.laatste_vlucht);

    if (!type_lookup[desc]) type_lookup[desc] = { date };
    if (!method_lookup[row.start_methode])
      method_lookup[row.start_methode] = date;

    // check to see if this date is later then prepopulated, if so overrule
    if (date.isAfter(type_lookup[desc].date)) type_lookup[desc].date = date;
    if (date.isAfter(method_lookup[row.start_methode]))
      method_lookup[row.start_methode] = date;

    // add in the number of flights to create a cross table lookup
    type_lookup[desc][row.start_methode] = {
      v: row.vluchten,
      t: row.vluchtduur,
    };
  });

  // and now order the methods and type lookups
  const types = Object.keys(type_lookup).sort();
  const methods = Object.keys(method_lookup).sort();

  return (
    <div>
      <p className="subtitle">{title}</p>

      {!data || data.length === 0 || !types || types.length === 0 ? (
        <p className="gray">{$t("Geen vluchten")}</p>
      ) : (
        <table className="dataBlock">
          <tbody>
            <tr style={{ backgroundColor: "#F8F9FA" }}>
              <th>{$t("Kist")}</th>
              <th>{$t("Datum")}</th>
              {methods.map((method, idx) => (
                <th key={idx}>{$t(method)}</th>
              ))}
            </tr>

            <tr style={{ borderBottom: "1pt solid gray" }}>
              <th />
              <th />
              {methods.map((method, idx) => (
                <th style={{ fontWeight: 500 }} key={idx}>
                  {method_lookup[method].format("DD-MM")}
                </th>
              ))}
            </tr>

            {types &&
              types.map((type, key) => (
                <tr key={key} className="bodyRow">
                  <td className="col">{type}</td>
                  <td className="col">
                    {type_lookup[type] && type_lookup[type].date
                      ? type_lookup[type].date.format("DD-MM-YY")
                      : ""}
                  </td>

                  {methods.map((method, idx) => (
                    <td className="col" key={idx}>
                      {type_lookup[type] && type_lookup[type][method] ? (
                        <span>
                          <span style={{ marginRight: 9 }}>
                            {type_lookup[type][method].v}{" "}
                          </span>{" "}
                          {parseMinutes(type_lookup[type][method].t)}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
