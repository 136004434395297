import { Button, List, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DateFormat } from "~/components/date/DateFormat.jsx";
import DateTimeDiff from "~/components/date/DateTimeDiff.jsx";
import { GCard } from "~/components/GCard.jsx";
import { $t } from "~/i18n.js";

export const DutiesWidget = () => {
  const days = useSelector((state) => state.days);

  const items = days.allDays
    .map((day) => ({ ...day, diensten: days.mijn_diensten[day.dag_id] }))
    .filter((day) => day.can_aanmeld && day.diensten)
    .sort((a, b) => a.date - b.date);

  // only show block if user has diensten
  if (!items.length) return null;

  return (
    <GCard
      title={$t("Diensten")}
      style={{ width: "100%", height: "100%" }}
      loading={days.isPending}
      extra={
        <Button type="link">
          <Link to="/register">
            {$t("Bekijk alles")} ({items.length})
          </Link>
        </Button>
      }
    >
      <List
        dataSource={items.slice(0, 5)}
        renderItem={(item) => (
          <Link to={`/register/${item.dag_id}`}>
            <List.Item className="clickable">
              <List.Item.Meta
                title={
                  <>
                    <span>
                      <DateFormat date={item.datum} /> {`${item.briefing_tijd}`}
                      ,
                      <span style={{ marginLeft: 6 }}>
                        <DateTimeDiff value={item.datum} />
                      </span>
                    </span>
                  </>
                }
                description={
                  <>
                    <Tag>{item.type_dag}</Tag>

                    {item.diensten.map((dienst, key) => (
                      <Tag key={key} color="geekblue">
                        {dienst.rooster_name}
                      </Tag>
                    ))}

                    <span style={{ marginLeft: 4 }}>
                      {item.titel.slice(0, 15)}
                    </span>
                  </>
                }
              />
            </List.Item>
          </Link>
        )}
      />
    </GCard>
  );
};
