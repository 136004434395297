import React from 'react'
import { $t } from '~/i18n.js'

import { Tooltip } from 'antd'
import { DataCard } from '~/components/dataCards/dataCard.jsx'

export const CardStart = ({ club, profile, gegevens, saveData, type, message }) => {
  // automatically start
  const saveForm = async () => saveData()

  return (
    <DataCard title={$t('Start')} onSave={saveForm} type={type} buttonTitle={$t('Start')}>

      <p className='bold large spacer'>{$t('Is je profiel nog up to date?')}</p>

      <p className='spacer'>{$t('Als zweefvliegclub willen we dat de administratie ten behoeve van vliegactiviteiten sluitend en ondersteunend is voor een soepel en veilig vliegbedrijf.')}</p>

      <p>{$t('Zou jij het volgende kunnen')} <Tooltip placement='top' title={message}> {$t('verifiëren?')}</Tooltip>
      </p>

      <ul>
        <li>{profile.avatar ? $t('Is je profielfoto recent en helder?') : $t('Je mist een profielfoto')}</li>
        <li>{(gegevens.medical_valid_to && new Date(gegevens.medical_valid_to) > new Date(Date.now() + 30 * 24 * 60 * 60 * 1000))
          ? $t('Is je medical nog geldig?')
          : <span>{$t('Je medical is')} <span className='bold'>{$t('niet')}</span> {$t('meer geldig of verloopt binnen 30 dagen, kan je deze bijwerken?')}</span>}
        </li>
        <li>{(profile.phone ? $t('Is je telefoonnummer nog geldig?') : $t('We missen je telefoonnummer'))}</li>
        <li>{(gegevens.address1 ? $t('Is je woonadres nog geldig?') : $t('We missen je woonadres'))}</li>
        <li>{(gegevens.emergency_contact ? $t('Is je noodcontact nog geldig?') : $t('We missen je contactpersoon in geval van nood'))}</li>
        {club.has_bank && club.enforce_bank && !profile.iban && <li>{$t('We missen je')} <span className='bold'>{$t('bankrekeningnummer')}</span></li>}
      </ul>
    </DataCard>
  )
}
