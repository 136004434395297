import { Divider } from "antd";
import React from "react";
import { DescriptionRow } from "~/components/descriptionRow/descriptionRow.jsx";
import { $t } from "~/i18n.js";

export const CardRollen = ({
  aanmeldingen,
  groups_aanmeld,
  group_name_lookup,
}) => {
  return (
    <div>
      <DescriptionRow label={$t("Aanmeldingen")}>
        {aanmeldingen.rollen.vliegers}
      </DescriptionRow>

      {aanmeldingen.rollen.nieuw > 0 && (
        <DescriptionRow label={$t("Nieuwe leden")}>
          {aanmeldingen.rollen.nieuw}
        </DescriptionRow>
      )}

      <Divider style={{ margin: "12px 0" }} />

      <DescriptionRow label={$t("Club vliegers")}>
        {aanmeldingen.rollen.club}
      </DescriptionRow>

      <DescriptionRow label={$t("sleepvlieger")}>
        {aanmeldingen.rollen.as_sleepvlieger}
      </DescriptionRow>

      {groups_aanmeld &&
        groups_aanmeld.length > 1 &&
        groups_aanmeld.split(",").map((g, idx) => (
          <DescriptionRow key={idx} label={group_name_lookup[g] || g}>
            {aanmeldingen.rollen[g] || 0}
          </DescriptionRow>
        ))}
    </div>
  );
};
