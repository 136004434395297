import { Radio } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const SectionSleepStartMethode = ({ flight, saveFlight, readOnly, loading }) => {
  return (
    <Radio.Group
      style={{ marginBottom: 12 }}
      size="small"
      buttonStyle="solid"
      disabled={readOnly || loading}
      value={flight?.start_methode}
      onChange={(e) => {
        console.log("e?", e);
        saveFlight({start_methode: e.target.value}, $t('Startmethode van sleepvliegtuig'));
      }}
    >
      {["tmg-a", "sep-a"].map((method, index) => (
        <Radio.Button key={index} value={method}>
          {$t(method)}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};


