import { Checkbox, Collapse, Space } from "antd";
import React, { useEffect, useState } from "react";
import { i18n } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { Loader } from "../loader/loader.jsx";
import { Chart } from "./chart.jsx";

const { Panel } = Collapse;

const maxFlights = 14000;
const maxMotor = 1000;
const chartOptions = {
  chart: {
    type: "line",
  },
  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Aug",
      "Sept",
      "Okt",
      "Nov",
      "Dec",
    ],
    max: 11,
  },
  yAxis: {
    title: false,
    labels: {
      formatter() {
        return new Intl.NumberFormat(i18n.locale, {
          maximumSignificantDigits: 3,
        }).format(this.value);
      },
    },
  },
  plotOptions: {
    series: {
      cumulative: true,
    },
  },
  tooltip: {
    headerFormat: "{point.key} {series.name}<br />",
    pointFormat: "<b>{point.y}</b> (+{point.flightops})",
  },
  series: null,
};

export const FlightOperations = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [rawData, setRawData] = useState(null);
  const [options, setOptions] = useState(null);
  const [showFlights, setShowFlights] = useState(true);
  const [showMotor, setShowMotor] = useState(true);
  const [year, setYear] = useState(null);
  const [remainingFlightOps, setRemainingFlightOps] = useState(null);
  const [remainingMotorOps, setRemainingMotorOps] = useState(null);

  const colors = ["#4572A7", "#AA4643", "#89A54E"];

  const filterData = (data) => {
    const modifiedData = data || rawData;

    if (!modifiedData || !["glider"]) return;

    // fix missing month data
    const keys = ["glider", "motor"];
    const years = Object.keys(modifiedData.glider);
    keys.forEach((key) => {
      if (!modifiedData[key][year]) return;
      years.forEach((year) => {
        if (!modifiedData[key]) return;
        let maxMonth = 12; // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        if (year === String(new Date().getFullYear())) {
          maxMonth = new Date().getMonth() + 1;
        }
        const monthRange = Array.from(
          { length: maxMonth },
          (_, index) => index + 1
        );

        const monthLookup = monthRange.reduce((all, month) => {
          all[month] = { month, y: 0 };
          return all;
        }, {});
        modifiedData[key][year].forEach(
          (data) => (monthLookup[data.month] = data)
        );

        // now update the modified data with the objects
        modifiedData[key][year] = Object.values(monthLookup);

        // and fix the last months so that the line keeps to the big one
        let prev = 0;
        modifiedData[key][year].forEach((data) => {
          if (data.y == 0) data.y = prev;
          prev = data.y;
        });
      });
    });

    const newOptions = { ...chartOptions };
    newOptions.series = [];
    newOptions.yAxis.plotLines = [];

    if (modifiedData && showMotor) {
      Object.entries(modifiedData.motor).forEach((item, index) => {
        newOptions.series.push({
          name: item[0],
          data_type: "motor",
          linkedTo: showFlights ? item[0] : null,
          color: colors[index],
          dashStyle: "shortdot",
          data: item[1],
          visible: false,
        });
      });

      newOptions.yAxis.plotLines.push({
        color: "blue",
        width: 2,
        value: maxMotor,
        zIndex: 3,
      });
      if (newOptions.series.length > 0) {
        const lastSeries = newOptions.series.slice(-1)[0];
        const lastIndex = lastSeries.data.length - 2;
        lastSeries.zoneAxis = 'x';
        lastSeries.zones = [
          {
            value: lastIndex,
          },
          {
            dashStyle: 'dot',
          },
        ];

        lastSeries.visible = true; // Only show last year by default
        newOptions.yAxis.tickInterval = 100;
        newOptions.yAxis.max = maxMotor + newOptions.yAxis.tickInterval;
      }
    }

    if (modifiedData && showFlights) {
      Object.entries(modifiedData.glider).forEach((item, index) => {
        newOptions.series.push({
          id: item[0],
          data_type: "glider",
          name: item[0],
          color: colors[index],
          data: item[1],
          visible: false,
        });
      });
      newOptions.yAxis.plotLines.push({
        color: "red",
        width: 2,
        value: maxFlights,
        zIndex: 3,
      });
      if (newOptions.series.length > 0) {
        const lastSeries = newOptions.series.slice(-1)[0];
        const lastIndex = lastSeries.data.length - 2;
        lastSeries.zoneAxis = 'x';
        lastSeries.zones = [
          {
            value: lastIndex,
          },
          {
            dashStyle: 'dot',
          },
        ];

        lastSeries.visible = true; // Only show last year by default
        newOptions.yAxis.tickInterval = 1000;
        newOptions.yAxis.max = maxFlights + newOptions.yAxis.tickInterval;
      }
    }

    setOptions(newOptions);
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const res = await api.get("flightops/");
      if (!res || !Object.keys(res).length) {
        return;
      }

      setRawData(res);
      filterData(res);

      if (
        !res.glider ||
        !Object.keys(res.glider).length > 0 ||
        !Object.keys(res.motor).length > 0
      ) {
        setIsLoading(false);
      } else {
        const current_year = Object.keys(res.glider).slice(-1)[0];
        setYear(current_year);
        setRemainingFlightOps(
          maxFlights - res.glider[current_year].slice(-1)[0].y
        );
        setRemainingMotorOps(maxMotor - res.motor[current_year].slice(-1)[0].y);

        setIsLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    filterData(rawData);
  }, [showFlights, showMotor]);

  return isLoading ? (
    <Loader style={{ margin: "auto" }} />
  ) : (
    <div>
      <Chart chartOptions={options} />

      <Space
        direction="horizontal"
        align="center"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Checkbox
          checked={showFlights}
          onChange={(e) => setShowFlights(e.target.checked)}
        >
          Vluchten
        </Checkbox>

        <Checkbox
          checked={showMotor}
          onChange={(e) => setShowMotor(e.target.checked)}
        >
          Gemotoriseerd
        </Checkbox>
      </Space>

      <h2>Resterend {year}</h2>
      <p>Vliegbewegingen: {remainingFlightOps}</p>
      <p>Motorbewegingen: {remainingMotorOps}</p>

      <Collapse style={{ marginTop: "20px" }}>
        <Panel header="Toelichting berekening">
          <p style={{ padding: "6px 10px" }}>
            In de berekening van bewegingen word gekeken naar vluchten vanuit
            Soesterberg. Zweefvliegtuigen die weggelierd worden tellen mee als 2
            bewegingen als die weer landen op Soesterberg, anders 1 beweging.
            Conform artikel 3.2.6.3.6 van luchtvaartregeling geldt dat het
            opslepen van een zweefvliegtuig twee vliegbewegingen inhoudt, zowel
            in het totaal als richting de gemotoriseerde bewegingen.
            Zelfstarters die weer op Soesterberg landen tellen twee keer mee in
            de totale bewegingen, en eenmaal richting de gemotoriseerde
            bewegingen. "Touring Motor Glider" (TMG) die weer op Soesterberg
            landen tellen twee keer mee in zowel de totale als gemotoriseerde
            bewegingen.
          </p>
        </Panel>
      </Collapse>
    </div>
  );
};
