import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const InputFileSingle = ({
  accept = "image/png, image/jpeg",
  id = "",
  value = "",
  onChange = (e) => {},
  includeDescription = false,
}) => {
  return (
    <Upload
      name="file"
      listType="picture"
      accept={accept}
      multiple={false}
      maxCount={1}
      beforeUpload={(file) => false}
      onChange={(data) => {
        onChange(
          data.fileList.length > 0 ? data.fileList[0].originFileObj : null
        );
      }}
    >
      <div className='column' style={{alignItems: 'flex-start', gap: 6}}>
        <Button size="small" icon={<UploadOutlined />}>
          {$t("Selecteer bestand")}
        </Button>

        {includeDescription && <p className="gray small">{$t("Advies is om een PDF of plaatje te uploaden. Microsoft office bestanden, zoals Word of Excel, openen vaak moeilijker in de browser.")}</p>}
      </div>
    </Upload>
  );
};
