import dayjs from "dayjs";

export const setAlertDate = (deadline, valid_through) => (!deadline || !valid_through) ? false : dayjs(valid_through, "YYYY-MM-DD").diff(dayjs(), "days") < deadline

export const minRecencyValidThrough = (items) => items.reduce((minDate, item) => {
    if (item.valid_through && item.deadline > 0 && (!minDate || new Date(item.valid_through) < new Date(minDate))) {
      return item.valid_through;
    }
    return minDate;
  }, null);

export const mapRecency = item => {
    return {
        ...item,
        isUsed: Number(item.value) || Number.isInteger(item.value) || item.hasDate,
        alert_date: setAlertDate(item.deadline, item.valid_through),
        is_valid: (
            item.hasDate
                ? item.date !== ""
                : (
                    item.min === 0 ? true : item.value >= item.min
                )
        )
    }
}
