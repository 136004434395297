import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea.js";
import React from "react";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { LengthValidator } from "~/components/form/formHelpers.js";
import { InputFileSingle } from "~/components/form/InputFileSingle.jsx";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";
import { $t } from "~/i18n.js";
import { ruleCheckLogoMaxSize } from "~/lib/helpers.js";

export const NameLogo = () => {
  return (
    <SettingCard title={$t("Naam en logo")}>
      <Form.Item
        label={$t("Afkorting clubnaam")}
        name="name"
        rules={[LengthValidator(100, true)]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={$t("Volledige clubnaam")}
        name="full_name"
        rules={[LengthValidator(200, true)]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="logo"
        label={$t("Logo")}
        extra={$t("Max ${size} bij ${size}px", { size: 200 })}
        rules={[ruleCheckLogoMaxSize]}
      >
        <InputFileSingle accept="image/png, image/jpeg" />
      </Form.Item>
    </SettingCard>
  );
};
