import attention from './attention.mp3'
import departed from './departed.mp3'
import inspection from './inspection.mp3'
import { $t, i18n } from '~/i18n.js'

const letterLookup = {
  A: 'Alpha',
  B: 'Bravo',
  C: 'Charlie',
  D: 'Delta',
  E: 'Echo',
  F: 'Foxtrot',
  G: 'Golf',
  H: 'Hotel',
  I: 'India',
  J: 'Juliet',
  K: 'Kilo',
  L: 'Lima',
  M: 'Mike',
  N: 'November',
  O: 'Oscar',
  P: 'Papa',
  Q: 'Quebec',
  R: 'Romeo',
  S: 'Sierra',
  T: 'Tango',
  U: 'Uniform',
  V: 'Victor',
  W: 'Whiskey',
  X: 'Xray',
  Y: 'Yankee',
  Z: 'Zulu'
}

const PAUZE = 100

export const text_to_speech = (callsign, message) => {

  let audio_file = null;
  if (message === 'STARTED') {
    return playAudio(departed)
  } else if (message === "DAILY_INSPECTION") {
    audio_file = inspection
    message = $t('is geinspecteerd')
  } else {
    audio_file = attention
    message = $t('is geland')
  }

  // transcribe callsign in case the it is M12
  const transcribed_message = []
  if (callsign.length < 4) {
    // add in each number seperately
    let callsign_msg = ' '
    Array.from(callsign).forEach(c => {
      callsign_msg += (c.toUpperCase() in letterLookup ? (letterLookup[c.toUpperCase()] + ' ') : c)
    })
    transcribed_message.push(callsign_msg)
  } else {
    transcribed_message.push(callsign)
  }

  // then add in a pause and the message
  transcribed_message.push(message)

  // choose good voice
  let voice = null

  // choose in NL for belgium
  const lng = i18n.language.startsWith('nl') ? 'nl-BE' : i18n.language

  window.speechSynthesis.getVoices().some(v => {
    if (v.lang.includes(lng) && v.localService) {
      voice = v
      return v
    }
    return false
  })

  const speak = (index) => {
    if (index >= transcribed_message.length) return null

    const talk = new SpeechSynthesisUtterance(transcribed_message[index])
    talk.lang = lng

    // setup voices
    if (voice) talk.voice = voice
    talk.rate = 0.8

    // and we ensure that by the end of this voice, we break and set the new one
    talk.onend = () => {
      setTimeout(() => speak(index + 1), PAUZE)
    }
    speechSynthesis.speak(talk)
  }

  // then we start the attention speach, after which we
  const audio = new Audio(audio_file)
  audio.onended = () => speak(0)
  audio.play().then(() => {
  }).catch(e => {
    // if error, e.g. on Safari with audio file, only do the speach
    speak(0)
  })
}

export const playAudio = (audioFile) => {
  const audio = new Audio(audioFile)
  try {
    audio.play()
  } catch (e) {
    console.log('Audio not allowed')
  }
}
