import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined, CloseOutlined, PlusOutlined, StopOutlined, ToolOutlined } from '@ant-design/icons'
import { Button, Collapse, Descriptions, Input, Select, Switch, Tag } from 'antd'
import React from 'react'
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { Icon, double_check } from '~/components/icon/icon.jsx'
import { $t } from '~/i18n.js'
import { generate_uuid } from '~/lib/helpers.js'
import { colors } from '~/theme/colors.js'
import { SwitchYesNo } from '~/components/form/SwitchYesNo.jsx'

const _baseNewTask = (tasks) => ({
  id: 'new_' + generate_uuid(),
  action: 'new',
  name: '',
  is_template: false,
  is_critical: false,
  is_applicable: true,
  is_altered: true,
  remark_required: false,
  file_required: false,
  restriction: 'all',
  beschrijving: '',
  order: (tasks ? (tasks[tasks.length - 1]?.order || 0) : 0) + 1,
  tasks: []
})

const restrictions_kist = [
  { value: 'all', label: 'Iedereen (geen beperkingen)' }, // $t('Iedereen (geen beperkingen)')
  { value: 'technicus_pom', label: 'POM technici' }, // $t('POM technici')
  { value: 'technicus_vliegend', label: 'Technici' }, // $t('Technici')
]

const restrictions_niet_vliegend = [
  { value: 'all', label: 'Iedereen (geen beperkingen)' }, // $t('Iedereen (geen beperkingen)')
  { value: 'technicus_rollend', label: 'Technici' } // $t('Technici')
]

const TaskEditCard = ({ category, task, saveTask, isDeleted, nonApplicable, level, isWorkorder }) => {
  const saveData = (newData, save_is_altered) => {
    // save altered only if task itself is a template
    saveTask({ id: task.id, action: task.action === 'new' ? 'new' : 'update', is_altered: save_is_altered && task.is_template ? true : task.is_altered, ...newData })
  }

  if (isDeleted) return <p style={{ margin: '12px 24px' }}>{$t('Wordt bij opslaan verwijderd')}</p>

  return (
    <div className='partCard' style={{ margin: '12px 0 12px 12px' }}>

      <Descriptions size='small' column={1} style={{ maxWidth: 700 }}>
        <Descriptions.Item label={<span>{$t('Naam')} {task.is_template && <HelpBubble content={$t('Deze taak komt uit de template, wijzigingen worden bijgehouden.')} />}</span>}>
          <Input
            value={task.name} disabled={isDeleted || nonApplicable}
            onChange={e => saveData({ name: e.target.value }, true)}
          />
        </Descriptions.Item>

        <Descriptions.Item label={$t('Beschrijving')}>
          <Input.TextArea
            rows={3} value={task.description} disabled={isDeleted || nonApplicable}
            onChange={e => saveData({ description: e.target.value }, true)}
          />
        </Descriptions.Item>


        {/* Toplevel tasks in maintenance don't have restrictions, workorders do */}
        {task.tasks.length === 0 && <>
          <Descriptions.Item label={<p>{$t('Uitgevoerd door')} <HelpBubble content={$t('De minimale bevoegheid van de gebruiker om deze taak af te tekenen.')} /></p>}>
            <Select
              style={{ width: 250 }} mode='single' disabled={isDeleted || nonApplicable}
              value={task.restriction} onChange={value => saveData({ restriction: value })}
              >
              {(category === 'kist' ? restrictions_kist : restrictions_niet_vliegend)
                .map((restriction, key) =>
                  <Select.Option value={restriction.value} key={key}>{$t(restriction.label)}</Select.Option>)}
            </Select>
          </Descriptions.Item>

          <Descriptions.Item label={<p>{$t('Is kritiek')} <HelpBubble content={$t('Taak is kritiek, en moet door een tweede persoon worden bekeken en gechecked.')} /></p>}>
            <SwitchYesNo
              checked={task.is_critical} disabled={isDeleted || nonApplicable}
              onChange={checked => saveData({ is_critical: checked })}
            />
          </Descriptions.Item>

          <Descriptions.Item label={<p>{$t('Opmerking verplicht')} <HelpBubble content={$t('Bij afvinken moet de gebruiker een opmerking achterlaten.')} /></p>}>
            <SwitchYesNo
              checked={task.remark_required} disabled={isDeleted || nonApplicable}
              onChange={checked => saveData({ remark_required: checked })}
            />
          </Descriptions.Item>

          <Descriptions.Item label={<p>{$t('Bestand verplicht')} <HelpBubble content={$t('Bij afvinken moet de gebruiker een bestand uploaden.')} /></p>}>
            <SwitchYesNo
              checked={task.file_required} disabled={isDeleted || nonApplicable}
              onChange={checked => saveData({ file_required: checked })}
            />
          </Descriptions.Item>

          {task?.id?.toString().startsWith('new') && isWorkorder &&
            <Descriptions.Item label={<p>{$t('Vink direct af')} <HelpBubble content={$t('Toegevoegde taken in een workorder kunnen direct afgevinkt worden.')} /></p>}>
              <Switch
                checked={task.signoff_directly} disabled={isDeleted || nonApplicable}
                onChange={checked => saveData({ signoff_directly: checked })}
                checkedChildren={<p><CheckOutlined /> {$t('Ja')}</p>}
                unCheckedChildren={<p><CloseOutlined /> {$t('Nee')}</p>}
              />
            </Descriptions.Item>}
        </>}
      </Descriptions>

      <EditCardCollapsible
        category={category} tasks={task?.tasks} isDeleted={isDeleted} nonApplicable={nonApplicable}
        level={level + 1} saveTasks={(tasks) => saveTask({ ...task, tasks })} isWorkorder={isWorkorder}
      />
      <Button style={{ margin: '6px 0' }} onClick={() => saveData({ tasks: task.tasks.concat([_baseNewTask(task.tasks)]) })} size='small' icon={<PlusOutlined />}>{$t('Voeg subtaak toe')} </Button>
    </div>
  )
}

const EditCardCollapsible = ({ category, tasks, saveTasks, isDeleted, nonApplicable, level, isWorkorder }) => {
  const rollUpSave = (newSubTask) => {
    // if a subtask below is saved, we rollup these changes and save to the main task

    const new_tasks = []
    tasks.forEach(_t => {
      if (_t.id === newSubTask.id) {
        if (_t.id.toString().startsWith('new') && newSubTask.action === 'delete') return
        new_tasks.push({ ..._t, ...newSubTask })
      } else {
        new_tasks.push(_t)
      }
    })
    saveTasks(new_tasks)
  }

  const delete_or_make_non_applicable = (task) => {
    // depends if task is template or not
    if (task.is_template) {
      rollUpSave({ id: task.id, action: task.action === 'new' ? 'new' : 'update', is_applicable: !task.is_applicable })
    } else {
      rollUpSave({ id: task.id, action: task.action === 'delete' ? 'update' : 'delete' })
    }
  }

  tasks = tasks.sort((a, b) => a.order - b.order)

  const moveTasks = (lookup) => {
    const new_tasks = tasks.map(_t => {
      if (_t.id in lookup) {
        _t.action = _t.action === 'new' ? 'new' : 'update'
        _t.order = lookup[_t.id]
      }
      return _t
    })
    saveTasks(new_tasks)
  }

  const move_up = (task, key) => {
    // swap with previous task in array
    if (key > 0) {
      const prev_task = tasks[key - 1]
      const lookup = {}
      lookup[task.id] = prev_task.order
      lookup[prev_task.id] = task.order
      if (lookup[task.id] === lookup[prev_task.id]) lookup[prev_task.id] += 1
      moveTasks(lookup)
    }
  }

  const move_down = (task, key) => {
    if (key < tasks.length) {
      const next_task = tasks[key + 1]
      const lookup = {}
      lookup[task.id] = next_task.order
      lookup[next_task.id] = task.order
      if (lookup[task.id] === lookup[next_task.id]) lookup[task.id] += 1
      moveTasks(lookup)
    }
  }

  return (
    <div>

      {tasks?.length > 0 && <Collapse style={{ marginTop: 12 }}>
        {tasks.map((task, key) => {
          const itemDeleted = isDeleted || task.action === 'delete'
          const itemNonApplicable = nonApplicable || !task.is_applicable
          return (
            <Collapse.Panel
              key={key}
              header={<div style={{ justifyContent: 'space-between' }} className='row'>
                <p>
                  {task.prefix && <Tag style={{ marginRight: 6 }}>{task.prefix}</Tag>}
                  {task.name ? task.name : <Tag color='red'>{$t('Naam mist')}</Tag>}
                  {task.is_critical && <Icon style={{ color: colors.red, marginLeft: 6 }} icon={double_check} viewBox='0 0 1024 800' />}
                  {itemDeleted && <Tag style={{ marginLeft: 12 }} color='red'>{$t('Wordt verwijderd')}</Tag>}
                  {itemNonApplicable && <Tag style={{ marginLeft: 12 }}>{$t('n.v.t.')}</Tag>}
                  {task.action == 'new' && <Tag style={{ marginLeft: 12 }} color='green'>{$t('nieuw')}</Tag>}
                  {task.action == 'update' && <Tag style={{ marginLeft: 12 }} color='orange'>{$t('update')}</Tag>}
                  {!itemNonApplicable && task.is_altered && <ToolOutlined style={{ marginLeft: 12, color: colors.darkgray }} />}
                </p>

                <div className='row' style={{ gap: 6 }}>

                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      move_up(task, key)
                    }} key='up' shape='circle' size='small' icon={<ArrowUpOutlined />}
                  />

                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      move_down(task, key)
                    }} key='down' shape='circle' size='small' icon={<ArrowDownOutlined />}
                  />

                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      delete_or_make_non_applicable(task)
                    }} key='delete' shape='circle' size='small' icon={<StopOutlined />}
                  />

                </div>
                      </div>}
            >
              <TaskEditCard category={category} saveTask={rollUpSave} task={task} isDeleted={itemDeleted} nonApplicable={itemNonApplicable} level={level} isWorkorder={isWorkorder} />
            </Collapse.Panel>
          )
        }
        )}
                            </Collapse>}
    </div>
  )
}

export const EditTasksList = ({ category, tasks, saveTasks, isWorkorder }) => {
  return (
    <div>
      <EditCardCollapsible category={category} tasks={tasks} saveTasks={saveTasks} level={0} isWorkorder={isWorkorder} />

      <Button style={{ margin: '24px 0' }} onClick={() => saveTasks(tasks.concat([_baseNewTask(tasks)]))} size='small' icon={<PlusOutlined />}>{$t('Voeg taak toe')} </Button>
    </div>
  )
}
