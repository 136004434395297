import {
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
  StopOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Tabs,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { ReadOnlyFormTag } from "~/components/form/ReadOnlyFormTag.jsx";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { $t } from "~/i18n.js";
import { _parseDate, _parseDateTime, parseHoursLong } from "~/lib/localize.js";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";
import { EditTasksList } from "../../editTasks.jsx";
import { MaintenanceStatusTag } from "../../materialTags.jsx";
import { DocumentUsedList } from "../documents/documentUsedList.jsx";
import { SelectPartCascader } from "../parts/selectPartCascader.jsx";

// using Custom controls form, clear out values in form
const ClearInterval = ({
  onChange,
  field_prefix,
  schedule,
  label,
  setActive,
  prefixLabel=null
}) => (
  <p className="row" style={{ width: 200, justifyContent: "space-between" }}>

    <span className="label">{prefixLabel ? prefixLabel :  `${schedule === "recurring" ? $t("Interval") : $t("Levensduur")} ${label}`}</span>
    <Button
      shape="circle"
      size="small"
      danger
      icon={<CloseOutlined />}
      onClick={() => {
        const data = {};
        data[field_prefix + "_remain"] = null;
        data[field_prefix + "_next"] = null;
        data[field_prefix + "_interval"] = 0;
        setActive(false);
        onChange(data);
      }}
    />
  </p>
);

export const IntervalField = ({
  current,
  nextValue,
  nextValueFormat,
  alertValue,
  maintenance,
  schedule,
  units,
  field_prefix,
  label,
  prefixLabel = null
}) => {
  const [isActive, setActive] = useState(null);
  useEffect(() => {
    setActive(!!maintenance[field_prefix + "_next"]);
  }, [field_prefix]);

  // alert period depends on the units
  let alertOn = null;
  if (nextValue && field_prefix === "date") {
    alertOn = _parseDate(nextValue.subtract(alertValue || 0, "days"));
  } else if (nextValue) {
    alertOn = nextValue - (alertValue || 0);
  }
  return (
    <div style={{ margin: "9px 0" }}>
      {!isActive && (
        <p
          className="row"
          style={{ width: 250, justifyContent: "space-between", paddingTop: 4 }}
        >
          <span className="label">
            {prefixLabel
              ? prefixLabel
              : `${
                  schedule === "recurring" ? $t("Interval") : $t("Levensduur")
                } ${label}`}
          </span>
          <Button
            onClick={() => setActive(true)}
            shape="circle"
            size="small"
            icon={<PlusOutlined />}
          />
        </p>
      )}

      {isActive && (
        <>
          <Form.Item name="clear">
            <ClearInterval
              field_prefix={field_prefix}
              schedule={schedule}
              label={label}
              prefixLabel={prefixLabel}
              setActive={setActive}
            />
          </Form.Item>

          {maintenance.part_is_installed && current && (
            <ReadOnlyFormTag
              name={$t("Huidige")}
              value={current}
              labelCol={7}
            />
          )}

          <Form.Item
            label={
              <p>
                {$t("Volgende")}{" "}
                {field_prefix === "date" ? $t("op") : $t("bij")}
              </p>
            }
            name={field_prefix + "_next"}
          >
            {field_prefix === "date" ? (
              <DatePicker
                format={localStorage.localeFormat}
                style={{ width: 200 }}
              />
            ) : (
              <Input
                style={{ width: 200 }}
                type="number"
                suffix={units}
                min={0}
              />
            )}
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 6 }}
            label={
              <p>
                {$t("Waarschuwing")}{" "}
                {field_prefix === "date" ? $t("op") : $t("bij")}
                <HelpBubble
                  content={
                    <span>
                      {$t(
                        "Aantal ${units} voorafgaande aan volgende deadline. Hierna kleurt de taak geel",
                        { units }
                      )}
                    </span>
                  }
                />
              </p>
            }
            name={field_prefix + "_alert"}
          >
            <Input
              style={{ width: 200 }}
              suffix={units}
              type="number"
              placeholder={$t("resterende")}
              min={0}
            />
          </Form.Item>

          <Row style={{ marginBottom: 24 }}>
            <Col className="gutter-row" span={16} offset={7}>
              <p className="row">
                <MaintenanceStatusTag maintenance_status="red" hideTag={true} />{" "}
                ≥ {nextValueFormat || nextValue}{" "}
                {field_prefix != "date" && units}
              </p>
              <p className="row">
                <MaintenanceStatusTag
                  maintenance_status="yellow"
                  hideTag={true}
                />{" "}
                ≥ {alertOn} {field_prefix != "date" && units}
              </p>
              <p className="row">
                <MaintenanceStatusTag
                  maintenance_status="green"
                  hideTag={true}
                />{" "}
                {"<"} {alertOn} {field_prefix != "date" && units}
              </p>
            </Col>
          </Row>

          {schedule === "recurring" && (
            <Form.Item
              label={
                <p>
                  <RetweetOutlined /> {$t("Periode")}{" "}
                  <HelpBubble
                    content={$t(
                      "Terugkomende interval, in ${units}, nadat er nieuw onderhoud gedaan moet worden, bijvoorbeeld elke 6 maanden of 3000 uur.",
                      { units }
                    )}
                  />
                </p>
              }
              name={field_prefix + "_interval"}
            >
              <Input
                style={{ width: 200 }}
                suffix={units}
                type="number"
                min={0}
              />
            </Form.Item>
          )}
        </>
      )}
    </div>
  );
};

export const ModalEditMaintenance = ({
  currentMaterial,
  maintenanceProgram,
  tasks,
  visible,
  closeModal,
}) => {
  const [wijzigPart, setWijzigPart] = useState(false);
  const [part_id, setNewPart] = useState(null);

  const [documents_used, setDocumentsUsed] = useState([]);

  const [form] = Form.useForm();
  const schedule = Form.useWatch("schedule", form);

  const date_next = Form.useWatch("date_next", form);
  const starts_next = Form.useWatch("starts_next", form);
  const hours_next = Form.useWatch("hours_next", form);
  const motor_hours_next = Form.useWatch("motor_hours_next", form);

  const date_alert = Form.useWatch("date_alert", form);
  const starts_alert = Form.useWatch("starts_alert", form);
  const hours_alert = Form.useWatch("hours_alert", form);
  const motor_hours_alert = Form.useWatch("motor_hours_alert", form);

  const [newTasks, setNewTasks] = useState(null);
  useEffect(() => {
    form.resetFields();
    setNewTasks(tasks);
    setDocumentsUsed(maintenanceProgram.documents_used);
  }, [maintenanceProgram, tasks]);

  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const saveData = async () => {
    try {
      setSaving(true);
      const values = await form.validateFields();
      const payload = {
        id: maintenanceProgram.id,
        ...values,
        ...values?.clear,
        date_next: values.date_next
          ? values.date_next.format("YYYY-MM-DD")
          : null,
        tasks: newTasks,
        documents_used,
      };

      if (part_id) payload.part_id = part_id;

      const res = await dispatch(
        saveMaterialTab({
          activeTab: "maintenance",
          materialID: currentMaterial.id,
          payload,
        })
      );
      if (res) {
        setSaving(false);
        closeModal();
      } else {
        setSaving(false);
      }
    } catch (info) {
      setSaving(false);
    }
  };

  const [deleting, setDeleting] = useState(false);
  const deleteProgram = async () => {
    setDeleting(true);
    const payload = { id: maintenanceProgram.id, action: "delete" };
    const res = dispatch(
      saveMaterialTab({
        activeTab: "maintenance",
        materialID: currentMaterial.id,
        payload,
      })
    );
    if (res) closeModal();
    setDeleting(false);
  };

  return (
    <Modal
      title={$t("Wijzig onderhoudsprogramma")}
      style={{ top: 20 }}
      width={800}
      open={visible}
      onCancel={closeModal}
      footer={[
        <Popconfirm
          key="remove"
          placement="bottom"
          title={$t("Zeker weten dat je dit programma wilt verwijderen")}
          onConfirm={deleteProgram}
          okText={$t("Ja")}
          cancelText={$t("Nee")}
        >
          <Button danger icon={<StopOutlined />} loading={deleting}>
            {$t("Verwijder")}
          </Button>
        </Popconfirm>,
        <AnnuleerButton key="annuleren" onClick={closeModal} />,
        <SaveButton key="opslaan" onClick={saveData} loading={saving} />,
      ]}
    >
      <Tabs>
        <Tabs.TabPane tab={$t("Algemeen")} key="general">
          <Form
            form={form}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 16 }}
            name="maintenance"
            preserve={false}
            requiredMark={false}
            initialValues={{
              ...maintenanceProgram,
              date_next: maintenanceProgram.date_next
                ? dayjs(maintenanceProgram.date_next)
                : null,
            }}
          >
            <Form.Item
              label={$t("Naam")}
              name="name"
              rules={[
                {
                  required: true,
                  max: 90,
                  message: $t("Maximaal ${c} characters", { c: 50 }),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label={$t("Onderdeel")}>
              {wijzigPart ? (
                <SelectPartCascader
                  onChange={setNewPart}
                  materialID={currentMaterial.id}
                />
              ) : (
                <div className="row" style={{ gap: 6 }}>
                  <Tag>{maintenanceProgram.part_name  || "???"}</Tag>
                  <Button
                    onClick={() => setWijzigPart(true)}
                    type="text"
                    size="small"
                  >
                    <EditOutlined /> {$t("wijzig")}
                  </Button>
                </div>
              )}
            </Form.Item>

            <Form.Item
              label={$t("Schedule")}
              name="schedule"
              rules={[{ required: true }]}
            >
              <Select>
                <Select.Option key="recurring" value="recurring">
                  <RetweetOutlined /> {$t("Periodiek")}
                </Select.Option>
                <Select.Option key="lifetime" value="lifetime">
                  <VerticalAlignTopOutlined
                    style={{ transform: "rotate(90deg)" }}
                  />{" "}
                  {$t("Levensduur")}
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={$t("Beschrijving")}
              name="description"
              rules={[
                {
                  required: false,
                  max: 190,
                  message: $t("Maximaal ${c} characters", { c: 200 }),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={$t("Opmerking (vrij veld)")}
              name="remark"
              rules={[
                {
                  required: false,
                  max: 300,
                  message: $t("Maximaal ${c} characters", { c: 300 }),
                },
              ]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>

            <DocumentUsedList
              documents={documents_used}
              setDocuments={setDocumentsUsed}
            />

            <p className="subtitle" style={{ marginTop: 24 }}>
              {$t("Onderhoudsintervallen")}
            </p>

            {!maintenanceProgram.part_is_installed && (
              <p>
                <Tag color="red">{$t("niet actief")}</Tag> Onderdeel is
                uitgebouwd op:{" "}
                {_parseDate(maintenanceProgram.part_date_uninstalled)}
              </p>
            )}

            <IntervalField
              maintenance={maintenanceProgram}
              nextValue={date_next}
              alertValue={date_alert}
              nextValueFormat={_parseDate(date_next)}
              schedule={schedule}
              label={$t("datum")}
              units={$t("dagen")}
              field_prefix="date"
            />

            {maintenanceProgram.part_total_starts !== null && (
              <IntervalField
                maintenance={maintenanceProgram}
                current={maintenanceProgram.part_total_starts}
                nextValue={starts_next}
                alertValue={starts_alert}
                schedule={schedule}
                label={$t("starts")}
                units={$t("starts")}
                field_prefix="starts"
              />
            )}

            {maintenanceProgram.part_total_minutes !== null && (
              <IntervalField
                maintenance={maintenanceProgram}
                current={parseHoursLong(
                  maintenanceProgram.part_total_minutes,
                  0
                )}
                nextValue={hours_next}
                alertValue={hours_alert}
                schedule={schedule}
                label={$t("uren")}
                units={$t("uren")}
                field_prefix="hours"
              />
            )}

            {currentMaterial.kist_configuration !== "Glider" ||
              maintenanceProgram.motor_hours_next !== null ||
              (maintenanceProgram.part_total_motor_minutes !== null && (
                <IntervalField
                  maintenance={maintenanceProgram}
                  current={parseHoursLong(
                    maintenanceProgram.part_total_motor_minutes,
                    1
                  )}
                  nextValue={motor_hours_next}
                  alertValue={motor_hours_alert}
                  schedule={schedule}
                  label={$t("motor uren")}
                  units={$t("uren")}
                  field_prefix="motor_hours"
                />
              ))}

            {(maintenanceProgram.part_total_starts === null ||
              maintenanceProgram.part_total_minutes === null ||
              (currentMaterial.kist_configuration !== "Glider" &&
                maintenanceProgram.part_total_motor_minutes === null)) && (
              <p className="small" style={{ maxWidth: 350 }}>
                <QuestionCircleOutlined />{" "}
                {$t(
                  "Als je de maintenance task wilt verbinden aan de starts, uren of motoruren moet je bij het part aangeven dat je de inservice lifetime wilt meten."
                )}
              </p>
            )}
          </Form>

          {maintenanceProgram.user_updated && (
            <p className="small" style={{ marginTop: 42 }}>
              {$t("Laatst gewijzigd door")}: {maintenanceProgram.user_updated},{" "}
              {_parseDateTime(maintenanceProgram.date_updated)}
            </p>
          )}
        </Tabs.TabPane>

        <Tabs.TabPane tab={$t("Taken")} key="tasks">
          <EditTasksList
            category={currentMaterial.category}
            tasks={newTasks}
            saveTasks={setNewTasks}
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};
