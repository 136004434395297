import React from 'react';
import { Card } from 'antd';

export const GCard = ({ children, ...props }) => {
    return (
        <Card size="small" {...props}>
            {children}
        </Card>
    );
};
