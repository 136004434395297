import { ToolOutlined } from "@ant-design/icons";
import { Collapse, Modal, Tag } from "antd";
import React from "react";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { $t } from "~/i18n.js";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { Icon, double_check } from "~/components/icon/icon.jsx";
import { colors } from "~/theme/colors.js";
import { DocumentList } from "../documents/documentList.jsx";

export const restrictionLookup = {
  all: "Iedereen", // $t("Iedereen")
  technicus_pom: "POM technici", // $t("POM technici")
  technicus_rollend: "Technici (rollend)", // $t("Technici (rollend)")
  technicus_vliegend: "Technici", // $t("Technici")
  technicus_arc: "ARC technici", // $t("ARC technici")
};

export const TaskCard = ({ task }) => {
  return (
    <div className="partCard" style={{ padding: "12px 0 12px 12px" }}>
      <div className="row" style={{ gap: 12 }}>
        {task.restriction && (
          <p>
            <Tag color="green">{restrictionLookup[task.restriction]}</Tag>
            <HelpBubble content="De minimale bevoegheid van de gebruiker om deze taak af te tekenen." />
          </p>
        )}
        {task.is_critical && (
          <p>
            <Tag color="orange">
              <Icon
                style={{ color: colors.red }}
                icon={double_check}
                viewBox="0 0 1024 800"
              />{" "}
              Kritiek
            </Tag>
            <HelpBubble content="Taak is kritiek, en moet door een tweede persoon worden bekeken en afgetekend." />
          </p>
        )}
      </div>

      <p className="userInput">{task.description}</p>

      {task.tasks?.length > 0 && <CollapsibleViewTasks tasks={task.tasks} />}
    </div>
  );
};

const CollapsibleViewTasks = ({ tasks }) => (
  <Collapse style={{ marginTop: 12, width: "100%" }}>
    {tasks.map((subtask, key) => (
      <Collapse.Panel
        key={key}
        header={
          <span>
            {subtask.name}
            {subtask.is_critical && (
              <Icon
                style={{ color: colors.red, marginLeft: 6 }}
                icon={double_check}
                viewBox="0 0 1024 800"
              />
            )}
            {!subtask.is_applicable && (
              <Tag style={{ marginLeft: 12 }}>n.v.t.</Tag>
            )}
            {subtask.is_applicable && subtask.is_altered && (
              <ToolOutlined
                style={{ marginLeft: 12, color: colors.darkgray }}
              />
            )}
          </span>
        }
        collapsible={!subtask.is_applicable ? "disabled" : null}
        showArrow={
          subtask.description ||
          subtask.is_critical ||
          subtask.tasks?.length > 0
        }
      >
        <TaskCard task={subtask} />
      </Collapse.Panel>
    ))}
  </Collapse>
);

export const ModalViewMaintenance = ({
  maintenanceProgram,
  tasks,
  visible,
  closeModal,
}) => {
  let n_aangepast = 0;
  const add_count = (task) => {
    if (task.is_altered || !task.is_applicable) n_aangepast += 1;
    task.tasks?.forEach(add_count);
  };
  tasks.forEach(add_count);

  return (
    <Modal
      title={$t("Bekijk ${maintenanceProgram}", {
        maintenanceProgram: maintenanceProgram?.name,
      })}
      width={800}
      open={visible}
      onCancel={closeModal}
      footer={[<SluitButton key="sluit" onClick={closeModal} />]}
    >
      {maintenanceProgram?.documents_used?.length > 0 && (
        <DocumentList
          hideStatus={true}
          header={$t("Gebruikte documenten")}
          readOnly={true}
          documents={maintenanceProgram.documents_used}
        />
      )}

      <p className="label darkgray" style={{ marginTop: 18 }}>
        {$t("Taken")}
      </p>

      {tasks?.length === 0 && (
        <p style={{ marginTop: 12 }}>{$t("Geen taken ingeladen")}</p>
      )}

      {tasks?.length > 0 && <CollapsibleViewTasks tasks={tasks} />}
    </Modal>
  );
};
