import { DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { $t } from "~/i18n.js";
import { DataCard } from "~/components/dataCards/dataCard.jsx";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { _parseDate } from "~/lib/localize.js";

export const CardRegister = ({ kist, saveData, readOnly }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const has_register = kist?.register;

  const saveForm = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      values.arc_valid_to = values?.arc_valid_to?.format("YYYY-MM-DD");
      values.date_registered = values?.date_registered?.format("YYYY-MM-DD");
      const res = await saveData({ kist: values });
      setLoading(false);
      return res;
    } catch (info) {
      console.log("Validate Failed:", info);
      setLoading(false);
    }
  };

  return (
    <DataCard
      title={$t("Luchtvaartregister")}
      readOnly={readOnly}
      onSave={!readOnly && saveForm}
      loading={loading}
    >
      <Form
        style={{ width: 320 }}
        form={form}
        name="logbook"
        initialValues={{
          ...kist,
          arc_valid_to: kist?.arc_valid_to ? dayjs(kist?.arc_valid_to) : null,
          date_registered: kist?.date_registered
            ? dayjs(kist?.date_registered)
            : null,
        }}
        requiredMark={false}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
      >
        {kist.register && (
          <p className="spacer">
            {$t("Register")}: <span className="bold">{kist.register}</span>,{" "}
            {_parseDate(kist.register_date_updated)}{" "}
            <HelpBubble content="Datum waarop de laatste wijziging in het register gevonden is. De data wordt elke dag opnieuw opgehaald en ververst." />
          </p>
        )}

        <Form.Item
          label={$t("Producent")}
          name="manufacturer"
          rules={[{ required: false, max: 100 }]}
        >
          <Input disabled={readOnly || has_register} />
        </Form.Item>
        <Form.Item
          label={$t("Model")}
          name="model"
          rules={[{ required: false, max: 100 }]}
        >
          <Input disabled={readOnly || has_register} />
        </Form.Item>

        <Form.Item
          label={$t("Configuratie")}
          name="configuration"
          rules={[
            {
              required: true,
              message: $t("Selecteer een van de configuraties"),
            },
          ]}
        >
          <Select disabled={readOnly || has_register}>
            <Select.Option value="Glider">
              {$t("Glider (zonder motor)")}
            </Select.Option>
            <Select.Option value="Sustainer">({$t("Sustainer")}</Select.Option>
            <Select.Option value="Self-launching">
              ({$t("Self-launching")}
            </Select.Option>
            <Select.Option value="TMG">
              ({$t("Touring Motor Glider (TMG)")}
            </Select.Option>
            <Select.Option value="SEP">
              ({$t("Single Engine Piston (SEP)")}
            </Select.Option>
          </Select>
        </Form.Item>

        {/* arc_valid_to */}
        {has_register && (
          <Form.Item
            label={$t("Arc geldigheid")}
            name="arc_valid_to"
            rules={[{ required: false }]}
          >
            <DatePicker
              format={localStorage.localeFormat}
              disabled={readOnly || has_register}
              allowClear
            />
          </Form.Item>
        )}

        {/* date_registered */}
        <Form.Item
          label={$t("Registratie")}
          name="date_registered"
          rules={[{ required: false }]}
        >
          <DatePicker
            format={localStorage.localeFormat}
            disabled={readOnly || has_register}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Flarm ID")}{" "}
              <HelpBubble
                content={
                  <p>
                    {$t(
                      "Het Flarm ID haalt hij automatisch op vanuit het luchtvaartregister, indien bekend. Zorg er ook voor dat dit ID in het Devices DataBase van Glidernet staat (zie"
                    )}{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://ddb.glidernet.org/"
                    >
                      link
                    </a>
                    ).
                  </p>
                }
              />
            </p>
          }
          name="flarm"
          rules={[{ required: false, max: 30 }]}
        >
          <Input disabled={readOnly} />
        </Form.Item>
      </Form>
    </DataCard>
  );
};
