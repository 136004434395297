import { PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { Select } from "antd";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

export const SelectTagWithAddNew = ({
  id = "",
  value = "",
  onChange = (e) => {},
  allTags
}) => {
  
  const [activeTag, setTag] = useState("");
  const [allTagsList, setAllTags] = useState([]);
  useEffect(() => {
    setAllTags(allTags);
  }, [allTags]);

  return (
    <Select
      value={value} 
      onChange={onChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div
            style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
          >
            <Input
              style={{ flex: "auto" }}
              value={activeTag}
              onChange={(event) =>
                setTag(
                  event.target.value.replace(/^\w/, (c) =>
                    c.toUpperCase()
                  )
                )
              }
            />
            <Button
              type="primary"
              style={{ marginLeft: 4 }}
              onClick={() => {
                if (activeTag?.length > 0) {
                  setAllTags(allTagsList.concat(activeTag));
                  setTag("");
                }
              }}
            >
              <PlusOutlined /> {$t("Voeg toe")}
            </Button>
          </div>
        </div>
      )}
    >
      {allTagsList?.map(
        (tag, key) =>
          tag && (
            <Select.Option key={key} value={tag}>
              {tag}
            </Select.Option>
          )
      )}
    </Select>
  );
};
