import { Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import React from "react";
import { $t } from "~/i18n.js";

import { Phone } from "~/components/profile/phone.jsx";
import { RecencyCurrencyBarometer } from "~/components/recency/RecencyCurrencyBarometer.jsx";
import {
  getUserAvatar,
  getUserAvatarError,
} from "~/components/profile/selectPilot.jsx";
import { ReadMore } from "~/components/readmore/readMore.jsx";
import { _parseDate } from "~/lib/localize.js";
import { BevoegdheidScore } from "~/components/bevoegdheden/components/BevoegdheidScore.jsx";
import { Flex } from "antd";
import { ParseTrainingFlightDate } from "~/components/profile/parseTrainingFlightDate.jsx";

const Avatar = (groups_filter, group_name_lookup = {}, history = null) => {
  return {
    title: $t("Lid"),
    dataIndex: "name",
    sorter: (a, b, sortOrder) => {
      if (sortOrder === "ascend") {
        return ("" + a.lowercase).localeCompare(b.lowercase);
      }
      return ("" + a.last_name).localeCompare(b.last_name);
    },

    filters:
      groups_filter &&
      groups_filter.length > 1 &&
      groups_filter
        .split(",")
        .map((g) => ({ text: group_name_lookup[g] || g, value: g.trim() })),
    onFilter: (value, record) => record.group_keys?.includes(value),
    render: (text, vlieger) => (
      <div
        className="row"
        onClick={() =>
          history && history.push(`${history.location.pathname}/${vlieger.id}`)
        }
      >
        <img
          className="avatarLedenLijst"
          alt=""
          src={getUserAvatar(vlieger, true)}
          onError={getUserAvatarError}
        />
        <div
          className="column"
          style={{ marginLeft: 12, alignItems: "flex-start" }}
        >
          <p>{vlieger.name}</p>

          <div className="Row">
            {groups_filter &&
              groups_filter.length > 1 &&
              groups_filter
                .split(",")
                .map(
                  (group, idx) =>
                    vlieger.group_keys &&
                    vlieger.group_keys?.includes(group.trim()) && (
                      <Tag key={idx}>{group_name_lookup[group] || group}</Tag>
                    )
                )}
          </div>
        </div>
      </div>
    ),
  };
};

const PhoneWrap = () => ({
  title: $t("Telefoon"),
  dataIndex: "phone",
  render: (text, vlieger) => (
    <Phone number={vlieger.phone} private_mode={vlieger.private_mode} />
  ),
});

const CurrencyColumn = () => ({
  title: $t("Currency"),
  filters: [
    { text: $t("Groen"), value: "groen" },
    { text: $t("Geel"), value: "geel" },
    { text: $t("Oranje"), value: "orange" },
    { text: $t("Rood"), value: "rood" },
    { text: $t("Verloopt binnenkort"), value: "3m" },
    { text: $t("Check medical"), value: "check_med" },
    { text: $t("Ongeldig brevet"), value: "spl" },
    { text: $t("Ongeldig lierstart"), value: "lier" },
    { text: $t("Ongeldig sleepstart"), value: "sleep" },
    { text: $t("Ongeldig zelfstart"), value: "zelf" },
    { text: $t("Ongeldig TMG"), value: "tmg" },
    { text: $t("Ongeldig pax"), value: "pax" },
    { text: $t("Ongeldig FI(s)"), value: "fis" },
  ],
  onFilter: (value, record) => {
    if (value === "check_med") {
      return record.check_med
    }
    if (value === "spl") {
      return record.recency && record.recency.spl === "invalid";
    }
    if (value === "lier") {
      return record.recency && record.recency.lieraantekening === "invalid";
    }
    if (value === "sleep") {
      return record.recency && record.recency.sleepaantekening === "invalid";
    }
    if (value === "zelf") {
      return (
        record.recency && record.recency.zelfstartaantekening === "invalid"
      );
    }
    if (value === "tmg") {
      return record.recency && record.recency.tmgaantekening === "invalid";
    }
    if (value === "pax") {
      return record.recency && record.recency.passagiervliegen === "invalid";
    }
    if (value === "fis") {
      return record.recency && record.recency.fis === "invalid";
    }
    if (value === "3m" && record.recency) {
      // show users that have a recency key that stops
      const now = dayjs();
      return Object.keys(record.recency).some((key) => {
        if (!key.endsWith("pax_valid_to") && key.endsWith("valid_to")) {
          if (dayjs(record.recency[key], "YYYY-MM-DD").diff(now, "days") < 90) {
            return true;
          }
        }
      });
    }
    return value === record.currency;
  },
  render: (text, vlieger, index) =>
    vlieger.currency ? <RecencyCurrencyBarometer pilot={vlieger} /> : null,
});

const Pic = () => ({
  title: $t("Starts totaal"),
  dataIndex: "pic",
  sorter: (a, b) => a.pic - b.pic,
});

const Starts = () => ({
  title: $t("Starts"),
  dataIndex: "starts",
  sorter: (a, b) => a.starts - b.starts,
});

const Lier = () => ({
  title: $t("Lier"),
  dataIndex: "lier",
  sorter: (a, b) => a.lier - b.lier,
});

const Sleep = () => ({
  title: $t("Sleep"),
  dataIndex: "sleep",
  sorter: (a, b) => a.sleep - b.sleep,
});

const Check = () => ({
  title: $t("Trainingsvlucht"),
  dataIndex: "last_check_start",
  render: (text) => (
   <ParseTrainingFlightDate last_check_start={text} hideOnbekendTag={true} /> 
  ),
});

export const NotitieColumn = (notities) => ({
  title: $t("Laatste notitie"),
  sorter: (a, b) => {
    let an = notities[a.id];
    if (!an) return -1;
    let bn = notities[b.id];
    if (!bn) return 1;
    return new Date(an.datum) - new Date(bn.datum);
  },
  render: (_, vlieger) => {
    const v = notities && notities[vlieger.id];
    if (!v) return null;
    return <ReadMore text={v.bericht} maxLength={100} />;
  },
});

export const LatestBehaald = (latestBehaaldeBevoegdheden) => ({
  title: $t("Laatst behaald"),
  render: (_, vlieger) => {
    const b = latestBehaaldeBevoegdheden[vlieger.id];
    if (b) {
      return (
        <Flex align="center" gap={2}>
          <span>{_parseDate(b.date_assigned)}</span>
          <BevoegdheidScore score={b.score} />
          <span>{b.bevoegdheid_name}</span>
        </Flex>
      );
    }
  },
});

const Dbo = () => ({
  title: $t("DBO starts"),
  dataIndex: "dbo",
  sorter: (a, b) => a.dbo - b.dbo,
});

const Date_first_flight = () => ({
  title: $t("Eerste vlucht"),
  dataIndex: "date_first_flight",
  render: (text) => _parseDate(text),
  sorter: (a, b) =>
    ("" + a.date_first_flight).localeCompare(b.date_first_flight),
});

const Solo = () => ({
  title: $t("Solo starts"),
  dataIndex: "pic",
  sorter: (a, b) => a.pic - b.pic,
});

// sort value must return 0 because you can't compare undefined
const get_value = (vlieger, key, data) =>
  data && data[vlieger.id + "_" + key] ? data[vlieger.id + "_" + key] : 0;
const get_show_value = (vlieger, key, data) =>
  data && data[vlieger.id + "_" + key] ? data[vlieger.id + "_" + key] : 0;

export const clusterColumn = (
  cluster,
  behaalde_bevoegdheden,
  active_dto_trainingen
) => ({
  title: cluster.name,
  sorter: (a, b) =>
    get_value(a, cluster.name, behaalde_bevoegdheden) -
    get_value(b, cluster.name, behaalde_bevoegdheden),
  render: (text, vlieger, index) => {
    const status =
      active_dto_trainingen &&
      active_dto_trainingen[vlieger.id + "_" + cluster.id]
        ? active_dto_trainingen[vlieger.id + "_" + cluster.id]
        : null;
    return (
      status && (
        <>
          {status === "started" && (
            <p>
              {get_show_value(vlieger, cluster.name, behaalde_bevoegdheden)}
            </p>
          )}
          {status === "completed" && <Tag color="gold">{$t("afgerond")}</Tag>}
          {status === "discontinued" && <Tag>{$t("gestaakt")}</Tag>}
        </>
      )
    );
  },
});

export const ledenLijst = (groups_filter, group_name_lookup, history) => [
  Avatar(groups_filter, group_name_lookup, history),
  CurrencyColumn(),
  PhoneWrap(),
];

export const InstructeurLijst = (
  notities,
  groups_filter,
  group_name_lookup,
  history
) => [
  Avatar(groups_filter, group_name_lookup, history),
  CurrencyColumn(),
  PhoneWrap(),
  Pic(),
  Starts(),
  Lier(),
  Sleep(),
  Check(),
  NotitieColumn(notities),
];
window.InstructeurLijst = InstructeurLijst;

export const DTOlijst = (
  groups_filter,
  group_name_lookup,
  latestBehaaldeBevoegdheden,
  history
) => [
  Avatar(groups_filter, group_name_lookup, history),
  CurrencyColumn(),
  Dbo(),
  Solo(),
  Date_first_flight(),
  {
    title: $t("Laatste vlucht"),
    dataIndex: "date_last_flight",
    render: (text) => _parseDate(text),
    sorter: (a, b) =>
      ("" + a.date_last_flight).localeCompare(b.date_last_flight),
  },
  LatestBehaald(latestBehaaldeBevoegdheden),
];
