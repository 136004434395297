import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { $t } from "~/i18n.js";

import {
  ClockCircleFilled,
  MinusCircleOutlined,
  PlusOutlined,
  StarTwoTone,
  StopOutlined,
} from "@ant-design/icons";
import { Button, Modal, Popconfirm } from "antd";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { api } from "~/lib/api.js";
import { colors } from "~/theme/colors.js";
import { ScoreForm } from "./ScoreForm.jsx";
import { BevoegdheidHistory } from "./BevoegdheidHistory.jsx";
import { Divider } from "antd";
import { Tag } from "antd";
import { Rate } from "antd";
import { _parseDate } from "~/lib/localize.js";

const EndorsementCreated = (link) => {
  Modal.success({
    title: $t("Endorsement aangemaakt"),

    content: $t(
      "De endorsement is opgeslagen onder jou bestanden, je kan hem nu downloaden."
    ),
    okText: $t("Bekijk"),

    afterClose: () => window.location.assign(link),
  });
};

const TagScore = ({ score, color }) => (
  <Tag color={color} style={{ fontSize: 14, marginTop: 6 }}>
    {score}
  </Tag>
);

export const BevoegdheidModal = ({
  closeModal,
  user_id,
  bevoegdheid,
  setBevoegdheid,
  cluster,
}) => {
  if (!bevoegdheid) return null;

  const { profile } = useSelector((state) => state.persist);

  const [isUpdated, setIsUpdated] = useState(false);
  const [date_assigned, setDateAssigned] = useState(null);
  const [date_geldig, setDateGeldig] = useState(null);
  const [remark, setRemark] = useState("");
  const [newScore, setScore] = useState(null);
  const score = bevoegdheid.behaald.score || 0;
  useEffect(() => {
    if (bevoegdheid) {
      setDateAssigned(dayjs(bevoegdheid.behaald?.date_assigned));
      setDateGeldig(bevoegdheid.behaald?.date_geldig ? dayjs(bevoegdheid.behaald?.date_geldig) : null);
      setRemark(bevoegdheid.behaald?.remark);
      setScore(bevoegdheid.behaald?.score);
    }
  }, [bevoegdheid]);

  const [loading, setLoading] = useState("");
  const triggerSave = async (action, _score) => {
    setLoading(action);
    await setBevoegdheid({
      user_id,
      score: _score,
      remark: remark ? remark.substring(0, 90) : "",
      is_dto: cluster.is_dto,
      id: bevoegdheid.id,
      date_assigned: date_assigned ? date_assigned.format("YYYY-MM-DD") : datejs().format("YYYY-MM-DD"),
      date_geldig: date_geldig ? date_geldig.format("YYYY-MM-DD") : null,
    });
    setLoading("");
    closeModal();
  };

  const [loadingEndorse, setLoadingEndorse] = useState(false);
  const download_endorsement = async () => {
    setLoadingEndorse(true);
    const res = await api.post("bevoegdheid/create_endorsement.json", {
      bevoegdheid_id: bevoegdheid.id,
      user_id,
    });
    if (res?.link) {
      EndorsementCreated(res.link, res.name);
    }
    setLoadingEndorse(false);
  };

  const isUserOrInstructor =
    profile.id === parseInt(user_id) || profile.is_instructeur;
  const canEndorse = bevoegdheid.endorse_title && isUserOrInstructor;

  return (
    <Modal
      title={bevoegdheid.label || bevoegdheid.name}
      open={bevoegdheid !== null}
      okText={$t("Opslaan")}
      cancelText={$t("Annuleren")}
      onCancel={closeModal}
      footer={[
        canEndorse && bevoegdheid.behaald?.date_behaald && (
          <Button
            key="endorse"
            style={{ marginRight: 12 }}
            loading={loadingEndorse}
            icon={<PlusOutlined />}
            onClick={download_endorsement}
          >
            {$t("Maak endorsement")}
          </Button>
        ),

        setBevoegdheid && bevoegdheid.behaald.date_assigned && (
          <Popconfirm
            key="remove"
            placement="bottom"
            title={$t("Zeker weten dat je de bevoegdheid wilt intrekken?")}
            onConfirm={() => triggerSave("revoked", "revoked")}
            okText={$t("Ja")}
            cancelText={$t("Nee")}
          >
            <Button
              danger
              icon={<StopOutlined />}
              style={{ marginRight: 24 }}
              loading={loading === "revoked"}
            >
              {$t("Intrekken")}
            </Button>
          </Popconfirm>
        ),

        <SluitButton key="sluit" onClick={closeModal} />,

        setBevoegdheid && (
          <SaveButton
            key="opslaan"
            loading={loading === "save"}
            disabled={!isUpdated}
            onClick={() => triggerSave("save", newScore)}
          />
        ),
      ]}
    >
      <p style={{ marginBottom: 12 }} className="userInput">
        {bevoegdheid.description}
      </p>

      {!score && (
        <p>
          <MinusCircleOutlined
            style={{ color: colors.gray_light, fontSize: 17, marginRight: 6 }}
          />

          {$t("Bevoegdheid nog niet toegekend.")}
        </p>
      )}

      {setBevoegdheid && !!score && (
        <Divider orientation="left" plain>
          {$t("Wijzig")}
        </Divider>
      )}

      {score === "assigned" && (
        <TagScore score={$t("toegekend")} color={colors.groen_dark} />
      )}
      {score === "revoked" && (
        <TagScore score={$t("ingetrokken")} color={"red"} />
      )}

      {!!score && !profile.is_instructeur && (
        <div>
          {score === "briefing" && (
            <TagScore score={$t("briefing")} color={colors.groen} />
          )}
          {score === "demo" && (
            <TagScore score={$t("demo")} color={colors.groen} />
          )}
          {[1, 2, 3, 4, 5].indexOf(score) > -1 && (
            <Rate disabled={true} value={score} style={{ fontSize: 32 }} />
          )}

          <p>{bevoegdheid.remark}</p>
        </div>
      )}

      {!!score && profile.is_instructeur && (
        <ScoreForm
          bevoegdheid={bevoegdheid}
          score={newScore}
          remark={remark}
          setScore={(_v) => {
            setScore(_v);
            setIsUpdated(true);
          }}
          setRemark={(_v) => {
            setRemark(_v);
            setIsUpdated(true);
          }}
          date_assigned={date_assigned}
          setDateAssigned={(_v) => {
            setDateAssigned(_v);
            setIsUpdated(true);
          }}
          date_geldig={date_geldig}
          setDateGeldig={(_v) => {
            setDateGeldig(_v);
            setIsUpdated(true);
          }}
        />
      )}

      {bevoegdheid.endorse_title && (
        <p style={{ marginTop: 12 }} className="small">
          <span className="bold">
            <StarTwoTone twoToneColor={colors.primary} /> {$t("Endorsement")}:{" "}
            {bevoegdheid.endorse_title}
          </span>
          {$t(
            "Voor deze bevoegdheid kan je een Endorsement aanmaken waarmee je buiten de club om kan laten zien dat je aan de benodige eisen voldoet."
          )}
        </p>
      )}

      {!!score && isUserOrInstructor && (
        <BevoegdheidHistory
          behaalde_bevoegdheid_id={bevoegdheid.behaald?.id}
          currentBevoegdheid={bevoegdheid.behaald}
        />
      )}
    </Modal>
  );
};
