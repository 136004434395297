import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditOutlined,
  SoundTwoTone,
} from "@ant-design/icons";
import { Button, Collapse, Tag } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";

import { DescriptionRow } from "~/components/descriptionRow/descriptionRow.jsx";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { _parseDate } from "~/lib/localize.js";
import { colors } from "~/theme/colors.js";
import { InFlightService } from "../../materialTags.jsx";
import { DocumentList } from "../documents/documentList.jsx";
import "./partCard.css";

export const PartCard = ({
  editPart,
  currentMaterial,
  part,
  level,
  readOnly,
  parts_path,
  openMaintenance,
  saveParts,
}) => {
  const subparts = part.parts?.sort((a, b) => a.order - b.order);

  const [moving, setMoving] = useState(false);
  const move_up = async (subpart, key) => {
    if (!moving && key > 0) {
      setMoving(true);
      const prev_part = subparts[key - 1];

      // in case orders are same, bump up previous
      const delta = prev_part.order === subpart.order ? 1 : 0;
      await saveParts([
        { id: prev_part.id, order: subpart.order + delta },
        { id: subpart.id, order: prev_part.order },
      ]);
      setMoving(false);
    }
  };

  const move_down = async (subpart, key) => {
    if (!moving && key < subparts.length - 1) {
      setMoving(true);
      const next_part = subparts[key + 1];

      // in case orders are same, bump up previous
      const delta = subpart.order === next_part.order ? 1 : 0;
      await saveParts([
        { id: subpart.id, order: next_part.order + delta },
        { id: next_part.id, order: next_part.order },
      ]);
      setMoving(false);
    }
  };

  return (
    <div className="partCard">
      <div>
        <DescriptionRow>
          {part.description}
          {part.is_mel && (
            <Tag color="red" style={{ marginLeft: 6 }}>
              MEL{" "}
              <HelpBubble content="Item staat op Minimum Equipment List. Indien het onderdeel defect is dan is het vliegtuig buiten gebruik." />
            </Tag>
          )}
        </DescriptionRow>

        <DescriptionRow show={part.manufacturer}>
          {part.manufacturer}
        </DescriptionRow>

        <DescriptionRow label={$t("Part")} show={part.part_number}>
          {part.part_number}
        </DescriptionRow>

        <DescriptionRow label={$t("Serie")} show={part.serie_number}>
          {part.serie_number}
        </DescriptionRow>

        <DescriptionRow
          label={<SoundTwoTone twoToneColor="green" />}
          show={part.remark}
        >
          {part.remark}
        </DescriptionRow>

        <DescriptionRow show={part.track_inservice_life}>
          <InFlightService element={part} />
        </DescriptionRow>

        <DescriptionRow label={$t("Ingebouwd op")} show={part.date_installed}>
          {_parseDate(part.date_installed)}
        </DescriptionRow>

        <DescriptionRow
          label={$t("Uitgebouwd op")}
          show={part.date_uninstalled}
        >
          {_parseDate(part.date_uninstalled)}
        </DescriptionRow>

        <DescriptionRow
          label={$t("Maintenance")}
          show={part.maintenance?.length > 0}
        >
          <div className="row" style={{ gap: 6, flexWrap: 'wrap'}}>
          {part.maintenance?.map((maintenance, idx) => (
            <Tag
              className="clickable"
              key={idx}
              onClick={() => openMaintenance(maintenance.id)}
            >
              <p className="row" style={{ gap: 6}}>
                {maintenance.status && (
                  <span
                    className="icon"
                    style={{
                      backgroundColor: colors[maintenance.status],
                      width: 15,
                      height: 15,
                      borderRadius: "50%",
                    }}
                  />
                )}
                {maintenance.name}
              </p>
            </Tag>
          ))}
          </div>
        </DescriptionRow>
      </div>

      {part?.documents_used?.length > 0 && (
          <DocumentList
            header={$t("Documenten")}
            readOnly={true}
            documents={part.documents_used}
            materialID={currentMaterial.id}
          />
      )}

      {subparts?.length > 0 && (
        <Collapse style={{ marginTop: 12 }}>
          {part.parts.map((subpart, key) => {
            // Connect path to next level
            return (
              <Collapse.Panel
                key={key}
                header={
                  <span>
                    {subpart.name}{" "}
                    {!subpart.is_installed && (
                      <Tag style={{ marginLeft: 18 }} color="red">
                        Niet geinstalleerd
                      </Tag>
                    )}{" "}
                  </span>
                }
                extra={[
                  !readOnly && (
                    <Button
                      disabled={moving}
                      onClick={(e) => {
                        e.stopPropagation();
                        move_up(subpart, key);
                      }}
                      key="up"
                      shape="circle"
                      size="small"
                      icon={<ArrowUpOutlined />}
                    />
                  ),

                  !readOnly && (
                    <Button
                      disabled={moving}
                      onClick={(e) => {
                        e.stopPropagation();
                        move_down(subpart, key);
                      }}
                      key="down"
                      shape="circle"
                      size="small"
                      icon={<ArrowDownOutlined />}
                    />
                  ),

                  !readOnly && (
                    <Button
                      style={{ marginLeft: 6 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        editPart({ ...subpart, parts_path });
                      }}
                      key="alter"
                      shape="circle"
                      size="small"
                      icon={<EditOutlined />}
                    />
                  ),
                ]}
              >
                <PartCard
                  editPart={editPart}
                  currentMaterial={currentMaterial}
                  readOnly={readOnly}
                  level={level + 1}
                  part={subpart}
                  parts_path={parts_path.concat(subpart.id)}
                  openMaintenance={openMaintenance}
                  saveParts={saveParts}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      )}
    </div>
  );
};
