import { Checkbox, Modal, Tag, Timeline } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PincodeInput from "~/components/form/PincodeInput.jsx";
import { $t } from "~/i18n.js";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";

export const ModalSignWorkorder = ({
  visible,
  workorder,
  currentMaterial,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { profile, gegevens } = useSelector((state) => state.persist);

  const [overallOK, setOverall] = useState(false);
  const [signOK, setSignOK] = useState(false);
  const [pincode, setPincode] = useState(null);

  const isTechnicus = profile?.groups?.some(
    (g) => g === "technicus_vliegend" || g === "technicus_arc"
  );
  const number = isTechnicus ? gegevens?.aml_nummer : gegevens?.brevet_nummer;
  const disabled = !overallOK || !signOK || pincode?.length != 4;

  const [saving, setSaving] = useState(false);
  const triggerCompleteWorkorder = async () => {
    setSaving(true);
    const payload = {
      id: workorder.id,
      action: "sign",
      pincode,
    };
    const res = await dispatch(
      saveMaterialTab({
        activeTab: "workorders",
        materialID: currentMaterial.id,
        payload,
      })
    );
    if (res) {
      closeModal();
      Modal.success({
        title: $t("Afgerond"),
        content: $t(
          "Een ondertekende Workorder (CRS) is beschikbaar onder de documenten."
        ),
      });
    }
    setOverall(false);
    setSignOK(false);
    setPincode(null);
    setSaving(false);
  };

  if (!visible) return null;
  return (
    <Modal
      title={$t("Onderteken oplevering")}
      confirmLoading={saving}
      open={visible}
      onCancel={closeModal}
      okButtonProps={{ disabled }}
      onOk={triggerCompleteWorkorder}
    >
      <Timeline>
        <Timeline.Item color={overallOK ? "green" : "blue"}>
          <Checkbox
            checked={overallOK}
            onChange={(e) => setOverall(e.target.checked)}
          >
            {workorder.category === "amp" && (
              <p className="spacer">
                {isTechnicus ? (
                  <span>
                    {$t(
                      "Hierbij verklaar ik dat de het gespecifieerde werk, tenzij anders gespecificeerd, is uitgevoerd conform Part-ML, en met betrekking tot dit werk kan een vrijgave voor gebruik van het vliegtuig gegeven worden."
                    )}
                  </span>
                ) : (
                  <span>
                    {$t(
                      "Hierbij verklaar ik dat de het pilot-owner onderhoud, tenzij anders gespecificeerd, is uitgevoerd conform Part-ML, en met betrekking tot dit werk kan een vrijgave voor gebruik van het vliegtuig gegeven worden."
                    )}
                  </span>
                )}
              </p>
            )}
            {workorder.category === "arc" && (
              <p className="spacer">
                {$t(
                  "Hierbij bevestig ik dat de Airworthiness review is uitgevoerd conform Part-M subpart I, M.A.901."
                )}
              </p>
            )}
            {workorder.category === "non_aviation" && (
              <p className="spacer">
                {$t(
                  "Hierbij bevestig ik dat dit onderhoud correct is uitgevoerd."
                )}
              </p>
            )}
          </Checkbox>
        </Timeline.Item>

        {workorder.category !== "non_aviation" && (
          <Timeline.Item color={signOK ? "green" : "blue"}>
            {number && (
              <Checkbox
                checked={signOK}
                onChange={(e) => setSignOK(e.target.checked)}
              >
                {workorder.category === "amp" && (
                  <p className="spacer">
                    {isTechnicus ? (
                      <span>
                        {$t(
                          "Hierbij teken ik digitaal de Certificate of release to service (CRS), conform M.A.801(e).5, met mijn Maintenance Licence (AML) nummer: ${gevens}",
                          { gevens: gegevens?.aml_nummer || "ONBEKEND" }
                        )}
                      </span>
                    ) : (
                      <span>
                        {$t(
                          "Hierbij teken ik digitaal de Certificate of release to service (CRS), conform M.A.801(e).5, met mijn Brevet nummer: ${gevens}",
                          { gevens: gegevens?.brevet_nummer || "ONBEKEND" }
                        )}
                      </span>
                    )}
                  </p>
                )}

                {workorder.category === "arc" && (
                  <p className="spacer">
                    {$t(
                      "Hierbij teken ik digitaal de Airworthiness review certificate (ARC), conform M.A.801(e).5, met mijn Maintenance Licence (AML) nummer: ${gegevensnumber}.",
                      { gegevensnumber: gegevens?.aml_nummer }
                    )}
                  </p>
                )}
              </Checkbox>
            )}

            {!number && (
              <p>
                <Tag color="red">{$t("Let op")}</Tag>{" "}
                {$t("Geen ${isTechnicus} bekend. Ga naar", {
                  isTechnicus: isTechnicus
                    ? "Maintenance Licence (AML) nummer"
                    : "Brevet nummer",
                })}{" "}
                <Link to="/mijn_gegevens">{$t("mijn gegevens")}</Link>{" "}
                {$t("om je nummer toe te voegen.")}
              </p>
            )}
          </Timeline.Item>
        )}

        {workorder.category === "non_aviation" && (
          <Timeline.Item color={signOK ? "green" : "blue"}>
            <Checkbox
              checked={signOK}
              onChange={(e) => setSignOK(e.target.checked)}
            >
              <p className="spacer">{$t("Hierbij teken ik digitaal.")}</p>
            </Checkbox>
          </Timeline.Item>
        )}

        <Timeline.Item color={pincode?.length === 4 ? "green" : "blue"}>
          <p className="subtitle">{$t("Pincode")}</p>

          <PincodeInput
            disabled={!signOK || !overallOK}
            value={pincode}
            onComplete={setPincode}
          />
        </Timeline.Item>
      </Timeline>
    </Modal>
  );
};
