import React from 'react'
import { $t } from '~/i18n.js'

import { Tag } from 'antd'

export const SearchTags = ({ items, setSearchTags, searchTags, default_label }) => {
  const toggleTag = (tag) => {
    if (searchTags.indexOf(tag) >= 0) {
      setSearchTags(searchTags.filter(t => t !== tag))
    } else {
      setSearchTags(searchTags.concat(tag))
    }
  }
  return items.map((i, key) => <Tag
    className='clickable' key={key}
    color={i.color || 'green'} onClick={() => toggleTag(i.name)}
    style={{ opacity: searchTags.indexOf(i.name) === -1 ? 0.3 : 1 }}
                               >
    {i.name ? $t(i.name) : (default_label || $t('algemeen'))}
  </Tag>)
}
