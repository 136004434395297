import React from "react";
import { $t, i18n } from "~/i18n.js";

function timeSince(date = new Date()) {
  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
  ];
  const secondsDelta = Math.floor((date.getTime() - Date.now()) / 1000);

  // for today and tomorrow add in this
  if (secondsDelta <= 0) {
    return $t("vandaag");
  } else if (secondsDelta <= 86400) {
    return $t("morgen");
  }

  const secondsAbs = Math.abs(secondsDelta);
  const interval = intervals.find((i) => i.seconds < secondsAbs);
  const count = Math.floor(secondsDelta / interval.seconds);

  const rtf = new Intl.RelativeTimeFormat(i18n ? (i18n.language || "en") : "en", {
    style: "long",
  });
  return rtf.format(count, interval.label);
}

function DateTimeDiff({ value }) {
  return (
    <span className="DateTimeDiff" title={value}>
      {timeSince(new Date(value))}
    </span>
  );
}

export default DateTimeDiff;
