export const persistState = {

  isAuthenticated: false,

  isPending: false,

  // club overview
  lastUpdatedClubs: null,
  all_clubs: [],

  // vlieger data with my starts
  lastUpdatedData: null,
  vlieger_data: {
    flights: [],
    notities: [],
    tweede: [],
    gezagvoerder: [],
    bestanden: [],
    currency: {},
    gegevens: {},
    behaalde_bevoegdheden: {}
  },

  // user profile
  lastUpdated: null,
  club: {
    full_name: '',
    name: '',
    schema_name: '',
    voorval_link: null,
    has_bank: true,
    flight_cat: '',
    sleep_cat: '',
    tmg_cat: '',
    groups_filter: '',
    groups_aanmeld: '',
    default_vliegers: '',
    private_igc: false,
    unit: 'm',
    translation: {}
  },
  profile: {
    id: '',
    user_name: '',
    first_name: '',
    last_name: '',
    lid_nummer: '',
    iban: '',

    // roles based on app permissions
    group_keys: [],
    app_permissions: [],
    vliegt: false,
    zichzelf_aanmelden: false,
    iedereen_aanmelden: false,
    wijzig_start: false,
    wijzig_sleep: false,
    wijzig_rooster: false,
    wijzig_melding: false,
    wijzig_melding_vliegend: false,
    wijzig_melding_rollend: false,
    wijzig_winteruren: false,
    wijzig_material: false,
    goedkeuren_uren: false,
    wijzig_documenten: false,
    wijzig_leden: false,

    wijzig_dto: false,
    ziet_dto: false,
    ziet_alle_data: false,
    uitgifte_bevoegheid: false,
    is_instructeur: false,

    ziet_emaillijst: false,

    ziet_staff: false,

    // currency
    tag: '', // dbo, solist, brevet, instructeur

    check_required: false,
  },
  gegevens: {
    phone: ''
  },
  bestanden: [],
  news: [],

  // frontend personal preferences
  kist_aanmelding: null,
  aggregate_starts: false,
  preferred_reply_to: '',
  preferred_reply_to_name: '',

  // feedback
  votes: {},
  feedbackUpdated: null,
  feedback: [],

  // for personal data check
  check_last_shown: null

}
