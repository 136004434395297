import { Form, Input, Select } from "antd";
import React from "react";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";
import { $t, i18n, locales } from "~/i18n.js";
import { LengthValidator } from "~/components/form/formHelpers.js";

export const Basics = ({club}) => {
  return (
    <SettingCard title={$t("Basis")}>
      <Form.Item
        label={$t("Taal")}
        name="language"
        extra={
          <p>
            {$t("Configureer de vertalingen voor je eigen club via")}{" "}
            <a href="/admin/translation">{$t("club vertalingen")}</a>.
          </p>
        }
      >
        <Select>
          {i18n.languages.map((lng) => (
            <Select.Option key={lng} value={lng}>
              {locales[lng]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={$t("Eenheden")} name="unit">
        <Select>
          <Select.Option value="m">
            {$t("Europees (meter, km/h)")}
          </Select.Option>
          <Select.Option value="uk">{$t("Engels (feet, km/h)")}</Select.Option>
          <Select.Option value="us">
            {$t("United States (feet, mph)")}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label={$t("Tijdzone")} name="timezone">
        <Select>
          {[
            "Europe/Amsterdam",
            "Europe/London",
            "US/Central",
            "US/Eastern",
            "US/Pacific",
          ].map((key) => (
            <Select.Option key={key} value={key}>
              {key}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={$t("Bank module actief")}
        extra={$t(
          "Indien actief krijgen je leden de mogelijkheid om hun bankrekeningnummer in het systeem te voegen voor een automatische incasso"
        )}
        name="has_bank"
      >
        <SwitchYesNo />
      </Form.Item>

      <Form.Item
        label={$t("Bankrekening verplicht")}
        extra={$t("Gebruikers moeten dan hun bankrekening nummer invullen")}
        name="enforce_bank"
      >
        <SwitchYesNo />
      </Form.Item>


      <Form.Item
        label={$t("Check medical bij alle gebruikers")}
        extra={<span>{$t("De verantwoordelijkheid voor een correcte medical voor instructeurs en leerlingen ligt bij de DTO.")} {$t('Indien je als club ook de medical wilt controleren van brevethouders, zet deze optie dan aan.')}</span>}
        name="medical_must_check_all"
      >
        <SwitchYesNo />
      </Form.Item>

      {false && club.country == 'NL' && <Form.Item
        label={$t("Verbind met KNVvL")}
        extra={<span>{$t("Optie om de KNVvL te koppelen aan je club zodat de verzekering van vliegers automatisch gecontroleerd kunnen worden")} {$t('Via de KNVvL nummer en geboortedatum wordt de verzekeringsgegevens opgehaald, verder worden er geen gegevens gedeeld.')}</span>}
        name="knvvl_connection"
      >
        <SwitchYesNo />
      </Form.Item>}

      <Form.Item
        label={$t("IGC privé")}
        extra={$t(
          "Optie om leden de keuze te bieden om de zichtbaarheid van aangemaakte IGC bestanden aan te passen. Indien False, zal de zichtbaarheid standard aan staan"
        )}
        name="private_igc"
      >
        <SwitchYesNo />
      </Form.Item>

      <Form.Item
        label={$t("Mails bij melding")}
        extra={$t(
          "Email de contactpersonen van vliegend en rollend bij elke melding die is aangemeld en opgelost (niet alleen de technicus)"
        )}
        name="always_update_on_melding"
      >
        <SwitchYesNo />
      </Form.Item>

      <Form.Item
        label={$t("Email actief")}
        extra={$t(
          "Optie om (tijdelijk) alle emails uit te zetten. Indien nee, dan worden er geen mails verstuurt."
        )}
        name="enable_email"
      >
        <SwitchYesNo />
      </Form.Item>

      <Form.Item
        label={$t("Voorval link")}
        extra={$t("URL van link waar voorval anoniem gemeld kan worden")}
        name="voorval_link"
        rules={[LengthValidator(200, false)]}
      >
        <Input />
      </Form.Item>
    </SettingCard>
  );
};
