import {
  MessageOutlined,
  PlusOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";

import { testDateIsToday } from "~/lib/localize.js";
import { MaintenanceStatusTag, MeldingStatusTag } from "../../materialTags.jsx";
import { AddInspection } from "./addInspection.jsx";
import { Inspection } from "./inspection.jsx";
import { DescriptionRow } from "~/components/descriptionRow/descriptionRow.jsx";

export const MaterialStatusDescription = ({
  currentMaterial,
  inspection,
  last_motor_minutes,
  currentProfile,
  readOnly = true,
}) => {
  const [openInspection, setOpenInspection] = useState(false);

  return (
    <div>
      {openInspection && (
        <AddInspection
          currentMaterial={currentMaterial}
          closeModal={() => setOpenInspection(false)}
          visible={openInspection}
          last_motor_minutes={last_motor_minutes}
        />
      )}

      <div style={{ marginBottom: 12 }}>
        <DescriptionRow label={$t("Status")}>
          <MeldingStatusTag status={currentMaterial.status} />
        </DescriptionRow>

        {currentMaterial.maintenance_status !== "" && (
          <DescriptionRow label={$t("Maintenance")}>
            <MaintenanceStatusTag
              maintenance_status={currentMaterial.maintenance_status}
              default_value="nvt"
            />
          </DescriptionRow>
        )}

        {currentMaterial.document_status !== "" && (
          <DescriptionRow label={$t("Documenten")}>
            <MaintenanceStatusTag
              maintenance_status={currentMaterial.document_status}
              default_value="nvt"
            />
          </DescriptionRow>
        )}

        {currentMaterial.enable_inspections && (
          <DescriptionRow label={$t("Dagelijkse inpectie")}>
            {testDateIsToday(inspection?.date_created) ? (
              <Inspection inspection={inspection} currentUserID={currentProfile?.id} />
            ) : readOnly ? (
              <Inspection
                inspection={inspection}
                missingText={$t("Niet uitgevoerd")}
              />
            ) : (
              <>
                {currentProfile?.group_keys?.indexOf("brevet") > -1 ? (
                  <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setOpenInspection(true)}
                  >
                    {$t("Voer dagelijkse inspectie uit")}
                  </Button>
                ) : (
                  <p>
                    <WarningTwoTone
                      twoToneColor="red"
                      style={{ marginRight: 6 }}
                    />
                    {$t("Alleen voor brevethouders")}
                  </p>
                )}
              </>
            )}
          </DescriptionRow>
        )}

        {currentMaterial.remark && (
          <DescriptionRow>
            <MessageOutlined style={{ marginRight: 4 }} />{" "}
            {currentMaterial.remark}
          </DescriptionRow>
        )}
      </div>
    </div>
  );
};
