import React from 'react';

export const DateTimeFormat = ({
  date,
  options = {
    weekday: "short", // "narrow", "short", "long"
    year: "numeric", // "2-digit", "numeric"
    month: "2-digit", // "2-digit", "numeric", "narrow", "short", "long"
    day: "2-digit", // "2-digit", "numeric"
    hour: "2-digit", // "2-digit", "numeric"
    minute: "2-digit", // "2-digit", "numeric"
  },
}) => {
  const formattedDateTime = new Date(date).toLocaleString(
    localStorage.locale || "nl-NL",
    options
  );
  return <span>{formattedDateTime}</span>;
};
