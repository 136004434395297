import { MinusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { DateFormat } from "~/components/date/DateFormat.jsx";
import { $t } from "~/i18n.js";
import { colors } from "~/theme/colors.js";
import { RecencyValidThrough } from "./RecencyValidThrough.jsx";

export const RecencyRowTd = ({ item }) => {
  const {
    is_valid,
    label,
    value,
    min,
    valid_through,
    alert_date,
    date,
    hasDate,
  } = item;
  return (
    <>
      <td className="col">{label}</td>
      {hasDate && date && (
        <td className="small">
          <DateFormat
            date={date}
            options={{ month: "short", day: "2-digit", year: "2-digit" }}
          />
        </td>
      )}

      {hasDate && !date && (
        <MinusCircleOutlined
          style={{ color: colors.darkgray_light, marginLeft: 6 }}
        />
      )}

      {!hasDate && min > 0 && (
        <>
          {value < min ? (
            <td className="col">
              {value} / {min}
            </td>
          ) : (
            <td className="col">
              {value}
              <Tooltip
                placement="top"
                title={$t("Minimale recency eisen om te voldoen aan eisen.")}
              >
                <span className="gray">(≥{min})</span>
              </Tooltip>
            </td>
          )}
        </>
      )}

      {!hasDate && min == 0 && <td className="col" />}

      <RecencyValidThrough
        is_invalid={!is_valid}
        valid_through={valid_through}
        alert_date={alert_date}
      />
    </>
  );
};
