import {
  CheckCircleFilled,
  LinkOutlined,
  MessageOutlined,
  PlusOutlined,
  StopOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Button, Collapse, Modal, Popconfirm, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { $t } from "~/i18n.js";

import { check, double_check, Icon } from "~/components/icon/icon.jsx";

import { ConfirmTaskModal } from "./confirmTask.jsx";

import { useDispatch } from "react-redux";
import { BerichtFileThumbnails } from "~/components/bericht/bericht.jsx";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { _parseDateTime } from "~/lib/localize.js";
import {
  addMelding,
  confirmWorkorderTask,
} from "~/redux/materiaal/materiaal.actions.js";
import { colors } from "~/theme/colors.js";
import { RegistratieForm } from "../../../onderhoud/registratie.form.jsx";
import { AddMeldingModal } from "../../melding/sections/addMeldingModal.jsx";
import { DocumentList } from "../documents/documentList.jsx";
import { restrictionLookup } from "../maintenance/modalViewMaintenance.jsx";
import { WorkorderStatus } from "./workorderStatus.jsx";

export const TaskStatus = ({
  workorder,
  n_tasks_completed,
  n_critical_tasks_completed,
  remark,
}) => {
  if (workorder.state === "completed") return <Tag>{$t("Afgerond")}</Tag>;
  return (
    <div className="row" style={{ gap: 12 }}>
      <span>
        <Icon
          style={{ color: colors.groen_dark, marginRight: 4 }}
          icon={check}
        />
        {n_tasks_completed || workorder.n_tasks_completed} / {workorder.n_tasks}
      </span>

      {workorder.n_critical_tasks > 0 && (
        <Tooltip
          placement="top"
          title={$t(
            "Kritieke taken die door een tweede persoon worden gecontroleerd."
          )}
        >
          <span>
            <Icon
              style={{ color: colors.red, marginRight: 4 }}
              icon={double_check}
            />
            {n_critical_tasks_completed || workorder.n_critical_tasks_completed}{" "}
            / {workorder.n_critical_tasks}
          </span>
        </Tooltip>
      )}

      {remark && (
        <p>
          <MessageOutlined /> {remark}
        </p>
      )}
    </div>
  );
};

export const TaskCard = ({
  task,
  currentProfile,
  openConfirmTask,
  confirmTask,
  canSetTasks,
}) => {
  return (
    <div className="partCard" style={{ padding: "12px 0 12px 12px" }}>
      {task.description && <p className="userInput">{task.description}</p>}

      {task.remark && (
        <p className="userInput">
          <MessageOutlined style={{ marginRight: 6 }} />
          {task.remark}
        </p>
      )}

      {!task.signed_date &&
        !currentProfile[task.restriction] &&
        task.restriction && (
          <p className="small">
            <Icon
              style={{ color: colors.groen_dark, marginRight: 3 }}
              icon={check}
            />
            {$t("Mag alleen door ${restriction} worden afgetekend.", {
              restriction: $t(restrictionLookup[task.restriction]),
            })}
          </p>
        )}

      {task.signed_date &&
        !task.checked_date &&
        task.is_critical &&
        !currentProfile[task.restriction] &&
        task.restriction && (
          <p className="small">
            <Icon
              style={{ color: colors.groen_dark, marginRight: 3 }}
              icon={double_check}
            />
            {$t("Moet door ${restriction} worden gecontroleerd.", {
              restriction: $t(restrictionLookup[task.restriction]),
            })}
          </p>
        )}

      {task.signed_date && (
        <p className="small">
          <Icon
            style={{ color: colors.groen_dark, marginRight: 3 }}
            icon={check}
          />
          {$t("Afgetekend door ${name} op ${date}.", {
            name: task.signed_by_name,
            date: _parseDateTime(task.signed_date),
          })}

          <Popconfirm
            placement="bottom"
            title={$t("Zeker weten dat je het aftekenen wilt intrekken?")}
            disabled={!canSetTasks}
            onConfirm={() => confirmTask(task, "redact_sign")}
            okText={$t("Ja")}
            cancelText={$t("Nee")}
          >
            <Button
              danger
              icon={<StopOutlined />}
              loading={false}
              size="small"
              shape="circle"
              style={{ marginLeft: 6 }}
              type="text"
              disabled={!canSetTasks}
            />
          </Popconfirm>
        </p>
      )}

      {task.checked_date && (
        <p className="small">
          <Icon
            style={{ color: colors.groen_dark, marginRight: 3 }}
            icon={check}
          />
          {$t("Gecontroleerd door ${name} op ${date}.", {
            name: task.signed_by_name,
            date: _parseDateTime(task.signed_date),
          })}
          <Popconfirm
            placement="bottom"
            title={$t("Zeker weten dat je de controle wilt intrekken?")}
            disabled={!canSetTasks}
            onConfirm={() => confirmTask(task, "redact_check")}
            okText={$t("Ja")}
            cancelText={$t("Nee")}
          >
            <Button
              danger
              icon={<StopOutlined />}
              loading={false}
              size="small"
              shape="circle"
              style={{ marginLeft: 6 }}
              type="text"
              disabled={!canSetTasks}
            />
          </Popconfirm>
        </p>
      )}

      <BerichtFileThumbnails files={task.files} width={100} />

      {task.tasks?.length > 0 && (
        <CollapsibleViewTasks
          tasks={task.tasks}
          currentProfile={currentProfile}
          openConfirmTask={openConfirmTask}
          confirmTask={confirmTask}
          canSetTasks={canSetTasks}
        />
      )}
    </div>
  );
};

const CollapsibleViewTasks = ({
  tasks,
  currentProfile,
  openConfirmTask,
  confirmTask,
  canSetTasks,
}) => {
  let defaultActiveKeys = [];
  for (let i = 0; i < tasks.length; i++) {
    if (defaultActiveKeys.length < 1 && !tasks[i].is_completed) {
      defaultActiveKeys = [i];
      break;
    }
  }
  return (
    <Collapse
      style={{ marginTop: 12, width: "100%" }}
      defaultActiveKey={defaultActiveKeys}
      accordion
    >
      {tasks.map((subtask, key) => {
        const disabled =
          !canSetTasks ||
          (subtask.restriction && !currentProfile[subtask.restriction]);
        return (
          <Collapse.Panel
            key={key}
            header={
              <span>
                {subtask.prefix && (
                  <Tag style={{ marginRight: 6 }}>{subtask.prefix}</Tag>
                )}
                {subtask.name}
                {subtask.is_critical && (
                  <Icon
                    style={{ color: colors.red, marginLeft: 6 }}
                    icon={double_check}
                    viewBox="0 0 1024 800"
                  />
                )}
                {subtask.is_altered && (
                  <ToolOutlined
                    style={{ marginLeft: 6, color: colors.darkgray }}
                  />
                )}
                {subtask.files?.length > 0 && (
                  <Tag color="blue" style={{ marginLeft: 6 }}>
                    {subtask.files.length} <LinkOutlined />
                  </Tag>
                )}
              </span>
            }
            showArrow={
              subtask.description ||
              subtask.is_critical ||
              subtask.tasks?.length > 0
            }
            extra={[
              subtask.tasks_remain > 0 && (
                <span key="1" style={{ margin: "0 6px" }}>
                  {" "}
                  <Icon style={{ color: colors.groen_dark }} icon={check} />
                  {subtask.tasks_remain}
                </span>
              ),
              subtask.critical_remain > 0 && (
                <span key="2" style={{ margin: "0 6px" }}>
                  {" "}
                  <Icon style={{ color: colors.red }} icon={double_check} />
                  {subtask.critical_remain}
                </span>
              ),

              (subtask.tasks_remain > 0 || !subtask.signed_date) && (
                <Button
                  key="4"
                  onClick={(e) => {
                    e.stopPropagation();
                    openConfirmTask(subtask, "sign");
                  }}
                  disabled={disabled}
                  style={{ marginLeft: 6 }}
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={
                    <Icon
                      style={{
                        color: disabled ? colors.gray : "white",
                        marginLeft: 3,
                      }}
                      icon={check}
                    />
                  }
                />
              ),
              (subtask.critical_remain > 0 ||
                (subtask.is_critical &&
                  subtask.signed_date &&
                  !subtask.checked_date)) && (
                <Button
                  key="5"
                  onClick={(e) => {
                    e.stopPropagation();
                    openConfirmTask(subtask, "check");
                  }}
                  disabled={disabled}
                  style={{ marginLeft: 6 }}
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={
                    <Icon
                      style={{
                        color: disabled ? colors.gray : "white",
                        marginLeft: 3,
                      }}
                      icon={double_check}
                    />
                  }
                />
              ),

              subtask.is_completed && (
                <CheckCircleFilled
                  key="5"
                  style={{
                    color: colors.groen_dark,
                    fontSize: 20,
                    marginRight: 2,
                  }}
                />
              ),
            ]}
          >
            <TaskCard
              task={subtask}
              currentProfile={currentProfile}
              openConfirmTask={openConfirmTask}
              confirmTask={confirmTask}
              canSetTasks={canSetTasks}
            />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

export const ModalViewWorkorder = ({
  currentMaterial,
  currentProfile,
  workorder,
  tasks,
  visible,
  closeModal,
  readOnly,
}) => {
  const normalizeTask = (task) => {
    task.tasks_remain = 0;
    task.critical_remain = 0;
    task.tasks?.forEach((t) => {
      if (!t.signed_date) task.tasks_remain += 1;
      if (t.is_critical && !t.checked_date) task.critical_remain += 1;
      normalizeTask(t);
    });
    task.is_completed =
      task.tasks_remain === 0 &&
      task.critical_remain === 0 &&
      task.signed_date &&
      (!task.is_critical || task.checked_date);
  };
  tasks.forEach(normalizeTask);

  // Can only complete order if restriction same
  const canSetTasks = !readOnly && workorder?.state === "active";

  const [showConfirmTask, setShowConfirmTask] = useState(null);
  const [confirmAction, setConfirmAction] = useState("");
  const openConfirmTask = (task, action) => {
    setShowConfirmTask(task);
    setConfirmAction(action);
  };
  const closeConfirmModal = () => {
    setShowConfirmTask(null);
    setConfirmAction("");
  };

  const dispatch = useDispatch();
  const [meldingModalOpen, setMeldingModalOpen] = useState(false);
  const handleAddMeldingNew = async (data) => {
    const melding = await dispatch(addMelding(data));
    if (melding?.id) {
      window.location.reload();
    }
    return melding;
  };

  // redact task
  const confirmTask = async (task, action, remark) => {
    await dispatch(
      confirmWorkorderTask({
        id: task.id,
        action,
        remark: remark || task.remark,
      })
    );
  };

  const [activeRegistratie, setActiveRegistratie] = useState(null);

  return (
    <div>
      {activeRegistratie && (
        <RegistratieForm
          visible={!!activeRegistratie}
          registratie={activeRegistratie}
          showAllUsers={false}
          closeModal={() => setActiveRegistratie(null)}
        />
      )}

      {showConfirmTask && (
        <ConfirmTaskModal
          closeModal={closeConfirmModal}
          material_id={currentMaterial.id}
          currentProfile={currentProfile}
          task={showConfirmTask}
          action={confirmAction}
          confirmTask={confirmTask}
        />
      )}

      {meldingModalOpen && (
        <AddMeldingModal
          visible={meldingModalOpen}
          onSave={handleAddMeldingNew}
          onClose={() => setMeldingModalOpen(false)}
          activeMelding={null}
          currentMaterial={currentMaterial}
          currentWorkorderID={workorder.id}
        />
      )}

      <Modal
        title={$t("Bekijk taken voor ${workordername}", {
          workordername: workorder?.name,
        })}
        width={800}
        open={visible}
        onCancel={closeModal}
        footer={[
          <Button
            key="0"
            icon={<PlusOutlined />}
            onClick={() =>
              setActiveRegistratie({
                material_id: currentMaterial.id,
                workorder_id: workorder.id,
                date: dayjs().format("YYYY-MM-DD"),
              })
            }
          >
            {$t("Werkuren")}
          </Button>,

          <Button
            key="melding"
            icon={<PlusOutlined />}
            onClick={() => setMeldingModalOpen(true)}
            disabled={!canSetTasks}
          >
            {$t("Melding")}
          </Button>,

          <SluitButton key="sluit" onClick={closeModal} />,
        ]}
      >
        <div>
          <div
            className="row"
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 12,
            }}
          >
            <p className="darkgray label">{$t("Status")}</p>

            <div
              className="column"
              style={{ alignItems: "flex-start", gap: 6 }}
            >
              <TaskStatus
                workorder={workorder}
                n_tasks_completed={currentMaterial.n_tasks_completed}
                n_critical_tasks_completed={
                  currentMaterial.n_critical_tasks_completed
                }
                remark={workorder.remark}
              />

              <WorkorderStatus
                currentMaterial={currentMaterial}
                currentWorkorder={workorder}
                currentProfile={currentProfile}
                canSetTasks={canSetTasks}
                readOnly={readOnly}
              />
            </div>
          </div>

          {workorder?.documents_used?.length > 0 && (
            <DocumentList
              header={$t("Gebruikte documenten")}
              readOnly={true}
              documents={workorder.documents_used}
            />
          )}

          <p className="label darkgray" style={{ marginTop: 18 }}>
            {$t("Taken")}
          </p>

          {tasks?.length === 0 && (
            <p style={{ marginTop: 12 }}>{$t("Geen taken ingeladen")}</p>
          )}

          {tasks?.length > 0 && (
            <CollapsibleViewTasks
              tasks={tasks}
              currentProfile={currentProfile}
              openConfirmTask={openConfirmTask}
              confirmTask={confirmTask}
              canSetTasks={canSetTasks}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
