import { Breadcrumb } from "antd";
import React from "react";

export const BreadcrumbHeader = ({ breadcrumbs, buttons }) => {
  function itemRender(currentRoute) {
    // https://ant.design/components/breadcrumb#use-with-browserhistory
    return typeof currentRoute === "string" ? (
      <p className="title">{currentRoute}</p>
    ) : (
      currentRoute
    );
  }

  return (
    <div
      className="row"
      style={{
        justifyContent: "space-between",
        minHeight: 35,
        flexWrap: "wrap",
      }}
    >
      <Breadcrumb className="row" itemRender={itemRender} items={breadcrumbs} />

      <div className="row">
        {buttons?.map((button, idx) => (
          <div key={idx}>{button}</div>
        ))}
      </div>
    </div>
  );
};
