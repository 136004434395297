import { marked } from "marked";
import React from "react";

// using dangerous html to parse text to markdown
export const Markdown = ({ text }) => {
  if (!text) return null;
  
  return (
    <div className="markdown" dangerouslySetInnerHTML={{ __html: marked.parse(text) }} />
  );
}