import { LoginOutlined } from '@ant-design/icons'
import { Button, Descriptions, Skeleton, Tabs } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { $t } from '~/i18n.js'

import { materiaalActions } from '~/redux/actions.js'

import { Link } from 'react-router-dom'
import { BreadcrumbHeader } from '~/components/layout/breadcrumbHeader.jsx'
import { MainLayout } from '~/components/layout/layout.jsx'
import { MaterialData } from './tabs/data/materialData.jsx'
import { MaterialDocuments } from './tabs/documents/materialDocuments.jsx'
import { MaterialHours } from './tabs/hours/materialHours.jsx'
import { MaterialLogbook } from './tabs/logbook/materialLogbook.jsx'
import { MaterialMaintenance } from './tabs/maintenance/materialMaintenance.jsx'
import { MaterialParts } from './tabs/parts/materialParts.jsx'
import { MaterialStatus } from './tabs/status/materiaalStatus.jsx'
import { MaterialWorkorders } from './tabs/workorders/materialWorkorders.jsx'
import { Flex } from 'antd'
import { RefreshBaseData } from '~/components/buttons/RefreshBaseData.jsx'

const MaterialTabs = ({
  activeTab, history, currentMaterial, persist,
  materialID, obj_id, action, hasAccess, readOnly
}) => {
  return (
    <Tabs
      activeKey={activeTab}
      onChange={(newTab) =>
        history.push(
          `/${
            hasAccess ? "material" : "status/material"
          }/${materialID}/${newTab}`
        )
      }
    >
      <Tabs.TabPane tab={$t("Status")} key="meldingen">
        <Skeleton loading={!currentMaterial.meldingen} active paragraph>
          {currentMaterial.meldingen && (
            <MaterialStatus
              history={history}
              currentProfile={persist.profile}
              currentMaterial={currentMaterial}
              meldingen={currentMaterial.meldingen?.meldingen}
              inspection={currentMaterial.meldingen?.inspection}
              last_motor_minutes={currentMaterial.meldingen?.last_motor_minutes}
            />
          )}
        </Skeleton>
      </Tabs.TabPane>

      {hasAccess && currentMaterial.category === "kist" && (
        <Tabs.TabPane tab={$t("Logbook")} key="logbook">
          <Skeleton loading={!currentMaterial.logbook} active paragraph>
            {currentMaterial.logbook && (
              <MaterialLogbook
                history={history}
                year={obj_id}
                currentMaterial={currentMaterial}
              />
            )}
          </Skeleton>
        </Tabs.TabPane>
      )}

      {hasAccess && (
        <Tabs.TabPane tab={$t("Workorders")} key="workorders">
          <Skeleton loading={!currentMaterial.workorders} active paragraph>
            {currentMaterial.workorders && (
              <MaterialWorkorders
                history={history}
                currentMaterial={currentMaterial}
                currentProfile={persist.profile}
                currentRole={currentMaterial?.current_role}
                workorders={currentMaterial.workorders?.workorders}
                tasks={currentMaterial.workorders?.tasks}
                active_workorder_id={activeTab === "workorders" && obj_id}
                action={action}
                readOnly={readOnly}
              />
            )}
          </Skeleton>
        </Tabs.TabPane>
      )}

      <Tabs.TabPane tab={$t("Maintenance")} key="maintenance">
        <Skeleton loading={!currentMaterial.maintenance} active paragraph>
          {currentMaterial.maintenance && (
            <MaterialMaintenance
              history={history}
              currentMaterial={currentMaterial}
              isAuthenticated={persist.isAuthenticated}
              maintenance={currentMaterial.maintenance?.maintenance}
              tasks={currentMaterial.maintenance?.tasks}
              active_maintenance_id={activeTab === "maintenance" && obj_id}
              action={action}
              hasAccess={hasAccess}
              readOnly={readOnly}
            />
          )}
        </Skeleton>
      </Tabs.TabPane>

      <Tabs.TabPane tab={$t("Documenten")} key="documents">
        <Skeleton loading={!currentMaterial.documents} active paragraph>
          {currentMaterial.documents && (
            <MaterialDocuments
              currentMaterial={currentMaterial}
              chapters={currentMaterial.documents?.chapters}
              readOnly={readOnly}
            />
          )}
        </Skeleton>
      </Tabs.TabPane>

      <Tabs.TabPane tab={$t("Parts")} key="parts">
        <Skeleton loading={!currentMaterial.parts} active paragraph>
          <MaterialParts
            currentMaterial={currentMaterial}
            hasAccess={hasAccess}
            parts={currentMaterial.parts?.parts || []}
            readOnly={readOnly}
            history={history}
          />
        </Skeleton>
      </Tabs.TabPane>

      {hasAccess && (
        <Tabs.TabPane tab={$t("Werkuren")} key="hours">
          <Skeleton loading={!currentMaterial.hours} active paragraph>
            {currentMaterial.hours && (
              <MaterialHours
                history={history}
                readOnly={readOnly}
                currentPeriodID={obj_id}
                currentMaterial={currentMaterial}
              />
            )}
          </Skeleton>
        </Tabs.TabPane>
      )}

      <Tabs.TabPane tab={$t("Gegevens")} key="data">
        <Skeleton loading={!currentMaterial.data} active paragraph>
          <MaterialData
            currentMaterial={currentMaterial}
            hasAccess={hasAccess}
            wijzig_material={persist.profile.wijzig_material}
            kist={currentMaterial.data?.kist}
            members={currentMaterial.data?.members || []}
            readOnly={readOnly}
            history={history}
          />
        </Skeleton>
      </Tabs.TabPane>
    </Tabs>
  );
}

const MaterialTabsComponent = ({ persist, history, match, currentMaterial, openNewMaterial, getMaterialTab }) => {
  // parameters from the URL path, with defaults
  let { materialID, activeTab, obj_id, action } = match.params
  materialID = materialID?.length < 5 ? parseInt(materialID) : materialID
  activeTab = activeTab || 'meldingen'
  obj_id = obj_id || ''

  useEffect(() => {
    // only open if material id updated
    if (materialID !== currentMaterial.id && materialID !== currentMaterial.uuid) {
      openNewMaterial({ materialID })
    }
  }, [materialID, currentMaterial.id])

  const is_technician_vliegend = currentMaterial.category === 'kist' && persist.profile?.group_keys?.indexOf('technicus_vliegend') > 0
  const hasAccess = persist.isAuthenticated &&
    (persist.profile.wijzig_material || currentMaterial.current_role ||
      ['overig', 'rollend'].indexOf(currentMaterial.category) > -1 ||
      ['club', 'sleep'].indexOf(currentMaterial.kist_category) > -1 ||
      (currentMaterial.visible_for_technicians && is_technician_vliegend)
    )

  // determine status of user, #TODO: finetune model to tweak
  const readOnly = !(persist.profile.wijzig_material ||
    currentMaterial?.current_role === 'admin' ||
    currentMaterial?.current_role === 'manager' ||
    currentMaterial?.current_role === 'technician' ||
    currentMaterial?.current_role === 'arc_technician' ||
    (currentMaterial.visible_for_technicians && currentMaterial.kist_category === 'prive' && is_technician_vliegend) ||
    (currentMaterial.category !== 'kist' && persist.profile.group_keys?.indexOf('technicus_rollend') > 0) ||
    (currentMaterial.category === 'kist' && currentMaterial.kist_category === 'club' && persist.profile.group_keys?.indexOf('technicus_vliegend') > 0)
  )

  // switch tabs
  useEffect(() => {
    // only if you switch tabs or have a new material downloaded
    const prev_object_id = currentMaterial && (currentMaterial[activeTab]?.obj_id)

    // make sure redux first loads in ID into redux
    if (materialID && (currentMaterial?.id === materialID || currentMaterial?.uuid === materialID) &&
      (
        // a. if tab is empty, load in
        !currentMaterial[activeTab] ||
        // b. if tab is not empty, load in only if you go from null to int
        (!prev_object_id && !!obj_id) ||
        // c. if you switch from one to another
        (!!prev_object_id && !!obj_id && prev_object_id !== obj_id)
      )) {
      getMaterialTab({ materialID, activeTab, obj_id })
    }
  }, [materialID, currentMaterial, activeTab, obj_id])

  if (persist.isAuthenticated) {
    return (
      <MainLayout history={history}>

        <Flex justify='space-between'>
          <BreadcrumbHeader
            breadcrumbs={[
              <Link key='1' className='title' to='/material'>{$t('Materieel')}</Link>,
              <p key='2' className='title'>{currentMaterial?.name}</p>
            ]}
            buttons={[]}
          />

          <RefreshBaseData />
        </Flex>

        <MaterialTabs
          activeTab={activeTab}
          history={history}
          currentMaterial={currentMaterial}
          persist={persist}
          readOnly={readOnly}

          materialID={materialID}
          obj_id={obj_id}
          action={action}
          hasAccess={hasAccess}
        />

      </MainLayout>
    )
  }

  if (!persist.isAuthenticated) {
    return (
      <div style={{ height: '100vh', overflowY: 'auto' }}>

        <div style={{ padding: 12 }}>
          <p className='title'>{currentMaterial?.name || '.'}</p>
          <Descriptions.Item>{currentMaterial.type}</Descriptions.Item>

          <MaterialTabs
            activeTab={activeTab}
            history={history}
            currentMaterial={currentMaterial}
            persist={persist}
            readOnly={readOnly}

            materialID={materialID}
            obj_id={obj_id}
            action={action}
            hasAccess={hasAccess}
          />
        </div>

        <div
          className='row' style={{
            justifyContent: 'center',
            zIndex: 2000,
            padding: '6px 0',
            backgroundColor: 'white',
            borderTop: '1px solid #bfbfbf',
            position: 'absolute',
            width: '100%',
            marginTop: 42,
            bottom: 0
          }}
        >
          <p>
            <Button style={{ paddingRight: 0 }} href='/login' icon={<LoginOutlined />} siz='small' type='link'>Login</Button> voor meer details
          </p>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentMaterial: state.materiaal.currentMaterial,
  persist: state.persist
})

const mapDispatchToProps = (dispatch) => {
  return {
    openNewMaterial: (d) => dispatch(materiaalActions.openNewMaterial(d)),
    getMaterialTab: (d) => dispatch(materiaalActions.getMaterialTab(d))
  }
}

export const MaterialTabsPage = connect(mapStateToProps, mapDispatchToProps)(MaterialTabsComponent)
