import { ToolOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'
import { _parseDate } from '~/lib/localize.js'
import { MeldingCategoryTag, MeldingStatusTag } from '../../materialTags.jsx'

const renderDate = (melding, field) => (
  <Space>
    <p>{melding[field] ? _parseDate(melding[field]) : '-'}</p>
  </Space>
)

const date_created = () => ({
  title: $t('Aangemaakt'),
  dataIndex: 'date_created',
  render: (_, melding) => renderDate(melding, 'date_created'),
  sorter: (a, b) => new Date(a.date_created) - new Date(b.date_created)
})

const date_updated = () => ({
  title: $t('Laatste update'),
  dataIndex: 'date_updated',
  render: (_, melding) => renderDate(melding, 'date_updated'),
  sorter: (a, b) => new Date(a.date_updated) - new Date(b.date_updated)
})

const date_verwacht_afgehandeld = () => ({
  title: $t('Verwachte einddatum'),
  dataIndex: 'date_verwacht_afgehandeld',
  render: (_, melding) => renderDate(melding, 'date_verwacht_afgehandeld')
})

const status = () => ({
  title: $t('status'),
  dataIndex: 'status',
  filters: [
    { text: $t('aangemeld'), value: 'aangemeld' },
    { text: $t('opgepakt'), value: 'opgepakt' },
    { text: $t('uitgesteld'), value: 'uitgesteld' },
    { text: $t('afgerond'), value: 'afgerond' },
    { text: $t('afgewezen'), value: 'afgewezen' }
  ],
  onFilter: (value, record) => {
    if (value === 'opgepakt') return record?.workorder_id !== null
    return record?.workorder_id === null && record?.status === value
  },
  render: (_t, melding) => (
    <div className='row'>
      {melding.workorder_id &&
        melding.status !== 'afgerond' &&
        melding.status !== 'afgewezen' && (
          <ToolOutlined style={{ marginRight: 6 }} />
      )}
      <MeldingStatusTag
        status={melding.status}
        workorder_id={melding.workorder_id}
        category={melding.category}
      />
    </div>
  )
})

const technicus = () => ({
  title: $t('Technicus'),
  dataIndex: 'technicus_name'
})

const titel = () => ({
  title: $t('titel'),
  dataIndex: 'titel',
  render: (value, melding) => (
    <p>
      <MeldingCategoryTag category={melding.category} /> {value}
    </p>
  )
})

const materialName = () => ({
  title: $t('Materiaal'),
  dataIndex: 'material_name',
  filters: [
    { text: $t('vliegend'), value: 'kist' },
    { text: $t('rollend'), value: 'rollend' },
    { text: $t('overig'), value: 'overig' }
  ],
  onFilter: (value, _r) => _r.material?.category === value
})

export const MeldingColumns = () => [
  date_created(),
  date_updated(),
  status(),
  technicus(),
  materialName(),
  titel(),
  date_verwacht_afgehandeld()
]

export const MeldingColumnsSingleMateriaal = () => [
  date_created(),
  status(),
  titel(),
  date_verwacht_afgehandeld()
]
