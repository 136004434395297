import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { $t } from "~/i18n.js";
import { HelpBubble } from "../../components/help/helpBubble.jsx";

export const PeriodForm = ({ activePeriod, savePeriod, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { group_name_lookup, all_group_keys } = useSelector(
    (state) => state.flights
  );

  useEffect(() => {
    form.resetFields();
  }, [activePeriod, form]);

  const saveForm = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      const result = await savePeriod({
        ...values,
        id: activePeriod.id,
        date_from: values.date_from.format("YYYY-MM-DD"),
        date_to: values.date_to.format("YYYY-MM-DD"),
        exclude_groups: values.exclude_groups || [],
      });

      if (result) {
        closeModal();
        form.resetFields();
      }
      setLoading(false);
    } catch (info) {
      setLoading(false);
      console.log("Validate Failed:", info);
    }
  };

  const [isDeleting, setIsDeleting] = useState(false);
  const deletePeriod = async () => {
    setIsDeleting(true);

    const result = await savePeriod({
      id: activePeriod.id,
      status: "archived",
    });

    if (result) {
      closeModal();
      form.resetFields();
    }
    setIsDeleting(false);
  };

  const footer = [
    <AnnuleerButton key="annuleren" onClick={closeModal} />,
    <SaveButton key="ok" onClick={saveForm} loading={loading} />,
  ];

  return (
    <Modal
      title={
        activePeriod && activePeriod.id
          ? $t("Wijzig onderhoudsperiode")
          : $t("Voeg nieuwe onderhoudsperiode toe")
      }
      open={!!activePeriod}
      confirmLoading={loading}
      onCancel={closeModal}
      onOk={saveForm}
      okText={$t("Opslaan")}
      cancelText={$t("Annuleren")}
      footer={
        activePeriod?.id
          ? [
              <Popconfirm
                placement="bottom"
                title={$t("Zeker weten dat je deze periode wilt verwijderen?")}
                key="delete"
                onConfirm={deletePeriod}
                okText={$t("Ja")}
                cancelText={$t("Nee")}
              >
                <Button danger style={{ marginRight: 32 }} loading={isDeleting}>
                  {$t("Verwijderen")}
                </Button>
              </Popconfirm>,
            ].concat(footer)
          : footer
      }
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="onderhouds"
        preserve={false}
        initialValues={
          activePeriod && {
            ...activePeriod,
            date_from: dayjs(activePeriod.date_from),
            date_to: dayjs(activePeriod.date_to),
          }
        }
        requiredMark={false}
      >
        <Form.Item
          label={$t("Naam")}
          autoFocus
          name="naam"
          rules={[{ required: true, message: $t("Voeg een naam toe!") }]}
        >
          <Input placeholder={$t("naam")} />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Toelichting")}{" "}
              <HelpBubble
                content={$t("Zichtbaar voor leden onder hun pagina.")}
              />
            </p>
          }
          name="remark"
          rules={[{ required: false }]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>

        <Divider style={{ margin: "12px 0" }} />

        <p style={{ margin: "6px" }}>
          {$t("Vluchtperiode waarover uren berekend worden")}
        </p>

        <Form.Item label={$t("Vanaf")} name="date_from">
          <DatePicker format={localStorage.localeFormat} />
        </Form.Item>

        <Form.Item label={$t("Tot")} name="date_to">
          <DatePicker format={localStorage.localeFormat} />
        </Form.Item>

        <Divider style={{ margin: "12px 0" }} />

        <p style={{ margin: "6px" }}>
          {$t(
            "Onderhoudsformule = factor * √ (starts op clubkisten * uren op clubkisten)"
          )}
        </p>

        <Form.Item label={$t("Factor")} name="factor">
          <Input type="number" style={{ width: 120 }} suffix="x" />
        </Form.Item>

        <Form.Item label={$t("Minimum")} name="minimum_uren">
          <Input type="number" style={{ width: 120 }} suffix="uren" />
        </Form.Item>

        <Form.Item label={$t("Uitgezonderde groepen")} name="exclude_groups">
          <Select mode="multiple">
            {all_group_keys.map((group, key) => (
              <Select.Option value={group} key={key}>
                {group_name_lookup[group] || group}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Divider style={{ margin: "12px 0" }} />

        <p style={{ margin: "6px" }}>
          {$t(
            'Na het aanmaken van de periode is deze in "Concept". Hierna kan je alle verplichtingen bekijken, en definitief maken. Eenmaal definitief zullen leden hun uren kunnen inzien en kan jij hen update mails sturen.'
          )}
        </p>
      </Form>
    </Modal>
  );
};
