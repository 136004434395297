import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Tag,
  Upload,
} from "antd";

import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { api } from "~/lib/api.js";
import {
  normFileDuringFormUpload,
  ruleCheckImageMaxSize,
} from "~/lib/helpers.js";
import { MeldingCategoryBeschrijving } from "../../materialTags.jsx";


export const AddMeldingModal = ({
  visible,
  onClose,
  onSave,
  activeMelding,
  materiaal,
  currentMaterial,
  currentWorkorderID,
}) => {
  const [form] = Form.useForm();
  const [category, setCategory] = useState("");
  const meldingCat = Form.useWatch("category", form);
  const material_id = Form.useWatch("material_id", form);

  const tech = Form.useWatch("technicus_id", form);

  // filter technici on general or rollend/vliegend, depending on selector
  const tech_filter =
    category === "kist" ? "technicus_vliegend" : "technicus_rollend";
  const technici = materiaal?.technici
    ? materiaal.technici?.filter((t) => t.group_keys?.includes(tech_filter))
    : [];

  const isVliegend =
    activeMelding?.material?.category === "kist" ||
    currentMaterial?.category === "kist" ||
    category === "kist";

  useEffect(() => {
    setCategory(activeMelding?.material?.category || "kist");
  }, [activeMelding]);

  const [teamData, setTeam] = useState({});
  const [teamIDs, setTeamIDs] = useState([])
  const [loadingTeam, setLoadingTeam] = useState(true);
  const fetchTeam = async (found_mat_id) => {
    const res = await api.get(`materiaal/${found_mat_id}/team.json`)
    setLoadingTeam(false)
    if (res) {
      setTeam(res)
      const ids = res.members ? res.members.map(member => member.user?.id) : []
      setTeamIDs(ids)
      if (!activeMelding?.id) {
        form.setFieldsValue({ technicus_id: ids[0] })
      }
    }
  }
  useEffect(() => {
    // update team if there is an active material, either selected or pass along
    // and the id does not equal the team id
    let found_mat_id = (currentMaterial?.id || material_id)
    if (visible && found_mat_id && (!teamData?.id || teamData?.id !== found_mat_id)) {
      fetchTeam(found_mat_id)
    }
  }, [visible, currentMaterial?.id, material_id])

  const [saving, setSaving] = useState(false);
  const triggerSave = async () => {
    try {
      setSaving(true);
      const values = await form.validateFields();
      const result = await onSave({
        ...values,
        material_id:
          currentMaterial?.id ||
          activeMelding?.material_id ||
          values.material_id,
        id: activeMelding ? activeMelding.id : null,
        date_verwacht_afgehandeld:
          values.date_verwacht_afgehandeld &&
          values.date_verwacht_afgehandeld.format("YYYY-MM-DD"),
        workorder_id: currentWorkorderID,
      });
      if (result) {
        onClose();
        form.resetFields();
      }
      setSaving(false);
    } catch (info) {
      console.log("Validate Failed:", info);
      setSaving(false);
    }
  };

  return (
    <Modal
      title={
        activeMelding
          ? $t("Pas melding aan")
          : currentMaterial
          ? $t("Melding aan ${name}", { name: currentMaterial.name })
          : $t("Voeg nieuwe melding toe")
      }
      open={visible}
      confirmLoading={saving}
      onCancel={onClose}
      okText={$t("Opslaan")}
      cancelText={$t("Annuleren")}
      onOk={triggerSave}
      okButtonProps={{ disabled: loadingTeam, loading: saving }}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="melding"
        preserve={false}
        requiredMark={false}
        initialValues={
          activeMelding
            ? {
                ...activeMelding,
                date_verwacht_afgehandeld:
                  activeMelding.date_verwacht_afgehandeld &&
                  dayjs(activeMelding.date_verwacht_afgehandeld, "YYYY-MM-DD"),
              }
            : {}
        }
      >
        {!activeMelding && !currentMaterial && (
          <Radio.Group
            className="spacer"
            size="small"
            value={category}
            onChange={(event) => setCategory(event.target.value)}
          >
            <Radio.Button value="kist">{$t("Vliegend")}</Radio.Button>
            <Radio.Button value="rollend">{$t("Rollend")}</Radio.Button>
            <Radio.Button value="overig">{$t("Overig")}</Radio.Button>
          </Radio.Group>
        )}

        {!activeMelding && !currentMaterial && (
          <Form.Item
            label={$t("Materiaal")}
            name="material_id"
            rules={[{ required: true }]}
          >
            <Select>
              {materiaal?.material?.map(
                (mat, key) =>
                  mat.category === category && (
                    <Select.Option key={key} value={mat.id}>
                      {mat.name}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={$t("Titel")}
          name="titel"
          rules={[
            {
              required: true,
              max: 100,
              message: $t("Maximaal ${c} characters", { c: 100 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        {!activeMelding && (
          <Form.Item
            label={$t("Beschrijving")}
            name="beschrijving"
            rules={[
              {
                required: true,
                max: 700,
                message: $t("Verplicht, max ${max} tekens.", { max: 700 }),
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        )}

        {technici && !currentWorkorderID && (
          <Form.Item
            label={
              <p>
                <span>
                  {activeMelding ? $t("Wijs toe") : $t("Initiele toewijzing")}
                </span>
                <HelpBubble
                  content={$t(
                    "Toewijzing kan door het project team worden aangepast."
                  )}
                />
              </p>
            }
            name="technicus_id"
          >
            <Select disabled={loadingTeam}>
              <Select.Option  value={null}>{$t("Geen")}</Select.Option>

              {teamData?.members?.map((member) => (
                <Select.Option key={member.user?.id} value={member.user?.id}>
                  {member.user?.name} <Tag>{$t(member.role)}</Tag>
                </Select.Option>
              ))}

              {technici
                .filter((_tech) => !teamIDs.includes(_tech.id))
                .map((_tech) => (
                  <Select.Option key={_tech.id} value={_tech.id}>
                    {_tech.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={$t("Category")}
          name="category"
          rules={[
            {
              required: true,
              message: "Selecteer category",
            },
          ]}
        >
          <Select>
            <Select.Option key="defect" value="defect">
              <span className="bold">{$t("Defect")}</span>:{" "}
              {$t("Materiaal kan niet meer ingezet worden")}.
            </Select.Option>
            <Select.Option key="klacht" value="klacht">
              <span className="bold">{$t("Klacht")}</span>:{" "}
              {isVliegend
                ? $t("Een observatie, géén invloed op luchtwaardigheid")
                : $t("Een melding waarna het materiaal inzetbaar blijft")}
              .
            </Select.Option>
            <Select.Option key="visueel" value="visueel">
              <span className="bold">{$t("Visueel")}</span>:{" "}
              {$t("Een kras of deuk")}
              {isVliegend ? $t(", géén invloed op luchtwaardigheid") : ""}.
            </Select.Option>
            <Select.Option key="algemeen" value="algemeen">
              <span className="bold">{$t("Algemeen")}</span>:
              {$t("Mededeling rondom het gebruik")}.
            </Select.Option>

            {/* for translation pickup tool */}
            {/* $t('defect') */}
            {/* $t('klacht') */}
            {/* $t('visueel') */}
            {/* $t('algemeen') */}
          </Select>
        </Form.Item>

        <div style={{ marginBottom: 12 }}>
          {meldingCat && (
            <HelpBubble>
              {MeldingCategoryBeschrijving(isVliegend, meldingCat)}
            </HelpBubble>
          )}

          {!activeMelding && (
            <p style={{ marginTop: 4 }}>
              {meldingCat === "defect" && !tech ? (
                <>
                  <Tag color="red">{$t("Let op")}</Tag>{" "}
                  {$t(
                    "Bij een mogelijk defect adviseren we om een technicus te spreken."
                  )}
                </>
              ) : (
                $t(
                  "Twijfel je? De technici zullen de melding beoordelen en mogelijk aanpassen."
                )
              )}
            </p>
          )}

          {activeMelding && meldingCat === "defect" && (
            <p>
              {$t(
                "Een defect kan je uitstellen als de defect niet de luchtwaardigheid van het materiaal beinvloed of niet een component omvat dat op de minimum equipment list staat. Indien een defect uitgesteld wordt is het materiaal weer inzetbaar."
              )}
            </p>
          )}
        </div>

        {!activeMelding && (
          <Form.Item
            name="files"
            label={$t("Bijlage")}
            valuePropName="fileList"
            getValueFromEvent={normFileDuringFormUpload}
            rules={[ruleCheckImageMaxSize]}
          >
            <Upload
              name="file"
              listType="picture"
              multiple
              beforeUpload={(file) => false}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
            >
              <Button icon={<UploadOutlined />}>
                {$t("Selecteer 1 of meerdere foto's")}
              </Button>
            </Upload>
          </Form.Item>
        )}

        {!activeMelding && currentWorkorderID && (
          <p style={{ marginTop: 12 }}>
            <span className="bold">{$t("Workorder")}</span>:{" "}
            {$t(
              "er zal een taak opgenomen worden om aan de melding te werken."
            )}
          </p>
        )}

        {activeMelding && (
          <Form.Item name="recalculate_hours_starts" valuePropName="checked">
            <Checkbox className="small">
              {$t("Herbereken Vliegtuig starts uren")}{" "}
              <HelpBubble
                content={$t(
                  'Bij het toevoegen van de melding worden de actuele stars en uren (in-service lifetime) van het onderdeel "Vliegtuig" toegevoegd. Heb je dit onderdeel niet dan zal deze informatie niet toegevoegd worden aan de melding.'
                )}
              />
            </Checkbox>
          </Form.Item>
        )}

        {activeMelding && (
          <Form.Item
            label={$t("Verwachte einddatum")}
            name="date_verwacht_afgehandeld"
          >
            <DatePicker format={localStorage.localeFormat} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
