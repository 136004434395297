import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CommentOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Collapse, Flex, List, Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { BevoegdheidScore } from "~/components/bevoegdheden/components/BevoegdheidScore.jsx";
import { StartIcon } from "~/components/icon/icon.jsx";
import { ParseTrainingFlightDate } from "~/components/profile/parseTrainingFlightDate.jsx";
import { RecencyCurrencyBarometer } from "~/components/recency/RecencyCurrencyBarometer.jsx";
import { ReadMoreExpander } from "~/components/readmore/ReadMoreExpander.jsx";
import { $t } from "~/i18n.js";
import { _parseDate } from "~/lib/localize.js";
import { PilotAvatar } from "~/pages/ledenlijst/PilotAvatar.jsx";

export const AanmeldList = ({
  aanmeldingen,
  profile,
  afmelden,
  viewInstructorInfo,
  setOpenPilot,
  notities,
  bevoegdheden,
  isVliegend,
  groups_aanmeld, 
  group_name_lookup
}) => {
  // Group aanmeldingen by role
  const groupedAanmeldingen = aanmeldingen.reduce((groups, item) => {
    let role = "other";
    if (item.as_instructeur) role = "instructeur";
    else if (item.kist?.includes("privé")) role = "privé";
    else if (item.vlieger?.group_keys?.includes("niet_vliegend")) role = "niet_vliegend";
    else if (item.vlieger?.group_keys?.includes("dbo")) role = "dbo";
    else if (item.vlieger?.group_keys?.includes("solist")) role = "solist";
    else if (item.vlieger?.group_keys?.includes("brevet")) role = "brevet";
    if (!item.aangemeld) role = "afgemeld";
    if (!groups[role]) groups[role] = [];
    groups[role].push(item);
    return groups;
  }, {});

  const showInstructorInfo = isVliegend && viewInstructorInfo;
  return (
    <Collapse
      size="small"
      defaultActiveKey={Object.keys(groupedAanmeldingen)}
      style={{ maxWidth: 750 }}
    >
      {[
        "dbo", // $t("dbo")
        "solist", // $t("solist")
        "instructeur", // $t("instructeur")
        "brevet", // $t("brevet")
        "privé", // $t("privé")
        "niet_vliegend", // $t("niet_vliegend")
        "afgemeld", // $t("afgemeld")
      ].map((role) => {
        const items = groupedAanmeldingen[role] || [];
        if (!items.length) return null;

        return (
          <Collapse.Panel
            key={$t(role)}
            header={
              <span className="capitalize">
                {$t(role)} ({items.length})
              </span>
            }
          >
            <List
              itemLayout="horizontal"
              dataSource={items}
              renderItem={(item) => (
                <List.Item style={{ alignItems: "flex-start" }}>
                  <div
                    className="column"
                    style={{
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      className="row"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <List.Item.Meta
                        style={{ alignItems: "center" }}
                        avatar={
                          <div
                            className="clickable"
                            onClick={() => setOpenPilot(item.vlieger)}
                          >
                            <PilotAvatar vlieger={item.vlieger} />
                          </div>
                        }
                        title={
                          <div
                            className="clickable"
                            onClick={() => setOpenPilot(item.vlieger)}
                          >
                            {item.vlieger.name}{" "}
                            {item.vlieger.recency?.starts < 5 && (
                              <Tag color="green" style={{ fontWeight: 400 }}>
                                {$t("Nieuw lid")}
                              </Tag>
                            )}
                          </div>
                        }
                        description={item.opmerking}
                      />

                      {item.aangemeld ? (
                        <div
                          style={{ width: 100 }}
                          className="clickable"
                          onClick={() => setOpenPilot(item.vlieger)}
                        >
                          {isVliegend && (
                            <RecencyCurrencyBarometer pilot={item.vlieger} />
                          )}
                        </div>
                      ) : (
                        <Tag color="volcano">{$t("Afgemeld")}</Tag>
                      )}

                      {profile.iedereen_aanmelden && !item.aangemeld && (
                        <a onClick={() => afmelden(item.vlieger.id, true)}>
                          <Button shape="circle" icon={<CheckOutlined />} />
                        </a>
                      )}
                      {profile.iedereen_aanmelden && item.aangemeld && (
                        <a onClick={() => afmelden(item.vlieger.id, false)}>
                          <Button
                            danger
                            shape="circle"
                            icon={<CloseOutlined />}
                          />
                        </a>
                      )}
                    </div>

                    {item.aangemeld && (
                      <div style={{ marginLeft: 55 }}>
                        {(item.van || item.tot) && (
                          <p style={{ fontWeight: 400 }}>
                            <ClockCircleOutlined
                              style={{ color: "red", marginRight: 4 }}
                            />{" "}
                            {item.van || $t("begin")} -{" "}
                            {item.tot || $t("einde")}
                          </p>
                        )}

                        {isVliegend && (
                          <div>
                            {group_name_lookup &&
                              groups_aanmeld &&
                              groups_aanmeld.length > 1 &&
                              groups_aanmeld
                                .split(",")
                                .map(
                                  (g_key, idx) =>
                                    item.vlieger.group_keys?.includes(
                                      g_key
                                    ) && (
                                      <Tag key={idx}>
                                        {group_name_lookup[g_key] || g_key}
                                      </Tag>
                                    )
                                )}

                            {item.as_sleepvlieger && (
                              <Tag>{$t("sleepvlieger")}</Tag>
                            )}
                            {item.overland && (
                              <Tag color="gold">{$t("Ovl")}</Tag>
                            )}
                            {item.kist &&
                              item.kist.map((kist, key) => (
                                <Tag
                                  key={key}
                                  color={key === 0 ? "green" : "default"}
                                >
                                  {kist === "privé" ? $t('privé') : kist}
                                </Tag>
                              ))}
                          </div>
                        )}

                        {!showInstructorInfo && item.checkstart && (
                          <Tag color="gold">
                            {$t("wil")} {$t("trainingsvlucht")}
                          </Tag>
                        )}

                        {showInstructorInfo && (
                          <Flex
                            vertical={true}
                            gap={4}
                            style={{ marginTop: 6, color: "black" }}
                          >
                            {(item.vlieger.tag == "brevet" ||
                              item.vlieger.tag == "instructeur") && (
                              <div className="row" style={{ gap: 2 }}>
                                <UnorderedListOutlined />{" "}
                                <ParseTrainingFlightDate
                                  last_check_start={
                                    item.vlieger.last_check_start
                                  }
                                />
                                {item.checkstart && (
                                  <Tag color="gold">
                                    {$t("wil")} {$t("trainingsvlucht")}
                                  </Tag>
                                )}
                              </div>
                            )}

                            {item.vlieger.date_last_flight && (
                              <div className="row">
                                <StartIcon />{" "}
                                <Tag color="white" style={{ color: "black" }}>
                                  {_parseDate(item.vlieger.date_last_flight)}
                                </Tag>{" "}
                                <span className="small">
                                  (
                                  {dayjs(
                                    item.vlieger.date_last_flight
                                  ).fromNow()}
                                  )
                                </span>
                              </div>
                            )}

                            {notities[item.vlieger.id] && (
                              <div
                                className="row"
                                style={{ gap: 8, alignItems: "flex-start" }}
                              >
                                <CommentOutlined style={{ marginTop: 4 }} />
                                <ReadMoreExpander
                                  text={`${
                                    notities[item.vlieger.id].bericht
                                  } - ${
                                    notities[item.vlieger.id].instructeur_naam
                                  } (${_parseDate(
                                    notities[item.vlieger.id].datum
                                  )})`}
                                />
                              </div>
                            )}

                            {(item.vlieger.tag == "dbo" ||
                              item.vlieger.tag == "solist") &&
                              bevoegdheden &&
                              bevoegdheden[item.vlieger.id] && (
                                <div className="row" style={{ gap: 8 }}>
                                  <BevoegdheidScore
                                    score={bevoegdheden[item.vlieger.id].score}
                                  />
                                  <span>
                                    {
                                      bevoegdheden[item.vlieger.id]
                                        .bevoegdheid_name
                                    }
                                  </span>
                                  <span>
                                    (
                                    {_parseDate(
                                      bevoegdheden[item.vlieger.id]
                                        .date_assigned
                                    )}
                                    )
                                  </span>
                                </div>
                              )}
                          </Flex>
                        )}
                      </div>
                    )}
                  </div>
                </List.Item>
              )}
            />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};
