export const checkRecencyWarnings = (vlieger) => {
  const { recency = {} } = vlieger || {};

  if (!recency) return false;
  const isNotValid =
    recency.passagiervliegen ||
    recency.tmg_passagiervliegen ||
    recency.spl === "invalid" ||
    (vlieger.tag != "dbo" && recency.medical) ||
    vlieger?.check_med ||
    recency.starts < 15 ||
    recency.vliegduur < 300 ||
    recency.checks < 2 ||
    recency.lieraantekening === "invalid" ||
    recency.sleepaantekening === "invalid" ||
    recency.zelfstartaantekening === "invalid" ||
    recency.tmgaantekening === "invalid" ||
    recency["tmg-a-aantekening"] === "invalid" ||
    recency.bungeeaantekening === "invalid" ||
    recency.caraantekening === "invalid" ||
    recency.fis === "invalid" ||
    recency.fes === "invalid";
  return isNotValid;
};