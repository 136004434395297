import { Cascader } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "~/components/loader/loader.jsx";
import { getMaterialTab } from "~/redux/materiaal/materiaal.actions.js";

export const SelectPartCascader = ({ onChange, materialID }) => {
  const [loading, setLoading] = useState(false);
  
  const [selectedItems, setSelectedItems] = useState([]);
  const [partsList, setPartsList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const loadParts = async () => {
      // load in parts
      const res = await dispatch(
        getMaterialTab({ materialID, activeTab: "parts" })
      );

      const normalize = (part) => ({
        value: part.id,
        label: part.name,
        children: part.parts.map((p) => normalize(p)),
      });
      const parts = res?.data?.parts?.map((part) => normalize(part));
      console.log("part", parts, isMounted);
      if (isMounted) {
        setPartsList(parts);
        setLoading(false);
      }
    };
    loadParts();
    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) return <Loader size={20} />;

  return (
    <Cascader
        disabled={!partsList}
        style={{ width: 300 }}
        placeholder=""
        options={partsList}
        changeOnSelect
        value={selectedItems}
        onChange={(list) => {
            setSelectedItems(list);
            onChange(list[list.length - 1]);
        }}
    />
  );
};
