import React from "react";
import './GFlex.css'

export const GFlex = ({ children, ...props }) => {
  return (
    <div className={`GFlex ${props.c}`} {...props}>
      {children}
    </div>
  );
};
