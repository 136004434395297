import React from "react";
import {
  FESRecencyTable,
  FISRecencyTable,
  SPLRecencyTable,
  TMGRecencyTable,
} from "~/components/recency/index.js";
import "./overviewFlights.css";
import { TotalTypes } from "./TotalTypes.jsx";

export const Recency = ({ recency, profile }) => {
  return (
    <div className="overviewFlightTab">
      {recency && recency.starts >= 0 && <SPLRecencyTable recency={recency} />}

      {recency && recency.tmg_starts >= 0 && (
        <TMGRecencyTable recency={recency} />
      )}

      {recency && recency.fis && <FISRecencyTable recency={recency} />}

      {recency && recency.is_fes && <FESRecencyTable recency={recency} />}

      {profile && (
        <div style={{ marginTop: 24 }}>
          <TotalTypes user_id={profile?.id} />
        </div>
      )}
    </div>
  );
};
