import React from 'react'
import { BreadcrumbHeader } from '~/components/layout/breadcrumbHeader.jsx'
import { MainLayout } from '~/components/layout/layout.jsx'
import { MyNews } from "~/components/profile/myNews.jsx";
import { $t } from "~/i18n.js";
import { RefreshBaseData } from '~/components/buttons/RefreshBaseData.jsx';

export const MyNewsPagina = ({ history }) => {

  return (
    <MainLayout history={history}>
      <div className='row' style={{justifyContent: 'space-between'}}>
      <BreadcrumbHeader breadcrumbs={[$t("Mijn nieuws")]} />
      <RefreshBaseData />
      </div>
      <MyNews />
    </MainLayout>
  )
}
