import { InfoCircleOutlined, StarTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";

import { Button, Modal } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";
import { colors } from "~/theme/colors.js";
import { ScoreForm } from "./ScoreForm.jsx";

import { _parseDate } from "~/lib/localize.js";

import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { BevoegdheidExpirationWarning } from "./BevoegdheidExpirationWarning.jsx";
import { BevoegdheidHistory } from "./BevoegdheidHistory.jsx";
import { BevoegdheidScore } from "./BevoegdheidScore.jsx";

export const Bevoegdheid = ({
  user_id,
  cluster,
  bevoegdheid,
  setBevoegdheid,
  openBevoegheid,
}) => {
  const [loading, setLoading] = useState(false);
  const [showScoreBevoegdheid, setScoreShowModal] = useState(null);

  const [remark, setRemark] = useState("");
  const [newScore, setScore] = useState(null);
  const [date_assigned, setDateAssigned] = useState(dayjs());
  const [date_geldig, setDateGeldig] = useState(null);

  const triggerSetBevoegdheid = async (id, _score) => {
    setLoading(true);
    await setBevoegdheid({
      id,
      user_id,
      score: _score,
      remark: remark ? remark.substring(0, 90) : "",
      is_dto: cluster.is_dto,
      date_assigned: date_assigned.format("YYYY-MM-DD"),
      date_geldig: date_geldig ? date_geldig.format("YYYY-MM-DD") : null,
    });
    setRemark("");
    setScore(null);
    setDateGeldig(null);
    setScoreShowModal(null);
    setLoading(false);
  };

  const score = bevoegdheid.behaald.score || 0;
  return (
    <div className="row bevoegdheid">
      {showScoreBevoegdheid && (
        <Modal
          title={bevoegdheid.label || bevoegdheid.name}
          open={showScoreBevoegdheid !== null}
          onCancel={() => setScoreShowModal(false)}
          footer={[
            <SluitButton
              key="sluit"
              onClick={() => setScoreShowModal(false)}
            />,
            <Button
              key="opslaan"
              type="primary"
              loading={loading}
              disabled={newScore == score}
              onClick={() => {
                // check remark or scoring
                triggerSetBevoegdheid(showScoreBevoegdheid.id, newScore);
              }}
            >
              {$t("toekennen")}
            </Button>,
          ]}
        >
          <ScoreForm
            bevoegdheid={showScoreBevoegdheid}
            setScore={setScore}
            setRemark={setRemark}
            score={newScore}
            remark={remark}
            date_assigned={date_assigned}
            setDateAssigned={setDateAssigned}
            date_geldig={date_geldig}
            setDateGeldig={setDateGeldig}
          />

          <BevoegdheidHistory
            behaalde_bevoegdheid_id={bevoegdheid.behaald?.id}
            currentBevoegdheid={bevoegdheid.behaald}
          />
        </Modal>
      )}

      <div className="row">
        <BevoegdheidScore score={score} />

        {bevoegdheid.endorse_title && (
          <StarTwoTone
            twoToneColor={colors.primary}
            style={{ marginLeft: 6 }}
          />
        )}

        <p
          className="clickable row"
          onClick={() => openBevoegheid(bevoegdheid)}
          style={{ marginLeft: 6 }}
        >
          {bevoegdheid.label || bevoegdheid.name}{" "}
          <BevoegdheidExpirationWarning bevoegdheid={bevoegdheid} />
          {!bevoegdheid.behaald.nearly_invalid &&
            bevoegdheid.behaald.remark && (
              <span className="gray" style={{ marginLeft: 6 }}>
                {" "}
                - {bevoegdheid.behaald.remark}
              </span>
            )}
        </p>
      </div>

      <div>
        {bevoegdheid.behaald.date_behaald && bevoegdheid.behaald.valid && (
          <span className="small gray">
            {_parseDate(bevoegdheid.behaald.date_behaald)}
          </span>
        )}

        {setBevoegdheid && !bevoegdheid.behaald.assigned && (
          <Button
            onClick={() => {
              // direct scoring only for boolean without remark
              if (
                bevoegdheid.scoring === "boolean" &&
                !bevoegdheid.has_remark &&
                !bevoegdheid.geldigheid
              ) {
                return triggerSetBevoegdheid(bevoegdheid.id, "assigned");
              }
              if (bevoegdheid.geldigheid) {
                setDateGeldig(dayjs().add(bevoegdheid.geldigheid, "day"));
              }
              if (bevoegdheid.scoring === "boolean") {
                setScore("assigned");
              } else {
                setScore(score);
              }
              return setScoreShowModal(bevoegdheid);
            }}
            type="link"
            loading={loading}
            size="small"
          >
            {$t("toekennen")}
          </Button>
        )}

        {setBevoegdheid && bevoegdheid.behaald.assigned && (
          <Button
            ghost
            shape="circle"
            className="openButton"
            onClick={() => openBevoegheid(bevoegdheid)}
            icon={
              <InfoCircleOutlined
                className="clickable"
                style={{ color: colors.primary_light, fontSize: 16 }}
              />
            }
            size="small"
          />
        )}
      </div>
    </div>
  );
};
