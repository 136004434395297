import { Modal, Radio, Space, Tag } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { Icon, landing, start, vak_kwart, vak_open } from '~/components/icon/icon.jsx'

import { selectElementByID } from '~/lib/helpers.js'
import './kistStatus.css'
import { useSelector } from "react-redux";
import { Inspection } from "~/pages/materiaal/tabs/status/inspection.jsx";

const statusCheck = { gepland: { icon: vak_kwart }, ingedeeld: { icon: start, viewBox: '0 0 1400 1024' }, gestart: { icon: landing, viewBox: '0 0 1100 1024' }, beschikbaar: { icon: vak_open } }

const RenderKistStatus = ({ kist, activeFlightUUID, openKist }) => {
  const [loading, setLoading] = useState(false)
  const active = activeFlightUUID && kist.flight_uuid === activeFlightUUID
  return (
    <Tag
      className={`kist clickable ${active && ' active'}`} color={active ? 'orange' : 'geekblue'}
      onClick={async () => {
        if (!loading) {
          setLoading(true)
          await openKist({ uuid: kist.flight_uuid, kist })
          setLoading(false)
        }
      }}
    >
      {kist.callsign}
      <Icon {...statusCheck[kist.status]} style={{ marginLeft: 4 }} />
    </Tag>
  )
}

export const SectionKistStatus = ({ kistStatus, activeFlightUUID, openKist, is_today }) => {
  const [showOtherKistModal, openOtherKistModal] = useState(false)
  const [otherKistID, setOtherKistID] = useState(null)
  const [loading, setLoading] = useState(false)

  const { inspections } = useSelector((state) => state.materiaal);
    
  if (!kistStatus) return null;

  const ingezette_kisten = kistStatus?.filter(kist => kist.status !== 'hangaar')

  const triggerSave = async () => {
  
    if (!loading) {
      setLoading(true)
      const kist = selectElementByID(kistStatus, 'id', otherKistID)
      await openKist({ uuid: kist?.flight_uuid, kist })
      openOtherKistModal(false)
      setLoading(false)
    }
  }

  return (
    <div className="kistStatus row">
      <Modal
        title={$t("Selecteer kist uit hangaar")}
        confirmLoading={loading}
        open={showOtherKistModal}
        onCancel={() => openOtherKistModal(false)}
        okText="Selecteer"
        cancelText={$t("Annuleren")}
        onOk={triggerSave}
      >
        <Radio.Group
          onChange={(event) => setOtherKistID(event.target.value)}
          value={otherKistID}
        >
          <Space direction="vertical">
            {kistStatus.map(
              (kist, key) =>
                kist.status === "hangaar" && (
                  <Radio
                    key={key}
                    value={kist.id}
                    
                  >
                    <div style={{ display: "flex", gap: 24 }}>
                    <p>{kist.callsign} {kist.registratie}</p>
                    {is_today && kist?.material_status === "in onderhoud" && (
                      <Tag color="warning">{$t("In onderhoud")}</Tag>
                    )}
                    {is_today && kist?.material_status === "niet inzetbaar" && (
                      <Tag color="error">{$t("Niet inzetbaar")}</Tag>
                    )}
                    {kist?.material_status == 'in bedrijf' &&  kist.enable_inspections && (
                      <Inspection inspection={inspections[kist.material_id]} missingText={$t('Niet geinspecteerd')} />
                    )}
                    </div>
                  </Radio>
                )
            )}
            <Radio value={-1}>{$t("Andere (prive) kist")}</Radio>
          </Space>
        </Radio.Group>
      </Modal>

      {ingezette_kisten.map(
        (kist, key) => (
            <RenderKistStatus
              key={key}
              kist={kist}
              activeFlightUUID={activeFlightUUID}
              openKist={openKist}
            />
          )
      )}

      <Tag
        className="kist clickable"
        color="blue"
        onClick={() => openOtherKistModal(true)}
      >
        
        {ingezette_kisten.length > 0 ? "+ " + $t("andere kist") : $t("selecteer kist")}
      </Tag>
    </div>
  );
}
