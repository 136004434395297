import { $t } from '~/i18n.js'
import React from 'react'
import { Tabs } from 'antd'
import { TotalTypes } from '~/components/profile/tabs/TotalTypes.jsx'
import {
  SPLRecencyTable,
  FESRecencyTable,
  FISRecencyTable,
  TMGRecencyTable,
} from "~/components/recency/index.js";

export const CardTypeTotals = ({ recency, profile }) => {
  return (
    <div className="overviewFlightTab">
      <Tabs defaultActiveKey={recency ? "easa" : "gezagvoerder"}>
        {recency && recency.starts >= 0 && (
          <Tabs.TabPane tab={$t("EASA SPL")} key="easa">
            <div>
              <SPLRecencyTable recency={recency} />
            </div>
          </Tabs.TabPane>
        )}

        {recency && recency.tmg_starts && (
          <Tabs.TabPane tab={$t("EASA TMG")} key="easatmg">
            <div>
              <TMGRecencyTable recency={recency} />
            </div>
          </Tabs.TabPane>
        )}

        {recency && recency.fis && (
          <Tabs.TabPane tab={$t("EASA FI(s)")} key="easafis">
            <div>
              <FISRecencyTable recency={recency} />
            </div>
          </Tabs.TabPane>
        )}

        {recency && recency.is_fes && (
          <Tabs.TabPane tab={$t("EASA FE(s)")} key="easafes">
            <div>
              <FESRecencyTable recency={recency} />
            </div>
          </Tabs.TabPane>
        )}

          <Tabs.TabPane tab={$t("Vluchten")} key="vluchten">
            <TotalTypes user_id={profile?.id} />
          </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
