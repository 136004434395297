import { EditOutlined, MailOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import React, { useState } from "react";
import { ParseMessage } from "~/components/atom/parseMessage.jsx";
import { $t } from "~/i18n.js";
import { _parseDate } from "~/lib/localize.js";
import { colors } from "~/theme/colors.js";
import { MessageModal } from "./messageModal.jsx";
import { Card } from "antd";

export const MessageCard = ({ messages, currentUserID, canEditMessage }) => {
  const [activeMessage, setActiveMessage] = useState(null);

  return (
    <>
      <MessageModal
        visible={activeMessage !== null}
        message={activeMessage}
        onClose={() => setActiveMessage(null)}
      />

      <Card
        size="small"
        className="topCard"
        title={$t("Berichten")}
        style={{ overflowY: "auto", overflowX: "hidden" }}
        extra={
          canEditMessage && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              size="small"
              shape="circle"
              onClick={() => setActiveMessage({ id: null })}
            />
          )
        }
      >
        {!messages?.length && <span className="gray small">{$t('Geen berichten')}</span>}

        {messages?.length > 0 ? (
          messages.map((message, key) => {
            const canEdit = canEditMessage && message.user_id === currentUserID;
            return (
              <div key={key} className="spacer">
                <p className="small" style={{ color: colors.darkgray_light }}>
                  {_parseDate(message.date_created, {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>

                <div
                  className={`row ${canEdit && "clickable"}`}
                  onClick={() => canEdit && setActiveMessage(message)}
                  style={{ marginTop: 4 }}
                >
                  {canEdit && (
                    <EditOutlined style={{ marginRight: 4, fontSize: 12 }} />
                  )}

                  <p className="bold small">{message.user_name}</p>
                </div>
                <div>
                  {message.as_email && (
                    <MailOutlined
                      style={{
                        marginRight: 4,
                        fontSize: 12,
                        color: colors.primary,
                      }}
                    />
                  )}
                  <ParseMessage message={message.message} />
                </div>

                <Divider style={{ margin: "2px 0" }} />
              </div>
            );
          })
        ) : (
          <p style={{ marginLeft: 36 }} />
        )}
      </Card>
    </>
  );
};
