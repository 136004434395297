import { PlusOutlined } from "@ant-design/icons";
import { Button, Image, Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import useMedia from "use-media";
import { BevoegdhedenClusterTab } from "~/components/bevoegdheden/BevoegdhedenClusterTab.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { persistActions } from "~/redux/actions.js";
import { HelpBubble } from "../help/helpBubble.jsx";
import { Loader } from "../loader/loader.jsx";
import { InstructorRefresher } from "./instructorRefresher.jsx";
import { NotitieModal } from "./notitieModal.jsx";
import { getUserAvatar, getUserAvatarError } from "./selectPilot.jsx";
import { Bestanden } from "./tabs/bestanden.jsx";
import { DTOtrainingTab } from "./tabs/dtoTraining.jsx";
import { LastFlights } from "./tabs/LastFlights.jsx";
import { Notities } from "./tabs/notities.jsx";
import { Recency } from "./tabs/overviewFlights.jsx";
import { VliegerStats } from "./vliegerStats.jsx";

function successUserGroupBevoegdheid(content) {
  Modal.success({
    content: (
      <div>
        <p>{content}</p>
        <p className="small alert" style={{ marginTop: 12 }}>
          {$t(
            "Deze wijzigen zullen in de loop van de dag doorgevoerd worden in alle currency systemen."
          )}
        </p>
      </div>
    ),
  });
}

const UserProfileUnconnected = ({
  pilot,
  currentUser,
  bevoegdheden,
  allDays,
  getVliegerProfile,
  link,
}) => {
  const isWide = useMedia({ minWidth: "500px" }, true);
  const [modalOpen, setModalOpen] = useState(false);
  const [notitie, setNotitie] = useState(null);
  const [loading, setLoading] = useState(false);

  const [pilotData, setData] = useState({});
  const setBevoegdheid = async (payload) => {
    // set bevoegheid and save to pilotData for use in model
    const res = await api.post("bevoegdheid/set.json", payload);
    if (res) {
      const behaalde_bevoegdheden = pilotData.behaalde_bevoegdheden;
      behaalde_bevoegdheden[res.bevoegdheid_id] = {
        ...res,
        assigned: res.score === 5 || res.score == "assigned",
      };
      setData({ ...pilotData, behaalde_bevoegdheden });
      if (res.message) successUserGroupBevoegdheid(res.message);
    }
  };

  const addFlightToProfile = ({ flight }) => {
    // update element f in pilotData.flights
    let flights = [flight];
    if (pilotData.flights?.length > 0) {
      flights = pilotData.flights.map((f) => {
        if (f.uuid == flight.uuid) return flight;
        return f;
      });
    }
    setData({ ...pilotData, flights: flights });
  };

  const src_avatar = getUserAvatar(pilot.uuid ? pilot : pilotData);

  // for updates we retrieve it for each flight
  useEffect(() => {
    // on load we automatically check data if required, otherwise only do this whenever you press open data
    const loadData = async (pilot) => {
      if (pilot) {
        setLoading(true);
        const res = await getVliegerProfile({ id: pilot.id });
        if (res) setData(res);
        setLoading(false);
      }
    };
    setData(pilot);
    loadData(pilot);
  }, [pilot, currentUser, getVliegerProfile, setData, setLoading]);

  const onAddNotitie = (notitie) => {
    let newNotities = pilotData.notities
      ? pilotData.notities.filter((n) => n.id !== notitie.id)
      : [];
    if (!notitie.action || notitie.action !== "deleted") {
      newNotities = [notitie].concat(newNotities);
    }
    setData({
      ...pilotData,
      notities: newNotities,
    });
    setModalOpen(false);
  };

  const onAddBestand = (bestanden) => {
    setData({
      ...pilotData,
      bestanden,
    });
    setModalOpen(false);
  };

  const onAddDTOprogramme = (dto_training) => {
    setData({
      ...pilotData,
      dto_training,
    });
  };

  const isInstructorOrPilot =
    currentUser.is_instructeur || currentUser.id === pilot.id;

  if (pilot) {
    return (
      <div>
        <NotitieModal
          visible={modalOpen}
          notitie={notitie}
          onCancel={() => {
            setModalOpen(false);
            setNotitie(null);
          }}
          onOk={onAddNotitie}
          allDays={allDays}
          pilot={pilot}
        />

        {!isWide && (
          <img
            className="modalAvatar spacer"
            alt=""
            width="140px"
            height="140px"
            src={src_avatar}
            onError={getUserAvatarError}
          />
        )}

        <div className="row">
          <div style={{ marginRight: 12 }}>
            {isWide && pilot && (
              <Image
                preview
                className="modalAvatar"
                alt=""
                width="200px"
                height="200px"
                src={src_avatar}
                onError={getUserAvatarError}
              />
            )}
          </div>

          <VliegerStats
            currentProfile={currentUser}
            link={link}
            pilot={pilotData}
            gegevens={pilotData && pilotData.gegevens}
            showName
            isInstructorOrPilot={isInstructorOrPilot}
            canWijzigDTO={currentUser.wijzig_dto}
            onUpdateVliegerdata={(d) => setData(d)}
          />
        </div>

        {loading && <Loader />}

        {!loading && (
          <Tabs>
            {isInstructorOrPilot && (
              <>
                <Tabs.TabPane tab={$t("Notities")} key="notities">
                  {!loading && currentUser.is_instructeur && (
                    <div className="row">
                      <Button
                        icon={<PlusOutlined />}
                        size="small"
                        type="link"
                        style={{ padding: 0, marginRight: 6 }}
                        onClick={() => {
                          setNotitie({
                            id: null,
                            bericht: "",
                            is_kritiek: false,
                            is_prive: false,
                          });
                          setModalOpen(true);
                        }}
                      >
                        {$t("Voeg notitie toe")}
                      </Button>
                      <HelpBubble content="Alleen instructeurs mogen notities achterlaten." />
                    </div>
                  )}

                  <Notities
                    notities={pilotData.notities}
                    currentUserID={currentUser.id}
                    alleenPublic={currentUser.id === pilot.id}
                    openNotitie={(notitie) => {
                      setNotitie(notitie);
                      setModalOpen(true);
                    }}
                  />
                </Tabs.TabPane>

                <Tabs.TabPane tab={$t("Recency")} key="recency">
                  {currentUser.wijzig_dto &&
                    pilotData.is_instructeur &&
                    pilotData.recency && (
                      <InstructorRefresher
                        pilot={pilotData}
                        recency={pilotData.recency}
                        setRecency={(r) =>
                          r && setData({ ...pilotData, recency: r })
                        }
                      />
                    )}

                  <Recency recency={pilotData.recency} profile={pilotData} />
                </Tabs.TabPane>

                <Tabs.TabPane tab={$t("Vluchten")} key="vluchten">
                  <LastFlights
                    flights={pilotData.flights}
                    pilot={pilot}
                    addFlightToProfile={addFlightToProfile}
                  />
                </Tabs.TabPane>
              </>
            )}

            {pilotData.behaalde_bevoegdheden &&
              bevoegdheden &&
              bevoegdheden.map(
                (cluster, key) =>
                  // always show if cluster is not dto, or otherwise only if dto training has started
                  // only if instructor or your own profile
                  (!cluster.is_dto ||
                    ((isInstructorOrPilot || currentUser.uitgifte_bevoegheid) &&
                      pilotData.dto_training &&
                      pilotData.dto_training[cluster.id] &&
                      pilotData.dto_training[cluster.id].status ===
                        "started")) && (
                    <Tabs.TabPane tab={cluster.name} key={key}>
                      <BevoegdhedenClusterTab
                        pilot={pilot}
                        behaalde_bevoegdheden={pilotData.behaalde_bevoegdheden}
                        cluster={cluster}
                        setBevoegdheid={
                          (currentUser.is_instructeur ||
                            currentUser.uitgifte_bevoegheid) &&
                          setBevoegdheid
                        }
                      />
                    </Tabs.TabPane>
                  )
              )}

            {isInstructorOrPilot && (
              <>
                <Tabs.TabPane tab={$t("Bestanden")} key="bestanden">
                  <Bestanden
                    currentUser={currentUser}
                    bestanden={pilotData.bestanden}
                    pilot={pilot}
                    onAddBestand={onAddBestand}
                  />
                </Tabs.TabPane>

                <Tabs.TabPane tab={$t("DTO")} key="DTO">
                  <DTOtrainingTab
                    canWijzigDTO={currentUser.wijzig_dto}
                    dto_training={pilotData.dto_training}
                    bevoegdheden={bevoegdheden}
                    pilot={pilot}
                    onAddDTOprogramme={onAddDTOprogramme}
                    gegevens={pilotData && pilotData.gegevens}
                  />
                </Tabs.TabPane>
              </>
            )}
          </Tabs>
        )}
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state) => ({
  currentUser: state.persist.profile,
  allDays: state.days.allDays,
  bevoegdheden: state.flights.bevoegdheden,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getVliegerProfile: (data) =>
      dispatch(persistActions.getVliegerProfile(data)),
  };
};

export const UserProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileUnconnected);
