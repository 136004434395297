import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GCard } from "~/components/GCard.jsx";
import { GGrid } from "~/components/GGrid.jsx";
import { MainLayout } from "~/components/layout/layout.jsx";
import { Markdown } from "~/components/markdown/markdown.jsx";
import { usePermission } from "~/compositions/state.js";
import { $t } from "~/i18n.js";
import "./grid.css";
import { AanmeldlijstWidget } from "./widgets/AanmeldlijstWidget.jsx";
import { DutiesWidget } from "./widgets/DutiesWidget.jsx";
import { EditHomePage } from "./widgets/EditHomePage.jsx";
import { NewsWidget } from "./widgets/NewsWidget.jsx";
import { RecencyWidget } from "./widgets/RecencyWidget.jsx";
import { CalendarWidget } from "./widgets/CalendarWidget.jsx";

export const Dashboard = () => {
  const persist = useSelector((state) => state.persist);
  const { club } = persist;

  const [wijzigHomePage, showWijzigHomePage] = useState(false);

  const { can } = usePermission();

  return (
    <MainLayout>
      {(club.homepage_message || can("wijzig_news")) && (
        <GCard style={{ width: "100%", marginBottom: 12 }}>
          {can("wijzig_news") && (
            <div>
              {!wijzigHomePage && (
                <Button
                  type="link"
                  onClick={(e) => showWijzigHomePage(true)}
                  icon={<EditOutlined />}
                >
                  <span>{$t("Wijzig")}</span>
                </Button>
              )}

              {wijzigHomePage && (
                <Button
                  type="link"
                  onClick={(e) => showWijzigHomePage(false)}
                  icon={<CloseOutlined />}
                >
                  <span>{$t("Annuleer")}</span>
                </Button>
              )}
            </div>
          )}

          {!club.homepage_message && (
            <>{$t("Je club heeft op dit moment geen notificatie")}</>
          )}
          {!wijzigHomePage && <Markdown text={club.homepage_message} />}
          {wijzigHomePage && (
            <EditHomePage
              onComplete={() => showWijzigHomePage(false)}
              visible={wijzigHomePage}
              homepage_message={club.homepage_message}
            />
          )}
        </GCard>
      )}

      <GGrid>
        <CalendarWidget />

        <AanmeldlijstWidget />

        <DutiesWidget />

        <RecencyWidget />

        <NewsWidget />
      </GGrid>
    </MainLayout>
  );
};
