import { Form, Input, Flex } from "antd";
import React from "react";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";
import { $t } from "~/i18n.js";
import {
  LengthValidator,
  NumberValidator,
} from "~/components/form/formHelpers.js";

export const Airport = ({form}) => {

  const club_receiver = Form.useWatch('club_receiver', form);

  return (
    <SettingCard title={$t("Thuisveld")}>
      <Form.Item
        label={$t("Vliegveld naam")}
        name="vliegveld"
        rules={[LengthValidator(100, false)]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={$t("Vliegveld ICAO code")}
        name="vliegveld_icao"
        rules={[LengthValidator(100, false)]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item
        label={$t("Receiver naam")}
        extra={
          <p>
            {$t(
              "Install receiver with good sight on runway, OGN receiver name is found via"
            )}{" "}
            <a
              target="_blank"
              href="http://wiki.glidernet.org/list-of-receivers"
            >
              wiki.glidernet.org/list-of-receivers
            </a>
          </p>
        }
        name="club_receiver"
        rules={[LengthValidator(100, false)]}
      >
        <Input />
      </Form.Item>

      {club_receiver && (
        <Flex justify="center" style={{ marginBottom: 24 }}>
          <img
            alt="receiver"
            style={{ width: 200 }}
            src={`http://glidertracking.fai.org/availability.php?station=${club_receiver}`}
          />
        </Flex>
      )} */}

      <Form.Item 
        label={$t("Registreer onbekende vliegtuigen")} 
        extra={$t("Indien je het veld gebruikt samen met andere clubs is het handig om onbekende vliegtuigen niet automatisch te registeren.")}
        name="track_guest">
        <SwitchYesNo />
      </Form.Item>

      <Form.Item
        label={$t("Altitude MSL (meter)")}
        name="vliegveld_alt"
        rules={[NumberValidator(-100, 2500)]}
      >
        <Input type="number" suffix="m" style={{ maxWidth: 200 }} />
      </Form.Item>

      <Form.Item
        label={$t("Latitude")}
        name="vliegveld_lat"
        rules={[NumberValidator(40, 60)]}
      >
        <Input type="number" style={{ maxWidth: 200 }} />
      </Form.Item>

      <Form.Item
        label={$t("Longitude")}
        name="vliegveld_lon"
        rules={[NumberValidator(-20, 20)]}
      >
        <Input type="number" style={{ maxWidth: 200 }} />
      </Form.Item>
    </SettingCard>
  );
};
