import { MessageOutlined, ToolOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SaveButtonWithStatusSaved } from '~/components/buttons/SaveButtonWithStatusSaved.jsx'
import { $t } from '~/i18n.js'
import { _parseDate } from "~/lib/localize.js"
import { saveMaterialTab } from '~/redux/materiaal/materiaal.actions.js'
import { MaintenanceStatusTag } from '../../materialTags.jsx'
import { ParseMessage } from "~/components/atom/parseMessage.jsx";

const Interval = ({
  maintenance,
  label,
  unit,
  units,
  next_unit,
  field_prefix,
  readOnlyMode,
  onChange,
}) => {
  if (!maintenance[field_prefix + "_status"]) return null;
  return (
    <div>
      <div className="row" style={{ gap: 3 }}>
        <p className="label">{label}</p>
        <MaintenanceStatusTag
          maintenance_status={maintenance[field_prefix + "_status"]}
          hideTag
        />
        <p>
          {maintenance[field_prefix + "_remain"]}{" "}
          {maintenance[field_prefix + "_remain"] === 1 ? unit : units}{" "}
          {$t("over")}
        </p>
      </div>
      <div className="row" style={{ gap: 3, marginTop: 6 }}>
        <p className="label">
          {$t("Volgende")} {field_prefix === "date" ? $t("op") : $t("bij")}
        </p>

        {readOnlyMode ? (
          <p>
            {field_prefix === "date"
              ? _parseDate(maintenance[field_prefix + "_next"])
              : maintenance[field_prefix + "_next"]}{" "}
            {next_unit || ""}
          </p>
        ) : field_prefix === "date" ? (
          <DatePicker
            format={localStorage.localeFormat}
            style={{ width: 125 }}
            disabledDate={(current) =>
              current && current < dayjs().endOf("day")
            }
            defaultValue={dayjs(maintenance[field_prefix + "_next"])}
            onChange={(e) => onChange(e ? e.format("YYYY-MM-DD") : null)}
            size="small"
          />
        ) : (
          <Input
            style={{ width: 110 }}
            type="number"
            suffix={units}
            min={0}
            size="small"
            defaultValue={maintenance[field_prefix + "_next"]}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      </div>

      {!readOnlyMode && maintenance[field_prefix + "_interval"] > 0 && (
        <div className="row" style={{ gap: 3, marginTop: 6 }}>
          <p className="label">{$t("Interval")}</p>{" "}
          <p className="gray">
            {maintenance[field_prefix + "_interval"]}{" "}
            {field_prefix === "date" ? $t("dagen") : next_unit}
          </p>
        </div>
      )}
    </div>
  );
};

export const MaintenanceCard = ({
  currentMaterial,
  maintenance,
  openWorkorder,
  readOnlyMode,
}) => {
  const dispatch = useDispatch();

  const [newData, setNewData] = React.useState({});
  useEffect(() => setNewData({}), [maintenance]);
  const updateMaintenance = (newData) =>
    setNewData({ action: "update", ...newData });

  const [loading, setLoading] = React.useState(false);

  const saveMaintenance = async () => {
    setLoading(true);
    const res = await dispatch(
      saveMaterialTab({
        activeTab: "maintenance",
        materialID: currentMaterial.id,
        payload: { id: maintenance.id, ...newData },
      })
    );
    if (res) {
      setLoading(false);
      setNewData({});
    }
  };

  return (
    <div style={{ padding: 12 }}>
      {maintenance.remark && (
        <p>
          <MessageOutlined /> <ParseMessage message={maintenance.remark} />
        </p>
      )}

      {openWorkorder && maintenance.workorder_id && (
        <p
          className="clickable"
          onClick={() => openWorkorder(maintenance.workorder_id)}
          style={{ marginTop: 12 }}
        >
          <ToolOutlined style={{ marginRight: 6 }} />
          {$t("Workorder actief")}:
          <Button size="small" style={{ marginLeft: 6 }}>
            {$t("open")}
          </Button>
        </p>
      )}

      <div className="row" style={{ gap: 32, marginTop: 12 }}>
        <Interval
          maintenance={maintenance}
          label={$t("Datum")}
          unit={$t("dag")}
          units={$t("dagen")}
          field_prefix="date"
          readOnlyMode={readOnlyMode}
          onChange={(next) => updateMaintenance({ date_next: next })}
        />
        <Interval
          maintenance={maintenance}
          label={$t("Starts")}
          unit={$t("start")}
          units={$t("starts")}
          next_unit={$t("starts")}
          field_prefix="starts"
          readOnlyMode={readOnlyMode}
          onChange={(next) => updateMaintenance({ starts_next: next })}
        />
        <Interval
          maintenance={maintenance}
          label={$t("Uren")}
          unit={$t("uur")}
          units={$t("uren")}
          next_unit={$t("uren")}
          field_prefix="hours"
          readOnlyMode={readOnlyMode}
          onChange={(next) => updateMaintenance({ hours_next: next })}
        />
        <Interval
          maintenance={maintenance}
          label={$t("Motoruren")}
          unit={$t("motoruur")}
          units={$t("motoruren")}
          next_unit={$t("motoruren")}
          field_prefix="motor_hours"
          readOnlyMode={readOnlyMode}
          onChange={(next) => updateMaintenance({ motor_hours_next: next })}
        />

        {!readOnlyMode && (
          <SaveButtonWithStatusSaved
            onClick={saveMaintenance}
            disabled={!newData.action}
            loading={loading}
            size="small"
          />
        )}
      </div>
    </div>
  );
};
