import { Button, Calendar } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GCard } from "~/components/GCard.jsx";
import { $t } from "~/i18n.js";
import { updateIfNeeded } from "~/lib/helpers.js";
import { daysActions } from "~/redux/actions.js";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { DateTimeFormat } from "~/components/date/DateTimeFormat.jsx";

export const CalendarWidget = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const days = useSelector((state) => state.days);

  useEffect(() => {
    updateIfNeeded(days.lastUpdated, () => dispatch(daysActions.getAllDays()));
  }, [days.lastUpdated, dispatch]);

  let maxDate = dayjs(
    days.allDays.reduce((max, d) => {
      return d.datum > max ? d.datum : max;
    }, days.allDays[0]?.datum) || dayjs().add(1, "month")
  ).endOf("day");

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const nextDate = () => {
    const next = selectedDate.add(1, "month");
    if (next.isBefore(dayjs(maxDate).endOf("day"))) {
      setSelectedDate(next);
    }
  };

  const prevDate = () => {
    const prev = selectedDate.subtract(1, "month");
    if (prev.isAfter(dayjs().startOf("day"))) {
      setSelectedDate(prev);
    }
  };

  return (
    <GCard
      title={
        <DateTimeFormat
          options={{ month: "long", year: "numeric" }}
          date={selectedDate}
        />
      }
      loading={days.isPending}
      extra={[
        <Button
          icon={<LeftOutlined />}
          shape="circle"
          size="small"
          onClick={prevDate}
        />,
        <Button
          icon={<RightOutlined />}
          shape="circle"
          size="small"
          onClick={nextDate}
        />,
      ]}
    >
      <div>
        <Calendar
          onChange={(value) => {
            const selectedDay = days.allDays.find(
              (d) => d.datum === value.format("YYYY-MM-DD")
            );
            if (selectedDay) history.push(`/register/${selectedDay.dag_id}`);
          }}
          headerRender={() => null}
          value={selectedDate}
          validRange={[dayjs().startOf("day"), maxDate]}
          cellRender={(value) => {
            const thisDay = value.format("YYYY-MM-DD");
            const day = days.allDays.find((d) => d.datum === thisDay);
            return (
              <div
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                {day && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: "5px",
                      backgroundColor: day.is_vliegend ? "green" : "gold",
                    }}
                  />
                )}
              </div>
            );
          }}
          disabledDate={(current) =>
            current && current < dayjs().startOf("day")
          }
          mode="month"
          fullscreen={false}
          locale={localStorage.locale || "en_GB"}
        />
      </div>
    </GCard>
  );
};
