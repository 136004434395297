import { $t } from "~/i18n.js";
import React from "react";
import { Button } from "antd";

export const NotAuthorised = ({ history }) => {
  
  // redirect unless it's the 403 catch all
  let path = window.location.pathname
  if (window.location.pathname === '/403') {
    path = ""
  }

  console.log('redirecting to', path)

  return (
    <div className="not_found" style={{ textAlign: "center" }}>
      <h2>{$t("Geen authorisatie")}.</h2>
      <p style={{ maxWidth: 450, marginTop: 24 }}>
        {$t(
          "Het lijkt erop dat je iets doet waar je de rechten niet voor hebt"
        )}
        . {$t("Neem contact op met je club leden admin als dit niet klopt")}
        . {$t("Het zou mogelijk zijn de de rechten bij jouw als gebruiker of groep niet goed staan ingesteld")}.
      </p>

      <div className="row" style={{ gap: 24, marginTop: 42 }}>
        <Button
          className="homeButton"
          href="/"
          style={{ textDecoration: "none" }}
        >
          {$t("Terug")}
        </Button>

        <Button
          className="homeButton"
          type="primary"
          href={`/login?redirect=${path}`}
          style={{ textDecoration: "none" }}
        >
          {$t("Login")}
        </Button>
      </div>
    </div>
  );
}
