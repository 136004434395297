import React from "react";
import { Switch } from "antd";
import { $t } from "~/i18n.js";

export const SwitchYesNo = ({
  id = "",
  value = false,
  onChange = (e) => {},
  disabled = false,
  ...args
}) => {
  return (
    <Switch
      disabled={disabled}
      checked={value}
      onChange={onChange}
      checkedChildren={$t("Ja")}
      unCheckedChildren={$t("Nee")}
      {...args}
    />
  );
};
