import {
  CheckCircleTwoTone,
  DownCircleTwoTone,
  ExclamationCircleTwoTone,
  RightOutlined,
  UpCircleTwoTone,
} from "@ant-design/icons";
import { Button, Collapse, List, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { $t } from "~/i18n.js";

import { _parseDate } from "~/lib/localize.js";
import { colors } from "~/theme/colors.js";
import {
  MaintenanceStatusTag,
  MeldingCategoryTag,
  MeldingStatusTag,
} from "../materialTags.jsx";
import { MaterialStatusDescription } from "../tabs/status/materiaalStatusDescription.jsx";

import './overviewTabs.css'

const MeldingenOverview = ({ meldingen }) => {
  if (meldingen.length === 0)
    return (
      <p className="small" style={{ marginLeft: 18 }}>
        {$t("Geen meldingen")}
      </p>
    );
  return (
    <List
      style={{maxWidth: 800}}
      size="small"
      dataSource={meldingen}
      renderItem={(melding) => (
        <List.Item
          extra={[
            <Link key={melding.id} to={`/melding/${melding.id}`}>
              <Button icon={<RightOutlined />} size="small" shape="circle" />
            </Link>,
          ]}
        >
          <div
            className="row"
            style={{ gap: 6, flexWrap: "wrap" }}
          >
            <p>{melding.titel}</p>
            <MeldingCategoryTag category={melding.category} />
            {(melding.category === "defect" || melding.category === "klacht") &&
              (melding.status !== "aangemeld" || melding.workorder_id) && (
                <MeldingStatusTag
                  status={melding.status}
                  workorder_id={melding.workorder_id}
                />
              )}
            <p className="small gray">{_parseDate(melding.date_created)}</p>
            {melding.date_verwacht_afgehandeld && (
              <p className="small gray">
                {$t("afgerond voor")}{" "}
                {_parseDate(melding.date_verwacht_afgehandeld)}
              </p>
            )}
          </div>
        </List.Item>
      )}
    />
  );
};

export const OverviewTab = ({
  material,
  meldingen,
  search,
  searchTag,
  inspections,
}) => {
  const material_list = material?.filter((row) => {
    const inCategory =
      searchTag.length === 0 ||
      searchTag.some((t) => row.kist_category === t || row.category === t);
    if (search) {
      return (
        inCategory && row.material_name?.toLowerCase().indexOf(search) > -1
      );
    }
    return inCategory;
  });

  return (
    <Collapse
      style={{ marginTop: 42, width: "100%", background: "white" }}
      expandIcon={({ isActive }) =>
        isActive ? (
          <UpCircleTwoTone className="clickable" twoToneColor="blue" />
        ) : (
          <DownCircleTwoTone className="clickable" twoToneColor="blue" />
        )
      }
    >
      {material_list?.map((material, key) => {
        const _melding_filtered = meldingen?.filter(
          (m) => m.material_id === material.id
        );
        const url = `/material/${material.id}`;
        return (
          <Collapse.Panel
            key={key}
            collapsible={true}
            showArrow={true}
            header={
              <div className="row" style={{ gap: 18 }}>
                <div className="overviewTitle">
                  <Link onClick={(e) => e.stopPropagation()} to={url}>
                    {material.material_name}
                  </Link>
                  <span className="small" style={{ color: "black" }}>
                    {material.type}
                  </span>
                </div>
                {material.kist_category === "prive" && (
                  <Tag color="green">{$t("prive")}</Tag>
                )}

                {material.status !== "in bedrijf" && (
                  <MeldingStatusTag status={material.status} />
                )}

                {material.status === "in bedrijf" &&
                  material.maintenance_status === "red" && (
                    <MaintenanceStatusTag
                      maintenance_status={material.maintenance_status}
                    />
                  )}
                {material.status === "in bedrijf" &&
                  material.document_status === "red" && (
                    <MaintenanceStatusTag
                      maintenance_status={material.document_status}
                    />
                  )}
              </div>
            }
            extra={[
              material.enable_inspections && inspections && (
                <span style={{ marginRight: 12 }} key={1}>
                  {material.id in inspections ? (
                    <CheckCircleTwoTone twoToneColor={colors.green} />
                  ) : (
                    <ExclamationCircleTwoTone
                      twoToneColor={colors.gray_light}
                    />
                  )}
                </span>
              ),
              <Link key={2} onClick={(e) => e.stopPropagation()} to={url}>
                <Button type="primary" size="small">
                  {$t("Open")}
                </Button>
              </Link>,
            ]}
          >
            <div>
              <MaterialStatusDescription
                readOnly={true}
                currentMaterial={material}
                inspection={inspections[material.id]}
              />

              <p className="row subtitle" style={{ marginTop: 12 }}>
                {$t("Meldingen")}
              </p>
              <MeldingenOverview meldingen={_melding_filtered} />
            </div>
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};
