import { baseCurrentMaterial, materiaalState } from './materiaal.state'
import { normalizeMaterial, normalizeMeldingen, normalizeSingleMaterial } from './materiaal.normalizers'

export const materiaalReducer = (state = materiaalState, action) => {
  switch (action.type) {
    case 'MATERIAAL_PENDING':
      return {
        ...state,
        isPending: true
      }

    case 'MATERIAAL_ERROR':
      return {
        ...state,
        isPending: false
      }

    case 'MATERIAAL_RESET_LAST_DATE':
      return {
        ...state,
        isPending: true,
        lastUpdated: null
      }

    case 'MATERIAAL_BASE_DATA':
      return {
        ...state,
        isPending: false,
        lastUpdated: new Date(),
        material: normalizeMaterial(action.material),
        meldingen: normalizeMeldingen(action.meldingen, action.material, action.technici),
        technici: action.technici,
      }

    case 'SET_INSPECTIONS':
      return {
        ...state,
        inspectionsUpdated: new Date(),
        inspections: action.inspections?.reduce((acc, inspection) => ({
          ...acc,
          [inspection.material_id]: inspection
        }), {})
      }

    case 'UPDATE_INSPECTION':
        let _new = {...state.inspections}
        _new[action.material.id] = action.inspection
        return {
          ...state,
          inspections: _new
        }

    case 'MATERIAAL_SET_OVERVIEW':
      return {
        ...state,
        isPending: false,
        ...action
      }

    case 'MATERIAAL_OPEN_NEW':
      // if materialID is different, overwrite full block to wipe data held in tabs
      return {
        ...state,
        currentMaterial: {
          ...baseCurrentMaterial,
          id: action.materialID
        }
      }

    case 'MATERIAAL_SET_TAB':
      const data = { ...state.currentMaterial, ...action.material }

      // only overwrite the tab ensuring you don't overwrite the data in the other tabs
      const object_id = action.obj_id === 'current' ? data[action.activeTab]?.obj_id : action.obj_id
      data[action.activeTab] = { ...action.tab_data, obj_id: object_id || '' }

      return {
        ...state,
        // sometimes you have meldingen and starts/hours, normalize this on the material
        currentMaterial: normalizeSingleMaterial(data)
      }

    case 'MATERIAAL_CLEAR_TAB':
      // after saving, make sure you clear out other tabs if required
      return {
        ...state,
        currentMaterial: { ...state.currentMaterial, ...action }
      }

    case 'MATERIAAL_SAVE_WORKORDER_TASK':
      // save signedoff task, overwrite in workorders
      let newTasks = state.currentMaterial?.workorders?.tasks
      if (!newTasks) return state
      let n_tasks_completed = 0
      let n_critical_tasks_completed = 0

      // loop accross new tasks, and save the action
      const normalize = (t) => {
        if (t.id === action.task.id) {
          t = { ...t, ...action.task }
        }

        // keep track of full workorder data
        if (t.signed_date) n_tasks_completed += 1
        if (t.checked_date) n_critical_tasks_completed += 1
        t.tasks = t.tasks.map(subt => normalize(subt))
        return t
      }
      newTasks = newTasks.map(task => normalize(task))

      // deepcopy of current, to ensure that update redux goes well
      const current = { ...state.currentMaterial, n_tasks_completed, n_critical_tasks_completed }
      current.workorders.tasks = newTasks

      return {
        ...state,
        currentMaterial: current
      }

    case 'ADD_MELDING':
      // add new melding after creating one, but not after updating an existing melding
      return {
        ...state,
        meldingen: [action.newMelding].concat(state.meldingen)
      }

    case 'MATERIAAL_OPEN_MELDING':
      return {
        ...state,
        isPending: false,
        activeMelding: action.activeMelding
      }
    case 'MATERIAAL_UPDATE_MELDING':
      return {
        ...state,
        isPending: false,
        activeMelding: action.melding,
        meldingen: [action.melding].concat(state.meldingen.filter(melding => melding.id !== action.melding.id))
      }

    case 'RESET_MATERIAAL_STATE':
      return {
        ...materiaalState
      }

    default:
      return state
  }
}
