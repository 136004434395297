import { CheckCircleFilled } from "@ant-design/icons";
import { Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { DateFormat } from "~/components/date/DateFormat.jsx";
import { $t } from "~/i18n.js";
import { colors } from "~/theme/colors.js";

export const RecencyValidThrough = ({
  is_invalid,
  valid_through,
  alert_date,
}) => {
  return (
    <td className="col">
      {is_invalid ? (
        <Tag color="red">
          <span className="small">{$t("Onvoldoende")}</span>
        </Tag>
      ) : (
        <div className="row">
          <CheckCircleFilled style={{ color: colors.groen_dark }} />
          {alert_date ? (
            <Tag color="error" size="small">
              <DateFormat
                date={valid_through}
                options={{ month: "short", day: "2-digit", year: '2-digit' }}
              />
            </Tag>
          ) : (
            <span className="small" style={{ marginLeft: 4 }}>
              <DateFormat
                date={valid_through}
                options={{ month: "short", day: "2-digit", year: '2-digit' }}
              />
            </span>
          )}
        </div>
      )}
    </td>
  );
};
