import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RecencyBaro } from "~/components/baro/RecencyBaro.jsx";
import { GCard } from "~/components/GCard.jsx";
import {
  FESRecencyTable,
  FISRecencyTable,
  SPLRecencyTable,
  TMGRecencyTable,
} from "~/components/recency/index.js";
import { MedicalTable } from "~/components/recency/MedicalTable.jsx";
import { $t } from "~/i18n.js";
import { updateIfNeeded } from "~/lib/helpers.js";
import { getVliegerData } from "~/redux/persist/persist.actions.js";

export const RecencyWidget = () => {
  const [aanmeldingen, setAanmeldingen] = useState([]);

  const dispatch = useDispatch();

  const persist = useSelector((state) => state.persist);
  const pilot = persist?.vlieger_data;
  const recency = pilot?.recency;

  useEffect(() => {
    updateIfNeeded(persist.lastUpdatedData, async () => {
      dispatch(getVliegerData());
    });
  }, []);

  // check profile vliegen
  if (!persist.profile.vliegt) return null;

  return (
    <GCard
      title={$t("Recency")}
      style={{ width: "100%", height: "100%" }}
      loading={!persist.lastUpdatedData || persist.isPending}
      extra={
        <Button type="link">
          <Link to="/my_starts">{$t("Mijn starts")}</Link>
        </Button>
      }
    >
      {pilot.currency && (
        <RecencyBaro
          starts={pilot?.starts}
          hours={pilot?.uren}
          currency={pilot?.currency}
        />
      )}

      <table className="column" style={{ alignItems: "flex-start" }}>
        <tbody style={{ width: "100%" }}>
          <MedicalTable gegevens={persist?.gegevens} />

          {recency?.starts >= 0 && (
            <SPLRecencyTable recency={recency} showOnlyInvalid={true} />
          )}
          {recency?.tmg_starts && (
            <TMGRecencyTable recency={recency} showOnlyInvalid={true} />
          )}
          {recency?.fis && (
            <FISRecencyTable recency={recency} showOnlyInvalid={true} />
          )}
          {recency?.is_fes && (
            <FESRecencyTable recency={recency} showOnlyInvalid />
          )}
        </tbody>
      </table>
    </GCard>
  );
};
