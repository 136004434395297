import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { VerwijderButtonConfirm } from "~/components/buttons/VerwijderButtonConfirm.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { _parseDateTime } from "~/lib/localize.js";
import { colors } from "~/theme/colors.js";

const SAVE = "save";
const DELETE = "delete";

export const NewsForm = ({
  visible,
  groups,
  activeNews,
  closeModal,
  saveNews,
  group_name_lookup,
  deleteNews,
}) => {
  if (!activeNews) return null;

  const [loadingAction, setLoading] = useState("");
  const [form] = Form.useForm();

  const text = Form.useWatch("text", form);

  const [userReadList, setUserReadList] = useState([]);
  const [userReadListLoading, setUserReadListLoading] = useState(false);
  const getUserReadList = async () => {
    setUserReadListLoading(true);
    const data = await api.get(
      `news_admin/${activeNews.id}/get_read_users.json`
    );
    setUserReadList(data);
    console.log(data);
    setUserReadListLoading(false);
  };

  useEffect(() => {
    form.resetFields();
    if (visible && activeNews.id) getUserReadList();
  }, [visible]);

  const deleteItem = async () => {
    setLoading(DELETE);
    const data = await deleteNews({ id: activeNews.id });
    if (data) closeModal();
    setLoading("");
  };

  const saveForm = async (action) => {
    try {
      const values = await form.validateFields();
      setLoading(action);

      const result = await saveNews({
        id: activeNews.id,
        title: values.title,
        text: values.text,
        groups: values.groups,
        valid_from: values.valid_from.format("YYYY-MM-DD"),
        valid_to: values.valid_to.format("YYYY-MM-DD"),
      });

      if (result) {
        closeModal();
      }
      setLoading("");
    } catch (info) {
      console.error("error: ", { info });
      message.error(
        $t("Data is incorrect, ${e} fouten gemeld", {
          e: info?.errorFields?.length,
        })
      );
      setLoading("");
    }
  };

  return (
    <Modal
      title={activeNews?.id ? $t("Wijzig nieuws") : $t("Voeg nieuw nieuws toe")}
      open={visible}
      confirmLoading={!!loadingAction}
      onCancel={() => {
        if (text === activeNews.text) closeModal();
      }}
      footer={[
        activeNews?.id === "draft" && (
          <VerwijderButtonConfirm onConfirm={deleteItem} />
        ),

        <AnnuleerButton key="annuleren" onClick={closeModal} />,
        <SaveButton
          key="o"
          onClick={() => saveForm(SAVE)}
          loading={loadingAction === SAVE}
        />,
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        name="addNews"
        layout="vertical"
        preserve={false}
        initialValues={{
          ...activeNews,
          valid_from: activeNews.valid_from
            ? dayjs(activeNews.valid_from)
            : null,
          valid_to: activeNews.valid_to ? dayjs(activeNews.valid_to) : null,
        }}
        requiredMark={false}
      >
        <Form.Item
          label={$t("Titel")}
          name="title"
          rules={[{ required: true, message: $t("Titel is verplicht") }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={$t("Tekst")}
          name="text"
          rules={[{ required: true, message: $t("Tekst is verplicht") }]}
        >
          <Input.TextArea rows={8} />
        </Form.Item>

        <Form.Item
          label={$t("Zichtbaar van")}
          name="valid_from"
          rules={[{ required: true, message: $t("Begindatum is verplicht") }]}
          extra={$t("Datum waarop het nieuws zichtbaar wordt voor de leden")}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label={$t("Zichtbaar tot")}
          name="valid_to"
          rules={[{ required: true, message: $t("Einddatum is verplicht") }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        {!activeNews?.id && (
          <Form.Item
            label={$t("Groepen")}
            name="groups"
            rules={[{ required: true, message: $t("Groepen zijn verplicht") }]}
            extra={$t(
              "Gebruikers van deze groepen moeten bevestigen dat hij het bericht heeft gelezen."
            )}
          >
            <Select mode="multiple">
              {groups.map((group, idx) => (
                <Select.Option key={idx} value={group.replace(" ", "").trim()}>
                  {group_name_lookup[group] || group}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>

      {activeNews?.id && (
        <div>
          <p>{$t("Groepen")}</p>
          <small>
            {$t(
              "Gebruikers van deze groepen moeten bevestigen dat hij het bericht heeft gelezen."
            )}
          </small>
          <div className="row" style={{ gap: 6 }}>
            {activeNews?.group_keys?.map((group, idx) => (
              <Tag key={idx}>{group_name_lookup[group] || group}</Tag>
            ))}
          </div>
        </div>
      )}

      {activeNews?.id && (
        <Table
          dataSource={userReadList}
          rowKey="user_id"
          style={{ marginTop: 12 }}
          size="small"
          columns={[
            {
              title: $t("Naam"),
              dataIndex: "user_name",
              sorter: (a, b) => ("" + a.user_name).localeCompare(b.user_name),
            },
            {
              title: `${$t("Gelezen")} (${activeNews.total_read} / ${
                activeNews.total_users
              })`,
              dataIndex: "has_read",
              filters: [
                { text: $t("Ja"), value: true },
                { text: $t("Nee"), value: false },
              ],
              onFilter: (value, record) => record.has_read === value,
              render: (v) =>
                v && <CheckCircleFilled style={{ color: colors.green }} />,
            },
          ]}
        />
      )}

      {activeNews?.created_by && (
        <p className="small" style={{ marginTop: 12 }}>
          {$t("Laatst gewijzigd door")}: {activeNews.created_by.name},{" "}
          {_parseDateTime(activeNews.date_updated)}
        </p>
      )}

      {activeNews?.status === "draft" && (
        <div style={{ marginTop: 24 }}>
          <p className="label">{$t("Status")}</p>
          <p>
            <Tag color="blue">{$t("Concept")}</Tag>{" "}
            {$t("Pas bij publicatie zal hij zichtbaar worden bij leden")}
          </p>
        </div>
      )}
    </Modal>
  );
};
