import { Form, Input, Select } from 'antd'
import React, { useState } from 'react'
import { DataCard } from "~/components/dataCards/dataCard";
import { $t, i18n, locales } from '~/i18n.js'
import { AvatarUpload } from './avatar.upload'
import { DatePicker } from "antd";
import dayjs from "dayjs";

export const CardPersonalia = ({ profile, gegevens, saveData, type }) => {
  const [loading, setLoading] = useState(false);
  const [missingImage, setMissingImage] = useState(false);
  const [form] = Form.useForm();

  const saveForm = async () => {
    try {
      if (!profile.avatar) {
        setMissingImage(true);
      } else {
        setLoading(true);
        const values = await form.validateFields();

        i18n.changeLanguage(values.language);

        // manipulate values for save
        const res = await saveData({
          profile: values,
          gegevens: {
            date_of_birth: values.date_of_birth
              ? values.date_of_birth.format("YYYY-MM-DD")
              : null,
          },
        });
        setLoading(false);
        return res;
      }
    } catch (info) {
      console.log("Validate Failed:", info);
      setLoading(false);
    }
  };
  return (
    <DataCard
      title={$t("Personalia")}
      onSave={saveForm}
      loading={loading}
      type={type}
    >
      <div className="row">
        <div className="column">
          <AvatarUpload profile={profile} saveData={saveData} />
          {missingImage && (
            <p className="small alert" style={{ paddingRight: 22 }}>
              {$t("Voeg een foto toe")}.
            </p>
          )}
        </div>

        <Form
          form={form}
          name="personalia"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            ...profile,
            date_of_birth: gegevens.date_of_birth
              ? dayjs(gegevens.date_of_birth)
              : null,
          }}
          requiredMark={false}
        >
          <Form.Item
            label={$t("Voornaam")}
            name="first_name"
            rules={[{ required: true, message: $t("Wat is je voornaam?") }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={$t("Achternaam")}
            name="last_name"
            rules={[{ required: true, message: $t("Wat is je achternaam?") }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={$t("Geboortedatum")}
            name="date_of_birth"
            rules={[{ required: false }]}
          >
            <DatePicker
              format={localStorage.localeFormat}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </Form.Item>

          <Form.Item
            label={$t("Taal")}
            name="language"
            rules={[
              {
                required: true,
                message: $t("Selecteer taal"),
              },
            ]}
          >
            <Select>
              {/* $t('en') $t('de') $t('fr') $t('nl') $t('nl-BE') $t('nl-be') */}
              {i18n.languages.map((lng) => (
                <Select.Option key={lng} value={lng}>
                  {locales[lng]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </DataCard>
  );
};
