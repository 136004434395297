import { Button, List } from "antd";
import React, { useState } from "react";

import { EditOutlined, LinkOutlined } from "@ant-design/icons";
import { AddButton } from "~/components/buttons/AddButton.jsx";
import { config } from "~/config.js";
import { $t } from "~/i18n.js";
import { MaintenanceStatusTag } from "../../materialTags.jsx";
import { MatDocumentModal } from "./sections/matDocumentModal.jsx";
import { OrderDeleteButtons } from "~/pages/admin/dto/components/OrderDeleteButtons.jsx";
import { getMaterialTab } from "~/redux/materiaal/materiaal.actions.js";
import { useDispatch } from "react-redux";

export const DocumentTitle = ({ document, hideStatus }) => (
  <p className="clickable row">
    {!hideStatus && (
      <MaintenanceStatusTag maintenance_status={document.status} hideTag />
    )}

    {document.url && <LinkOutlined style={{ marginRight: 2 }} />}
    {document.name}

    {document.active_file?.version && (
      <span className="gray" style={{ marginLeft: 6 }}>
        (𝘃 {document.active_file?.version})
      </span>
    )}
  </p>
);

export const DocumentList = ({
  documents,
  readOnly,
  chapter,
  allChapters,
  materialID,
  header,
  hideStatus,
}) => {
  const [openDocument, setOpenDocument] = useState(null);

  const dispatch = useDispatch();

  const [loadingOrder, setLoadingOrder] = useState(false);
  const save_document_order = async (documents) => {
    setLoadingOrder(true);
    const payload = { action: "document_order", documents: documents.map(d => {return {id: d.id, order: d.order, name: d.name}}) };
  
      const data = await dispatch(
        getMaterialTab({
          method: "POST",
          activeTab: "documents",
          materialID: materialID,
          payload
        })
      );
      setLoadingOrder(false);
      return data
  };
    

  return (
    <>
      {openDocument && (
        <MatDocumentModal
          document={openDocument}
          closeModal={() => setOpenDocument(null)}
          readOnly={readOnly}
          allChapters={allChapters}
          materialID={materialID}
          chapter={chapter}
        />
      )}

      <List
        header={header ? <p className="darkgray label">{header}</p> : null}
        className="documentList"
        itemLayout="horizontal"
        dataSource={documents}
        size="small"
        style={{ maxWidth: readOnly ? 400 : null }}
        locale={{ emptyText: $t("geen bestanden") }}
        renderItem={(document, idx) => (
          <List.Item>
            <div
              className="row"
              style={{
                justifyContent: "space-between",
                minWidth: 200,
                width: "100%",
              }}
            >
              <div onClick={() => setOpenDocument(document)}>
                <DocumentTitle document={document} hideStatus={hideStatus} />
              </div>

              <div className="row" style={{ gap: 6 }}>
                {document.active_file && (
                  <a
                    title="file"
                    href={
                      document.active_file?.file
                        ? config.media_prefix + document.active_file?.file
                        : document.active_file?.url
                    }
                    target="_blank"
                  >
                    {$t("Open")}
                  </a>
                )}

                {!readOnly && (
                  <OrderDeleteButtons
                    loading={loadingOrder}
                    task_index={idx}
                    task={document}
                    tasks={documents}
                    saveTasks={(d) => save_document_order(d)}
                    hasDelete={false}
                  />
                )}

                {!readOnly && (
                  <Button
                    icon={<EditOutlined />}
                    size="small"
                    shape="circle"
                    onClick={() => setOpenDocument(document)}
                  />
                )}
              </div>
            </div>
          </List.Item>
        )}
      />

      {!readOnly && (
        <AddButton
          style={{ marginTop: 12 }}
          size="small"
          onClick={() =>
            setOpenDocument({
              chapter_id: chapter.id,
              order:
                chapter.documents?.length > 0
                  ? chapter.documents?.length + 1
                  : 1,
              name: "",
              files: [],
            })
          }
        />
      )}
    </>
  );
};
