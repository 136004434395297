import { Input, Skeleton, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { $t } from "~/i18n.js";

import { materiaalActions } from "~/redux/actions.js";

import { RefreshBaseData } from "~/components/buttons/RefreshBaseData.jsx";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { MainLayout } from "~/components/layout/layout.jsx";
import { SearchTags } from "~/components/searchTag/searchTag.jsx";
import { AddMaterial } from "./overviewTabs/addMaterial.jsx";
import { OverviewTab } from "./overviewTabs/overview.jsx";
import { PartOverviewTab } from "./overviewTabs/parts.jsx";
import { TeamsOverviewTab } from "./overviewTabs/teams.jsx";
import { WorkorderOverviewTab } from "./overviewTabs/workorders.jsx";

const MaterialOverviewTabsComponent = ({
  history,
  profile,
  materiaal,
  getMaterialData,
  activeTab,
}) => {
  activeTab = activeTab || "material";
  const [search, setSearch] = useState("");
  const [searchTag, setSearchTag] = useState([]);

  // switch tabs
  useEffect(() => {
    // make sure redux first loads in ID into redux
    if (materiaal[activeTab] === null) {
      getMaterialData(activeTab);
    }
  }, [activeTab]);

  const readOnly = !profile.wijzig_material;

  return (
    <MainLayout history={history}>
      <BreadcrumbHeader
        breadcrumbs={[
          <p key="1" className="title">
            {$t("Materiaal")}
          </p>,
        ]}
        buttons={[
          <RefreshBaseData extra={() => getMaterialData(activeTab)} />,
          !readOnly && <AddMaterial readOnly={readOnly} history={history} />,
        ]}
      />

      <div className="row" style={{ gap: 24 }}>
        <Input.Search
          style={{ maxWidth: 250 }}
          value={search}
          allowClear
          size="small"
          placeholder={$t("Zoek op naam")}
          onChange={(event) => setSearch(event.target.value.toLowerCase())}
        />

        <div className="row" style={{ gap: 3 }}>
          <SearchTags
            items={[
              { name: "club", color: "blue" }, // $t('club')
              { name: "sleep", color: "gold" }, // $t('sleep')
              { name: "prive", color: "green" }, // $t('prive')
              { name: "rollend", color: "orange" }, // $t('rollend')
              { name: "overig", color: "default" }, // $t('overig')
            ]}
            searchTags={searchTag}
            setSearchTags={setSearchTag}
          />
        </div>
      </div>

      <Tabs
        activeKey={activeTab}
        onChange={(newTab) =>
          history.push(`/material/${newTab === "material" ? "" : newTab}`)
        }
      >
        <Tabs.TabPane key="material" tab={$t("Status")}>
          <Skeleton loading={materiaal.isPending} active paragraph>
            {materiaal.meldingen !== null && (
              <OverviewTab
                material={materiaal.material}
                meldingen={materiaal.meldingen}
                inspections={materiaal.inspections}
                searchTag={searchTag}
                search={search}
              />
            )}
          </Skeleton>
        </Tabs.TabPane>

        <Tabs.TabPane key="workorders" tab={$t("Workorders")}>
          <Skeleton loading={materiaal.isPending} active paragraph>
            {materiaal.workorders !== null && (
              <WorkorderOverviewTab
                workorders={materiaal.workorders}
                searchTag={searchTag}
                search={search}
              />
            )}
          </Skeleton>
        </Tabs.TabPane>

        <Tabs.TabPane key="parts" tab={$t("Parts")}>
          <Skeleton loading={materiaal.isPending} active paragraph>
            {materiaal.parts !== null && (
              <PartOverviewTab
                parts={materiaal.parts}
                searchTag={searchTag}
                search={search}
              />
            )}
          </Skeleton>
        </Tabs.TabPane>

        <Tabs.TabPane key="teams" tab={$t("Teams")}>
          <Skeleton loading={materiaal.isPending} active paragraph>
            {materiaal.teams !== null && (
              <TeamsOverviewTab
                teams={materiaal.teams}
                searchTag={searchTag}
                search={search}
              />
            )}
          </Skeleton>
        </Tabs.TabPane>
      </Tabs>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  materiaal: state.materiaal,
  profile: state.persist.profile,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getMaterialData: (d) => dispatch(materiaalActions.getMaterialData(d)),
  };
};

export const MaterialOverviewTabsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialOverviewTabsComponent);
