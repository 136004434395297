import i18next from 'i18next'
import { Backend } from './lib/i18next-fetch-backend.js'
import { logException } from './lib/sentry.js'
import dayjs from 'dayjs'
import enGb from 'dayjs/locale/en-gb'
import updateLocale from 'dayjs/plugin/updateLocale.js'
import { convert_ft_to_m, convert_m_to_ft } from './lib/localize.js'

dayjs.extend(updateLocale)
dayjs.updateLocale(localStorage.locale || 'nl-NL', {
  weekStart: 1
})

export const locales = {
  nl: 'Nederlands',
  en: 'English',
  'nl-BE': 'Nederlands (België)',
  fr: 'Français'
}

const languageToLocale = {
  en: 'en-GB'
}

const languageToLocaleFormat = {
  en: enGb.formats.L
}

export class ClassI18n {
  /**
   * @example 'nl-NL
   * @type {String}
   */
  locale = null
  language = null
  unit = localStorage.unit || 'm'

  async init () {
    // determine the default language, which is either set by the localStorage or set by the URL
    const lng = localStorage.language || (window.location.href.includes('zweef.app') ? 'nl' : 'en')

    this.persistLanguage(lng)

    const promise = i18next
      .use(Backend)
      .init({
        ns: [
          'translations'
        ],
        /** https://www.i18next.com/overview/configuration-options */
        partialBundledLanguages: true,
        // Don't use a fallback language
        fallbackLng: null,
        defaultValue (lng, ns, key) {
          console.log('defaultValue', lng, ns, key)
          return 'MISSING TRANSLATION'
        },
        lng,
        debug: false,

        // Use custom interpolation be be consisten with Vue's setup
        interpolation: {
          prefix: '${',
          suffix: '}',
          escapeValue: false // not needed for react
        },
        backend: {
          loadPath: '/locales/${lng}.json'
        },
        // We don't use namespaces/ nesting in our translations so we can have keys including : and .
        nsSeparator: false,
        keySeparator: false,
      })

    /** Watch events */
    i18next.on('languageChanged', function (lng) {
      console.log(`[i18n] languageChanged: ${lng}`)

      // Persist
      localStorage.language = lng

      document.documentElement.setAttribute('lang', lng)
    })
    i18next.on('loaded', function (...args) {
      console.log(`[i18n] loaded: ${lng}`, ...args)
    })

    this.language = lng
    this.languages = Object.keys(locales)

    return promise
  }

  persistLanguage (lng) {
    localStorage.language = lng
    localStorage.locale = languageToLocale[lng] || lng
    this.locale = languageToLocale[lng] || lng
    localStorage.localeFormat = languageToLocaleFormat[lng] || 'DD-MM-YYYY'
    this.language = lng
  }

  changeLanguage (code = '') {
    if (!code || code.length < 2) {
      console.log('Wrong language set: ', { code })
      return
    }

    // check if language has been updated
    if (code !== this.language) {
      if (localStorage.language !== code) {
        this.persistLanguage(code)
        i18next.changeLanguage(code)
      }
    }
  }

  // basic translation
  /**
     *
     * @param {*} key
     * @param {*} options
     * @returns {String}
     */
  t (key = '', options = {}) {
    if (!key) {
      console.warn('no key supplied')
      return
    }
    const tr = i18next.t(key, options)
    const value = tr.toString()
    if (!value) {
      const msg = `TRANSLATION ERROR - value not found: '${key}' [${this.language}]`
      console.warn(msg)
      logException(null, msg)
    }
    return value || key
  }


  setUnit (unit = 'm') {
    // set units to meter or feet according to club settings
    this.unit = unit
    localStorage.unit = unit
  }

  parseLocaleHeightString (value = 0) {
    if (!value || value < 1) return ''
    return `${parseInt(this.convertHeightToLocale(value)).toLocaleString(this.locale)} ${this.unit === 'm' ? 'm' : 'ft'}`
  }

  convertHeightToLocale (value = 0) {
    if (!value || value == 0 || this.unit == 'm') return value
    return convert_m_to_ft(value)
  }

  convertLocaleHeightToMetric (value = 0) {
    if (!value || value == 0 || this.unit == 'm') return value
    return convert_ft_to_m(value)
  }
}

// export class and shortcut convenience function
export const i18n = new ClassI18n()

export const $t = i18n.t.bind(i18n)

// Expose
window.i18n = i18n
window.i18next = i18next
