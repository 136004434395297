import { ToolOutlined } from '@ant-design/icons'
import { Select, Tag } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { $t } from '~/i18n.js'
import { getMaterialTab } from "~/redux/materiaal/materiaal.actions.js";

export const SetRelatedMaintenance = ({
  readOnly,
  currentMaterial,
  visible,
  maintenance_programs,
  setMaintenancePrograms,
  list_maintenance,
}) => {
  const dispatch = useDispatch();

  // normalize maintenance_programs to array of integers
  const maintenance_programs_ids = maintenance_programs.map((m) => m.id);

  useEffect(() => {
    if (!visible) return;
    if (currentMaterial && !currentMaterial.maintenance)
      dispatch(
        getMaterialTab({
          materialID: currentMaterial.id,
          activeTab: "maintenance",
        })
      );
  }, [visible, currentMaterial]);

  return (
    <div className="row" style={{ gap: 12, marginTop: 9, marginLeft: 12 }}>
      <p style={{ width: 60 }}>{$t("Taken")}:</p>
      {!readOnly && (
        <Select
          style={{ width: "100%" }}
          showSearch
          mode="multiple"
          disabled={!list_maintenance || readOnly}
          filterOption={(input, option) =>
            option?.children
              ?.join("")
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          value={maintenance_programs_ids}
          onChange={(ids) =>
            setMaintenancePrograms(
              ids.map((i) => {
                return { id: i };
              })
            )
          }
        >
          {list_maintenance?.map((m, key) => (
            <Select.Option key={key} value={m.id} disabled={!m.n_tasks}>
              <Tag style={{ marginRight: 6 }}>{m.part_name || "???"}</Tag>
              {m.name}
              {m.workorder_id && <ToolOutlined style={{ marginLeft: 6 }} />}
              {!m.n_tasks && (
                <Tag color="red" style={{ marginLeft: 6 }}>
                  0 {$t("taken")}
                </Tag>
              )}
            </Select.Option>
          ))}
        </Select>
      )}

      {readOnly &&
        maintenance_programs.map((m, key) => (
          <Tag key={key} style={{ marginRight: 6 }}>
            {m.name}
          </Tag>
        ))}
    </div>
  );
};
