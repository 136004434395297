import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { flightsActions } from "~/redux/actions.js";
import { all_start_methods } from "~/redux/flights/flights.state.js";
import { SectionKist } from "../flight/sections/sectionKist.jsx";
import { FlightTable } from "./flightTable.jsx";

const { RangePicker } = DatePicker;

export const FlightStats = () => {
  const dispatch = useDispatch();
  const flights = useSelector((state) => state.flights);

  const getFlightStats = (d) => dispatch(flightsActions.getFlightStats(d));

  useEffect(() => {
    if (flights.lastUpdatedFlightData) {
      getFlightStats({});
    }
  }, []);

  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);

  const loadFlights = async (values) => {
    if (values === undefined) {
      values = filters; // Default to the current set filters
    }

    setLoading(true);
    await getFlightStats(values);
    setLoading(false);
  };

  const setFormData = async (values) => {
    values.date_after =
      values.date &&
      values.date[0] &&
      values.date[0].format("YYYY-MM-DD 00:00");
    values.date_before =
      values.date &&
      values.date[1] &&
      values.date[1].format("YYYY-MM-DD 23:59");
    delete values.date;

    values.extra_filters &&
      values.extra_filters.forEach((key) => {
        values[key] = true;
      });
    delete values.extra_filters;

    setFilters(values);
    await loadFlights(values);
  };

  const loadNewFlights = async (page) => {
    const values = await form.validateFields();
    setFormData({
      ...values,
      page: page || 1,
    });
  };

  const types = Array.from(
    new Set(flights.allFlights?.map((flight) => flight.type))
  )?.sort();

  const clearForm = async () => {
    form.resetFields();
    setFilters({});
    await loadFlights();
  };

  const [form] = Form.useForm();

  const ranges = [6, 12, 24, null].reduce((all, m) => {
    let tag = "";
    if (m) {
      tag = $t("${m} maanden", { m });
      all[tag] = [dayjs().subtract(m, "months"), dayjs()];
    } else {
      tag = $t("Alles");
      all[tag] = [null, null];
    }
    return all;
  }, {});

  return (
    <>
      <Collapse ghost>
        <Collapse.Panel header={$t("Voeg filters toe")} key="1">
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 9 }}
            style={{ maxWidth: 1100 }}
            initialValues={{
              remember: true,
              date: [null, null],
              method: "",
              pic: "",
            }}
            autoComplete="off"
            onFinish={setFormData}
          >
            <Form.Item label={$t("Datum")}>
              <Form.Item name="date" noStyle>
                <RangePicker
                  style={{ width: "100%" }}
                  allowClear
                  allowEmpty={[true, true]}
                  ranges={ranges}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item label={$t("Type")} name="type">
              <Select mode="tags" allowClear placeholder={$t("Kist type")}>
                {types.map((type) => (
                  <Select.Option key={type}>{type}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={$t("Kist")}>
              <SectionKist
                kisten={flights.kisten}
                readOnly={false}
                saveFlight={() => {}}
                width="100%"
              />
            </Form.Item>

            <Form.Item label={$t("Locatie")}>
              <Input.Group compact width="100%">
                <Form.Item name="takeoff" noStyle>
                  <Input
                    style={{ width: "43%", textAlign: "center" }}
                    placeholder={$t("Vertrek")}
                  />
                </Form.Item>

                <Input
                  className="site-input-split"
                  style={{
                    width: "14%",
                    borderLeft: 0,
                    borderRight: 0,
                    pointerEvents: "none",
                    background: "white",
                    textAlign: "center",
                  }}
                  placeholder=">"
                  disabled
                />

                <Form.Item name={$t("landed")} noStyle>
                  <Input
                    className="site-input-right"
                    style={{
                      width: "43%",
                      textAlign: "center",
                      borderLeft: 0,
                    }}
                    placeholder={$t("Aankomst")}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item
              label={$t("Start methode")}
              name="method"
              style={{ width: 1000 }}
            >
              <Radio.Group size="small">
                <Radio.Button value="">{$t("Alle")}</Radio.Button>
                {all_start_methods.map((method, key) => (
                  <Radio.Button value={method} key={key}>
                    {$t(method)}
                  </Radio.Button>
                ))}
                <Radio.Button value="tmg-a">{$t("tmg-a")}</Radio.Button>
                <Radio.Button value="sep-a">{$t("sep-a")}</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item label={$t("Rol")} name="pic">
              <Radio.Group size="small">
                <Radio.Button value>{$t("Gezagvoerder")}</Radio.Button>
                <Radio.Button value={false}>
                  {$t("2de-inzittende")}
                </Radio.Button>
                <Radio.Button value="">{$t("Beide")}</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item label={$t("Filters")} name="extra_filters">
              <Checkbox.Group>
                <Row>
                  <Col span={12}>
                    <Checkbox value="dbo">{$t("Instructievlucht")}</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="training">
                      {$t("Trainingsvlucht")}
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="with_passenger">
                      {$t("Met passagier")}
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="overland">{$t("Overland")}</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="profcheck">{$t("Profcheck")}</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {$t("Filteren")}
              </Button>
              <Button
                htmlType="button"
                style={{ marginLeft: "10px" }}
                onClick={clearForm}
              >
                {$t("Reset")}
              </Button>
            </Form.Item>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <FlightTable
        loadNewFlights={loadNewFlights}
        filters={filters}
        loading={loading}
      />
    </>
  );
};
