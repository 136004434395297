import { Layout } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import useMedia from "use-media";
import { Logo } from "../logo.jsx";
import { OnlineIndicator } from "../onlineIndicator.jsx";
import { MobileMenu } from "./MobileMenu.jsx";
import { ProfileMenu } from "./ProfileMenu.jsx";
import { Link } from "react-router-dom";

export function AppHeader({ triggerShowCheckModal }) {
  const persist = useSelector((state) => state.persist);
  const isNarrow = useMedia({ maxWidth: "1000px" }, true);
  const showMobileMenu = isNarrow;

  return (
    <Layout.Header
      style={{
        position: "fixed",
        top: 0,
        zIndex: 500,
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {showMobileMenu && (
        <MobileMenu
          showCheckDataButton={persist.profile.check_required}
          triggerShowCheckModal={triggerShowCheckModal}
          profile={persist.profile}
          club={persist.club}
        />
      )}

      {/* <LargeMenu mode="vertical" /> */}

      <Link className="row" to={"/"}>
        {persist.club && persist.club.logo && <Logo url={persist.club.logo} />}

        {persist.club && (
          <p
            className="white"
            style={{ fontWeight: 700, fontSize: 15 }}
          >
            {isNarrow ? persist.club.name : persist.club.full_name}
          </p>
        )}
      </Link>

      <div style={{ flexGrow: 1 }} />

      <OnlineIndicator />
      {(persist.profile.vliegt ||
        persist.profile.group_keys?.includes("niet_vliegend")) && (
        <ProfileMenu persist={persist} />
      )}
    </Layout.Header>
  );
}
