export const normalizeAanmeldingen = (aanmeldingen) => {
  // return data,

  const kisten = {}
  const rollen = {

    vliegers: 0,
    prive: 0,
    club: 0,

    nieuw: 0,
    as_sleepvlieger: 0,

    checkstart: 0,
    overland: 0
  }

  const sleep_vliegers = []

  aanmeldingen && aanmeldingen.forEach((aanmelding) => {
    // concat currency tab one level higher for quick lookup in tables
    aanmelding.vlieger = { ...aanmelding.vlieger, ...aanmelding.vlieger.currency }

    // first rollen zijn mutual exclusive
    if (aanmelding.aangemeld) {

      rollen.vliegers += 1
      if (aanmelding.checkstart) rollen.checkstart += 1

      if (aanmelding.kist?.includes('privé')) {
        rollen.prive += 1

      } else {
        rollen.club += 1

        // Voorkeuren voor kist
        if (aanmelding.kist && aanmelding.kist.length >= 1) {
          const kist = aanmelding.kist[0]
          if (!kisten[kist]) kisten[kist] = 0
          kisten[kist] += 1
        }

        aanmelding.vlieger?.group_keys.forEach(group => {
          if (!rollen[group]) rollen[group] = 0
          rollen[group] += 1
        })

        if (aanmelding.overland) rollen.overland += 1

        if (aanmelding.as_instructeur) {
          rollen.as_instructeur += 1
        }
        if (aanmelding.as_sleepvlieger) {
          rollen.as_sleepvlieger += 1
          aanmelding.as_sleepvlieger = true
          sleep_vliegers.push(aanmelding)
        }

        // nieuw lid is < 5 dbo flights
        if (aanmelding.vlieger && aanmelding.vlieger.group_keys?.includes('dbo') && aanmelding.vlieger.dbo <= 5) {
          rollen.nieuw += 1
          aanmelding.nieuw_lid = true
        }
      }
    }
  })

  // get all keys of kisten
  kisten.types = Object.keys(kisten)
  kisten.types.sort()

  return {
    aanmeldingen,
    sleep_vliegers,
    kisten,
    rollen
  }
}
