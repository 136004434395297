import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Button, List, Tag } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DateFormat } from "~/components/date/DateFormat.jsx";
import DateTimeDiff from "~/components/date/DateTimeDiff.jsx";
import { GCard } from "~/components/GCard.jsx";
import { StartIcon } from "~/components/icon/icon.jsx";
import { $t } from "~/i18n.js";
import { updateIfNeeded } from "~/lib/helpers.js";
import { daysActions } from "~/redux/actions.js";

export const AanmeldlijstWidget = () => {
  const dispatch = useDispatch();

  const days = useSelector((state) => state.days);
  const club = useSelector((state) => state.persist.club);

  useEffect(() => {
    updateIfNeeded(days.lastUpdated, () => dispatch(daysActions.getAllDays()));
  }, [days.lastUpdated, dispatch]);

  const allDays = days.allDays
    .filter((day) => day.can_aanmeld)
    .map((d) => ({
      ...d,
      starts: days.totals[d.dag_id]?.starts,
      aanmeldingen: days.totals[d.dag_id]?.aanmeldingen,
      registered: days.mijn_aanmeldingen[d.dag_id],
    }))
    .sort((a, b) => a.date - b.date);

  return (
    <GCard
      title={$t("Aankomende dagen")}
      loading={days.isPending}
      extra={
        <Button type="link">
          <Link to="/register">
            {$t("Bekijk alles")} ({allDays.length})
          </Link>
        </Button>
      }
    >
      <List
        dataSource={allDays.slice(0, 5)}
        locale={{ emptyText: $t("Geen dagen") }}
        renderItem={(item) => (
          <Link
            to={`/${
              item.is_vliegend && item.starts > 0 ? "start" : "register"
            }/${item.dag_id}`}
          >
            <List.Item className="clickable">
              <List.Item.Meta
                title={
                  <>
                    <span>
                      <DateFormat date={item.datum} />{" "}
                      {`${item.briefing_tijd || ""}`},
                      <span style={{ marginLeft: 6 }}>
                        <DateTimeDiff value={item.datum} />
                      </span>
                    </span>
                  </>
                }
                description={
                  <>
                    <Tag>
                      {!item.is_vliegend && <TeamOutlined />}{" "}
                      {item.type_dag || $t("Automatisch aangemaakt")}
                    </Tag>
                    {club.vliegveld_icao !== item.vertrek_vliegveld && (
                      <Tag>{item.vertrek_vliegveld}</Tag>
                    )}

                    {!item.gaat_door && (
                      <Tag color="red">{$t("Geannuleerd")}</Tag>
                    )}

                    {item.gaat_door &&
                      item.is_vliegend &&
                      item.aanmeldingen > 0 && (
                        <Tag color="geekblue" icon={<UserOutlined />}>
                          {item.aanmeldingen}
                        </Tag>
                      )}

                    {item.gaat_door && item.registered && (
                      <Tag color="geekblue">{$t("ingeschreven")}</Tag>
                    )}

                    {item.is_vliegend && item.starts > 0 && (
                      <Tag color="gold" icon={<StartIcon />}>
                        {item.starts}
                      </Tag>
                    )}

                    <span style={{ marginLeft: 4 }}>
                      {item.titel.slice(0, 15)}
                    </span>
                  </>
                }
              />
            </List.Item>
          </Link>
        )}
      />
    </GCard>
  );
};
