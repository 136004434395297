import { Button, Input, Modal, Popconfirm, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { $t } from '~/i18n.js'

import { ClockCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { BreadcrumbHeader } from '../../components/layout/breadcrumbHeader.jsx'
import { MainLayout } from '../../components/layout/layout.jsx'
import { defaultTableConfig } from '../../components/layout/table.jsx'
import { selectElementByID, updateIfNeeded } from '../../lib/helpers.js'
import { _parseDate } from '../../lib/localize.js'
import { adminActions } from '../../redux/actions.js'
import { EmailForm } from './EmailForm.jsx'

const columns = (pendingSend, triggerSend, setActiveEmailID) => {
  return [
    { title: $t('Datum'), dataIndex: 'date_created', render: (d) => _parseDate(d) },
    { title: $t('Gebruiker'), dataIndex: 'created_by_name' },
    { title: $t('Onderwerp'), dataIndex: 'subject' },
    { title: `# ${$t('Ontvangers')}`, dataIndex: 'n_recipients' },
    { title: `# ${$t('Mislukt')}`, render: (_, m) => m.failed_recipients?.length || '-' },
    {
      title: $t('Status'),
      filters: [
        { text: $t('Concept'), value: 'Concept' },
        { text: $t('Verstuurd'), value: 'Verstuurd' }
      ],
      onFilter: (value, campaign) => {
        if (value === 'Concept') return !campaign.date_send
        if (value === 'Verstuurd') return campaign.date_send
      },
      render: (_, campaign) => {
        if (campaign.date_send || campaign.status === 'send') return <Tag color='green'>{$t('Verstuurd')}</Tag>
        if (campaign.status === 'pending') return <Tag color='gold' icon={<ClockCircleOutlined />}>{$t('Ingepland')}</Tag>
        return <Tag color='blue'>{$t('Concept')}</Tag>
      }
    },

    { title: $t('Bijlage'), render: (_, campaign) => campaign.attachments?.length },

    {
      title: '',
      render: (_, campaign) => {
        return (

          <div style={{ width: 150 }}>
            <Button
              size='small'
              type='primary'
              onClick={() => setActiveEmailID(campaign.id)}
              style={{ marginRight: 6 }}
            >
              {$t('Open')}
            </Button>

            {!campaign.date_send && campaign.status !== 'pending' && campaign.status !== 'send' && <Popconfirm
              placement='bottom'
              loading={pendingSend === campaign.id || campaign.savingAttachments}
              title={$t('Zeker weten dat je deze email wilt versturen?')}
              onConfirm={() => triggerSend(campaign)}
              okText={$t('Ja')}
              cancelText={$t('Nee')}
            >
              <Button
                size='small'
                loading={
                pendingSend === campaign.id || campaign.savingAttachments
              }
                disabled={
                pendingSend === campaign.id || campaign.savingAttachments
              }
                type='primary'
              >
                {$t('Verstuur')}
              </Button>
            </Popconfirm>}
          </div>
        )
      }
    }
  ]
}

const EmailPageComponent = ({
  persist,
  admin,
  getEmailCampaigns,
  saveEmailCampaign,
  history,
  flights
}) => {
  const [activeEmailID, setActiveEmailID] = useState(null)
  let activeEmail = null
  if (activeEmailID === -2) {
    activeEmail = { date_send: null, ...history.location.state.email }
  } else if (activeEmailID === -1) {
    activeEmail = {
      subject: '',
      content: '',
      date_send: null,
      reply_to: persist.preferred_reply_to || persist.profile.email,
      reply_to_name: persist.preferred_reply_to_name || persist.profile.name
    }
  } else if (activeEmailID !== null) {
    activeEmail = selectElementByID(admin.campaigns, 'id', activeEmailID)
  }

  // generate if email in prop
  useEffect(() => {
    if (history.location.state?.email) {
      setActiveEmailID(-2)
    }
  }, [history.location.state])

  // get campaigns
  useEffect(() => {
    updateIfNeeded(admin.campaignsUpdated, getEmailCampaigns)
  }, [admin.documentsUpdated, getEmailCampaigns])

  const [search, setSearch] = useState('')
  const [campaigns, setCampaigns] = useState([])
  useEffect(() => {
    let campaigns = admin.campaigns
    if (search) {
      const searchText = search.toLowerCase()
      campaigns = campaigns.filter(
        (c) => c.subject.toLowerCase().indexOf(searchText) > -1
      )
    }
    setCampaigns(campaigns)
  }, [search, admin.campaigns])

  const [pendingSend, setPendingSend] = useState(null)
  const triggerSend = async (campaign) => {
    setPendingSend(campaign.id)
    const data = await saveEmailCampaign({ campaign: { id: campaign.id, action: 'send' } })
    setPendingSend(null)
    data && Modal.success({
      title: $t('Versturen gestart'),
      content: $t('Binnen het aankomend uur ontvangen alle leden de email.')
    })
  }

  return (
    <>
      <EmailForm
        visible={activeEmail !== null}
        closeModal={() => setActiveEmailID(null)}
        saveEmail={saveEmailCampaign}
        activeEmail={activeEmail}
        groups={persist.club.groups_filter}
        group_name_lookup={flights.group_name_lookup}
      />

      <MainLayout history={history} isLoading={admin.isPending}>
        <BreadcrumbHeader
          breadcrumbs={['Email']}
          buttons={[
            <Button
              key='1'
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => setActiveEmailID(-1)}
            >
              {$t('Voeg toe')}
            </Button>
          ]}
        />

        <Input.Search
          style={{ maxWidth: 250 }}
          value={search}
          allowClear
          size='small'
          placeholder='Zoek'
          onChange={(event) => {
            setSearch(event.target.value)
          }}
        />

        <Table
          style={{ marginTop: 12, width: '95vw', cursor: 'pointer' }}
          pagination={{ ...defaultTableConfig, defaultPageSize: 100 }}
          size='small'
          rowKey='id'
          dataSource={campaigns}
          columns={columns(pendingSend, triggerSend, setActiveEmailID)}
        />
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state) => ({
  persist: state.persist,
  flights: state.flights,
  admin: state.admin
})

const mapDispatchToProps = (dispatch) => {
  return {
    getEmailCampaigns: (d) => dispatch(adminActions.getEmailCampaigns(d)),
    saveEmailCampaign: (d) => dispatch(adminActions.saveEmailCampaign(d))
  }
}

export const EmailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailPageComponent)
