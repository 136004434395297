import { CheckCircleFilled } from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";
import { $t } from "~/i18n.js";
import { colors } from "~/theme/colors.js";
import { RecencyTable } from "./components/RecencyTable.jsx";
import { RecencyRowTd } from "./components/RecencyRow.jsx";
import {
  mapRecency,
  minRecencyValidThrough,
  setAlertDate,
} from "./components/helpers.js";
import { RecencyValidThrough } from "./components/RecencyValidThrough.jsx";

export const createFISRules = (recency) => {
  return [
    { label: $t("Flight instructor exam"), value: recency.fis_date_exam, min: 0, valid_through: recency.fis_date_exam_valid_to, skip_valid_alert: recency.fis_date_exam_invalid },
    { label: $t("of FI(s) uren"), value: parseInt(recency.fis_uren / 6) / 10, min: 30, valid_through: recency.fis_uren_valid_to, deadline: 120 },
    { label: $t("of FI(s) starts"), value: recency.fis_starts, min: 60, valid_through: recency.fis_starts_valid_to, deadline: 120 },
    { label: $t("Refresher Training"), date: recency.fis_date_refresher_course, valid_through: recency.fis_date_refresher_course_valid_to, skip_valid_alert: recency.fis_date_refresher_course_invalid, deadline: 300, hasDate: true},
    /** @todo full label text */
    { label: $t("Instr. flt under supervision"), date: recency.fis_date_training_flight, valid_through: recency.fis_date_training_flight_valid_to, skip_valid_alert: recency.fis_date_training_flight_invalid, deadline: 300, hasDate: true},
  ]
}

export const FISRecencyTable = ({ recency, showOnlyInvalid = false }) => {
  if (!recency) return null;

  const items = createFISRules(recency).map(mapRecency).filter(e => e.isUsed)

  const _items = showOnlyInvalid ? items
    .filter(item => !item.is_valid || item.alert_date) :
    items

  const minValidThrough = minRecencyValidThrough(items);

  const limitToOnlyHeader = recency.fis !== "invalid" && _items.length === 0;

  const body = (
    <>
      <tr style={{ width: "100%" }}>
        <td className="col ">
          <span className="bold">{$t("FI(s) recency")}</span>
          {recency.spl === "invalid" && (
            <>
              {" "}
              <br /> <span className="small">{$t("Note: Ongeldig SPL")}</span>
            </>
          )}
        </td>
        <td className="col" />

        {RecencyValidThrough({
          is_invalid: recency.fis === "invalid",
          valid_through: minValidThrough,
          alert_date: setAlertDate(120, minValidThrough),
        })}
      </tr>

      {!limitToOnlyHeader &&
        _items.map((item, index) => (
          <tr key={index}>
            <RecencyRowTd item={item} />
          </tr>
        ))}
    </>
  );
  if (showOnlyInvalid) return body;
  return (
    <RecencyTable title={$t('EASA FI(s) recency, afgelopen 3 jaar')}>
        {body}
    </RecencyTable>
  );
};
