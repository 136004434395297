import { Alert, Form, Input } from "antd";

import React, { useState } from "react";
import { DataCard } from "~/components/dataCards/dataCard.jsx";
import { EmailValidator } from "~/components/form/formHelpers.js";
import { $t } from "~/i18n.js";

export const CardExport = ({ currentMaterial, saveData }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const saveForm = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      // manipulate values for save
      const payload = {
        export_scheduled: values.export_scheduled,
      };
      const res = await saveData(payload);
      setLoading(false);
      return res;
    } catch (info) {
      console.log("Validate Failed:", info);
      setLoading(false);
    }
  };

  const [downloading, setDownloading] = useState(false);
  const downloadCode = async () => {
    if (downloading) return;
    setDownloading(true);
    await api.open_file(
      `materiaal/qrcode?id=${currentMaterial.id}&label=${currentMaterial.name}`,
      `QR ${currentMaterial.name}.png`
    );
    setDownloading(false);
  };

  return (
    <DataCard title={$t("Exporteer data")} onSave={saveForm} loading={loading}>
      <Form
        form={form}
        name="export"
        initialValues={{
          export_scheduled: currentMaterial.export_scheduled,
        }}
        requiredMark={false}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label={$t("Exporteer naar")}
          name="export_scheduled"
          extra={$t("Vul emailadres in waar de data heen gestuurd kan worden")}
          rules={[EmailValidator(false)]}
        >
          <Input type="email" style={{ maxWidth: 250 }} allowClear={true} />
        </Form.Item>

        {currentMaterial.export_scheduled && (
          <Alert
            description={
              <span>
                {$t("Data wordt vanavond verstuurd naar")}:{" "}
                {currentMaterial.export_scheduled}
              </span>
            }
            type="success"
            showIcon
          />
        )}
      </Form>
    </DataCard>
  );
};
