import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LinkOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Descriptions,
  Form,
  Input,
  List,
  Modal,
  Radio,
  Select,
  Tag,
} from "antd";
import dayjs from "dayjs";
import { AddButton } from "~/components/buttons/AddButton.jsx";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { VerwijderButtonConfirm } from "~/components/buttons/VerwijderButtonConfirm.jsx";
import { InputFileSingle } from "~/components/form/InputFileSingle.jsx";
import { ReadOnlyFormTag } from "~/components/form/ReadOnlyFormTag.jsx";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { api } from "~/lib/api.js";
import { generate_uuid } from "~/lib/helpers.js";
import { _parseDate, _parseDateTime } from "~/lib/localize.js";
import { DifCheckTag } from "~/pages/admin/dto/components/DifCheckTag.jsx";
import { OrderDeleteButtons } from "~/pages/admin/dto/components/OrderDeleteButtons.jsx";
import { MaintenanceStatusTag } from "~/pages/materiaal/materialTags.jsx";
import { IntervalField } from "../../maintenance/modalEditMaintenance.jsx";
import { useDispatch } from "react-redux";
import { getMaterialTab } from "~/redux/materiaal/materiaal.actions.js";
import { DocumentSelectModal } from "../documentSelectModal.jsx";
import { config } from "~/config.js";

const FileTitle = ({ idx, file }) => (
  <p className="row" style={{ gap: 6 }}>
    <Tag style={{ marginRight: 6 }} color={idx === 0 ? "gold" : "red"}>
      {idx === 0 ? $t("Actief") : $t("Archief")}
    </Tag>
    {file.url && <LinkOutlined />}
    {file.alias || <Tag>{$t("Naam ontbreekt")}</Tag>}

    {file.version && <span className="gray">(𝘃 {file.version})</span>}
  </p>
);

const FileLink = ({ file }) => {
  
  if (file.url?.length > 0) {
    return (
      <a href={file.url} target="blank">
        {file.url}
      </a>
    );
  }
  let fileName = file.file;
  
  if (fileName && typeof fileName === "string") {
    fileName = fileName.split("___");
    fileName = fileName[fileName.length - 1];
  }
  return (
    <a href={config.media_prefix + file.file} target="blank">
      {fileName}
    </a>
  );
};

export const MatDocumentModal = ({
  closeModal,
  document,
  allChapters,
  readOnly,
  materialID,
  chapter,
}) => {
  const [form] = Form.useForm();
  const date_next = Form.useWatch("date_next", form);
  const date_alert = Form.useWatch("date_alert", form);
  const dispatch = useDispatch();

  const documentExists = document && document.id;

  React.useEffect(() => {
    form.resetFields();
  }, [document]);

  const [loadingArchive, setLoadingArchive] = useState(true);
  const [archivedFiles, setArchivedFiles] = useState([]);
  let sortedArchivedFiles = archivedFiles.sort((a, b) => b.order - a.order);

  useEffect(() => {
    let isMounted = true;
    if (document && document?.active_file) {
      api.get(`materiaal/${document.id}/get_archive.json`).then((res) => {
        if (isMounted) {
          setArchivedFiles(res || []);
          setLoadingArchive(false);
        }
      });
    } else {
      setArchivedFiles(document?.active_file ? [document?.active_file] : []);
      setLoadingArchive(false);
    }
    return () => {
      isMounted = false;
    };
  }, [document]);

  useEffect(() => {}, [document]);

  const saveFile = (idx, newData) => {
    let newFile = {
      ...archivedFiles[idx],
      ...newData,
      action: archivedFiles[idx].action === "add" ? "add" : "update",
    };
    setArchivedFiles(
      archivedFiles.map((f) => (f.id === newFile.id ? newFile : f))
    );
  };

  const [saving, setSaving] = useState(null);

  const saveDocument = async (data) => {
    const payload = { action: "document", ...data };

    return await dispatch(
      getMaterialTab({
        method: data.method,
        activeTab: "documents",
        materialID: materialID,
        payload,
        as_json: false,
      })
    );
  };


  const [openVerhuisBestand, setOpenVerhuisBestand] = useState(null);

  const verhuisNaarNieuwBestand = async (newDocument) => {
    setLoadingArchive(true)
    let payload = {
      action: 'verhuis_bestand',
      id: openVerhuisBestand.id,
      new_document_id: newDocument.id,
    };
    await dispatch(
      getMaterialTab({
        method: "POST",
        activeTab: "documents",
        materialID: materialID,
        payload,
        as_json: true,
      })
    );
    
    const res = await api.get(`materiaal/${document.id}/get_archive.json`)
    setArchivedFiles(res || []);
    setLoadingArchive(false)
  };


  const deleteDocument = async () => {
    setSaving("delete");
    const result = await saveDocument({ method: "DELETE", id: document.id });
    // and get data to refresh all
    if (result) {
      await saveDocument({ method: "GET" });
      closeModal();
    }
    setSaving(null);
  };

  const triggerSave = async () => {
    try {
      const values = await form.validateFields();
      const data = {
        method: documentExists ? "PUT" : "POST",
        id: document.id,
        order: document.order,
        ...values,
        date_next: values.date_next
          ? values.date_next.format("YYYY-MM-DD")
          : null,
      };

      // delete key date_next if empty
      if (!data.date_next) delete data["date_next"];

      setSaving("save");
      const result = await saveDocument(data);
      let saved_document_id = result?.data?.saved_document_id;
      let newFiles = archivedFiles.filter((f) => f.action);

      // iterate over all files and save them
      for (let i = 0; i < newFiles.length; i++) {
        let file = {
          ...newFiles[i],
          document_id: saved_document_id,
        };
        // only save file on add, otherwise it fails
        if (file.action !== "add" || file.url.length > 0) delete file["file"];
        await api.post_form(`materiaal/save_doc.json`, file);
      }

      if (result) {
        // and get data to refresh all
        await saveDocument({ method: "GET" });
        closeModal();
        form.resetFields();
      }
      setSaving(null);
    } catch (info) {
      console.log("Validate Failed:", info);
      setSaving(null);
    }
  };

  return (
    <Modal
      title={
        readOnly
          ? $t("Bekijk document")
          : document && document.id
          ? $t("Pas document aan")
          : $t("Voeg nieuw document toe")
      }
      open={!!document}
      onCancel={closeModal}
      footer={[
        !readOnly && document && document.id && (
          <VerwijderButtonConfirm
            key="verwijder"
            title={$t("Zeker weten dat je dit documenten wilt verwijderen?")}
            onConfirm={deleteDocument}
          />
        ),
        <AnnuleerButton
          key="annuleren"
          onClick={closeModal}
          title={readOnly ? $t("Sluit") : null}
        />,
        !readOnly && (
          <SaveButton
            key="ok"
            onClick={triggerSave}
            loading={saving === "save"}
          />
        ),
      ]}
    >
      {openVerhuisBestand && (
        <DocumentSelectModal
          visible={!!openVerhuisBestand}
          addDocument={verhuisNaarNieuwBestand}
          title={$t("Verhuis bestand")}
          onClose={() => setOpenVerhuisBestand(null)}
          documents={[]}
        />
      )}

      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="hoofstuk"
        preserve={false}
        requiredMark={false}
        initialValues={{
          ...document,
          date_next: document.date_next ? dayjs(document.date_next) : null,
        }}
      >
        <Form.Item
          label={$t("Naam")}
          name="name"
          rules={[
            {
              required: true,
              max: 150,
              message: $t("Maximaal ${c} characters", { c: 150 }),
            },
          ]}
        >
          <Input disabled={readOnly} />
        </Form.Item>

        {chapter && (
          <ReadOnlyFormTag
            name={$t("Zichtbaarheid")}
            value={
              chapter.is_public ? (
                <p>
                  <EyeOutlined /> {$t("Via de QR code ook voor niet leden")}
                </p>
              ) : (
                <p>
                  <EyeInvisibleOutlined />{" "}
                  {$t("Alleen zichtbaar als je ingelogd bent")}
                </p>
              )
            }
          />
        )}

        {!readOnly && (
          <Form.Item label={$t("Hoofdstuk")} name="chapter_id">
            <Select mode="single">
              {allChapters?.map((chapter, key) => (
                <Select.Option value={chapter.id} key={key}>
                  {chapter.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {!readOnly && (
          <IntervalField
            maintenance={document}
            nextValue={date_next}
            alertValue={date_alert}
            nextValueFormat={_parseDate(date_next)}
            schedule="lifetime"
            label={$t("datum")}
            prefixLabel={$t("Vervaldatum")}
            units={$t("dagen")}
            field_prefix="date"
          />
        )}

        {readOnly && (
          <ReadOnlyFormTag
            name={$t("Vervaldatum")}
            value={
              <p className="row">
                <MaintenanceStatusTag
                  maintenance_status={document.status}
                  hideTag
                />

                {document.date_next
                  ? _parseDate(document.date_next)
                  : $t("Geen vervaldatum")}
              </p>
            }
          />
        )}

        {readOnly && (
          <List
            loading={loadingArchive}
            style={{ marginTop: 12 }}
            header={<p className="subtitle">{$t("Bestanden")}</p>}
            dataSource={sortedArchivedFiles}
            size="small"
            renderItem={(file, idx) => (
              <List.Item key={idx}>
                <div
                  className="row"
                  style={{
                    gap: 6,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <FileTitle idx={idx} file={file} />
                  <FileLink file={file} />
                </div>
              </List.Item>
            )}
          />
        )}

        {!readOnly && (
          <div
            className="row"
            style={{ marginTop: 12, justifyContent: "space-between" }}
          >
            <p className="subtitle">
              {$t("Bestanden")}{" "}
              <HelpBubble
                content={$t(
                  "Het eerste bestand wordt getoond, de rest van de bestanden vormen het archief."
                )}
              />
            </p>

            <AddButton
              title={$t("Voeg bestand toe")}
              type="primary"
              size="small"
              disabled={loadingArchive}
              loading={loadingArchive}
              onClick={() => {
                let newFile = {
                  order:
                    archivedFiles.length > 0 ? archivedFiles[0].order + 1 : 1,
                  id: "new_" + generate_uuid(),
                  action: "add",
                  alias: "",
                  version: "",
                  url: "",
                  file: null,
                };
                if (archivedFiles.length > 0) {
                  setArchivedFiles(archivedFiles.concat(newFile));
                } else {
                  setArchivedFiles([newFile]);
                }
              }}
            />
          </div>
        )}

        {!readOnly &&
          sortedArchivedFiles.map((file, idx) => {
            let hasURL = file.url.length > 0;
            return (
              <Card
                style={{ marginTop: 12 }}
                key={idx}
                title={<FileTitle idx={idx} file={file} />}
                extra={[
                  file.action && <DifCheckTag key="dif" action={file.action} />,

                  !file.action && (
                    <Button
                      style={{ marginRight: 6 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenVerhuisBestand(file);
                      }}
                      key="verhuis"
                      shape="circle"
                      size="small"
                      icon={<RetweetOutlined />}
                    />
                  ),

                  <OrderDeleteButtons
                    key="order"
                    task={file}
                    task_index={idx}
                    tasks={archivedFiles}
                    saveTasks={setArchivedFiles}
                  />,
                ]}
                size="small"
              >
                {file.action === "delete" && <p>{$t("Wordt verwijderd")}</p>}

                {file.action !== "delete" && (
                  <Descriptions
                    size="small"
                    column={1}
                    style={{ maxWidth: 700 }}
                  >
                    <Descriptions.Item label={$t("Naam")}>
                      <Input
                        type="text"
                        value={file.alias}
                        onChange={(e) => {
                          saveFile(idx, {
                            alias: e.target.value?.substring(0, 99),
                          });
                        }}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label={$t("Versie")}>
                      <Input
                        type="text"
                        value={file.version}
                        onChange={(e) =>
                          saveFile(idx, {
                            version: e.target.value?.substring(0, 99),
                          })
                        }
                      />
                    </Descriptions.Item>

                    {file.action !== "add" && (
                      <Descriptions.Item
                        label={hasURL ? $t("Website") : $t("Bestand")}
                      >
                        <FileLink file={file} />
                      </Descriptions.Item>
                    )}

                    {file.action === "add" && (
                      <Descriptions.Item label={$t("Bestand of website")}>
                        <Radio.Group
                          className="spacer"
                          size="small"
                          value={hasURL ? "url" : "file"}
                          onChange={(event) => {
                            saveFile(idx, {
                              url:
                                event.target.value === "url" ? "https://" : "",
                              file: null,
                            });
                          }}
                        >
                          <Radio.Button value="file">
                            {$t("Bestand")}
                          </Radio.Button>
                          <Radio.Button value="url">
                            {$t("Website")}
                          </Radio.Button>
                        </Radio.Group>
                      </Descriptions.Item>
                    )}

                    {file.action === "add" && (
                      <Descriptions.Item
                        label={hasURL ? $t("Website") : $t("Bestand")}
                      >
                        {hasURL ? (
                          <Input
                            type="text"
                            value={file.url}
                            onChange={(e) =>
                              saveFile(idx, {
                                url: e.target.value?.substring(0, 99),
                              })
                            }
                          />
                        ) : (
                          <InputFileSingle
                            includeDescription={true}
                            accept={null}
                            onChange={(f) => saveFile(idx, { file: f })}
                          />
                        )}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                )}
              </Card>
            );
          })}

        {document.user_name && (
          <p className="small gray" style={{ marginTop: 24 }}>
            {$t("Laatst gewijzigd door")}: {document.user_name},{" "}
            {_parseDateTime(document.date_updated)}
          </p>
        )}
      </Form>
    </Modal>
  );
};
