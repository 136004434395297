import React, { useState, useRef, useEffect } from "react";

import "./DropdownMenu.css";

export function DropdownMenu({ trigger, items }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const debounceRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  return (
    <div
      ref={dropdownRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="dropdown" onClick={toggleDropdown}>
        {trigger}
      </div>
      {isOpen && (
        <div className={`dropdown-menu ${isOpen ? "open" : ""}`}>
        {items.map((item, index) => {
          if (item.type === "divider") {
            return <div key={index} className="dropdown-divider"></div>;
          }
          return (
            <div key={index} className={"dropdown-item"}>
              {item.label}
            </div>
          );
        })}
      </div>
      )}
    </div>
  );
}
