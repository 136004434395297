import React from 'react';
import { Button } from 'antd';
import { $t } from '~/i18n.js';
import { FileTextOutlined } from '@ant-design/icons';
import { _parseDate } from '~/lib/localize.js';

const _flarm = (flarmID) => {
    // if flarmID starts with digit, add "ICAO"
    if (!flarmID) return ""

    if (flarmID && flarmID.length > 0 && !isNaN(flarmID[0])) {
        return 'icao:' + flarmID;
    }
    return 'flarm:' + flarmID;
}

const _flightTime = (minutes) => {
    // convert minutes to HH:MM
    const minInt = parseInt(minutes)
    if (!isNaN(minInt) && minutes > 0) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `00:${hours < 10 ? '0' : ''}${hours}:${mins < 10 ? '0' : ''}${mins}`;
    }
    return '00:00:00';
}

const _launchMethod = (method) => {
    // convert launch method to string
    console.log('method', method)
    switch (method) {
        case 'lier':
            return 'W';
        case 'sep-a':
        case 'tmg-a':
        case 'sep':
        case 'tmg':
            return 'S';
        case 'sleep':
            return 'T';
        default:
            return '';
    }
}

export const ExportFlightsToCSV = ({flights, date}) => {
    const handleExport = () => {
        
        const requiredHeaders = [
            'DATE', 'SEQ_NR', 'ID', 'CALLSIGN', 'COMPETITION_NUMBER', 'TYPE', 'DETAILED_TYPE', 
            'CREW1_NAME', 'CREW1_NR', 'RESERVED_1', 'CREW2_NAME', 'CREW2_NR', 'RESERVED_2', 
            'TKOF_TIME', 'TKOF_AP', 'TKOF_RWY', 'RESERVED_3', 'LDG_TIME', 'LDG_AP', 'LDG_RWY', 
            'LDG_TURN', 'MAX_ALT', 'VERTICAL_SPEED', 'INITIAL_CLIMBRATE', 'AVERAGE_CLIMB_RATE', 
            'FLIGHT_TIME', 'DAY_DIFFERENCE', 'LAUNCH_METHOD', 'TOW_ID', 'TOW_CALLSIGN', 
            'TOW_COMPETITION_NUMBER', 'TOW_SEQUENCE_NUMBER'
        ];

        const csvRows = [requiredHeaders.join(', ')]; // Add headers as the first row

        flights.forEach(flight => {
            // input rows:
            // ['uuid', 'id', 'datum', 'date_created', 'date_updated', 'volg_nummer', 'is_deleted', 'is_locked', 'dag_id', 'is_prive', 
            // 'vertrek_vliegveld', 'aankomst_vliegveld', 
            // 'kist_id', 'callsign', 'registratie', 'type', 'flarm', 
            // 'gezagvoerder_id', 'gezagvoerder_naam', 'tweede_inzittende_id', 'tweede_inzittende_naam', 'betalend_lid_id', 
            // 'is_fis', 'start_methode', 'sleep_uuid', 'category', 'is_training', 'is_examen', 'is_profcheck', 'is_overland', 'afstand', 'starts', 
            // 'start_tijd', 'landings_tijd', 'vluchtduur', 'blocktime', 'start_ogn', 'landings_ogn', 'height', 'pic_time', 'dbo_time', 'bijzonderheden', 'notitie', 
            // 'igc', 'igc_visible', 'signed']
            
            const sleepFlight = !flight.sleep_uuid ? null : flights.find(f => f.uuid === flight.sleep_uuid);
            console.log(flight.vluchtduur)

            const rowData = {
                DATE: _parseDate(new Date(flight.datum)),
                SEQ_NR: flight.uuid,
                ID: _flarm(flight.flarm),
                CALLSIGN: flight.registratie || '',
                COMPETITION_NUMBER: flight.callsign || '',
                TYPE: ['sep', 'tmg', 'sep-a', 'tmg-a'].includes(flight.type) ? 2 : 1,
                DETAILED_TYPE: flight.type,
                CREW1_NAME: flight.gezagvoerder_naam || '',
                CREW1_NR: flight.gezagvoerder_id || '',
                RESERVED_1: "",
                CREW2_NAME: flight.tweede_inzittende_naam || '',
                CREW2_NR: flight.tweede_inzittende_id || '',
                RESERVED_2: "",
                TKOF_TIME: flight.start_tijd + ":00" || '',
                TKOF_AP: flight.vertrek_vliegveld || '',
                TKOF_RWY: "",
                RESERVED_3: "",
                LDG_TIME: flight.landings_tijd + ":00" || '',
                LDG_AP: flight.aankomst_vliegveld || '',
                LDG_RWY: "",
                LDG_TURN: "",
                MAX_ALT: "",
                VERTICAL_SPEED: "",
                INITIAL_CLIMBRATE: "",
                AVERAGE_CLIMB_RATE: "",
                FLIGHT_TIME: _flightTime(flight.vluchtduur),
                DAY_DIFFERENCE: 0,
                LAUNCH_METHOD: _launchMethod(flight.start_methode),
                TOW_ID: _flarm(sleepFlight?.flarm) || '',
                TOW_CALLSIGN: sleepFlight?.registratie || '',
                TOW_COMPETITION_NUMBER: sleepFlight?.callsign || '',
                TOW_SEQUENCE_NUMBER: sleepFlight?.uuid || ''
            }
            const row = requiredHeaders.map(header => rowData[header] || '').join(',');
            csvRows.push(row);
        });

        // create CSV content
        const csvContent = csvRows.join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `flights ${_parseDate(new Date(date))}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        
    };

    return (
        <div>
            <Button size='small' type="primary" onClick={handleExport} icon={<FileTextOutlined />}>
                {$t('Exporteer')}
            </Button>
        </div>
    );
};