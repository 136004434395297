import React from "react";

import { Loader } from "~/components/loader/loader.jsx";

import { MeldingTable } from "../../melding/sections/meldingTable.jsx";
import { MaterialStatusDescription } from "./materiaalStatusDescription.jsx";

export const MaterialStatus = ({
  history,
  currentMaterial,
  meldingen,
  inspection,
  last_motor_minutes,
  currentProfile,
  loading,
}) => {
  if (!currentMaterial?.name) return <Loader />;
  return (
    <div>
      <MaterialStatusDescription
        currentMaterial={currentMaterial}
        inspection={inspection}
        last_motor_minutes={last_motor_minutes}
        currentProfile={currentProfile}
        readOnly={false}
      />

      <MeldingTable
        showArchive
        history={history}
        isPending={loading}
        currentMaterial={currentMaterial}
        meldingen={meldingen}
      />
    </div>
  );
};
