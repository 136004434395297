import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  PrinterOutlined,
  AppstoreAddOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { $t } from "~/i18n.js";
import { toLocaleDate } from "~/lib/localize.js";
import { api } from "~/lib/api.js";
import { Modal } from "antd";
import { Input } from "antd";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";

export const AanmeldHeader = ({
  days,
  profile,
  currentAanmelding,
  loading,
  club,
  match,
  editAanmelding,
  afmelden,
  setmodalOpen,
}) => {
  const [showAfmeldForm, setShowAfmeldForm] = useState(false);
  const [awaitAfmelding, setAwaitAfmelding] = useState(false);
  const [remark, setRemark] = useState("");

  return (
    <div
      className="row"
      style={{
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignContent: "center",
      }}
    >
      <BreadcrumbHeader
        breadcrumbs={[
          <Link key="1" to="/aanmelden" className="title">
            {$t("Aanmeldlijst")}
          </Link>,
          <p key="2" style={{ display: "flex" }}>
            {toLocaleDate(new Date(days.activeDay.datum))}
          </p>,
        ]}
      />

      <Modal
        width={340}
        loading={awaitAfmelding}
        title={$t("Meld af")}
        open={showAfmeldForm}
        onCancel={() => setShowAfmeldForm(false)}
        footer={[
          <Button
            key="slaan"
            danger
            loading={awaitAfmelding}
            onClick={async () => {
              setAwaitAfmelding(true);
              afmelden(profile.id, false, remark);
              setShowAfmeldForm(false);
              setAwaitAfmelding(false);
            }}
            icon={<CloseOutlined />}
          >
            {$t("Meld af")}
          </Button>,
          <AnnuleerButton
            key="annuleren"
            onClick={() => setShowAfmeldForm(false)}
          />,
        ]}
      >
        <p>{$t("Weet je zeker dat je wilt afmelden?")}</p>
        <Input.TextArea
          rows={4}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          placeholder={$t("Laat opmerking achter")}
        />
      </Modal>

      <div className="row" style={{ flexWrap: "wrap", gap: 6 }}>
        {profile.zichzelf_aanmelden && (
          <>
            {currentAanmelding && currentAanmelding.aangemeld ? (
              <>
                <Tooltip placement="top" title={$t("Pas aan")}>
                  <Button
                    type="primary"
                    onClick={() => editAanmelding(currentAanmelding)}
                    icon={<EditOutlined />}
                  >
                    {$t("Edit")}
                  </Button>
                </Tooltip>
                <Button
                  loading={loading}
                  danger
                  icon={<CloseOutlined />}
                  onClick={setShowAfmeldForm}
                >
                  {$t("Meld af")}
                </Button>
              </>
            ) : (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => editAanmelding(currentAanmelding || {})}
              >
                {$t("Meld aan")}
              </Button>
            )}
          </>
        )}
        {profile.iedereen_aanmelden && (
          <Button icon={<PlusOutlined />} onClick={() => editAanmelding({})}>
            {$t("Meld lid aan")}
          </Button>
        )}
        {days.activeDay.can_start && (
          <>
            <Button
              icon={<PrinterOutlined />}
              href={`/aanmelden/print/${match.params.id}`}
              target="_blank"
            >
              {$t("print")}
            </Button>
            {club.schema_name === "acvz" && (
              <Button
                icon={<AppstoreAddOutlined />}
                href={`https://startleider.app/load/zweefapp/${
                  days.activeDay.dag_id
                }?club=${club.schema_name}&token=${api.getToken()}`}
                target="_blank"
              >
                Startleider.app
              </Button>
            )}
          </>
        )}
        {(profile?.wijzig_rooster || profile?.wijzig_dagen) && <Button onClick={() => setmodalOpen("day")} icon={<EditOutlined />}>
          {$t("Dag aanpassen")}
        </Button>}
      </div>
    </div>
  );
};
