import { combineReducers } from 'redux'

import { aanmeldingenReducer } from './aanmeldingen/aanmeldingen.reducer.js'
import { adminReducer } from './admin/admin.reducer.js'
import { daysReducer } from './days/days.reducer.js'
import { flightsReducer } from './flights/flights.reducer.js'
import { materiaalReducer } from './materiaal/materiaal.reducer.js'
import { onderhoudReducer } from './onderhoud/onderhoud.reducer.js'
import { persistReducer } from './persist/persist.reducer.js'

export const resetAllStates = (dispatch) => {
  // convenience function used to rest all states required
  localStorage.removeItem('access_token')

  dispatch({
    type: 'RESET_PERSIST_STATE'
  })
  dispatch({
    type: 'RESET_ADMIN_STATE'
  })
  dispatch({
    type: 'RESET_DAYS_STATE'
  })
  dispatch({
    type: 'RESET_FLIGHTS_STATE'
  })
  dispatch({
    type: 'RESET_MATERIAAL_STATE'
  })
  dispatch({
    type: 'RESET_AANMELDING_STATE'
  })
  dispatch({
    type: 'RESET_ONDERHOUD_STATE'
  })
}

export const rootReducer = combineReducers({
  persist: persistReducer,
  aanmeldingen: aanmeldingenReducer,
  days: daysReducer,
  flights: flightsReducer,
  materiaal: materiaalReducer,
  onderhoud: onderhoudReducer,
  admin: adminReducer
})
