import {
  EditOutlined,
  ExclamationCircleTwoTone,
  LinkOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, List, Tooltip } from "antd";
import React, { useState } from "react";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { CopyIcon } from "~/components/icon/copy.jsx";
import { $t } from "~/i18n.js";
import { toLocaleDate } from "~/lib/localize.js";
import { DocumentModal } from "./documentModal.jsx";

import { config } from "~/config.js";

export const Chapter = ({
  allChapters,
  chapter,
  readOnly,
  editChapter,
  saveDocument,
  search,
}) => {
  const [openDocument, setOpenDocument] = useState(null);

  // show all or only the filtered
  const documents = !search
    ? chapter?.documents
    : chapter?.documents?.filter(
        (file) =>
          file.name.toLowerCase().indexOf(search) > -1 ||
          file.url.toLowerCase().indexOf(search) > -1
      );

  if (search && documents?.length === 0) return null;

  return (
    <Card
      size="small"
      className="Chapter"
      title={
        readOnly ? (
          chapter.name
        ) : (
          <span>
            <span className="gray">#{chapter.order}</span> {chapter.name}
          </span>
        )
      }
      style={{ margin: 4 }}
      extra={
        !readOnly && (
          <div className="row">
            <Tooltip placement="bottom" title={$t("Wijzig")}>
              <Button
                icon={<EditOutlined />}
                size="small"
                shape="circle"
                onClick={() => editChapter(chapter)}
              />
            </Tooltip>
            <Tooltip placement="bottom" title={$t("Voeg bestand toe")}>
              <Button
                style={{ marginLeft: 6 }}
                type="primary"
                icon={<PlusOutlined />}
                size="small"
                shape="circle"
                onClick={() =>
                  setOpenDocument({
                    chapter_id: chapter.id,
                    order: 0,
                    name: "",
                    files: null,
                    url: "",
                  })
                }
              />
            </Tooltip>
          </div>
        )
      }
    >
      <DocumentModal
        closeModal={() => setOpenDocument(null)}
        chapter={chapter}
        document={openDocument}
        saveDocument={saveDocument}
        allChapters={allChapters}
      />

      <List
        className="documentList"
        style={{ maxHeight: 320, overflowY: "auto", overflowX: "hidden" }}
        itemLayout="horizontal"
        dataSource={documents}
        size="small"
        locale={{ emptyText: $t("geen bestanden") }}
        renderItem={(document) => (
          <List.Item>
            <div
              className="row"
              style={{
                justifyContent: "space-between",
                minWidth: 200,
                width: "100%",
              }}
            >
              <Tooltip
                placement="top"
                title={
                  <span>
                    {document.user_name} |{" "}
                    {toLocaleDate(new Date(document.date_updated), {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}
                  </span>
                }
              >
                {readOnly ? (
                  <span>
                    {document.url && (
                      <span style={{ marginRight: 2 }}>
                        {document.url_valid ? (
                          <LinkOutlined />
                        ) : (
                          <Tooltip
                            placement="top"
                            title={$t(
                              "De link is foutief, vraag de admin om hem opnieuw toe te voegen."
                            )}
                          >
                            <ExclamationCircleTwoTone twoToneColor="red" />
                          </Tooltip>
                        )}
                      </span>
                    )}
                    {document.name}
                  </span>
                ) : (
                  <span>
                    <span className="gray">#{document.order}</span>{" "}
                    {document.name}
                  </span>
                )}
              </Tooltip>

              <div className="row" style={{ gap: 6 }}>
                {document.url || document.file ? (
                  <>
                    <CopyIcon
                      text={
                        document.url ||
                        `${window.location.origin}/d${document.uuid}`
                      }
                    />
                    <a
                      title="file"
                      href={
                        document.file
                          ? config.media_prefix + document.file
                          : document.url
                      }
                      target="_blank"
                    >
                      {$t("Open")}
                    </a>
                  </>
                ) : (
                  <p>
                    -{" "}
                    <HelpBubble
                      content={$t(
                        "Gebruiker heeft geen link of document toegevoegd"
                      )}
                    />
                  </p>
                )}

                {!readOnly && (
                  <Button
                    icon={<EditOutlined />}
                    size="small"
                    shape="circle"
                    onClick={() => setOpenDocument(document)}
                  />
                )}
              </div>
            </div>
          </List.Item>
        )}
      />
    </Card>
  );
};
