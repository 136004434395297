import { CheckCircleFilled, IssuesCloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { config } from "~/config.js";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { _parseDate } from "~/lib/localize.js";
import { normalizeVliegerData } from "~/redux/persist/persist.normalizers.js";

import { colors } from "~/theme/colors.js";
import { SluitButton } from "../buttons/SluitButton.jsx";
import { SaveButton } from "../buttons/SaveButton.jsx";
import { HelpBubble } from "../help/helpBubble.jsx";
import { DatePicker } from "antd";
import dayjs from "dayjs";

export const MedicalCheckModal = ({ onUpdateVliegerdata, user, currentProfile }) => {
  
  const dispatch = useDispatch();
  
  if (!user) return null;
  if (!currentProfile || !currentProfile.ziet_dto) return null;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);

  const [medical_valid, setMedicalValid] = useState([]);
  useEffect(() => {
    setMedicalValid([
                user?.gegevens?.medical_valid_from && dayjs(user?.gegevens?.medical_valid_from, "YYYY-MM-DD"),
                user?.gegevens?.medical_valid_to && dayjs(user?.gegevens?.medical_valid_to, "YYYY-MM-DD"),
              ])
  }, [user?.gegevens]) 

  
  const closeModal = () => setVisible(false);
  useEffect(closeModal, [user]);
  useEffect(() => {
    setLoading(false);
    setCheck(false);
  }, [visible]);

  const triggerSave = async () => {
    setLoading(true);

    let payload = { 
      id: user.id,
      medical_valid_from: medical_valid[0]?.format("YYYY-MM-DD"),
      medical_valid_to: medical_valid[1]?.format("YYYY-MM-DD"),
    }
    const res = await api.post("gegevens/verify_medical.json", payload);

    if (res) {
      const data = normalizeVliegerData(res);
      dispatch({
        type: "UPDATE_VLIEGER_IN_LIST",
        id: user.id,
        vlieger_data: data,
      });
      onUpdateVliegerdata(data);
    }

    setLoading(false);
    setVisible(false);
  };

  // only for dto users, with a medical uploaded
  const gegevens = user?.gegevens;
  if (!gegevens) return null;


  return (
    <div>
      <div className="clickable" onClick={() => setVisible(true)}>
        {gegevens.medical_checked_by && (
          <CheckCircleFilled style={{ color: colors.groen_dark }} />
        )}

        {user?.check_med && (
          <Button danger size="small" className="bounce">
            <IssuesCloseOutlined /> {$t("Controleer")}
          </Button>
        )}

        {!user?.check_med && !gegevens.medical_checked_by && (
          <Button size="small">{$t("Bekijk")}</Button>
        )}
      </div>

      <Modal
        title={$t("Controleer medical")}
        open={visible}
        onCancel={closeModal}
        footer={[
          <SluitButton key="sluit" onClick={closeModal} />,
          <SaveButton
            key="opslaan"
            loading={loading}
            disabled={!check}
            onClick={triggerSave}
          />,
        ]}
      >
        <p className="subtitle">
          {gegevens.medical_checked_by
            ? $t("Datum")
            : $t("Datum wijzigen (indien nodig)")}
          <HelpBubble
            content={$t(
              "De verantwoordelijkheid voor een correcte medical ligt bij de DTO, elke instructeur kan je medical controleren."
            )}
          />
        </p>
        <DatePicker.RangePicker
          value={medical_valid}
          disabled={gegevens.medical_checked_by}
          onChange={(dates) => setMedicalValid(dates)}
          format={localStorage.localeFormat}
          placeholder={[$t("Vanaf"), $t("Tot")]}
        />

        <div style={{ marginTop: 24 }}>
          {gegevens.medical ? (
            <p>
              {$t("Bestand")}:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="file"
                href={config.media_prefix + gegevens.medical.file}
              >
                {gegevens.medical.name}
              </a>
            </p>
          ) : (
            <p>{$t("Geen medical file toegevoegd.")}</p>
          )}
        </div>

        {gegevens.medical_checked_by && (
          <p className="small" style={{ color: "grey" }}>
            {$t("Gecontroleerd door ${by} op ${date}", {
              by: gegevens.medical_checked_by,
              date: _parseDate(gegevens.medical_checked_at),
            })}
          </p>
        )}

        {!gegevens.medical_checked_by && (
          <Checkbox
            checked={check}
            style={{ marginTop: 24 }}
            onChange={(e) => setCheck(e.target.checked)}
          >
            {$t(
              "Hierbij verklaar ik, ${checked_by}, dat de medical van ${medical_from} gecontroleerd is.",
              { checked_by: currentProfile?.name, medical_from: user?.name }
            )}
          </Checkbox>
        )}
      </Modal>
    </div>
  );
};
