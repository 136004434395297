import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Tabs,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import { StopOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { TimeInput } from "~/components/timeInput/timeInput.jsx";
import { _parseDateTime } from "~/lib/localize.js";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";
import { EditTasksList } from "../../editTasks.jsx";
import { AddMeldingButton } from "../../melding/sections/addMeldingButton.jsx";
import { SetRelatedMaintenance } from "./setRelatedMaintenance.jsx";
import { SetRelatedMelding } from "./setRelatedMelding.jsx";
import { WorkorderStatus } from "./workorderStatus.jsx";
import { DocumentUsedList } from "../documents/documentUsedList.jsx";

export const ModalEditWorkorder = ({
  currentMaterial,
  workorder,
  tasks,
  visible,
  closeModal,
  currentProfile,
}) => {
  // Arc inspections have limited editing possibilities
  const isArc = workorder.category === "arc";

  const [form] = Form.useForm();
  const [newTasks, setNewTasks] = useState(null);
  const [maintenance_programs, setMaintenancePrograms] = useState([]);
  const [documents_used, setDocumentsUsed] = useState([]);

  const [meldingen, setMeldingen] = useState([]);
  const [newMeldingen, setNewMeldingen] = useState([]);

  useEffect(() => {
    form.resetFields();
    setNewTasks(tasks);
    setNewMeldingen([]);
    setMaintenancePrograms(workorder.list_maintenance);
    setMeldingen(workorder.list_meldingen);
    setDocumentsUsed(workorder.documents_used);
  }, [workorder, tasks]);

  const dispatch = useDispatch();

  // Edit workorder only by admin and wijzig
  const canEditWorkorderTasks =
    currentProfile.wijzig_material ||
    currentMaterial?.current_role === "admin" ||
    currentMaterial?.current_role === "manager" ||
    currentMaterial?.current_role === "technician";

  const [saving, setSaving] = useState(false);
  const saveData = async () => {
    try {
      setSaving(true);
      const values = await form.validateFields();
      const payload = {
        ...workorder,
        ...values,
        expected_start_date: values.expected_start_date?.format("YYYY-MM-DD"),
        expected_end_date: values.expected_end_date?.format("YYYY-MM-DD"),
        tasks: isArc ? [] : newTasks,

        // only forward if changes are made in list
        maintenance_programs:
          workorder.list_maintenance.toString() ===
          maintenance_programs.toString()
            ? []
            : maintenance_programs,
        meldingen:
          workorder.list_meldingen.toString() === meldingen.toString()
            ? []
            : meldingen,
        documents_used,
      };
      const res = await dispatch(
        saveMaterialTab({
          activeTab: "workorders",
          materialID: currentMaterial.id,
          payload,
        })
      );
      if (res) {
        setSaving(false);
        closeModal();
      } else {
        setSaving(false);
      }
    } catch (info) {
      setSaving(false);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [deleting, setDelete] = useState(false);
  const triggerDelete = async () => {
    setDelete(true);
    const payload = { id: workorder.id, action: "delete" };
    const res = await dispatch(
      saveMaterialTab({
        activeTab: "workorders",
        materialID: currentMaterial.id,
        payload,
      })
    );
    dispatch({
      type: "MATERIAAL_CLEAR_TAB",
      meldingen: null,
      maintenance: null,
    });
    if (res) closeModal();
    setDelete(false);
  };

  const [reopening, setReopening] = useState(false);
  const reOpen = async () => {
    setReopening(true);
    const payload = { id: workorder.id, state: "active" };
    const res = await dispatch(
      saveMaterialTab({
        activeTab: "workorders",
        materialID: currentMaterial.id,
        payload,
      })
    );
    if (res) closeModal();
    setReopening(false);
  };

  return (
    <>
      <Modal
        title={$t("Bevestig verwijderen")}
        open={showDeleteModal}
        confirmLoading={deleting}
        onCancel={() => {
          setShowDeleteModal(false);
          setConfirmText("");
        }}
        onOk={triggerDelete}
        okButtonProps={{
          disabled: confirmText !== workorder.name,
          loading: deleting,
        }}
      >
        <p>
          <span className="bold">Let op</span>,{" "}
          {$t(
            "je gaat deze workorder, inclusief alle taken die zijn afgetekend, verwijderen."
          )}
        </p>
        <p>
          {$t("Om te bevestigen dat je dit bewust doet, schrijf")}{" "}
          <Tag>{workorder.name}</Tag> {$t("in het input hieronder.")}
        </p>
        <Input
          type="text"
          style={{ width: 250, margin: "10px auto" }}
          placeholder=""
          value={confirmText}
          onChange={(elm) => setConfirmText(elm.target.value)}
        />
      </Modal>

      <Modal
        title={$t("Wijzig workorder")}
        style={{ top: 20 }}
        width={800}
        open={visible}
        onCancel={closeModal}
        footer={[
          workorder?.state === "completed" && (
            <Button key="open" onClick={reOpen} loading={reopening}>
              {$t("Her-open")}
            </Button>
          ),
          <Button
            key="delete"
            danger
            icon={<StopOutlined />}
            onClick={() => setShowDeleteModal(true)}
          >
            {$t("Verwijder")}
          </Button>,
          <AnnuleerButton key="annuleren" onClick={closeModal} />,
          <SaveButton key="opslaan" onClick={saveData} loading={saving} />,
        ]}
      >
        <WorkorderStatus
          currentMaterial={currentMaterial}
          currentWorkorder={workorder}
          currentProfile={currentProfile}
          canSetTasks={workorder.state == "active"}
          readOnly={false}
        />

        <Tabs>
          <Tabs.TabPane tab={$t("Algemeen")} key="general">
            {isArc && (
              <p className="small spacer">
                {$t(
                  "Note: Een ARC is een standard ingeladen onderhoudsprogramma dat je beperkt kan wijzigen."
                )}
              </p>
            )}

            <Form
              form={form}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 16 }}
              name="maintenance"
              preserve={false}
              requiredMark={false}
              initialValues={{
                ...workorder,
                expected_start_date: workorder.expected_start_date
                  ? dayjs(workorder.expected_start_date)
                  : null,
                expected_end_date: workorder.expected_end_date
                  ? dayjs(workorder.expected_end_date)
                  : null,
              }}
            >
              <Form.Item
                label={$t("Naam")}
                name="name"
                rules={[
                  {
                    required: true,
                    max: 90,
                    message: $t("Maximaal ${c} characters", { c: 50 }),
                  },
                ]}
              >
                <Input disabled={isArc} />
              </Form.Item>

              <Form.Item
                label={
                  <p>
                    {$t("Nummer")}{" "}
                    <HelpBubble
                      content={$t(
                        "Workorder nummer wordt automatisch opgemaakt, maar kan aangepast worden."
                      )}
                    />
                  </p>
                }
                name="workorder_number"
                rules={[
                  {
                    required: true,
                    max: 90,
                    message: $t("Maximaal ${c} characters", { c: 90 }),
                  },
                ]}
              >
                <Input variant="filled" />
              </Form.Item>

              <Form.Item
                label={$t("Opmerking (vrij veld)")}
                name="remark"
                rules={[
                  {
                    required: false,
                    max: 300,
                    message: $t("Maximaal ${c} characters", { c: 300 }),
                  },
                ]}
              >
                <Input.TextArea rows={3} />
              </Form.Item>

              <Form.Item
                label={$t("Verwachte start")}
                name="expected_start_date"
              >
                <DatePicker
                  format={localStorage.localeFormat}
                  style={{ width: 200 }}
                />
              </Form.Item>

              <Form.Item
                label={$t("Verwachte oplevering")}
                name="expected_end_date"
              >
                <DatePicker
                  format={localStorage.localeFormat}
                  style={{ width: 200 }}
                />
              </Form.Item>

              {currentMaterial.category === "kist" && (
                <>
                  <p className="subtitle">
                    {$t("Actuele starts en uren")}
                    <HelpBubble
                      content={$t("Zal op het CRS genoteerd worden.")}
                    />
                  </p>

                  <Form.Item
                    label={$t("Starts")}
                    name="total_starts"
                    rules={[{ required: false }]}
                  >
                    <Input style={{ width: 135 }} type="number" />
                  </Form.Item>

                  <Form.Item
                    label={$t("Tijd")}
                    name="total_minutes"
                    rules={[{ required: false }]}
                  >
                    <TimeInput allowClear={false} />
                  </Form.Item>

                  {currentMaterial.kist_configuration !== "Glider" && (
                    <Form.Item
                      label={$t("Motor tijd")}
                      name="total_motor_minutes"
                      rules={[{ required: false }]}
                    >
                      <TimeInput allowClear={false} />
                    </Form.Item>
                  )}

                  {currentMaterial.kist_configuration !== "Glider" && (
                    <Form.Item
                      label={$t("Propeller tijd")}
                      name="total_propeller_minutes"
                      rules={[{ required: false }]}
                    >
                      <TimeInput allowClear={false} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form>

            <DocumentUsedList
              documents={documents_used}
              setDocuments={setDocumentsUsed}
            />

            <p className="subtitle" style={{ marginTop: 24 }}>
              {$t("Verbonden onderdelen")}
              <HelpBubble
                content={$t(
                  "Het toevoegen van onderdelen zal leiden tot het importeren van taken, het verwijderen van onderdelen zal taken niet verwijderen."
                )}
              />
            </p>

            <SetRelatedMaintenance
              readOnly={workorder.state != "active"}
              visible
              currentMaterial={currentMaterial}
              list_maintenance={currentMaterial.maintenance?.maintenance?.filter(
                (m) => (isArc ? m.category === "arc" : m.category !== "arc")
              )}
              maintenance_programs={maintenance_programs}
              setMaintenancePrograms={setMaintenancePrograms}
            />
            <SetRelatedMelding
              readOnly={workorder.state != "active"}
              visible
              currentMaterial={currentMaterial}
              list_meldingen={(
                currentMaterial.meldingen?.meldingen || []
              ).concat(newMeldingen)}
              meldingen={newMeldingen.concat(meldingen)}
              setMeldingen={setMeldingen}
            />

            <div className="row" style={{ marginTop: 18, marginLeft: 12 }}>
              <AddMeldingButton
                currentMaterial={currentMaterial}
                currentWorkorderID={workorder.id}
                propagateNewMelding={(m) =>
                  setNewMeldingen(newMeldingen.concat([m]))
                }
              />
            </div>

            <Divider />
            {workorder.user_updated && (
              <p className="small" style={{ marginTop: 24 }}>
                {$t("Laatst gewijzigd door")}: {workorder.user_updated},{" "}
                {_parseDateTime(workorder.date_updated)}
              </p>
            )}
          </Tabs.TabPane>

          {canEditWorkorderTasks && (
            <Tabs.TabPane tab={$t("Taken")} key="tasks">
              <EditTasksList
                isWorkorder
                category={currentMaterial.category}
                tasks={newTasks}
                saveTasks={setNewTasks}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Modal>
    </>
  );
};
