import { CheckCircleFilled } from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";
import { $t } from "~/i18n.js";
import { colors } from "~/theme/colors.js";
import { RecencyRowTd } from "./components/RecencyRow.jsx";
import { RecencyTable } from "./components/RecencyTable.jsx";
import {
  mapRecency,
  minRecencyValidThrough,
  setAlertDate,
} from "./components/helpers.js";
import { RecencyValidThrough } from "./components/RecencyValidThrough.jsx";

export const createFESRules = (recency) => {
  return [
    {
      label: $t("FE(S) refresher course"),
      deadline: 300,
      date: recency.fes_refresher,
      min: 0,
      valid_through: recency.fes_date_refresher_valid_to,
      skip_valid_alert: recency.fes_date_refresher_invalid,
      hasDate: true,
    },
  ];
};

export const FESRecencyTable = ({ recency, showOnlyInvalid = false }) => {
  if (!recency) return null;

  const items = createFESRules(recency)
    .map(mapRecency)
    .filter((e) => e.isUsed);

  const _items = showOnlyInvalid
    ? items.filter((item) => !item.is_valid || item.alert_date)
    : items;

  const minValidThrough = minRecencyValidThrough(items);

  const limitToOnlyHeader = recency.fes !== "invalid" && _items.length === 0;

  const body = (
    <>
      <tr style={{ width: "100%" }}>
        <td className="col bold">{$t("FE(s) recency")}</td>
        <td className="col" />

        {RecencyValidThrough({
          is_invalid: recency.fes === "invalid",
          valid_through: minValidThrough,
          alert_date: setAlertDate(120, minValidThrough),
        })}
      </tr>
      {!limitToOnlyHeader &&
        _items.map((item, index) => (
          <tr key={index}>
            <RecencyRowTd item={item} />
          </tr>
        ))}
    </>
  );

  if (showOnlyInvalid) return body;
  return (
    <RecencyTable title={$t("FE(s) recency, afgelopen 3 jaar")}>
      {body}
    </RecencyTable>
  );
};
