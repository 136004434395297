import { Card, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { _parseDate } from "~/lib/localize.js";
import { persistActions } from "~/redux/actions.js";

export const MyNews = ({ setModalVisible, toggleCheckbox }) => {
  const persist = useSelector((state) => state.persist);
  const [newsItems, setNewsItems] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let items = persist.news;
    if (toggleCheckbox) items = items.filter((i) => !i.has_read);
    setNewsItems(items);
  }, [setModalVisible]);

  if (!newsItems || newsItems?.length === 0) {
    return <p>{$t("Geen berichten beschikbaar")}</p>;
  }

  const handleMarkRead = (item) => {
    const updatedItems = newsItems.filter((i) => i.id !== item.id);
    setNewsItems(updatedItems);
    dispatch(persistActions.updateNewsItem(item));

    // Look for next unread item after the current one.
    if (updatedItems.length === 0) {
      setModalVisible(false);
    }
  };

  const descDateSort = (a, b) => {
    const dateA = new Date(a.news_item.valid_from).getTime() || 0;
    const dateB = new Date(b.news_item.valid_from).getTime() || 0;
    return dateB - dateA;
  }
  
  return (
    <div className="column" 
    id="start"
        style={{ gap: 12, alignItems: "flex-start" }}>
      {newsItems.sort(descDateSort).map((item, idx) => (
        <>
        <Card
          id={item.news_item.id}
          data-id={item.news_item.id}
          title={item.news_item.title}
          key={idx}
          style={{ width: "100%", maxWidth: 900 }}
          actions={[
            toggleCheckbox && (
              <Checkbox
                onChange={() => handleMarkRead({ ...item, has_read: true })}
                checked={item.has_read}
              >
                {$t("Ik bevestigd dat ik dit gelezen heb")}
              </Checkbox>
            )
          ].filter(f => f)}  
        >
          <div>
            <p className="small gray">
              {item.news_item.created_by.name}, {" "}
              {_parseDate(item.news_item.valid_from)}
            </p>

            <p style={{ whiteSpace: "pre-wrap", marginTop: 12 }}>
              {item.news_item.text}
            </p>
 
          </div>
        </Card>
        </>
      ))}
    </div>
  );
};
