import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { Button, Flex, Input, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min.js";
import { AddButton, ImportButton } from "~/components/buttons/index.js";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { defaultTableConfig } from "~/components/layout/table.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { selectElementByID, updateIfNeeded } from "~/lib/helpers.js";
import { getBaseData } from "~/redux/flights/flights.actions.js";
import { colors } from "~/theme/colors.js";
import { CheckIfUserInBaseGroup } from "../groups/components/baseGroups.js";
import { GroupDropdown } from "./components/groupDropdown.jsx";
import { ImportDialogUsers } from "./components/ImportDialogUsers.jsx";
import { UserForm } from "./components/UserForm.jsx";

export function AdminUsers({ history }) {
  const [users, setUsers] = useState([]);
  const updateUser = (newUser, userList = null) => {
    let newUsers = [];
    if (newUser.addedUser) {
      newUsers = [newUser].concat(users);
    } else {
      // update user in array, required for batch processing otherwise we need to refresh data constantly
      newUsers = (userList || users).map((u) => {
        if (u.id == newUser.id) return newUser;
        return u;
      });
    }
    setUsers(newUsers);
    filterUsers(null, null, newUsers);
    return newUsers;
  };

  const { group_name_lookup, lastUpdatedFlightData, isPending } = useSelector(
    (state) => state.flights
  );
  const { club } = useSelector((state) => state.persist);

  const dispatch = useDispatch();

  // init base data if required
  useEffect(() => {
    if (!lastUpdatedFlightData && !isPending) {
      updateIfNeeded(lastUpdatedFlightData, () => dispatch(getBaseData()));
    }
  }, []);

  const [isModal, setModal] = useState(false);

  const lidmaatschap_types = club.lidmaatschap_types;
  const columns = [
    {
      title: $t("Member"),
      dataIndex: "name",
      sorter: (a, b) => ("" + a.name).localeCompare(b.name),
      render: (_, user) => user.name || user.member || user.first_name,
    },
    { title: $t("Email"), dataIndex: "email" },
    {
      title: $t("Lidmaatschap"),
      dataIndex: "lidmaatschap",
      filters:
        lidmaatschap_types?.length > 1 &&
        lidmaatschap_types
          .split(",")
          .map((g) => ({ text: g, value: g.trim() })),
      onFilter: (value, record) => record.lidmaatschap === value,
      sorter: (a, b) => ("" + a.lidmaatschap).localeCompare(b.lidmaatschap),
      render: (_, user) =>
        user.has_temporary_password ? (
          <Tag>{$t("default")}</Tag>
        ) : (
          user.lidmaatschap
        ),
    },
    {
      title: $t("Lid nummer"),
      dataIndex: "lid_nummer",
      sorter: (a, b) => ("" + a.lid_nummer).localeCompare(b.lid_nummer),
    },
    {
      title: $t("Is actief"),
      dataIndex: "is_actief",
      filters: [
        { text: $t("Ja"), value: true },
        { text: $t("Nee"), value: false },
      ],
      onFilter: (value, user) => user.is_active === value,
      render: (_, user) => (
        <p>
          {user.is_active ? (
            <CheckCircleTwoTone twoToneColor={colors.green} />
          ) : (
            <CloseCircleTwoTone twoToneColor={colors.red} />
          )}
        </p>
      ),
    },
    {
      title: $t("Interne opmerking"),
      dataIndex: "remark",
      render: (remark, user) => {
        return (
          <div>
            {!CheckIfUserInBaseGroup(user) && (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {$t("Mist kerngroep")}
              </Tag>
            )}
            {remark}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    refresh();
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading, setLoading] = useState(false);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const fetchData = async () => {
    const users = await api.get("admin/users.json");

    if (users) {
      setUsers(users);
      filterUsers(null, null, users);
    }
  };

  const refresh = async () => {
    setLoading(true);
    // ajax request after empty completing
    await fetchData();

    setSelectedRowKeys([]);
    setLoading(false);
  };

  const [search, setSearch] = useState(null);
  const [groupFilter, setGroupSearch] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const filterUsers = (
    searchTerm = null,
    selectedGroupKey = null,
    newUsers = null
  ) => {
    let newGroupFilter = selectedGroupKey || groupFilter;
    if (newGroupFilter == "geen") newGroupFilter = null;
    if (groupFilter !== newGroupFilter) setGroupSearch(newGroupFilter);

    let selectedUsers = newUsers || users;

    if (searchTerm) {
      selectedUsers = selectedUsers.filter((user) => {
        return user.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      });
    }

    if (newGroupFilter) {
      selectedUsers = selectedUsers.filter((user) => {
        return user.group_keys.indexOf(newGroupFilter) > -1;
      });
    }

    setFilteredUsers(selectedUsers);
  };

  const [loadingActivating, setloadingActivating] = useState(false);
  const activateUser = async (userIDs) => {
    setloadingActivating(true);
    let newUsers = null;
    for (const index in userIDs) {
      const { data } = await api.patch("admin/users.json", {
        id: userIDs[index],
        is_active: true,
      });
      if (data) newUsers = updateUser(data, newUsers);
    }
    setloadingActivating(false);
  };

  async function onExportClick(selection = []) {
    api.openFileGET(`admin/export_users.json?ids=${selection.join(",")}`);
  }

  const renderedUserForm = ({ history, match }) => {
    let selectedUser = { id: null, is_active: false, isNew: true };
    if (match.params.userId !== "new") {
      selectedUser = selectElementByID(
        users,
        "id",
        Number(match.params.userId)
      );
    }
    return (
      <UserForm history={history} user={selectedUser} onSave={updateUser} />
    );
  };

  return (
    <Flex gap="middle" vertical>
      <Switch>
        {users.length && (
          <Route path="/admin/users/:userId" component={renderedUserForm} />
        )}
      </Switch>

      <Flex justify="space-between">
        <BreadcrumbHeader breadcrumbs={[$t("Gebruikers")]} />
        <Flex align="center" gap="middle">
          <AddButton onClick={() => history.push("/admin/users/new")} />

          <ImportButton onClick={() => setModal(true)} />

          <ImportDialogUsers
            refreshAfterImport={refresh}
            isModal={isModal}
            closeModal={() => setModal(false)}
          />
        </Flex>
      </Flex>

      <Flex align="center" gap="middle">
        <Input.Search
          style={{ maxWidth: 250 }}
          value={search}
          allowClear
          size="small"
          placeholder={$t("Zoek op naam")}
          onChange={(event) => {
            setSearch(event.target.value);
            filterUsers(event.target.value);
          }}
        />

        <Select
          value={groupFilter}
          onChange={(group_key) => filterUsers(search, group_key)}
          style={{ width: 200 }}
          placeholder={$t("Filter op groep")}
          size="small"
        >
          <Select.Option key="geen" value="geen">
            {$t("geen")}
          </Select.Option>

          {Object.keys(group_name_lookup).map((group_key) => (
            <Select.Option key={group_key} value={group_key}>
              {group_name_lookup[group_key]}
            </Select.Option>
          ))}
        </Select>
      </Flex>

      <Flex align="center" gap="middle">
        <p>
          {$t("${n} geselecteerde gebruiker(s)", { n: selectedRowKeys.length })}
        </p>

        <Button
          size="small"
          loading={loadingActivating}
          onClick={() => activateUser(selectedRowKeys)}
        >
          {$t("Activate")}
        </Button>

        <Button size="small" onClick={() => onExportClick(selectedRowKeys)}>
          {$t("Export")}
        </Button>

        <GroupDropdown
          userIDs={selectedRowKeys}
          addOrDelete="add"
          onSave={updateUser}
        />
        <GroupDropdown
          userIDs={selectedRowKeys}
          addOrDelete="delete"
          onSave={updateUser}
        />
      </Flex>

      <Table
        rowSelection={rowSelection}
        loading={loading}
        style={{
          marginTop: 12,
          width: "95vw",
          overflowX: "auto",
          cursor: "pointer",
        }}
        pagination={{ ...defaultTableConfig, defaultPageSize: 100 }}
        size="small"
        rowKey="id"
        dataSource={filteredUsers}
        columns={columns}
        onRow={(vlieger) => {
          return {
            onClick: () => history.push(`/admin/users/${vlieger.id}`),
          };
        }}
      />
    </Flex>
  );
}
