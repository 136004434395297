import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMedia from "use-media";
import { $t } from "~/i18n.js";

import { Flex, Tabs } from "antd";
import { MainLayout } from "../../components/layout/layout.jsx";

import { RefreshBaseData } from "~/components/buttons/RefreshBaseData.jsx";
import { updateIfNeeded } from "~/lib/helpers.js";
import { getFlightStats } from "~/redux/flights/flights.actions.js";
import { getVliegerData } from "~/redux/persist/persist.actions.js";
import { DataCard } from "../../components/dataCards/dataCard.jsx";
import { FlightStats } from "../../components/flightStats/flightStats.jsx";
import { BreadcrumbHeader } from "../../components/layout/breadcrumbHeader.jsx";
import { VliegerStats } from "../../components/profile/vliegerStats.jsx";
import { CardInstructeursboek } from "./cards/card.instructeursboek.jsx";
import { CardTypeTotals } from "./cards/card.typeTotals.jsx";
import { useSyncData } from "~/compositions/state.js";

export const MyStartsPagina = ({ history }) => {
  const dispatch = useDispatch();
  const persist = useSelector((state) => state.persist);
  const flights = useSelector((state) => state.flights);
  const bevoegdheden = useSelector((state) => state.flights.bevoegdheden);

  const isWide = useMedia({ minWidth: "1350px" }, true);
  const vlieger_data = persist.vlieger_data
    ? persist.vlieger_data
    : { gegevens: {} };

  const vliegendLid = persist?.profile?.vliegt;

  const { updateBaseIfRequired } = useSyncData();

  useEffect(() => {
    updateBaseIfRequired();
    updateIfNeeded(flights.lastUpdatedFlightData, async () => {
      dispatch(getFlightStats({}));
    });
    updateIfNeeded(persist.lastUpdatedData, async () => {
      dispatch(getVliegerData());
    });
  }, [dispatch]);
  
  return (
    <MainLayout
      history={history}
      isLoading={persist.isPending || !persist.lastUpdatedData}
    >
      <div className="row" style={{ justifyContent: "space-between" }}>
        <BreadcrumbHeader breadcrumbs={[$t("Mijn starts")]} />
        <RefreshBaseData
          extra={() => {
            dispatch(getFlightStats({}));
          }}
        />
      </div>

      {isWide ? (
        <Flex gap={24}>
          {vliegendLid && (
            <DataCard
              title={$t("Overzicht")}
              type="card"
              width={350}
              onSave={null}
            >
              <VliegerStats
                pilot={vlieger_data}
                gegevens={vlieger_data.gegevens ? vlieger_data.gegevens : {}}
                showPhone={false}
                isInstructorOrPilot
              />
            </DataCard>
          )}

          {vliegendLid && (
            <DataCard
              title={$t("Recency")}
              type="card"
              width={350}
              onSave={null}
            >
              <CardTypeTotals
                recency={vlieger_data.recency}
                profile={vlieger_data}
              />
            </DataCard>
          )}

          <DataCard
            title={$t("Instructeurs boek")}
            type="card"
            width={350}
            onSave={null}
          >
            <CardInstructeursboek
              notities={vlieger_data.notities}
              pilot={persist.profile}
              behaalde_bevoegdheden={vlieger_data.behaalde_bevoegdheden}
              dto_training={vlieger_data.dto_training}
              bevoegdheden={bevoegdheden}
            />
          </DataCard>
        </Flex>
      ) : (
        <div>
          <Tabs
            style={{ background: "white", paddingLeft: 6 }}
            defaultActiveKey="overzicht"
            items={[
              vliegendLid && {
                label: $t("Overzicht"),
                key: "overzicht",
                children: (
                  <VliegerStats
                    pilot={vlieger_data}
                    gegevens={
                      vlieger_data.gegevens ? vlieger_data.gegevens : {}
                    }
                    isInstructorOrPilot
                  />
                ),
              },
              vliegendLid && {
                label: $t("Recency"),
                key: "recency",
                children: (
                  <CardTypeTotals
                    recency={vlieger_data.recency}
                    profile={vlieger_data}
                  />
                ),
              },
              {
                label: $t("Instructeurs boek"),
                key: "Inst",
                children: (
                  <CardInstructeursboek
                    notities={vlieger_data.notities}
                    pilot={persist.profile}
                    behaalde_bevoegdheden={vlieger_data.behaalde_bevoegdheden}
                    dto_training={vlieger_data.dto_training}
                    bevoegdheden={bevoegdheden}
                  />
                ),
              },
            ]}
          />
        </div>
      )}

      <div style={{marginTop: 24}}>
        <p className="bold">{$t("Startoverzicht")}</p>

        <FlightStats />
      </div>
    </MainLayout>
  );
};
