import { Button, Divider, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { exportMaintenance } from "./ExportMaintenance.js";
import { SelectProgramList } from "./selectProgramList.jsx";
import { Alert } from "antd";

export const GoogleDriveMaintenanceList = () => (
  <a
  href="https://drive.google.com/drive/folders/1USN1ccGP3zHivdEgPG_vSiB8YVFviXov"
  target="_blank"
  rel="noopener noreferrer"
>
  {$t("Link")}
</a>
)

export const ExportMaintenanceModal = ({
  visible,
  currentMaterial,
  maintenancePrograms,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  

  const { profile, club } = useSelector((state) => state.persist);

  const handleExport = async () => {
    setLoading(true);

    const data = await api.post("materiaal/maintenance_export_tasks.json", {
      ids: selectedPrograms,
    });

    const programs = maintenancePrograms
      .filter((program) => selectedPrograms.includes(program.id))
      .map((p) => ({
        ...p,
        tasks: data[p.id],
      }));
    setLoading(false);
    exportMaintenance(programs, currentMaterial, profile, club);
    closeModal();
  };

  return (
    <Modal
      title={$t("Export Maintenance Programs")}
      open={visible}
      onCancel={closeModal}
      footer={[
        <SluitButton key="sluit" onClick={closeModal} />,
        <Button
          key="export"
          loading={loading}
          type="primary"
          onClick={handleExport}
          disabled={selectedPrograms.length === 0}
        >
          {$t("Export")}
        </Button>,
      ]}
    >
      <Alert
        style={{ margin: "6px 0" }}
        type="info"
        showIcon
        message={
          <p>
            {$t(
              "Het export bestand (.txt formaat) kan gedeeld worden met andere clubs via de gedeelde drive, via deze link"
            )}
            : <GoogleDriveMaintenanceList />
          </p>
        }
      />

      <p className="subtitle">{$t("Selecteer programma's")}</p>

      <SelectProgramList
        selectedPrograms={selectedPrograms}
        setSelectedPrograms={setSelectedPrograms}
        maintenancePrograms={maintenancePrograms}
      />
    </Modal>
  );
};
