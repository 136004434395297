import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";

import { PlusOutlined } from "@ant-design/icons";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { MainLayout } from "~/components/layout/layout.jsx";
import { defaultTableConfig } from "~/components/layout/table.jsx";
import { api } from "~/lib/api.js";
import { selectElementByID } from "~/lib/helpers.js";
import { NewsColumns } from "./newsColumns.jsx";
import { NewsForm } from "./NewsForm.jsx";

export const NewsPage = ({ history }) => {
  const flights = useSelector((state) => state.flights);

  const [activeNewsID, setActiveNewsID] = useState(null);

  const [loading, setIsLoading] = useState(false);
  const [news, setNews] = useState([]);

  const [seeArchive, setSeeArhive] = useState(false);

  const getNews = async (archive) => {
    const getArhive = archive;
    
    setIsLoading(true);
    const res = await api.get(`news_admin.json?archive=${getArhive ? 1 : 0}`);
    if (res) setNews(res);
    setIsLoading(false);
  };
  useEffect(() => {
    getNews();
  }, []);

  // Determine open activeNews
  let activeNews = null;
  if (activeNewsID === -1) {
    activeNews = {
      id: null,
      title: "",
      text: "",
      groups: [],
      valid_from: null,
      valid_to: null,
      is_active: true,
      status: 'draft',
    };
  } else if (activeNewsID !== null) {
    activeNews = selectElementByID(news, "id", activeNewsID);
  }

  const saveNews = async (newsData) => {
    const res = await api.post("news_admin.json", newsData);
    if (res) setNews(res);
    return res;
  };

  const deleteNews = async (newsData) => {
    const { response, data } = await api.delete("news_admin/delete.json", newsData);
    if (data) setNews(data);
    return data;
  };

  const triggerPublish = async (newsItem) => {
    const res = await api.post("news_admin.json", {
      id: newsItem.id,
      action: "publish",
    });
    if (res) setNews(res);
  };

  const triggerArchive = async (newsItem) => {
    const res = await api.post("news_admin.json", {
      id: newsItem.id,
      action: "archive",
    });
    if (res) setNews(res);
  };

  return (
    <>
      <NewsForm
        visible={activeNews !== null}
        closeModal={() => setActiveNewsID(null)}
        saveNews={saveNews}
        deleteNews={deleteNews}
        activeNews={activeNews}
        groups={flights.all_group_keys || []}
        group_name_lookup={flights.group_name_lookup}
      />

      <MainLayout history={history}>
        <BreadcrumbHeader
          breadcrumbs={["Nieuws"]}
          buttons={[
            <Button
              style={{ marginRight: 6 }}
              size="small"
              key="0"
              onClick={() => getNews(seeArchive)}
            >
              {$t("Sync")}
            </Button>,
            <Button
              size="small"
              key="1"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setActiveNewsID(-1)}
            >
              {$t("Toevoegen")}
            </Button>,
          ]}
        />

        <p style={{ margin: "24px 0" }}>
          {$t("Bekijk archief: ")}{" "}
          <SwitchYesNo
            value={seeArchive}
            onChange={async (v) => {
              await setSeeArhive(v);
              getNews(v);
            }}
          />
        </p>

        <Table
          style={{ marginTop: 12 }}
          pagination={{ ...defaultTableConfig, defaultPageSize: 30 }}
          size="small"
          rowKey="id"
          dataSource={news}
          loading={loading}
          columns={NewsColumns(triggerPublish, triggerArchive, setActiveNewsID)}
        />
      </MainLayout>
    </>
  );
};
