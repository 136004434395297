import React, { useState } from "react";
import {
  CheckOutlined,
  CloudDownloadOutlined,
  DoubleLeftOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Flex, Button } from "antd";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { ModalCompleteWorkorder } from "./modalCompleteWorkorder.jsx";
import { ModalSignWorkorder } from "./modalSignWorkorder.jsx";
import { useDispatch } from "react-redux";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";

export const WorkorderStatus = ({
  currentMaterial,
  currentWorkorder,
  currentProfile,
  canSetTasks,
  readOnly,
}) => {
  const dispatch = useDispatch();

  const [fileLoading, setFileLoading] = useState(false);
  const downloadFile = async () => {
    if (fileLoading) return;
    setFileLoading(true);
    await api.open_file(
      `materiaal/${currentMaterial.id}/workorder_print.json?workorder_id=${currentWorkorder.id}`,
      `Workorder ${currentWorkorder.name}.pdf`
    );
    setFileLoading(false);
  };

  const [reopenLoading, setReopenLoading] = useState(false);
  const reopenWorkorder = async () => {
    if (reopenLoading) return;
    setReopenLoading(true);
    const payload = {
      id: currentWorkorder.id,
      action: "reopen",
    };
    await dispatch(
      saveMaterialTab({
        activeTab: "workorders",
        materialID: currentMaterial.id,
        payload,
      })
    );
    setReopenLoading(false);
  };

  let maxRestriction =
    currentMaterial.category === "kist" ? "technicus_pom" : "technicus_rollend";
  const canCompleteOrder = canSetTasks && currentProfile[maxRestriction];

  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);

  return (
    <Flex gap={6}>
      <ModalCompleteWorkorder
        visible={showCompleteModal}
        workorder={currentWorkorder}
        currentMaterial={currentMaterial}
        closeModal={() => setShowCompleteModal(false)}
      />

      <ModalSignWorkorder
        visible={showSignModal}
        workorder={currentWorkorder}
        currentMaterial={currentMaterial}
        closeModal={() => setShowSignModal(false)}
      />

      {!readOnly && currentWorkorder.state == "active" && (
        <Button
          icon={<CheckOutlined />}
          size="small"
          onClick={() => setShowCompleteModal(true)}
          disabled={!canCompleteOrder}
        >
          {$t("Afronden")}
        </Button>
      )}

      {!readOnly && currentWorkorder.state == "completed" && (
        <Button
          icon={<DoubleLeftOutlined />}
          size="small"
          loading={reopenLoading}
          onClick={reopenWorkorder}
          disabled={readOnly}
        >
          {$t("Her-open")}
        </Button>
      )}

      {!readOnly && (
        <Button
          icon={<FileDoneOutlined />}
          size="small"
          onClick={() => setShowSignModal(true)}
          disabled={currentWorkorder.state != "completed"}
        >
          {$t("Onderteken ${type}", {
            type: currentMaterial.category === "kist" ? "CRS" : "Workorder",
          })}
        </Button>
      )}

      <Button
        icon={<CloudDownloadOutlined />}
        size="small"
        loading={fileLoading}
        onClick={downloadFile}
        disabled={readOnly}
      >
        {$t("Print concept ${type}", {
          type: currentMaterial.category === "kist" ? "CRS" : "Workorder",
        })}
      </Button>
    </Flex>
  );
};
