import { List, Tag } from "antd";
import React from "react";
import { DateFormat } from "~/components/date/DateFormat.jsx";
import { $t } from "~/i18n.js";

export const CardOverland = ({ overlandVliegers }) => {
  console.log(overlandVliegers);
  return (
    <List
      size="small"
      dataSource={overlandVliegers}
      renderItem={(vlieger) => (
        <List.Item>
          <List.Item.Meta
            title={vlieger.vlieger.name}
            description={
              <div
                className="column"
                style={{ gap: 6, alignItems: "flex-start" }}
              >
                <p className="small">
                  <span>
                    {$t("Laatste")} {$t("Ovl")}
                  </span>
                  :{" "}
                  <span className="darkgray">
                    {vlieger.last_xc ? (
                      <DateFormat
                        date={vlieger.last_xc}
                        options={{
                          day: "2-digit",
                          month: "short",
                          year: "2-digit",
                        }}
                      />
                    ) : (
                      $t("geen")
                    )}
                  </span>
                </p>
                <div className="row" style={{ gap: 6 }}>
                  {vlieger.kist &&
                    vlieger.kist.map((kist, key) => (
                      <Tag key={key} color={key === 0 ? "green" : "default"}>
                        {kist}
                      </Tag>
                    ))}

                  <p>{vlieger.opmerking}</p>
                </div>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};
