import { ClockCircleFilled } from "@ant-design/icons";
import React from "react";
import { $t } from "~/i18n.js";
import { colors } from "~/theme/colors.js";

export const BevoegdheidExpirationWarning = ({ bevoegdheid }) => {
  if (!bevoegdheid.behaald.date_assigned || !bevoegdheid.behaald.nearly_invalid)
    return null;
  return (
    <span className="gray" style={{ marginLeft: 12 }}>
      <ClockCircleFilled style={{ color: colors.orange, marginRight: 2 }} />

      {bevoegdheid.behaald.days_left <= 0 ? (
        <span>{$t("verlopen")}</span>
      ) : (
        <span>
          {bevoegdheid.behaald.days_left} {$t("dagen")}
        </span>
      )}
    </span>
  );
};
