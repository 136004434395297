import React from "react";
import './Ggrid.css'

export const GGrid = ({ children, ...props }) => {
  return (
    <div className="GGrid" {...props}>
      {children}
    </div>
  );
};
