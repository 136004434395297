import { Button, Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { InputFileSingle } from "~/components/form/InputFileSingle.jsx";
import { $t } from "~/i18n.js";
import { GoogleDriveMaintenanceList } from "./ExportMaintenanceModal.jsx";
import { Alert } from "antd";
import { splitText } from "~/pages/admin/dto/components/ExportCluster.js";
import { SelectProgramList } from "./selectProgramList.jsx";
import { useDispatch } from "react-redux";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";
import { generate_uuid } from "~/lib/helpers.js";

export const ImportMaintenanceModule = ({
  visible,
  closeModal,
  currentMaterial,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [maintenancePrograms, setMaintenancePrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const reset = () => {
    form.resetFields();
    setLoading(false);
    setError(false);
    setMaintenancePrograms([]);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const value = e.target.result.toString();
        const parts = value.split(splitText);
        if (parts.length < 1) {
          setError(true);
          return;
        }
        const programs = JSON.parse(parts[1]);
        setMaintenancePrograms(
          programs.map((p) => ({ ...p, id: "new_" + generate_uuid() }))
        );
      };
      reader.readAsText(file);
    }
  };

  const startImport = async () => {
    // Trigger Import function to be filled out later
    setLoading(true);
    const setTasks = (tasks) => {
      return tasks.map((t) => ({
        ...t,
        action: "new",
        tasks: setTasks(t.tasks),
      }));
    };

    const payload = {
      objects: maintenancePrograms
        .filter((p) => selectedPrograms.includes(p.id))
        .map((p) => ({ ...p, action: "import", tasks: setTasks(p.tasks) })),
    };
    const res = await dispatch(
      saveMaterialTab({
        activeTab: "maintenance",
        materialID: currentMaterial.id,
        payload,
      })
    );
    setLoading(false);
    if (res) {
      Modal.success({
        title: $t("Importeren gelukt"),
        content: $t(
          "Let op: Om de programmas compleet te maken die je het onderdeel en document te koppelen, alsmede de periode te selecteren."
        ),
        okText: $t("Sluit"),
        afterClose: closeModal,
      });
    }
  };

  return (
    <Modal
      title={$t("Importeer Programmas")}
      open={visible}
      width={600}
      onCancel={!loading && closeModal}
      footer={[
        <Button
          key="opslaan"
          disabled={
            maintenancePrograms.length === 0 || selectedPrograms.length === 0
          }
          onClick={startImport}
          type="primary"
          loading={loading}
        >
          {$t("Importeer")}
        </Button>,
        <SluitButton key="sluit" disabled={loading} onClick={closeModal} />,
      ]}
    >
      <Alert
        style={{ margin: "6px 0" }}
        type="info"
        showIcon
        message={
          <p>
            {$t(
              "Alleen bestanden die uit de export van de ZweefApp komen kunnen worden geïmporteerd."
            )}{" "}
            {$t("Zie link voor de gedeelde folder")}:{" "}
            <GoogleDriveMaintenanceList />
          </p>
        }
      />

      <p className="bold" style={{ marginBottom: 6, marginTop: 24 }}>
        1. {$t("Selecteer bestand")}:
      </p>

      <Form>
        <Form.Item
          name="file"
          rules={[{ required: false }]}
          onChange={handleFileChange}
        >
          <InputFileSingle accept=".txt" />
        </Form.Item>
      </Form>

      <p className="bold" style={{ marginBottom: 6, marginTop: 24 }}>
        2. {$t("Selecteer welke programma's wil je importeren")}
      </p>

      {error && (
        <Alert
          type="warning"
          message={$t(
            "Bestand is niet correct, zorg voor een goed import bestand."
          )}
        />
      )}

      {maintenancePrograms?.length > 0 && (
        <SelectProgramList
          selectedPrograms={selectedPrograms}
          setSelectedPrograms={setSelectedPrograms}
          maintenancePrograms={maintenancePrograms}
        />
      )}
    </Modal>
  );
};
