import { Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { $t } from "~/i18n.js";
import { RecencyValidThrough } from "./components/RecencyValidThrough.jsx";

export const MedicalTable = ({ gegevens }) => {
  if (!gegevens) return null;

  const date = gegevens?.medical_valid_to;
  const is_valid = date && new Date(gegevens.medical_valid_to) > new Date();

  const alert_date =
    date && dayjs(date, "YYYY-MM-DD").diff(dayjs(), "days") < 120;

  return (
    <tr style={{ width: "100%" }}>
      <td className="col bold">{$t("Medical")}</td>

      <td className="col" />

      {is_valid ? (
        <RecencyValidThrough
          is_invalid={false}
          valid_through={date}
          alert_date={alert_date}
        />
      ) : (
        <td className="col">
          <Tag color="red">{$t("Ongeldig")}</Tag>
        </td>
      )}
    </tr>
  );
};
