import { Card, Tabs } from "antd";
import React, { useState } from "react";
import useMedia from "use-media";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";
import { GFlex } from "~/components/GFlex.jsx";
import { $t } from "~/i18n.js";
import { AanmeldList } from "./AanmeldList.jsx";
import { CardKisten } from "./cardKisten.jsx";
import { CardRollen } from "./cardRollen.jsx";
import { CardRooster } from "./cardRooster.jsx";
import { MessageCard } from "./messageCard.jsx";
import { RecencyWarnings } from "./RecencyWarnings.jsx";
import { CardOverland } from "./cardOverland.jsx";

export const AanmeldLijstContent = ({
  days,
  aanmeldingen,
  club,
  flights,
  profile,
  currentAanmelding,
  setOpenPilot,
  editAanmelding,
  afmelden,
}) => {
  const [viewAsInstructor, setViewAsInstructor] = useState(
    profile.is_instructeur
  );
  const isVliegend = days.activeDay.is_vliegend;

  const isWide = useMedia({ minWidth: "1350px" }, true);

  const overlandVliegers = aanmeldingen.aanmeldingen
    .filter((a) => a.aangemeld && a.overland && !a.kist.includes("privé"))
    .map((a) => ({
      ...a,
      last_xc: a.vlieger?.recency?.last_xc
        ? new Date(a.vlieger?.recency?.last_xc)
        : null,
    }))
    .sort((a, b) => {
      if (a.last_xc === null && b.last_xc === null) return 0;
      if (a.last_xc === null) return -1;
      if (b.last_xc === null) return 1;
      return a.last_xc - b.last_xc;
    });

  return (
    <>
      <GFlex style={{ marginTop: 12 }}>
        {isWide && (
          <GFlex c="column" style={{ maxWidth: 300 }}>
            {isVliegend && (
              <Card size="small" title={$t("Rollen")}>
                <CardRollen
                  aanmeldingen={aanmeldingen}
                  groups_aanmeld={club.groups_aanmeld}
                  group_name_lookup={flights.group_name_lookup}
                />
              </Card>
            )}

            {aanmeldingen.diensten && aanmeldingen.diensten.length > 0 && (
              <Card size="small" title={$t("Diensten")}>
                <CardRooster
                  aanmeldingen={aanmeldingen.aanmeldingen}
                  group_name_lookup={flights.group_name_lookup}
                  diensten={aanmeldingen.diensten}
                  user={profile}
                  openAanmelding={() =>
                    !currentAanmelding && editAanmelding({})
                  }
                />
              </Card>
            )}

            {isVliegend && (
              <Card size="small" title={$t("Kisten")}>
                <CardKisten aanmeldingen={aanmeldingen} day={days.activeDay} />
              </Card>
            )}

            {isVliegend && overlandVliegers.length > 0 && (
              <Card size="small" title={$t("Overland")}>
                <CardOverland overlandVliegers={overlandVliegers} />
              </Card>
            )}
          </GFlex>
        )}

        <div>
          <div>
            {!isWide && (
              <Tabs
                style={{
                  background: "white",
                  paddingLeft: 6,
                  paddingBottom: 6,
                  marginBottom: 12,
                  maxWidth: 750,
                }}
                defaultActiveKey="message"
                items={[
                  aanmeldingen.diensten &&
                    aanmeldingen.diensten.length > 0 && {
                      label: $t("Diensten"),
                      key: "diensten",
                      children: (
                        <CardRooster
                          aanmeldingen={aanmeldingen.aanmeldingen}
                          group_name_lookup={flights.group_name_lookup}
                          diensten={aanmeldingen.diensten}
                          user={profile}
                          openAanmelding={() =>
                            !currentAanmelding && editAanmelding({})
                          }
                        />
                      ),
                    },
                  {
                    label: $t("Rollen"),
                    key: "rollen",
                    children: (
                      <CardRollen
                        aanmeldingen={aanmeldingen}
                        groups_aanmeld={club.groups_aanmeld}
                        group_name_lookup={flights.group_name_lookup}
                      />
                    ),
                  },
                  isVliegend && {
                    label: $t("Kisten"),
                    key: "kisten",
                    children: (
                      <CardKisten
                        aanmeldingen={aanmeldingen}
                        day={days.activeDay}
                      />
                    ),
                  },

                  isVliegend &&
                    overlandVliegers.length > 0 && {
                      label: $t("Overland"),
                      key: "xc",
                      children: (
                        <CardOverland overlandVliegers={overlandVliegers} />
                      ),
                    },
                ]}
              />
            )}

            <div
              style={{
                maxWidth: 750,
                maxHeight: 300,
                marginBottom: 16,
                overflowY: "auto",
              }}
            >
              <MessageCard
                messages={aanmeldingen.messages}
                canEditMessage
                currentUserID={profile.id}
              />
            </div>

            {isVliegend && profile.is_instructeur && (
              <div className="row" style={{ marginBottom: 16, gap: 12 }}>
                <p>{$t("Bekijk instructie informatie:")}</p>
                <SwitchYesNo
                  value={viewAsInstructor}
                  onChange={setViewAsInstructor}
                />
              </div>
            )}

            {isVliegend && profile.is_instructeur && (
              <RecencyWarnings
                setOpenPilot={setOpenPilot}
                aanmeldingen={aanmeldingen.aanmeldingen}
              />
            )}

            <AanmeldList
              aanmeldingen={aanmeldingen.aanmeldingen}
              profile={profile}
              afmelden={afmelden}
              notities={aanmeldingen.notities}
              bevoegdheden={aanmeldingen.bevoegdheden}
              viewInstructorInfo={viewAsInstructor}
              setOpenPilot={setOpenPilot}
              isVliegend={isVliegend}
              groups_aanmeld={club.groups_aanmeld}
              group_name_lookup={flights.group_name_lookup}
            />
          </div>
        </div>
      </GFlex>
    </>
  );
};
