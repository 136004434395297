import { Input, Modal, Timeline } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { $t } from '~/i18n.js'
import { getMaterialTab, saveMaterialTab } from '~/redux/materiaal/materiaal.actions.js'
import { SetRelatedMaintenance } from './setRelatedMaintenance.jsx'
import { SetRelatedMelding } from './setRelatedMelding.jsx'

export const AddWorkorderModal = ({ visible, currentMaterial, closeModal }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!visible) return
    if (!currentMaterial.maintenance) dispatch(getMaterialTab({ materialID: currentMaterial.id, activeTab: 'maintenance' }))
    if (!currentMaterial.meldingen) dispatch(getMaterialTab({ materialID: currentMaterial.id, activeTab: 'meldingen' }))
  }, [visible])

  const [name, setName] = useState('')
  const [maintenance_programs, setMaintenancePrograms] = useState([])
  const [meldingen, setMeldingen] = useState([])

  const [saving, setSaving] = useState(false)
  const addWorkorder = async () => {
    setSaving(true)
    // partID is cascade, as such is an array of the nest, take last
    const payload = { name, meldingen, maintenance_programs }
    const res = await dispatch(saveMaterialTab({ activeTab: 'workorders', materialID: currentMaterial.id, payload }))
    if (res) closeModal()
    setSaving(false)
  }
  return (
    <Modal
      title={$t('Voeg workorder toe')}
      confirmLoading={saving}
      open={visible}
      onCancel={closeModal}
      okButtonProps={{ disabled: !name }}
      onOk={addWorkorder}
    >

      <Timeline>

        <Timeline.Item color={name ? 'green' : 'blue'}>
          <p className='spacer'>{$t('Naam van workorder')}:</p>
          <Input
            style={{ width: 300 }} value={name}
            onChange={(event) => setName(event.target.value.substring(0, 149))}
          />
        </Timeline.Item>

        <Timeline.Item color={!name ? 'gray' : ((maintenance_programs.length || meldingen.length) ? 'green' : 'blue')}>
          <p>{$t('Verbonden onderdelen')}: </p>
          <SetRelatedMaintenance
            visible={visible} currentMaterial={currentMaterial}
            maintenance_programs={maintenance_programs} setMaintenancePrograms={setMaintenancePrograms}
            list_maintenance={currentMaterial.maintenance?.maintenance?.filter(m => !m.workorder_id && m.category !== 'arc')}
          />
          <SetRelatedMelding
            visible={visible} currentMaterial={currentMaterial}
            meldingen={meldingen} setMeldingen={setMeldingen}
            list_meldingen={currentMaterial.meldingen?.meldingen}
          />
        </Timeline.Item>

      </Timeline>

    </Modal>
  )
}
