import React, { useEffect, useRef } from "react"
import { Crepe } from "@milkdown/crepe"
import { editorViewCtx, serializerCtx } from "@milkdown/kit/core";
import "@milkdown/crepe/theme/common/style.css"
import "./style.css"

export const MilkdownEditor = ({ content = '', onChange = (e) => {}, editable = false }) => {
  const editorRef = useRef(null)

  useEffect(() => {
    if (editorRef.current) {
      const crepe = new Crepe({
        root: editorRef.current,
        defaultValue: content,
        features: {
          [Crepe.Feature.CodeMirror]: false,
          // [Crepe.Feature.ImageBlock]: false,
          [Crepe.Feature.Latex]: false,
        },
        featureConfigs: {
          // https://milkdown.dev/docs/guide/using-crepe
        },
        editable: false
      })

      crepe.create().then((editor) => {
        editor.onStatusChange((status) => {
          console.log(status);
        });
      }).catch((error) => {
        console.error("Failed to create editor:", error);
      });

      crepe.on((listener) => {
        listener.markdownUpdated(e => {
          // console.log(e)
          const markdown = crepe.getMarkdown();
          onChange(markdown);
        });
      })
      
      return () => {
        crepe.destroy()
      }
    }
  }, [content])

  const getMarkdown = (editor) =>
    editor.action((ctx) => {
      const editorView = ctx.get(editorViewCtx);
      const serializer = ctx.get(serializerCtx);
      return serializer(editorView.state.doc);
    });

  return <div ref={editorRef} />
}
