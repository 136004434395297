import { Form, Input, Modal, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { $t } from '~/i18n.js'

export const AanmeldForm = ({ visible, kist_aanmelding, activeAanmelding, closeModal, saveAanmelding, activeDay, profile, types, vliegers }) => {
  const [loading, setLoading] = useState(false)
  const [heleDag, setHeleDag] = useState(true)

  const [form] = Form.useForm()

  useEffect(() => {
    setHeleDag(!(activeAanmelding && activeAanmelding.id) 
    || ((!activeAanmelding.van || activeAanmelding.van === "" )
      && (!activeAanmelding.tot || activeAanmelding.tot === '')))
    form.resetFields()
  }, [setHeleDag, activeAanmelding, form, kist_aanmelding, activeDay.briefing_tijd])

  const saveForm = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)

      const result = await saveAanmelding({
        action: activeAanmelding && activeAanmelding.id ? 'wijzig' : 'meld_aan',
        aanmelding_id: activeAanmelding.id || null,
        dag_id: activeDay.dag_id,
        datum: activeDay.datum,
        ...values,
        kist: values.kist ? values.kist.slice(0, 3) : [],
        van: !heleDag ? values.van : '',
        tot: !heleDag ? values.tot : ''
      })

      if (result) {
        closeModal()
        form.resetFields()
      }
      setLoading(false)
    } catch (info) {
      setLoading(false)
      console.log('Validate Failed:', info)
    }
  }

  return (
    <Modal
      title={
        activeAanmelding && activeAanmelding.id
          ? activeAanmelding.aangemeld
            ? $t("Wijzig aanmelding")
            : $t("Meld opnieuw aan")
          : $t("Meld je aan")
      }
      open={visible}
      confirmLoading={loading}
      onCancel={closeModal}
      onOk={saveForm}
      okText={$t("Opslaan")}
      cancelText={$t("Annuleren")}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="addDay"
        preserve={false}
        initialValues={{
          checkstart: false,
          overland: false,
          van: activeAanmelding
            ? activeAanmelding.van
            : activeDay.briefing_tijd,
          tot: activeAanmelding ? activeAanmelding.tot : "",
          kist:
            activeAanmelding && activeAanmelding.kist
              ? activeAanmelding.kist
              : kist_aanmelding || [],
          ...(activeAanmelding || {}),
        }}
        requiredMark={false}
      >
        {profile.iedereen_aanmelden && vliegers && (
          <Form.Item label={$t("Lid")} name="lid">
            <Select
              showSearch
              filterOption={(input, option) =>
                option &&
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={$t("Selecteer lid om aan te melden")}
            >
              {vliegers.map(
                (vlieger, key) =>
                  vlieger.id > 0 && (
                    <Select.Option value={vlieger.id} key={key}>
                      {vlieger.name}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        )}

        {activeDay.is_vliegend && profile.vliegt && (
          <Form.Item label={$t("Kist")} name="kist">
            <Select
              mode="multiple"
              placeholder={$t("Welke kisten wil je op vliegen? (max 3)")}
            >
              {types.map((type, key) => (
                <Select.Option value={type} key={key}>
                  {type}
                </Select.Option>
              ))}
              <Select.Option value="privé">{$t("privé")}</Select.Option>
            </Select>
          </Form.Item>
        )}

        {activeDay.is_vliegend && profile.is_instructeur && (
          <Form.Item
            label={$t("Instructie geven")}
            name="as_instructeur"
            valuePropName="checked"
          >
            <Switch checkedChildren={$t("Ja")} unCheckedChildren={$t("Nee")} />
          </Form.Item>
        )}

        {activeDay.is_vliegend && profile.wijzig_sleep && (
          <Form.Item
            label={$t("Sleepvliegen")}
            name="as_sleepvlieger"
            valuePropName="checked"
          >
            <Switch checkedChildren={$t("Ja")} unCheckedChildren={$t("Nee")} />
          </Form.Item>
        )}

        {activeDay.is_vliegend && profile.group_keys?.indexOf("brevet") > 0 && (
          <Form.Item
            label={$t("EASA trainingsvlucht")}
            name="checkstart"
            valuePropName="checked"
          >
            <Switch checkedChildren={$t("Ja")} unCheckedChildren={$t("Nee")} />
          </Form.Item>
        )}

        {activeDay.is_vliegend && profile.vliegt && (
          <Form.Item
            label={$t("Overland")}
            name="overland"
            valuePropName="checked"
          >
            <Switch checkedChildren={$t("Ja")} unCheckedChildren={$t("Nee")} />
          </Form.Item>
        )}

        <Form.Item label={$t("Hele dag")}>
          <Switch
            onChange={(checked) => setHeleDag(checked)}
            checked={heleDag}
            checkedChildren={$t("Ja")}
            unCheckedChildren={$t("Nee")}
          />
        </Form.Item>

        {!heleDag && (
          <Form.Item label={$t("Vanaf")} name="van">
            <Input type="time" style={{ width: 120 }} placeholder="hh:mm" />
          </Form.Item>
        )}

        {!heleDag && (
          <Form.Item label={$t("Tot")} name="tot">
            <Input type="time" style={{ width: 120 }} placeholder="hh:mm" />
          </Form.Item>
        )}

        <Form.Item
          label={$t("Opmerking")}
          name="opmerking"
          rules={[
            {
              required: false,
              max: 500,
              message: $t("Maximaal ${c} characters", { c: 500 }),
            },
          ]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
