import { CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const PilotLink = ({ name, id, object }) => {
  if (!name) return "-";
  const isLinked = object?.id;
  return isLinked ? (
    <a
      onClick={(e) => e.stopPropagation()}
      target="_blank"
      href={`/members/${object.id}`}
    >
      {name} ({id})
    </a>
  ) : (
    <Tooltip placement="top" title={$t("Piloot niet gekoppeld")}>
      <CloseCircleOutlined color="grey" /> {name} ({id || "-"})
    </Tooltip>
  );
};
