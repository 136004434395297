import {
  CheckSquareOutlined,
  DatabaseOutlined,
  IssuesCloseOutlined,
  SettingOutlined,
  TeamOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { $t } from "~/i18n.js";
import { Icon, StartIcon, clipboard } from "../../icon/icon.jsx";
import "./LargeMenu.css";

/**
 * @deprecated Use a smarter solution to get the current menu
 * @param {*} currentPage
 * @returns
 */
function getCurrentMenuFromUrl(currentPage = "") {
  // check open menu and page
  let currentMenu = "";

  if (
    ["start", "total", "dailyreport", "tow", "rosters"].includes(currentPage)
  ) {
    currentMenu = "vluchten";
  }
  if (
    ["material", "status", "report", "maintenancehours"].includes(currentPage)
  ) {
    currentMenu = "mat";
  }
  if (["my_starts", "my_details"].includes(currentPage)) {
    currentMenu = "profiel";
  }
  if (["members", "dto", "email"].includes(currentPage)) currentMenu = "lid";
  if (
    ["flights", "users", "groups", "dto_settings", "settings"].includes(
      currentPage
    )
  )
    currentMenu = "admin";

  return currentMenu;
}

export const LargeMenu = ({
  showCheckDataButton = false,
  triggerShowCheckModal = () => {},
  club = {},
  profile = {},
}) => {
  let location = useLocation();
  let currentPage =
    location.pathname.replace("/admin", "").split("/")[1] || "registration";
  const currentMenu = getCurrentMenuFromUrl(currentPage);

  const showMenuVliegend = profile.vliegt || profile.group_keys?.includes("niet_vliegend")

  return (
    <Menu
      key="Sidebar"
      className="sideMenuBar"
      theme="dark"
      mode="inline"
      style={{
        backgroundColor: "var(--primary)",
      }}
      selectedKeys={[currentPage]}
      defaultOpenKeys={[currentMenu]}
    >
      {showCheckDataButton && (
        <Menu.Item key="hide_werkbij">
          <Button
            className="whiteButton"
            onClick={triggerShowCheckModal}
            style={{ width: 150 }}
          >
            {$t("Werk gegevens bij")}
          </Button>
        </Menu.Item>
      )}

      {(showMenuVliegend || profile.iedereen_aanmelden) && (
        <Menu.Item key="register" icon={<Icon icon={clipboard} />}>
          <Link to="/register">{$t("Aanmelden")}</Link>
        </Menu.Item>
      )}

      {showMenuVliegend && (
        <Menu.Item key="reservations" icon={<CalendarOutlined />}>
          <Link to="/reservations">{$t("Reserveren")}</Link>
        </Menu.Item>
      )}

      {(profile.vliegt || profile.wijzig_start) && (
        <Menu.SubMenu
          key="vluchten"
          icon={<StartIcon />}
          title={$t("Vluchten")}
        >
          <Menu.Item key="start">
            <Link to="/start">{$t("Startadmin")}</Link>
          </Menu.Item>
          {profile.wijzig_sleep && (
            <Menu.Item key="tow">
              <Link to="/tow">{$t("Sleepadmin")}</Link>
            </Menu.Item>
          )}
          {profile.ziet_dto && (
            <Menu.Item key="dailyreport">
              <Link to="/dailyreport">{$t("Dagverslagen")}</Link>
            </Menu.Item>
          )}
          <Menu.Item key="rosters">
            <Link to="/rosters">{$t("Roosters")}</Link>
          </Menu.Item>
        </Menu.SubMenu>
      )}

      {(showMenuVliegend || profile.iedereen_aanmelden) && (
        <Menu.SubMenu
          key="mat"
          icon={<IssuesCloseOutlined />}
          title={$t("Materieel")}
        >
          <Menu.Item key="material">
            <Link to="/material">{$t("Overzicht")} </Link>
          </Menu.Item>
          <Menu.Item key="report">
            <Link to="/report">{$t("Meldingen")}</Link>
          </Menu.Item>
          {(profile.wijzig_winteruren || profile.goedkeuren_uren) && (
            <Menu.Item key="maintenancehours">
              <Link to="/maintenancehours">{$t("Onderhoudsuren")}</Link>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      )}

      {profile.ziet_dto || profile.ziet_emaillijst || profile.wijzig_news ? (
        <Menu.SubMenu key="lid" icon={<TeamOutlined />} title={$t("Leden")}>
          <Menu.Item key="members">
            <Link to="/members">{$t("Ledenlijst")}</Link>
          </Menu.Item>
          {profile.ziet_dto && (
            <Menu.Item key="dto">
              <Link to="/dto">{$t("DTO lijst")}</Link>
            </Menu.Item>
          )}
          {profile.ziet_emaillijst && (
            <Menu.Item key="email">
              <Link to="/email">{$t("Email")}</Link>
            </Menu.Item>
          )}
          {profile.wijzig_news && (
            <Menu.Item key="news">
              <Link to="/news">{$t("Nieuws")}</Link>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      ) : (
        <>
          <Menu.Item key="members" icon={<TeamOutlined />}>
            <Link to="/members">{$t("Ledenlijst")}</Link>
          </Menu.Item>
        </>
      )}

      <Menu.Item key="documents" icon={<DatabaseOutlined />}>
        <Link to="/documents">{$t("Documenten")}</Link>
      </Menu.Item>

      {profile.wijzig_findings && (
        <Menu.Item key="findings" icon={<CheckSquareOutlined />}>
          <Link to="/findings">{$t("Bevindingen")}</Link>
        </Menu.Item>
      )}

      {profile.ziet_staff && (
        <Menu.SubMenu
          key="admin"
          icon={<SettingOutlined />}
          title={$t("Admin")}
        >
          <Menu.Item key="flights">
            <Link to="/admin/flights">{$t("Vluchten")}</Link>
          </Menu.Item>
          {profile.wijzig_leden && (
            <Menu.Item key="users">
              <Link to="/admin/users">{$t("Gebruikers")}</Link>
            </Menu.Item>
          )}
          {profile.wijzig_leden && (
            <Menu.Item key="groups">
              <Link to="/admin/groups">{$t("Groepen")}</Link>
            </Menu.Item>
          )}
          {profile.wijzig_dto && (
            <Menu.Item key="dto_settings">
              <Link to="/admin/dto_settings">{$t("Bevoegdheden")}</Link>
            </Menu.Item>
          )}
          <Menu.Item key="settings">
            <Link to="/admin/settings">{$t("Club Settings")}</Link>
          </Menu.Item>
        </Menu.SubMenu>
      )}

      {club && club.voorval_link && (
        <Menu.Item style={{ marginTop: 42 }} key="hide_melding">
          <Button
            className="whiteButton"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: 140 }}
            href={club.voorval_link}
          >
            {$t("Meld voorval")}
          </Button>
        </Menu.Item>
      )}

      <Menu.Item key="hide_zweefLogo" className="bottomBackgroundElement" />
    </Menu>
  );
};
