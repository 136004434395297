import {
  EditOutlined,
  EyeInvisibleOutlined
} from "@ant-design/icons";
import { Button, Card, Tag } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

import { MaintenanceStatusTag } from "~/pages/materiaal/materialTags.jsx";
import { DocumentList } from "../documentList.jsx";

export const MatChapterCard = ({
  chapter,
  allChapters,
  documents,
  setOpenChapter,
  readOnly,
  materialID,
}) => {
  return (
    <Card
      size="small"
      className="Chapter"
      title={
        <p className="row" style={{ gap: 6 }}>
          <MaintenanceStatusTag maintenance_status={chapter.status} hideTag />
          {!readOnly && <span className="gray">#{chapter.order}</span>}{" "}
          {chapter.name}
          {!chapter.is_public && <EyeInvisibleOutlined />}
          {chapter.is_archived && (
            <Tag color="red" style={{ fontWeight: 400, fontSize: 11 }}>
              {$t("archief")}{" "}
            </Tag>
          )}
        </p>
      }
      style={{ margin: 4 }}
      extra={
        !readOnly && (
          <Button
            icon={<EditOutlined />}
            size="small"
            shape="circle"
            onClick={() => setOpenChapter(chapter)}
          />
        )
      }
    >
      <DocumentList
        readOnly={readOnly}
        documents={documents}
        chapter={chapter}
        allChapters={allChapters}
        materialID={materialID}
      />
    </Card>
  );
};
