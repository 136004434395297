import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Empty, Switch, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { $t } from '~/i18n.js'

import { saveMaterialTab } from '~/redux/materiaal/materiaal.actions.js'
import { normalizePartsListForCascade } from '~/redux/materiaal/materiaal.normalizers.js'
import { AddPartModal } from './addPart.jsx'
import { PartCard } from './partCard.jsx'

export const MaterialParts = ({ currentMaterial, parts, readOnly, history, hasAccess }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  
  const setupParts = async () => {
    setLoading(true)
    const payload = { action: 'setup' }
    await dispatch(saveMaterialTab({ activeTab: 'parts', materialID: currentMaterial.id, payload }))
    setLoading(false)
  }

  const openMaintenance = (id) => history.push(`/${hasAccess ? 'material' : 'status/material'}/${currentMaterial.id}/maintenance/${id}/view`)

  const [openPart, setOpenPart] = useState(null)
  const [readOnlyMode, setReadOnlyMode] = useState(true)

  const [partsList, setPartsList] = useState(null)
  useEffect(() => setPartsList(normalizePartsListForCascade(parts)), [parts])

  if (!readOnly && !parts.length) {
    return (
      <div style={{ marginTop: 24 }}>
        <Button type='primary' loading={loading} onClick={setupParts}>{$t('Parts inladen')}</Button>
      </div>
    )
  }

  if (readOnly && !parts.length) return <div className='row' style={{ justifyContent: 'center', width: '100%', marginTop: 42 }}><Empty description={$t('Geen onderdelen ingeladen')} /></div>

  const saveParts = async (parts) => {
    return dispatch(saveMaterialTab({ activeTab: 'parts', materialID: currentMaterial.id, payload: parts }))
  }

  return (
    <div className='column' style={{ gap: 24, alignItems: 'flex-start' }}>

      {!readOnly && <div className='row' style={{ height: 30 }}>
        <p>{$t('Wijzig')}:
          <Switch size='small' style={{ marginLeft: 4 }} onChange={(checked) => setReadOnlyMode(!checked)} checked={!readOnlyMode} />
          {!readOnlyMode && <Button
            onClick={() => setOpenPart({ parent_part_id: null, is_installed: true })}
            style={{ marginLeft: 24 }} size='small' icon={<PlusOutlined />}
                            >{$t('Voeg toe')}
                            </Button>}
        </p>
                    </div>}

      {openPart && <AddPartModal part={openPart} partsList={partsList} currentMaterial={currentMaterial} closeModal={() => setOpenPart(null)} />}

      {parts.map((part, key) => {
        // path is on this level the highest level
        return (
          <Card
            key={key} type='inner' title={<span>{part.name} {!part.is_installed && <Tag style={{ marginLeft: 18 }} color='red'>Niet geinstalleerd</Tag>} </span>} size='small'
            style={{ width: '100%' }}
            extra={[
              !readOnlyMode && <Button key='alter' style={{ marginLeft: 12 }} onClick={() => setOpenPart({ ...part, parts_path: [] })} size='small' icon={<EditOutlined />}>{$t('Wijzig')}</Button>
            ]}
          >
            <PartCard
              editPart={(p) => setOpenPart(p)} readOnly={readOnlyMode} part={part} currentMaterial={currentMaterial} level={0} parts_path={[part.id]} openMaintenance={openMaintenance}
              saveParts={saveParts}
            />
          </Card>
        )
      })}

    </div>
  )
}
