import React from 'react';

export const DateFormat = ({
  date = new Date(),
  options = {
    day: "2-digit",
    weekday: "short",
    month: "short",
    year: "2-digit",
  },
}) => {
  const formattedDate = new Date(date).toLocaleDateString(
    localStorage.locale || "nl-NL",
    options
  );
  return <span>{formattedDate}</span>;
};
