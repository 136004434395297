import { Button, List } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DateTimeDiff from "~/components/date/DateTimeDiff.jsx";
import { DateTimeFormat } from "~/components/date/DateTimeFormat.jsx";
import { GCard } from "~/components/GCard.jsx";
import StringLimit from "~/components/StringLimit.jsx";
import { $t } from "~/i18n.js";

export const NewsWidget = () => {
  const loading = useSelector((state) => state.persist.isPending);
  const items = useSelector((state) => state.persist.news);

  if (!items || items.length === 0) return null;

  return (
    <GCard
      title={$t("Mijn nieuws")}
      style={{ width: "100%", height: "100%" }}
      loading={loading}
      extra={
        <Button type="link">
          <Link to="/my_news">
            {$t("Bekijk alles")} ({items.length})
          </Link>
        </Button>
      }
    >
      <List
        dataSource={items.slice(0, 3)}
        renderItem={(item) => (
          <Link to={`/my_news/#${item.news_item.id}`}>
            <List.Item className="clickable">
              <List.Item.Meta
                title={`${item.news_item.title}`}
                description={
                  <>
                    <div>
                      <DateTimeDiff value={item.news_item.valid_from} />{" "}
                      <small>
                        <DateTimeFormat date={item.news_item.valid_from} />
                      </small>
                    </div>
                    <StringLimit text={item.news_item.text} limit={150} />
                  </>
                }
              />
            </List.Item>
          </Link>
        )}
      />
    </GCard>
  );
};
