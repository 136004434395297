import { Button, Divider, Input, message, Modal } from "antd";
import React, { useState } from "react";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { $t } from "~/i18n.js";
import { DataCard } from "~/components/dataCards/dataCard.jsx";
import { api } from "../../../lib/api.js";
import { requestPasswordReset } from "../../login/password_forget.jsx";
import PincodeInput from "~/components/form/PincodeInput.jsx";

const successEmailCheck = () => {
  Modal.success({
    title: $t("Check je email"),
    content: $t(
      "We hebben je nét een email gestuurd met een link erin om je nieuwe email address te bevestigen."
    ),
  });
};

const successPincode = () => {
  Modal.success({
    title: $t("Pincode opgeslagen"),
    content: $t("Vanaf nu kan je met je nieuwe pincode vluchten aftekenen."),
  });
};

export const CardSecurity = ({ profile }) => {
  const [savingAction, setSavingAction] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const fireRequestPasswordReset = async () => {
    setSavingAction("password");
    await requestPasswordReset(profile.email);
    setSavingAction(null);
  };

  const validatePincode = (pincode) => {
    // validate pincode is an integer between 1000 and 9999
    return pincode.length === 4;
  };

  const fireNewEmail = async () => {
    if (!newEmail || newEmail.indexOf("@") === -1) {
      message.error("Voeg een valide email toe");
    } else {
      setSavingAction("email");
      const res = await api.post("auth/send_new_email_verification.json", {
        new_email: newEmail,
      });
      if (res) successEmailCheck();
      setSavingAction("");
      setNewEmail("");
    }
  };

  const [password, setNewPassword] = useState("");
  const [pincode, setPincode] = useState("");
  const [pinLoading, setPinLoading] = useState(false);

  const savePincode = async () => {
    setPinLoading(true);
    const { response } = await api.put("auth/pincode.json", {
      password,
      pincode,
    });
    response.ok && successPincode();
    setNewPassword("");
    setPincode("");
    setPinLoading(false);
  };

  return (
    <DataCard title={$t("Inloggegevens")} type="card" readOnly={true}>
      <p className="subtitle">{$t("Wijzig email")}</p>
      <p className="small gray">
        {$t(
          "Als je het wilt wijzigen versturen we je een bevestiging naar je nieuwe email adres"
        )}
      </p>
      <p style={{ marginTop: 12 }}>
        {$t("Je email is nu")}: {profile.email}
      </p>

      <div className="row spacer">
        <Input
          style={{ width: 210, marginRight: 24 }}
          onChange={(event) => setNewEmail(event.target.value)}
          placeholder={$t("Nieuw emailadres")}
          type="email"
          value={newEmail}
        />
        <Button loading={savingAction === "email"} onClick={fireNewEmail}>
          {$t("Wijzig email")}
        </Button>
      </div>

      <Divider />

      <p className="subtitle">{$t("Wijzig wachtwoord")}</p>
      <p className="small gray">
        {$t("We sturen je een email met vervolginstructies.")}
      </p>

      <Button
        style={{ marginTop: 12 }}
        loading={savingAction === "password"}
        onClick={fireRequestPasswordReset}
      >
        {$t("Wijzig wachtwoord")}
      </Button>

      <section>
        <Divider />
        <p className="subtitle">{$t("Wijzig pincode")}</p>
        {profile.is_instructeur && (
          <p className="gray small">
            {$t(
              "Je kunt hiermee zaken aftekenen als, bijvoorbeeld trainingsvluchten, solovluchten of technische workorders"
            )}.
          </p>
        )}

        <p style={{ marginTop: 12 }}>
          {$t("Huidig wachtwoord (ter controle)")}
        </p>
        <Input
          type="password"
          autoComplete="new-password"
          placeholder={$t("Wachtwoord")}
          onChange={(e) => setNewPassword(e.target.value)}
          value={password}
          style={{ width: "100%", maxWidth: 300 }}
        />
        <p>{$t("Nieuwe pincode")}</p>
        <PincodeInput onComplete={setPincode} value={pincode} />

        <Button
          style={{ marginTop: 12 }}
          loading={pinLoading}
          onClick={savePincode}
          disabled={!password || !pincode || !validatePincode(pincode)}
        >
          {$t("Wijzig pincode")}
        </Button>
      </section>
    </DataCard>
  );
};
