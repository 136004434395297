import { Checkbox, Modal, Tag, Timeline } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PincodeInput from "~/components/form/PincodeInput.jsx";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { TimeInput } from "~/components/timeInput/timeInput.jsx";
import { $t } from "~/i18n.js";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";

export const AddInspection = ({
  visible,
  currentMaterial,
  closeModal,
  last_motor_minutes,
}) => {
  const dispatch = useDispatch();
  const { gegevens } = useSelector((state) => state.persist);

  const [inspectionOK, setInspection] = useState(false);
  const [pincode, setPincode] = useState(null);
  const [total_motor_minutes, setMotorMinutes] = useState(null);

  const [listInspection, setListInspection] = useState([]);
  useEffect(() => {
    setListInspection(
      currentMaterial.list_inspection.map((item) => ({ item, checked: false }))
    );
  }, [currentMaterial.id]);

  useEffect(() => {
    setMotorMinutes(last_motor_minutes);
  }, [last_motor_minutes]);

  const brevet_number = gegevens?.brevet_nummer;

  const disabled =
    pincode?.length !== 4 ||
    !inspectionOK ||
    !brevet_number ||
    listInspection.filter((i) => !i.checked).length > 0;

  const [saving, setSaving] = useState(false);
  const triggerAddInspectie = async () => {
    setSaving(true);
    const payload = {
      total_motor_minutes,
      brevet_number,
      pincode,
      datum: dayjs().format("YYYY-MM-DD"),
    };
    const res = await dispatch(
      saveMaterialTab({
        activeTab: "meldingen",
        materialID: currentMaterial.id,
        payload,
      })
    );
    setPincode(null);
    if (res) {
      closeModal();
      Modal.success({
        title: $t("Inspectie voltooid"),
        content: $t("Veel plezier met vliegen"),
      });
    }
    setSaving(false);
  };

  return (
    <Modal
      title={$t("Voer dagelijkse inspectie uit")}
      confirmLoading={saving}
      open={visible}
      onCancel={closeModal}
      okButtonProps={{ disabled }}
      okText={$t("Aftekenen")}
      width={400}
      onOk={triggerAddInspectie}
    >
      {currentMaterial.status !== "in bedrijf" && (
        <p>
          <span className="bold">{$t('Status')}</span>{" "}
          {$t("niet inzetbaar: Inspectie kan niet worden uitgevoerd.")}
        </p>
      )}

      {currentMaterial.maintenance_status === "red" && (
        <p>
          <span className="bold">{$t('Onderhoud')}</span>{" "}
          {$t("verlopen: Inspectie kan niet worden uitgevoerd.")}
        </p>
      )}

      {currentMaterial.document_status === "red" && (
        <p>
          <span className="bold">{$t('Documenten')}</span>{" "}
          {$t("verlopen: Inspectie kan niet worden uitgevoerd.")}
        </p>
      )}

      {currentMaterial.maintenance_status !== "red" &&
        currentMaterial.document_status !== "red" &&
        currentMaterial.status === "in bedrijf" && (
          <Timeline>
            {listInspection.map((item, index) => (
              <Timeline.Item
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => {
                  const newList = [...listInspection];
                  newList[index] = { ...item, checked: !item.checked };
                  setListInspection(newList);
                }}
                dot={<Checkbox checked={item.checked} />}
              >
                <span>{item.item}</span>
              </Timeline.Item>
            ))}

            <Timeline.Item
              style={{ cursor: "pointer" }}
              onClick={(e) => setInspection(!inspectionOK)}
              dot={<Checkbox checked={inspectionOK} />}
            >
              <span>
                {currentMaterial.category == "kist"
                  ? $t(
                      "Hierbij verklaar ik de inspectie voltooid is volgens het Aircraft Flight Manual, zoals beschreven in SAO.GEN.130."
                    )
                  : $t(
                      "Hierbij verklaar ik de inspectie voltooid is volgens het handboek."
                    )}
              </span>
            </Timeline.Item>

            {currentMaterial.category == "kist" &&
              currentMaterial.kist_configuration !== "Glider" && (
                <Timeline.Item color={total_motor_minutes ? "green" : "blue"}>
                  <p className="spacer">
                    <span className="bold">{$t("Motortijd")}</span> (
                    {$t("minuten")}){" "}
                    <HelpBubble
                      content={$t(
                        "Voeg de laatste motor tijd toe, in minuten, om de in-service life van de motor componenten bij te houden."
                      )}
                    />
                    :
                  </p>

                  <div className="row">
                    <span style={{ width: 75 }}>{$t("Vorige")}:</span>
                    <TimeInput readOnly value={last_motor_minutes} />
                  </div>
                  <div className="row" style={{ marginTop: 12 }}>
                    <span style={{ width: 75 }}>{$t("Huidige")}:</span>
                    <TimeInput
                      readOnly={false}
                      onChange={setMotorMinutes}
                      value={total_motor_minutes}
                    />
                  </div>
                </Timeline.Item>
              )}

            <Timeline.Item color={pincode?.length === 4 ? "green" : "blue"}>
              {brevet_number ? (
                <div>
                  <p>
                    {$t(
                      "Hierbij teken ik digitaal de inspectie af met mijn Brevet nummer ${brevert}",
                      { brevert: gegevens?.brevet_nummer || "ONBEKEND" }
                    )}
                    <HelpBubble
                      content={$t(
                        "Je pincode je kan toevoegen en wijzigen via mijn gegevens"
                      )}
                    />
                  </p>

                  <PincodeInput value={pincode} onComplete={setPincode} />
                </div>
              ) : (
                <p>
                  <Tag color="red">{$t("Let op")}</Tag>{" "}
                  {$t(
                    "Geen Brevet nummer bekend, zonder nummer kan de dagelijkse inspectie niet worden afgetekend. Ga naar"
                  )}{" "}
                  <Link to="/mijn_gegevens">{$t("mijn gegevens")}</Link>{" "}
                  {$t("om je   brevetnummer toe te voegen.")}
                </p>
              )}
            </Timeline.Item>
          </Timeline>
        )}
    </Modal>
  );
};
