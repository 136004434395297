export const exportFile = (txt, filename) => {

    const blob = new Blob([txt], { type: 'text/plain' }); // Create a Blob object with the string
    const url = URL.createObjectURL(blob); // Create a URL for the Blob
    
    // Create a temporary anchor element and trigger a download
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; 
    document.body.appendChild(a);
    a.click();

    // Clean up the URL and remove the temporary element
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
}