import { Alert, Form, Input } from "antd";

import React from "react";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { EmailValidator } from "~/components/form/formHelpers.js";
import { $t } from "~/i18n.js";

export const Export = ({ clubData }) => {
  return (
    <SettingCard title={$t("Exporteer data")}>
      <Form.Item
        label={$t("Exporteer naar")}
        name="export_scheduled"
        extra={$t("Vul emailadres in waar de data heen gestuurd kan worden")}
        rules={[EmailValidator(false)]}
      >
        <Input type="email" style={{ maxWidth: 250 }} allowClear={true} />
      </Form.Item>

      {clubData.export_scheduled && (
        <Alert
          description={
            <span>
              {$t("Data wordt vanavond verstuurd naar")}:{" "}
              {clubData.export_scheduled}
            </span>
          }
          type="success"
          showIcon
        />
      )}
    </SettingCard>
  );
};
