import React from "react";

export const ParseMessage = ({ message }) => {
  return (
    <>
      {message.split("\n").map((line, i) => (
        <>
          <span style={{ margin: 0 }}>
            {line}
          </span>
          <br />
        </>
      ))}
    </>
  );
};
