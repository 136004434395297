import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import localForage from 'localforage'
import MemoryStorage from 'redux-persist-memory-storage'

import { api } from '~/lib/api.js'
import { config } from '~/config.js'
import { resetAllStates, rootReducer } from './reducer.js'

export const initStore = () => {
  // enable localForage only if localStorage is available (not on Firefox mobile)
  let enableLocalForage = true
  try {
    window.localStorage.setItem('__u', 'u')
  } catch (e) {
    enableLocalForage = false
  }

  const persistConfig = {
    key: 'root',
    storage: enableLocalForage ? localForage : new MemoryStorage(),
    whitelist: ['persist', 'flights', 'days', 'aanmeldingen']
  }

  // Logger only during development
  const middleWares = [thunk]
  if (config.debugRedux) {
    middleWares.push(logger)
  }

  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    applyMiddleware(...middleWares)
  )

  const persistor = persistStore(store, null, () => {
    // export store to api for usage
    api.store = store
  })

  // add clear in store
  store.clearState = () => resetAllStates(store.dispatch)

  // add listeners for internet offline/online, in which case you dispatch messages
  window.addEventListener('offline', (event) => {
    console.log('Netwerk: %cOFFLINE', 'color: red')
    store.dispatch({ type: 'OFFLINE' })
  })
  window.addEventListener('online', (event) => {
    console.log('Netwerk: %cONLINE', 'color: green')
    store.dispatch({ type: 'ONLINE' })
  })

  return { store, persistor }
}
