import { CheckCircleFilled, ExclamationCircleTwoTone } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { VerwijderButtonConfirm } from "~/components/buttons/VerwijderButtonConfirm.jsx";
import { $t } from "~/i18n.js";
import { _parseDateTime, parseMinutesLong } from "~/lib/localize.js";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";
import { colors } from "~/theme/colors.js";

export const Inspection = ({ inspection, missingText, currentUserID }) => {
  const [visible, setVisible] = useState(false);
  const closeModal = () => setVisible(false);

  const dispatch = useDispatch();

  const handleRemoveInspection = async () => {
    await dispatch(
      saveMaterialTab({
        activeTab: "meldingen",
        materialID: inspection.material_id,
        payload: { inspection_id: inspection.id, action: "delete" },
      })
    );
    closeModal();
  };

  if (!inspection)
    return (
      <p className="row" style={{ gap: 6 }}>
        <ExclamationCircleTwoTone
          style={{ fontSize: 18 }}
          twoToneColor={colors.gray_light}
        />{" "}
        <span className="small">{missingText || " "}</span>
      </p>
    );

  return (
    <div>
      <Modal
        title={$t("Inspectie voltooid")}
        open={visible}
        onCancel={closeModal}
        footer={[
          currentUserID === inspection.user_id && (
            <VerwijderButtonConfirm
              key="remove"
              onConfirm={handleRemoveInspection}
              title="Weet je zeker dat je deze inspectie wilt verwijderen?"
            />
          ),
          <SluitButton type='primary' key="close" onClick={closeModal} />,
        ]}
      >
        <div>
          <p>
            {_parseDateTime(inspection.date_created)}, {inspection.user_name} (
            {inspection.brevet_number})
          </p>
          {inspection.total_motor_minutes && (
            <p>
              <span className="bold">M</span>{" "}
              {parseMinutesLong(inspection.total_motor_minutes)}
            </p>
          )}
        </div>
      </Modal>

      <p className="clickable small row" onClick={() => setVisible(true)}>
        <CheckCircleFilled
          style={{ marginRight: 6, color: colors.green, fontSize: 19 }}
        />{" "}
        {inspection.user_name}{" "}
      </p>
    </div>
  );
};
