import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  EyeInvisibleOutlined,
  QuestionOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";
import { _parseDate, toLocale } from "../../lib/localize.js";
import { RecencyBaro } from "../baro/RecencyBaro.jsx";
import { RecencyBaroVerloop } from "../baro/RecencyBaroVerloop.jsx";
import { HelpBubble } from "../help/helpBubble.jsx";
import { CopyIcon } from "../icon/copy.jsx";
import { StartIcon } from "../icon/icon.jsx";
import { ContactModal } from "./contactModal.jsx";
import { MedicalCheckModal } from "./medicalCheckModal.jsx";
import { ParseTrainingFlightDate } from "./parseTrainingFlightDate.jsx";
import { Phone } from "./phone.jsx";
import { RecencyExplanationModal } from "../recency/RecencyExplanationModal.jsx";
import { RecencyCurrencyBarometer } from "../recency/RecencyCurrencyBarometer.jsx";
import { RecencyHistoricCalculation } from "./recencyHistoricCalculation.jsx";
import { DateFormat } from "../date/DateFormat.jsx";

export const VliegerStats = ({
  currentProfile,
  onUpdateVliegerdata = () => {},
  pilot,
  gegevens,
  showName,
  isInstructorOrPilot,
  link,
  canWijzigDTO,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [recencyModalVisible, showRecencyModal] = useState(false);
  
  // Note: do not show currency for gastvliegers
  const show_currency =
    (pilot.recency || pilot.currency) &&
    pilot.group_keys?.indexOf("gastvlieger") == -1;

    return (
      <>
        <ContactModal
          visible={modalOpen}
          onCancel={() => setModalOpen(false)}
          gegevens={gegevens}
        />

        <RecencyExplanationModal
          visible={recencyModalVisible}
          onClose={() => showRecencyModal(false)}
          recency={pilot.recency}
        />

        <div className="column" style={{ alignItems: "flex-start" }}>
          {showName && (
            <div className="row spacer">
              {link && <CopyIcon text={link} />}
              <p className="bold large">{pilot.name}</p>
            </div>
          )}

          <div className="row clickable" onClick={() => showRecencyModal(true)}>
            <RecencyCurrencyBarometer pilot={pilot} tag={pilot && pilot.tag} />

            <Button
              className="clickable"
              style={{ marginLeft: 3 }}
              shape="circle"
              size="small"
              onClick={() => showRecencyModal(true)}
            >
              <QuestionOutlined />
            </Button>
          </div>

          {showName && (
            <div className="row" style={{ marginTop: 12 }}>
              <Button
                onClick={() => {
                  setModalOpen(true);
                }}
                style={{ marginRight: 12 }}
                icon={<EnvironmentOutlined />}
                size="small"
              >
                {$t("contact")}
              </Button>
              <Phone number={pilot.phone} />

              {pilot.private_mode && (
                <div className="row" style={{ gap: 12 }}>
                  {" "}
                  <EyeInvisibleOutlined />{" "}
                  <HelpBubble
                    title={$t("Privacy")}
                    content={$t(
                      "Vlieger heeft de privacy modus aangezet, gegevens worden hierbij afgeschermd."
                    )}
                  />{" "}
                </div>
              )}
            </div>
          )}

          {show_currency && (
            <div style={{ marginTop: 12 }}>
              {isInstructorOrPilot && (
                <>
                  {(pilot.tag === "brevet" || pilot.tag === "instructeur") && (
                    <>
                      <div className="row capitalize">
                        <p>
                          {$t("brevet")}:{" "}
                          {pilot.recency && pilot.recency.spl === "invalid" ? (
                            <span className="bold alert">{$t("ongeldig")}</span>
                          ) : (
                            <span className="bold">{$t("geldig")}</span>
                          )}
                        </p>
                        {canWijzigDTO && (
                          <RecencyHistoricCalculation user={pilot} />
                        )}
                      </div>
                      <p>
                        <UnorderedListOutlined /> {$t("Trainingsvlucht")}:{" "}
                        <ParseTrainingFlightDate
                          last_check_start={pilot.last_check_start}
                        />
                      </p>
                    </>
                  )}

                  <div className="row" style={{ gap: 6 }}>
                    <span>{$t("Medical")}:</span>{" "}
                    {gegevens && gegevens.medical_valid_to ? (
                      <>
                        {new Date(gegevens.medical_valid_to) < new Date() ? (
                          <>
                            <span className="bold alert">{$t("verlopen")}</span>{" "}
                            {$t("op")} {_parseDate(gegevens.medical_valid_to)}
                          </>
                        ) : (
                          <>
                            <span className="bold">{$t("geldig")}</span>{" "}
                            {$t("t/m")} {_parseDate(gegevens.medical_valid_to)}
                          </>
                        )}
                      </>
                    ) : (
                      <span className="bold">{$t("onbekend")}</span>
                    )}
                    <MedicalCheckModal
                      currentProfile={currentProfile}
                      user={pilot}
                      onUpdateVliegerdata={onUpdateVliegerdata}
                    />
                  </div>

                  {pilot.recency?.last_xc &&<p>
                    <span className="bold">{$t("Ovl")}</span>:{" "}
                    <DateFormat date={pilot.recency.last_xc} options={{day: "2-digit",month: "short",year: "2-digit"}} />
                  </p>}

                  {pilot.dbo > 0 && (
                    <p>
                      {$t("Eerste start")}:{" "}
                      {pilot.date_first_flight
                        ? _parseDate(pilot.date_first_flight, {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                        : $t("onbekend")}
                    </p>
                  )}
                </>
              )}

              {show_currency && (
                <>
                  {pilot.dbo > 0 ? (
                    <div>
                      <p>
                        <StartIcon /> {toLocale(pilot.dbo)} {$t("dbo")} &{" "}
                        {toLocale(pilot.pic)} {$t("PIC starts")}
                      </p>
                      <p>
                        <ClockCircleOutlined style={{ marginRight: 2 }} />{" "}
                        {toLocale(pilot.dbo_uren)} DBO &{" "}
                        {toLocale(pilot.pic_uren)} {$t("PIC uren")}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <StartIcon /> {toLocale(pilot.pic)} {$t("starts")}
                      <ClockCircleOutlined style={{ marginLeft: 12 }} />{" "}
                      {toLocale(pilot.pic_uren)} {$t("uur")}
                      {pilot.fis_tot_uren > 0 && (
                        <>
                          <span style={{ marginLeft: 9, fontWeight: "bold" }}>
                            FI(s)
                          </span>{" "}
                          {toLocale(pilot.fis_tot_uren)} {$t("uur")}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}

              {isInstructorOrPilot && show_currency && (
                <>
                  <p>
                    {$t("Laatste 6 maanden")}: {toLocale(pilot.starts)}{" "}
                    {$t("starts")} & {toLocale(pilot.uren)} {$t("uren")}
                  </p>
                  {pilot.recency && pilot.recency["6m_methods"]?.length > 0 && (
                    <p
                      className="small row"
                      style={{ marginLeft: 50, gap: 3, flexWrap: "wrap" }}
                    >
                      <span>{$t("waarvan")}:</span>
                      {pilot.recency["6m_methods"].map((m, i) => (
                        <span key={i}>
                          {m["starts"]} {$t(m["start_methode"])}
                          {i < pilot.recency["6m_methods"].length - 1 && ","}
                        </span>
                      ))}
                    </p>
                  )}
                </>
              )}

              {show_currency && (
                <div
                  className="row"
                  style={{ width: 300, marginTop: 12, gap: 12 }}
                >
                  <RecencyBaro
                    starts={pilot.starts}
                    hours={pilot.uren}
                    currency={pilot.currency}
                  />
                  {isInstructorOrPilot && <RecencyBaroVerloop user={pilot} />}
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
};
