import {
  IssuesCloseOutlined,
  StopTwoTone,
  WarningOutlined
} from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

import { colors } from "~/theme/colors.js";

const WarningTag = ({ content }) => (
  <Tag
    className="small"
    color="red"
    style={{ fontSize: 11, marginTop: 1, padding: "2px 3px" }}
  >
    <WarningOutlined /> {content}
  </Tag>
);

const WarningCheckMedical = () => (
  <Tag
    className="small"
    color="orange"
    style={{ fontSize: 11, marginTop: 1, padding: "2px 3px" }}
  >
    <IssuesCloseOutlined /> {$t("medical")}
  </Tag>
);

export const RecencyCurrencyBarometer = ({
  pilot,
  tag,
  skipDetails = false,
}) => {
  if (!pilot) return <div style={{ height: 20 }} />;

  const recency = pilot?.recency;

  const brevet_invalid = recency?.spl === "invalid";

  const currency =
    pilot.currency &&
    (typeof pilot.currency === "string" || pilot.currency instanceof String)
      ? pilot.currency
      : "";
  const showCheck =
    currency && currency === "rood" && recency?.check_start_3mnd === "invalid";

  return (
    <div className="column" style={{ alignItems: "flex-start" }}>
      <div
        className="row"
        style={{
          flexWrap: "wrap",
          height: skipDetails ? 20 : null,
        }}
      >
        {tag && (
          <div className="tag row" style={{ marginRight: 6 }}>
            <span className="small">{$t(tag)}</span>
          </div>
        )}

        {pilot && currency && currency !== "nvt" && !brevet_invalid && (
          <div className="currency row" style={{ marginRight: 6 }}>
            <div
              className="icon"
              style={
                currency && {
                  backgroundColor: colors[currency],
                  position: "relative",
                }
              }
            >
              {showCheck && (
                <WarningOutlined
                  style={{
                    color: "white",
                    fontSize: 12,
                    position: "absolute",
                    top: 4,
                    left: 4,
                  }}
                />
              )}
            </div>
            {/* Currencies: $t('rood') $t('groen') $t('geel') */}
            <span className="small">
              {currency === "orange" ? $t("oranje") : $t(currency)}
            </span>
          </div>
        )}

        {brevet_invalid && (
          <div className="currency row" style={{ marginRight: 6 }}>
            <StopTwoTone
              style={{ fontSize: 20, marginRight: 4 }}
              twoToneColor={"red"}
            />
            <div className="column" style={{ alignItems: "flex-start" }}>
              <p className="small bold">{$t("Ongeldig")}</p>
              {!skipDetails && (
                <p className="small">
                  {recency?.checks < 2 && <span>{$t("training")} </span>}
                  {recency?.starts < 15 && <span>{$t("starts")} </span>}
                  {recency?.vliegduur < 300 && <span>{$t("uren")} </span>}
                  {recency?.medical && <span>{$t("medical")}</span>}
                </p>
              )}
            </div>
          </div>
        )}

        {/* Edgecase medical can be invalid if brevat is not invalid */}
        {!brevet_invalid && recency?.medical && (
          <WarningTag content={$t("medical")} />
        )}

        {recency !== null && pilot?.check_med && <WarningCheckMedical />}

        {(!brevet_invalid || !skipDetails) && recency && (
          <>
            {recency?.lieraantekening === "invalid" && (
              <WarningTag content={$t("Lier")} />
            )}
            {recency?.sleepaantekening === "invalid" && (
              <WarningTag content={$t("Sleep")} />
            )}
            {recency?.zelfstartaantekening === "invalid" && (
              <WarningTag content={$t("Zelf")} />
            )}
            {recency?.tmgaantekening === "invalid" && (
              <WarningTag content="TMG" />
            )}
            {recency && recency["tmg-a-aantekening"] === "invalid" && (
              <WarningTag content="TMG-A" />
            )}
            {recency?.passagiervliegen === "invalid" && (
              <WarningTag content="Pax" />
            )}
            {recency?.tmg_passagiervliegen === "invalid" && (
              <WarningTag content="T Pax" />
            )}
            {recency?.bungeeaantekening === "invalid" && (
              <WarningTag content="Bung" />
            )}
            {recency?.caraantekening === "invalid" && (
              <WarningTag content={$t("car")} />
            )}
            {recency?.fis === "invalid" && <WarningTag content="FI(s)" />}
            {recency?.fes === "invalid" && <WarningTag content="FE(s)" />}
          </>
        )}
      </div>
    </div>
  );
};
