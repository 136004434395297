import { Col, Row } from "antd";
import React from "react";

export const ReadOnlyFormTag = ({
  name,
  value,
  labelCol = 8,
  wrapperCal = 16,
}) => {
  return (
    <Row gutter={24}>
      <Col
        className="gutter-row"
        span={labelCol}
        style={{ textAlign: "right", padding: "0 1px 12px" }}
      >
        <p className="label">
          {name}
        </p>
      </Col>
      <Col className="gutter-row" span={wrapperCal} style={{ paddingLeft: 18 }}>
        <span className="gray">{value}</span>
      </Col>
    </Row>
  );
};
