import React, { useState } from 'react';
import { Modal } from 'antd';
import { MyNews } from "~/components/profile/myNews.jsx";
import { useSelector } from "react-redux";
import { $t } from '~/i18n.js';

export const MyNewsModal = () => {
  const [visible, setVisible] = useState(true);
  const news = useSelector((state) => state.persist.news);

  if (!news || news.length === 0 || !news.some(item => item.has_read === false)) {
    return null;
  }

  return (
    <Modal
      open={visible}
      title={$t("Bevestig inkomende news bulletins")}
      closable={false}
      maskClosable={false}
      footer={null}
      width={1000}
      >

      <MyNews setModalVisible={setVisible} toggleCheckbox={true} />

    </Modal>
  );
};
