import { CheckCircleFilled, FileExcelOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { _parseDate, parseMinutes, toLocaleDate } from "~/lib/localize.js";
import { colors } from "~/theme/colors.js";
import { FlightViewModal } from "../../flight/FlightViewModal.jsx";
import "./overviewFlights.css";

export const LastFlights = ({ flights, pilot, addFlightToProfile }) => {
  const [flight, openFlight] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const downloadFile = async () => {
    setFileLoading(true);
    await api.open_file(
      "flights/export_history.json?format=xlsx&user_id=" + pilot.id,
      `${$t("vluchten")} ${pilot.name} - ${toLocaleDate(new Date())}.xlsx`
    );
    setFileLoading(false);
  };

  const onUpdateFlight = ({ flight }) => {
    openFlight(flight);
    addFlightToProfile({ flight });
  };

  return (
    <div className="overviewFlightTab">
      <FlightViewModal
        onClose={() => openFlight(null)}
        flight={flight}
        currentUser={pilot}
        onUpdateFlight={onUpdateFlight}
      />

      <Button
        icon={<FileExcelOutlined />}
        size="small"
        loading={fileLoading}
        onClick={downloadFile}
      >
        {$t("Download")}
      </Button>

      <table className="dataBlock">
        <tbody>
          {!flights || flights === -1 ? (
            <tr style={{ marginLeft: 36 }}>
              <td>-</td>
            </tr>
          ) : (
            flights.map((row, key) => {
              const tag =
                row.gezagvoerder_id === pilot.id ? (
                  "PIC"
                ) : (
                  <span>
                    <span style={{ marginRight: 4 }}>
                      {row.gezagvoerder_naam}
                    </span>
                    {row.signed && (
                      <CheckCircleFilled style={{ color: colors.groen_dark }} />
                    )}
                    {row.is_training ? " (Training)" : ""}
                    {row.is_profcheck ? " (Prof)" : ""}
                    {row.is_examen ? " (Examen)" : ""}
                  </span>
                );
              return (
                <tr
                  className="clickable"
                  key={`${key}`}
                  onClick={() => openFlight(row)}
                >
                  <td className="col">{_parseDate(row.datum)}</td>
                  <td className="col onlyWide">
                    {row.callsign ? row.callsign : row.registratie}
                  </td>
                  <td className="col">{row.type}</td>
                  <td className="col center">{tag}</td>
                  <td className={"col center " + row.start_methode}>
                    {$t(row.start_methode)}
                  </td>
                  <td className="col">
                    {parseMinutes(row.vluchtduur)}
                    {row.starts > 1 ? ` ${row.starts} starts` : ""}
                    {row.is_overland && (
                      <Tag color="green">
                        {row.afstand > 0 ? row.afstand + "km" : $t("Ovl")}
                      </Tag>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};
