import React, { useEffect, useState } from 'react'
import { $t } from '~/i18n.js'

import { StopOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Tag
} from 'antd'
import { api } from '~/lib/api.js'
import { selectElementByID } from '~/lib/helpers.js'
import { _parseDate, parseDate_YYYYMMDD } from '~/lib/localize.js'
import { AnnuleerButton } from '../buttons/AnnuleerButton.jsx'

export const NotitieModal = ({
  flight_uuid,
  visible,
  notitie,
  onCancel,
  onOk,
  allDays,
  pilot,
  activeDay
}) => {
  const [form] = Form.useForm()
  const [pendingSave, setPendingSave] = useState(false)
  useEffect(() => {
    if (notitie) form.resetFields()
  }, [notitie, form])

  const onSave = async (data) => {
    setPendingSave(true)
    const day =
      activeDay || selectElementByID(allDays, 'dag_id', parseInt(data.dag_id))
    const newData = {
      ...data,
      user_id: pilot ? pilot.id : null,
      user_naam: pilot ? pilot.name : '',
      dag_id: day ? day.dag_id : null,
      datum: day ? day.datum : parseDate_YYYYMMDD(),
      id: notitie ? notitie.id : null,
      flight_uuid
    }
    const res = await api.post('instructie/notitie.json', newData)
    if (res) {
      onOk(res)
      form.resetFields()
    }
    setPendingSave(false)
  }

  const triggerCancel = () => {
    form.resetFields()
    onCancel()
  }
  return (
    <Modal
      title={
        !pilot
          ? $t('Voeg dagnotitie toe bij ${d}', { d: activeDay.datum })
          : $t('Voeg notitie toe bij ${name}', { name: pilot && pilot.name })
      }
      open={visible}
      confirmLoading={pendingSave}
      okText={$t('Opslaan')}
      cancelText={$t('Annuleren')}
      onCancel={triggerCancel}
      footer={[
        <Popconfirm
          key='remove'
          placement='bottom'
          title={$t('Zeker weten dat je deze notitie wilt verwijderen')}
          onConfirm={() => notitie && onSave({ id: notitie.id, delete: true })}
          okText={$t('Ja')}
          cancelText={$t('Nee')}
        >
          <Button
            disabled={!notitie}
            danger
            icon={<StopOutlined />}
            style={{ marginRight: 24 }}
            loading={pendingSave}
          >
            {$t('Verwijder')}
          </Button>
        </Popconfirm>,
        <AnnuleerButton  key="annuleren" onClick={triggerCancel}/>,
        <Button
          key='opslaan'
          onClick={() => {
            form
              .validateFields()
              .then((data) => {
                onSave(data).then(() => form.resetFields())
              })
              .catch((info) => {
                console.log('Validate Failed:', info)
              })
          }}
          type='primary'
          loading={pendingSave}
        >
          {$t('Opslaan')}
        </Button>
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        name='bericht'
        preserve={false}
        requiredMark={false}
        initialValues={{
          is_kritiek: notitie ? notitie.is_kritiek : false,
          is_prive: notitie ? notitie.is_prive : true,
          bericht: notitie ? notitie.bericht : '',
          dag_id: notitie ? notitie.dag_id : null
        }}
      >
        {!activeDay && (
          <Form.Item
            label={$t('Betreft vluchtdag')}
            name='dag_id'
            rules={[{ required: false }]}
          >
            <Select>
              {allDays.map(
                (dag, key) =>
                  dag.can_start && (
                    <Select.Option key={key} value={dag.dag_id}>
                      {_parseDate(dag.datum)} - {dag.title || dag.type_dag}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={$t('Notitie')}
          name='bericht'
          rules={[
            {
              required: true,
              max: 1000,
              message: $t(
                'De bericht mag maximaal 1000 characters lang zijn. Je kan meerdere notities toevoegen'
              )
            }
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <p className='small' style={{ marginBottom: 12 }}>
          {pilot
            ? $t('Let op: Goedgekeurde EASA trainingsvluchten worden per vlucht bijgehouden en behoeven geen losse notitie.')
            : $t(
              'Let op: Plaats hier geen privacy gevoelige notities. Deze worden in de notities van de betreffende vlieger opgenomen. Deze notities zijn bedoeld voor onderwerpen betreffende het verloop van de dag.'
            )}
        </p>

        {pilot && (
          <Form.Item name='is_kritiek' valuePropName='checked'>
            <Checkbox className='small'>
              {$t('Belangrijke notitie (informeer HoT/CI)')}
            </Checkbox>
          </Form.Item>
        )}

        {pilot && (
          <Form.Item name='is_prive' valuePropName='checked'>
            <Checkbox className='small'>
              {$t('Alleen zichtbaar voor instructeurs')}
            </Checkbox>
          </Form.Item>
        )}

        {pilot && (
          <Form.Item shouldUpdate>
            {() =>
              !form.getFieldValue('is_prive') && (
                <>
                  <Tag color='red'> {$t('Let op')}</Tag>

                  {$t('Deze notitie zal zichtbaar worden voor het lid.')}
                </>
              )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}
