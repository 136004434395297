import React from "react";
import { Tag } from "antd";
import { TeamOutlined } from "@ant-design/icons";
import { $t } from "~/i18n.js";
import { _parseDateTime } from "~/lib/localize.js";

export const DayInfo = ({ activeDay }) => (
  <div className="column" style={{ marginTop: 6, alignItems: "flex-start" }}>
    {!activeDay.gaat_door && (
      <Tag style={{ marginRight: 6 }} color="red">
        {$t("Geannuleerd")}
      </Tag>
    )}
    <p className="title">
      {activeDay.type_dag ? <span>{activeDay.type_dag}</span> : <span>{$t('Dag')}: {$t("Automatisch aangemaakt")}</span>}
      {activeDay.titel && activeDay.titel.length > 0 && ": " + activeDay.titel}
      {!activeDay.is_vliegend && (
        <span style={{ marginLeft: 12 }}>
          <TeamOutlined /> {$t("Niet vliegend")}
        </span>
      )}
    </p>
    <p>
      {activeDay.gaat_door && (
        <span>
          {$t("briefing om")} {activeDay.briefing_tijd || "??"},{" "}
          {activeDay.vertrek_vliegveld}
        </span>
      )}
    </p>
    {activeDay.updated_name && activeDay.updated_date && (
      <p className="small">
        {$t("aangepast door")} {activeDay.updated_name},{" "}
        {_parseDateTime(activeDay.updated_date)}
      </p>
    )}
  </div>
);
