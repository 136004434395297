import { StopOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { Checkbox } from "antd";
import { Input, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { DataCard } from "~/components/dataCards/dataCard.jsx";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { $t } from "~/i18n.js";

export const CardInspection = ({ currentMaterial, saveData }) => {
  
  const [hasInspection, setHasInspection] = useState(false);
  const [listInspection, setListInspection] = useState([]);
  useEffect(() => {
    setHasInspection(currentMaterial.enable_inspections);
    setListInspection(currentMaterial.list_inspection);
  }, [currentMaterial.id]);
  
  const [newItem, setNewItem] = useState("");
  const AddItem = (item) => {
    setNewItem("");
    setListInspection([...listInspection, item]);
  };
  
  const [loading, setLoading] = useState(false);
  const saveForm = async () => {
    try {
      setLoading(true);
      const payload = {
        enable_inspections: hasInspection,
        list_inspection: listInspection,
      };
      const res = await saveData(payload);
      setLoading(false);
      return res;
    } catch (info) {
      console.log("Validate Failed:", info);
      setLoading(false);
    }
  };

  return (
    <DataCard
      title={$t("Dagelijkse inspectie")}
      readOnly={false}
      onSave={saveForm}
      loading={loading}
    >
      <div className="row" style={{ gap: 6 }}>
        <p className="label">{$t("Heeft dagelijkse inspectie")}</p>
        <SwitchYesNo value={hasInspection} onChange={setHasInspection} />
      </div>

      {hasInspection && (
        <section style={{ marginTop: 18 }}>
          <p className="subtitle">{$t("Aangepaste inspectie")}</p>
          <p className="spacer">
            {$t(
              "Voeg hier club of materiaal specifieke items toe bij de dagelijkse inspectie"
            )}
            :
          </p>

          <div className="row" style={{ marginBottom: 32, gap: 6 }}>
            <Input
              style={{ width: 300 }}
              placeholder={$t("Voeg specifiek inspectie item toe")}
              value={newItem}
              onChange={(e) => setNewItem(e.target.value)}
              onPressEnter={(e) => {
                const newItem = e.target.value;
                if (newItem) {
                  setListInspection([...listInspection, newItem]);
                  e.target.value = "";
                }
              }}
            />
            <Button disabled={!newItem} onClick={() => AddItem(newItem)}>
              {$t("Voeg toe")}
            </Button>
          </div>

          <Timeline>
            {listInspection.map((item, index) => (
              <Timeline.Item key={index} dot={<Checkbox disabled />}>
                
                <span style={{ marginLeft: 6 }}>{item}</span>

                <Button
                  style={{ marginLeft: 6 }}
                  onClick={() =>
                    setListInspection(
                      listInspection.filter((_, i) => i !== index)
                    )
                  }
                  shape="circle"
                  size="small"
                  icon={<StopOutlined />}
                />
              </Timeline.Item>
            ))}

            <Timeline.Item dot={<Checkbox disabled />}>
              <span style={{ marginLeft: 6 }}>
                {currentMaterial.category == "kist"
                  ? $t(
                      "Hierbij verklaar ik dat de inspectie voltooid is volgens het Aircraft Flight Manual, zoals beschreven in SAO.GEN.130."
                    )
                  : $t(
                      "Hierbij verklaar ik dat de inspectie voltooid is volgens het handboek."
                    )}
              </span>
            </Timeline.Item>

            {currentMaterial.category == "kist" &&
              currentMaterial.kist_configuration !== "Glider" && (
                <Timeline.Item color="blue">
                  <p className="spacer">
                    <span className="bold">{$t("Motortijd")}</span> (
                    {$t("minuten")}){" "}
                    <HelpBubble
                      content={$t(
                        "Voeg de laatste motor tijd toe, in minuten, om de in-service life van de motor componenten bij te houden."
                      )}
                    />
                    :
                  </p>

                  <div className="row">
                    <span style={{ width: 75 }}>{$t("Vorige")}:</span>
                    <Input readOnly placeholder="0" />
                  </div>
                  <div className="row" style={{ marginTop: 12 }}>
                    <span style={{ width: 75 }}>{$t("Huidige")}:</span>
                    <Input readOnly placeholder="1" />
                  </div>
                </Timeline.Item>
              )}

            <Timeline.Item color="blue">
              <span style={{ marginLeft: 6 }}>
                {$t(
                  "Hierbij teken ik digitaal de inspectie af met mijn Brevet nummer ${brevert}",
                  { brevert: $t("<Brevetnummer van vlieger>") }
                )}
              </span>
            </Timeline.Item>
          </Timeline>
        </section>
      )}
    </DataCard>
  );
};
