export const emptyFlight = {
  index: null,
  uuid: null,
  sleep_uuid: null,
  id: null,
  volg_nummer: null,

  dag_id: null,
  datum: null,
  is_prive: false,

  vertrek_vliegveld: '',
  aankomst_vliegveld: '',

  kist_id: null,
  callsign: '',
  registratie: '',
  type: '',

  gezagvoerder_id: null,
  gezagvoerder_naam: '',

  tweede_inzittende_id: null,
  tweede_inzittende_naam: '',

  betalend_lid_id: null,

  start_methode: 'lier',

  is_training: false,
  is_profcheck: false,
  is_examen: false,
  is_overland: false,

  start_tijd: null,
  start_ogn: -1,
  landings_tijd: null,
  landings_ogn: -1,
  vluchtduur: 0,
  blocktime: 0,
  starts: 1,

  is_deleted: false,
  is_locked: false,

  height: 0,

  bijzonderheden: '',
  notitie: ''
}

export const all_start_methods = [
  'lier', // $t('lier')
  'sleep', // $t('sleep')
  'zelf', // $t('zelf')
  'bungee', // $t('bungee')
  'car', // $t('car')
  'tmg', // $t('tmg')
  'sep', // $t('sep')
  'overig' // $t('overig')

  // You also have the aerotow option, which we'll translate, but not pick up in the default list
  // $t('tmg-a')
  // $t('sep-a')
]

export const flightsState = {

  isPending: false,

  lastUpdated: null,
  lastUpdatedFlightData: null,
  allFlights: [],
  page: 1,
  totals: {
    count: 0,
    total_starts: 0,
    total_pic_starts: 0,
    total_dbo_starts: 0,
    total_time: 0,
    total_dbo_time: 0,
    total_pic_time: 0
  },

  lastUpdatedBaseData: null,
  vliegers: [],
  club_kisten: [],
  sleep_kisten: [],
  kisten: [],
  kistStatus: {},
  bevoegdheden: [],

  all_group_keys: [],
  group_name_lookup: {},

  ogn_mode: 'manueel',
  default_flight_cat: null,
  default_sleep_cat: null,
  default_tmg_cat: null,

  flightlogs: [],
  // fields: 'id', 'flight_uuid', 'registratie', 'type', 'callsign', 'start_tijd', 'landings_tijd'

  // for offline time writing
  network_online: true,

  // queue is dictionary with flight UUID objects to be synced with backend
  flight_queue: {}

}
