import { i18n } from "~/i18n.js"
import { generate_uuid } from "~/lib/helpers.js"

// copy(mappingTableLookup)
const fromStringBool = (str = '') => {
  return str.toLocaleLowerCase().trim() === 'true'
}

const mappingTableLookup = {
  uuid: 'uuid',
  number: 'volg_nummer',
  day_id: 'dag_id',
  date: 'datum',
  year: 'year',
  date_created: 'date_created',
  date_updated: 'date_updated',
  is_private: 'is_prive',
  departure_airport: 'vertrek_vliegveld',
  arrival_airport: 'aankomst_vliegveld',
  callsign: 'callsign',
  registration: 'registratie',
  type: 'type',
  aerotow_uuid: 'sleep_uuid',
  pic_id: 'gezagvoerder_id',
  pic_name: 'gezagvoerder_naam',
  passenger_id: 'tweede_inzittende_id',
  passenger_name: 'tweede_inzittende_naam',
  paying_member_id: 'betalend_lid_id',
  start_method: 'start_methode',
  category: 'category',
  is_fis: 'is_fis',
  is_training: 'is_training',
  is_exam: 'is_examen',
  is_profcheck: 'is_profcheck',
  is_crosscountry: 'is_overland',
  distance: 'afstand',
  starts: 'starts',
  departure_time: 'start_tijd',
  arrival_time: 'landings_tijd',
  flightduration: 'vluchtduur',
  blocktime: 'blocktime',
  height: 'height',
  remarks: 'bijzonderheden'
}

const mappingStartMethode = {
  winch: 'lier',
  tow: 'sleep',
  self: 'zelf',
  tmg: 'tmg',
  'tmg-a': 'tmg-a',
  sep: 'sep',
  'sep-a': 'sep-a',
  car: 'car',
  bungee: 'bungee',
  other: 'overig'
}

/**
 * Flight class
 */
export class Flight {
  /**
     * Automaticly converts english keyed objects to server keys
     * @param {*} obj English or Server keyed flight object
     */
  constructor(obj = {}) {
    // Map english keys to server keys
    const serverObject = this.mapEnglishKeysToServerKeys(obj)
    Object.assign(this, this.parse(serverObject))
  }

  mapEnglishKeysToServerKeys (obj = {}) {
    // also allows for NL keys to be uploaded
    const _obj = { ...obj }
    // replace keys from mappingTableLookup
    Object.keys(_obj).forEach(key => {
      if (mappingTableLookup[key]) {
        _obj[mappingTableLookup[key]] = _obj[key]
      }
    })
    _obj.start_methode = mappingStartMethode[_obj.start_methode] || 'overig'
    return _obj
  }

  parse (obj = {}) {
    const errors = []

    // custom for date now
    let newDate
    try {
      newDate = new Date(obj.datum)
      newDate = newDate.toISOString().split('T')[0]
    } catch (info) {
      errors.push('Error date format, "YYYY-MM-DD"')
    }

    const newobj = {
      ...obj,

      // imported flights are always private, and skip currency as you do not need to calculate this on import (bulk)
      datum: newDate,
      skip_currency: true,
      is_prive: true,
      volg_nummer: null,
      is_deleted: false,

      // ensure for max 35 length
      uuid: (obj.uuid || generate_uuid()).substr(0, 34),

      // remap characters that require types
      is_fis: fromStringBool(obj.is_fis),
      is_training: fromStringBool(obj.is_training),
      is_examen: fromStringBool(obj.is_examen),
      is_profcheck: fromStringBool(obj.is_profcheck),

      is_overland: fromStringBool(obj.is_overland),
      afstand: Number(obj.afstand) || 0,

      start_tijd: obj.start_tijd || null,
      landings_tijd: obj.landings_tijd || null,
      starts: Number(obj.starts) || 1,
      vluchtduur: Number(obj.vluchtduur) || 0,
      blocktime: Number(obj.blocktime) || 0,
      height: i18n.convertLocaleHeightToMetric(Number(obj.height) || 0),

      // ID's not always filled, as such the lookup is the name
      gezagvoerder_id: obj.gezagvoerder_id || "",
      tweede_inzittende_id: obj.tweede_inzittende_id || "",

      // for private flights always null
      betalend_lid_id: 'DELETE',

      // Optional fields that will not always be set in regular imports
      kist_id: Number(obj.kist_id) || null,
      dag_id: null,
    }
    newobj._valid = errors.length === 0,
    newobj._errors = errors

    return newobj
  }
}

// console.log(new Flight({
//     departure_airport: 'test',
//     arrival_time: 'test',
// }))

// console.log(new Flight({
//     vertrek_vliegveld: 'test',
//     arrival_time: 'test',
// }))
