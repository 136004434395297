import React from "react";

export const DescriptionRow = ({ label, children, show = true }) => {
  if (!show) return null;

  return (
    <div className="row" style={{}}>
      {label && (
        <p className="capitalize label darkgray" style={{ marginRight: 6 }}>
          {label}
        </p>
      )}
      {children}
    </div>
  );
};
