import { QuestionOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";

import { RecencyExplanationModal } from "../RecencyExplanationModal.jsx";

export const RecencyTable = ({ title, children }) => {
  const [recencyModalVisible, showRecencyModal] = useState(false);

  return (
    <>
      <div className="row">
        <p
          className="clickable"
          onClick={() => showRecencyModal(true)}
          size="small"
        >
          {title}
        </p>
        <Button
          className="clickable"
          style={{ marginLeft: 6 }}
          shape="circle"
          onClick={() => showRecencyModal(true)}
          size="small"
        >
          <QuestionOutlined />
        </Button>
      </div>
      
      {recencyModalVisible && (
        <RecencyExplanationModal
          visible={recencyModalVisible}
          onClose={() => showRecencyModal(false)}
        />
      )}

      <table className="column" style={{ alignItems: "flex-start" }}>
        <tbody style={{ width: "100%" }}>
          {children}
        </tbody>
      </table>
    </>
  );
};
