import { exportFile } from "~/lib/exportFile.js"
import { splitText } from "~/pages/admin/dto/components/ExportCluster.js"


export const exportMaintenance = (maintenancePrograms, currentMaterial, profile, club) => {

    // Simplified scope of data, without ID's and initial order
    const data = maintenancePrograms.map(program => {
        console.log('program', program)
        return {
            name: program.name,
            description: program.description,
            remark: `TODO: after the import: Review the maintenance period, and please add the following part and connected documents:

Connected part: ${program.part_name || 'No part'}
Connected documents: ${program.documents_used.map(d => d.name).join(', ')}`,
            schedule: program.schedule,
            date_interval: program.date_interval,
            starts_interval: program.starts_interval,
            hours_interval: program.hours_interval,
            motor_hours_interval: program.motor_hours_interval,

            // tasks is already mapped through serializer
            tasks: program.tasks
        }
    }
    )

    const txt = `GlidingApp Export Maintenance program "${currentMaterial.name}"
Date exported: ${new Date()}

Aircraft type: ${currentMaterial.type}
Registration: ${currentMaterial.name}

---
Originating club: ${club.full_name}
Contactperson: ${profile.name} via ${profile.email}

Details to the program can be added here:
-
-
-
-


For questions, please email contact@gliding.app




TECHNICAL DATA - PLEASE DO NOT ALTER AFTER THIS
${splitText}
${JSON.stringify(data)}
`
    console.log('export', txt)
    exportFile(txt, `${club.name} - ${currentMaterial.type} - ${currentMaterial.name}.txt`)
}