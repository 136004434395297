import { CloudSyncOutlined } from "@ant-design/icons";
import { Alert, Divider, Form } from "antd";
import React, { useEffect, useState } from "react";
import { InputFileSingle } from "~/components/form/InputFileSingle.jsx";
import { $t } from "~/i18n.js";
import { generate_uuid } from "~/lib/helpers.js";
import { splitText } from "./ExportCluster.js";

export const ShowAlertDelete = () => {
  return <Alert
  style={{ marginTop: 12 }}
  type="warning"
  showIcon
  message={$t(
    "Let op bij het verwijderen van items, indien je deze verwijderd verlies je progressie van je leerlingen."
  )}
/>
}

function diffCheck(newList = [], oldList = [], fieldCheck = []) {
  // Load through new cluster to see new categories
  if (newList.length === 0) return oldList;

  let output = [];

  let newItems = newList.filter(
    (c_new) => !oldList.find((c_old) => c_old.name === c_new.name)
  );

  newItems.forEach((c_new) => {
    // add in default data
    output.push({
      ...c_new,
      action: "add",
      id: generate_uuid(),
      bevoegdheid: c_new.bevoegdheid?.map((b) => {
        return {
          ...b,
          action: "add",
          id: generate_uuid(),
        };
      }),
    });
  });

  // load in existing cluster, and see if it must be updated or not
  oldList.forEach((c_old) => {
    const newCatObj = newList.find((c_new) => c_old.name == c_new.name);

    let addedObject = {
      ...c_old,
      // update new fields along this
      ...newCatObj,
      action: "identical",

      // in each case we add in a diffcheck on the bevoegdheden
      old_bevoegdheid: c_old.bevoegdheid,
      new_bevoegdheid: newCatObj?.bevoegdheid,
    };

    if (!newCatObj) {
      addedObject["action"] = "update";

      addedObject["label"] = `${addedObject["label"] || addedObject["name"]} - ${$t("Verwijderd")}`}
    if (newCatObj) {
      // check if update is required
      const isUpdated = fieldCheck.some((field) => {
        return (newCatObj[field] || "") !== (c_old[field] || "");
      });
      if (isUpdated) addedObject["action"] = "update";
    }
    // in all cases, push old items to output
    output.push(addedObject);
  });
  return output;
}

export function UpgradeDTO({ cluster, updateCluster }) {
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (!value) return;

    const parts = value.split(splitText);
    if (parts.length < 1) {
      setError(true);
      return;
    }

    // split on variable and load in via json
    let newCluster = JSON.parse(parts[1]);

    // Load in data, with custom action and id
    let updatedCluster = {
      ...cluster,
      name: newCluster.name,
      description: newCluster.description,
      curriculum_versie: newCluster.curriculum_versie,

      category: diffCheck(newCluster.category, cluster.category, [
        "description",
        "label",
      ]).map((cat) => {
        if (cat.old_bevoegdheid && cat.new_bevoegdheid) {
          return {
            ...cat,
            bevoegdheid: diffCheck(cat.new_bevoegdheid, cat.old_bevoegdheid, [
              "label",
              "easa_key",
              "description",
              "endorse_title",
              "endorse_body",
              "geldigheid",
              "group_key",
              "scoring",
              "has_remark",
            ]),
          };
        }
        return cat;
      }),
    };

    setCompleted(true);
    updateCluster(updatedCluster);
    form.resetFields();
  }, [value]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setCompleted(false);
      setError(false);
      const reader = new FileReader();
      reader.onload = (e) => {
        setValue(e.target.result.toString());
      };
      reader.readAsText(file);
    }
  };

  return (
    <>
      <Divider />
      <p>
        <b>
          <CloudSyncOutlined /> {$t("Update")}
        </b>
        :{" "}
        {$t(
          "Update het programma met een nieuw curriculum vanuit een export (.txt formaat) bestand van een andere club of branchevereniging"
        )}
        . {$t("Gemaakte wijzigingen kunnen nog worden aangepast")}
      </p>

      <Form form={form}>
        <Form.Item
          name="file"
          rules={[{ required: false }]}
          onChange={handleFileChange}
        >
          <InputFileSingle accept=".txt" />
        </Form.Item>
      </Form>

      {error && (
        <Alert
          type="warning"
          message={$t(
            "Bestand is niet correct, zorg voor een goed import bestand."
          )}
        />
      )}

      {completed && (
        <Alert
          type="success"
          message={$t(
            "Bestand is ingeladen, bekijk de veranderingen en sla dan op."
          )}
        />
      )}

      {completed && (
        <ShowAlertDelete/>
      )}
    </>
  );
}
