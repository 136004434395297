import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { VerwijderButtonConfirm } from "~/components/buttons/VerwijderButtonConfirm.jsx";
import { api } from "~/lib/api.js";
import { daysActions } from "~/redux/actions.js";

export function RemoveDayButton({ dag_id, disabled, history, ...args }) {
  const dispatch = useDispatch();
  const [loadingVerwijder, setLoadingVerwijder] = useState(false);
  const verwijder = async () => {
    setLoadingVerwijder(true);
    const { data } = await api.delete("days.json", { dag_id });
    if (data) {
      await dispatch(daysActions.getAllDays());
      history.push("/");
    }
    setLoadingVerwijder(false);
  };

  return (
    <VerwijderButtonConfirm
      key="verwijder"
      loading={loadingVerwijder}
      onConfirm={verwijder}
      disabled={disabled}
      {...args}
    />
  );
}
