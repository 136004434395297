import { WarningOutlined } from "@ant-design/icons";
import { Collapse, List } from "antd";
import React from "react";
import { checkRecencyWarnings } from "~/components/recency/checkRecencyWarnings.js";
import { RecencyCurrencyBarometer } from "~/components/recency/RecencyCurrencyBarometer.jsx";
import { $t } from "~/i18n.js";
import { PilotAvatar } from "~/pages/ledenlijst/PilotAvatar.jsx";

const { Panel } = Collapse;

export const RecencyWarnings = ({ aanmeldingen, setOpenPilot }) => {
  const vliegersWithWarnings = aanmeldingen.filter(
    (a) => a.aangemeld && a.vlieger && checkRecencyWarnings(a.vlieger)
  );
  if (vliegersWithWarnings.length === 0) return null;

  return (
    <Collapse
      defaultActiveKey={["1"]}
      style={{ marginBottom: 16, maxWidth: 750 }}
      size="small"
    >
      <Panel
        header={
          <div>
            <WarningOutlined style={{ color: "#faad14", marginRight: 8 }} />
            {$t("Recency Warnings")} ({vliegersWithWarnings.length})
          </div>
        }
        key="1"
      >
        <List
          size="small"
          dataSource={vliegersWithWarnings}
          renderItem={(item) => (
            <List.Item
              className="clickable"
              onClick={() => setOpenPilot(item.vlieger)}
            >
              <List.Item.Meta
                avatar={<PilotAvatar vlieger={item.vlieger} />}
                title={item.vlieger.name}
              />

              <div style={{ width: 100 }}>
                <RecencyCurrencyBarometer pilot={item.vlieger} />
              </div>
            </List.Item>
          )}
        />
      </Panel>
    </Collapse>
  );
};
