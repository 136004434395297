import { EditOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons'
import { Button, Divider, Switch, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataCard } from '~/components/dataCards/dataCard.jsx'
import { MemberModal } from './memberModal.jsx'
import { $t } from '~/i18n.js'

const roles = {
  arc_technician: { label: 'ARC technicus', color: 'gold' },
  technician: { label: 'Technicus', color: 'green' },
  admin: { label: 'Admin', color: 'orange' },
  manager: { label: 'Projectmanager', color: 'cyan' },
  member: { label: 'Projectlid', color: 'default' }
}

export const MemberRow = ({ member, setEditMember }) => (
  <div
    className='row'
    style={{
      justifyContent: 'space-between',
      gap: 12,
      paddingBottom: 3,
      marginBottom: 6,
      borderBottom: '1px solid #f0f0f0',
      opacity: member.action === 'delete' ? 0.3 : 1
    }}
  >
    <p>{member.user?.name}</p>

    <Tag color={roles[member.role].color}>{roles[member.role].label}</Tag>

    <div className='row' style={{ marginLeft: 'auto', gap: 6 }}>
      {member.action && (
        <>
          {member.action === 'delete'
            ? (
              <Tag icon={<StopOutlined />} color='warning' />
              )
            : (
              <Tag color='green'>{member.action}</Tag>
              )}
        </>
      )}

      {setEditMember && (
        <Button
          shape='circle'
          icon={<EditOutlined />}
          size='small'
          onClick={() =>
            member && setEditMember({ ...member, action: 'wijzig' })}
        />
      )}
    </div>
  </div>
)

export const CardMembers = ({ saveData, readOnly, members, kist }) => {
  const { profile } = useSelector((state) => state.persist)
  const [loading, setLoading] = useState(false)
  const [editMember, setEditMember] = useState(null)

  const [visible_for_technicians, setVisibleForAll] = useState(false)
  useEffect(() => {
    setVisibleForAll(kist?.visible_for_technicians)
  }, [kist])

  const isClub =
    !kist || kist?.category === 'sleep' || kist?.category === 'club'

  const [memberList, setMemberList] = useState([])
  useEffect(() => {
    setMemberList(members)
  }, [members])

  const triggerSave = async ({ member }) => {
    setLoading(true)
    const res = await saveData({
      members: [member],
      kist: { visible_for_technicians }
    })
    setLoading(false)
    setEditMember(null)
    return res
  }

  const saveVisible = async () => {
    setLoading(true)
    const res = await saveData({
      kist: { visible_for_technicians }
    })
    setLoading(false)
    return res
  }

  return (
    <DataCard
      title='Team'
      readOnly={readOnly}
      loading={loading}
      onSave={saveVisible}
    >
      <MemberModal
        loading={loading}
        currentProfile={profile}
        isClub={isClub}
        saveMember={triggerSave}
        member={editMember}
        closeModal={() => setEditMember(null)}
      />

      {!readOnly && (
        <Button
          onClick={() =>
            setEditMember({
              role: 'member',
              action: 'nieuw',
              user: { group_names: [] }
            })}
          size='small'
          icon={<PlusOutlined />}
        >
          {$t('Voeg toe')}
        </Button>
      )}

      <div
        style={{ marginTop: 12, maxHeight: 275, overflowY: 'auto', width: 320 }}
      >
        {memberList.map((member, idx) => (
          <MemberRow
            key={idx}
            member={member}
            setEditMember={
              !readOnly && profile.id !== member.user?.id && setEditMember
            }
          />
        ))}

        {memberList?.length === 0 && <p>{$t('Geen gebruikers toegevoegd aan team')}</p>}
      </div>

      <Divider style={{ margin: '24px 0' }} />

      {isClub
        ? (
          <p>
            <span className='bold'>{$t('Clubmateriaal')}</span>:{' '}
            {$t(
              'voor alle leden in te zien en alle technici mogen wijzigingen doen.'
            )}
          </p>
          )
        : (
          <p>
            <span className='bold'>{$t('Prive materiaal')}</span>:{' '}
            {$t(
              'alleen toegevoegde leden (en admin gebruikers) kunnen het materiaal inzien.'
            )}
          </p>
          )}

      {!isClub && kist && (
        <div className='row' style={{ margin: '12px 0', gap: 12 }}>
          <p>{$t('Bewerkbaar voor alle technici')}:</p>
          <Switch
            disabled={readOnly}
            checkedChildren='Ja'
            unCheckedChildren='Nee'
            checked={visible_for_technicians}
            onChange={(newValue) => setVisibleForAll(newValue)}
          />
        </div>
      )}
    </DataCard>
  )
}
