import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Popconfirm, Table, Tag } from "antd";
import { $t } from "~/i18n.js";
import { _parseDate } from "~/lib/localize.js";
import { HelpBubble } from "~/components/help/helpBubble.jsx";

export const NewsColumns = (
  triggerPublish,
  triggerArchive,
  setActiveNewsID
) => {
  return [
    { title: $t("Titel"), dataIndex: "title" },
    {
      title: $t("Zichtbaarheid"),
      render: (_, n) => {
        const today = new Date();

        const validTo = new Date(n.valid_to);
        if (validTo < today) {
          return (
            <Tag color="red">
              {$t("Verlopen")} {_parseDate(n.valid_to)}
            </Tag>
          );
        }

        const validFrom = new Date(n.valid_from);
        if (validFrom > today) {
          return (
            <Tag color="gold">
              {$t("Vanaf")} {_parseDate(n.valid_from)}
            </Tag>
          );
        }

        return (
          <p>
            {_parseDate(n.valid_from)} - {_parseDate(n.valid_to)}
          </p>
        );
      },
    },
    {
      title: $t("Status"),
      render: (_, news) => {
        if (news.status === "published")
          return <Tag color="green">{$t("Gepubliceerd")}</Tag>;
        if (news.status === "archived") return <Tag>{$t("Gearchiveerd")}</Tag>;
        return <Tag color="blue">{$t("Concept")}</Tag>;
      },
    },
    {
      title: (
        <p>
          {$t("Gelezen")}{" "}
          <HelpBubble
            content={$t(
              "Aantal gebruikers die het gelezen heeft over de totaal aantal gebruikers"
            )}
          />
        </p>
      ),
      render: (_, news) => {
        return (
          <p>
            {news.total_read} / {news.total_users}
          </p>
        );
      },
    },
    {
      title: "",
      render: (_, news) => {
        return (
          <div style={{ width: 200 }}>
            <Button
              size="small"
              type="default"
              onClick={() => setActiveNewsID(news.id)}
              style={{ marginRight: 6 }}
            >
              {$t("Bewerken")}
            </Button>

            {news.status === "draft" && (
              <Button
                size="small"
                type="primary"
                onClick={() => triggerPublish(news)}
                style={{ marginRight: 6 }}
              >
                {$t("Publiceren")}
              </Button>
            )}

            {news.status === "published" && (
              <Button
                size="small"
                type="default"
                onClick={() => triggerArchive(news)}
              >
                {$t("Archiveren")}
              </Button>
            )}

            {news.status === "archived" && (
              <Button
                size="small"
                onClick={() => triggerPublish(news)}
                style={{ marginRight: 6 }}
              >
                {$t("Terughalen")}
              </Button>
            )}
          </div>
        );
      },
    },
  ];
};
