import React from "react";
import { render } from "react-dom";
import { App } from "./App.jsx";
import "./components/pwa/register";
import { config } from "./config.js";
import { i18n } from "./i18n.js";
import { api } from "./lib/api.js";
import { setupSentry } from "./lib/sentry.js";
import { text_to_speech } from "./lib/speech.js";
import { store } from "./store.jsx";
import "./theme/main.css";

// Init prelim stuff
if (config.sentry_key && !window.location.href.includes('localhost')) {
  setupSentry(config.sentry_key, config.mode);
}

// Expose some to the CLI
window.zweef = {
  config,

  // Useful to quickly test out actions like: zweef.store.dispatch({type: 'RESET_PERSIST_STATE'})
  i18n,
  store,
  state: () => store.getState(),
  api,
  speech: text_to_speech,
};

// Simple determinant if URL is zweef or glide, used for logo's etc.
window.isZweef = () => window.location.href.indexOf("zweef") > -1;

render(<App store={store} />, document.getElementById("root"));
