import { CheckCircleFilled } from "@ant-design/icons";
import React from "react"
import { $t } from "~/i18n.js"
import { colors } from "~/theme/colors.js"
import { HelpBubble } from "~/components/help/helpBubble.jsx"
import { RecencyTable } from "./components/RecencyTable.jsx"
import { RecencyRowTd } from "./components/RecencyRow.jsx"
import {
  mapRecency,
  minRecencyValidThrough,
  setAlertDate,
} from "./components/helpers.js";
import { RecencyValidThrough } from "./components/RecencyValidThrough.jsx";
import { DateFormat } from "../date/DateFormat.jsx";

export const createTMGRules = (recency) => [
  {
    label: $t("TMG training"),
    deadline: 120,
    value: recency.tmg_check,
    min: 1,
    valid_through: recency.tmg_check_valid_to,
  },
  {
    label: $t("TMG starts"),
    deadline: 120,
    value: recency.tmg_starts,
    min: 12,
    valid_through: recency.tmg_starts_valid_to,
  },
  {
    label: $t("TMG uren"),
    deadline: 120,
    value: parseInt(recency.tmg_uren / 6) / 10,
    min: 6,
    valid_through: recency.tmg_uren_valid_to,
  },
  {
    label: $t("TMG Aerotow"),
    deadline: 120,
    value: recency["tmg-a"],
    min: 5,
    valid_through: recency["tmg-a_valid_to"],
  },
  {
    label: $t("TMG Passagier (${days} dagen)", { days: 90 }),
    deadline: 0,
    value: recency.tmg_pax,
    min: 3,
    valid_through: recency.tmg_pax_valid_to,
  },
];

export const TMGRecencyTable = ({ recency, showOnlyInvalid = false }) => {
  const items = createTMGRules(recency)
    .map(mapRecency)
    .filter((e) => e.isUsed);

  const _items = showOnlyInvalid
    ? items.filter((item) => !item.is_valid || item.alert_date)
    : items;

  const minValidThrough = minRecencyValidThrough(items);

  const limitToOnlyHeader =
    recency.tmgaantekening !== "invalid" && _items.length === 0;
  
  const body = (
    <>
      <tr style={{ width: "100%" }}>
        <td className="col bold">{$t("TMG recency")}</td>
        <td className="col" />

        {RecencyValidThrough({
          is_invalid: recency.tmgaantekening === "invalid",
          valid_through: minValidThrough,
          alert_date: setAlertDate(120, minValidThrough),
        })}
      </tr>

      {!limitToOnlyHeader && (
        <>
          {recency.tmg_profcheck && (
            <tr>
              <td className="col">{$t("Proficiency check (AoC)")}</td>
              <td className="small">
                <DateFormat
                  date={recency.tmg_profcheck}
                  options={{ month: "short", day: "2-digit", year: "2-digit" }}
                />
              </td>
              {RecencyValidThrough({
                is_invalid: false,
                valid_through: recency.tmg_profcheck_valid_to,
                alert_date: setAlertDate(120, recency.tmg_profcheck_valid_to),
              })}
            </tr>
          )}

          {!showOnlyInvalid && recency.tmg_excemption == "invalid" && (
            <tr>
              <td className="col">
                FCL{" "}
                <HelpBubble
                  content={$t(
                    "Bij een geldige FCL licentie met geldige TMG privilege hoeft de vlieger niet te voldoen aan een minimum uren en starts om de TMG aantekening geldig te houden"
                  )}
                />
              </td>
              <td> </td>
              <td>
                <CheckCircleFilled style={{ color: colors.groen_dark }} />
              </td>
            </tr>
          )}

          {_items.map((item, index) => (
            <tr key={index}>
              <RecencyRowTd item={item} />
            </tr>
          ))}
        </>
      )}
    </>
  );
  if (showOnlyInvalid) return body;
  return (
    <RecencyTable title={$t("EASA TMG recency, afgelopen 24 maanden:")}>
      {body}
    </RecencyTable>
  );
};