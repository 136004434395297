import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Input,
  Button,
  Card,
  List,
  Pagination,
  message,
  Layout,
  Switch,
} from "antd";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { i18n } from "~/i18n.js";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { api } from "~/lib/api.js";
import { $t } from "~/i18n.js";

const { Option } = Select;

export const Translation = () => {
  const [form] = Form.useForm();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [translations, setTranslations] = useState({});
  const [baseTranslations, setBaseTranslations] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filledTranslations, setFilledTranslations] = useState({});
  const [club, setClub] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showBaseKeys, setShowBaseKeys] = useState(false);

  const dispatch = useDispatch();
  const languages = i18n.languages;
  const pageSize = 10;

  const fetchTranslations = async () => {
    setLoading(true);
    const data = await api.get("admin/club_settings.json");
    if (data) {
      setClub(data);
    }
    setLoading(false);
  };

  const handleLanguageChange = (value = "") => {
    setSelectedLanguage(value);
    form.resetFields(["translations"]);
    setShowBaseKeys(false);
  };

  const updateFilledTranslations = () => {
    const filled = {};
    Object.entries(form.getFieldValue("translations") || {}).forEach(
      ([key, value]) => {
        if (value?.trim()) {
          filled[key] = value.trim();
        }
      }
    );
    setFilledTranslations(filled);
  };

  const saveTranslations = async (values) => {
    setSaving(true);
    const data = await api.post("admin/club_settings.json", {
      translation: {
        ...club.translation,
        [selectedLanguage]: filledTranslations,
      },
    });
    if (data) {
      setClub(data);
      dispatch({ type: "SET_CLUB", club: data });
    }
    setSaving(false);
    message.success($t("Translations saved successfully"));
  };

  const loadBaseKeys = (lang) => {
    const baseKeys =
      i18next.getResourceBundle(i18next.language, "translations") || {};
    setBaseTranslations(baseKeys);
  };

  const filteredTranslations = Object.entries(
    showBaseKeys ? baseTranslations : translations
  ).filter(([key, value]) =>
    value.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedTranslations = filteredTranslations.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const clearInput = (key) => {
    const updatedTranslations = { ...form.getFieldValue("translations") };
    delete updatedTranslations[key];
    form.setFieldsValue({ translations: updatedTranslations });
    updateFilledTranslations();
  };

  useEffect(() => {
    if (club) {
      const clubTranslations = club.translation?.[selectedLanguage] || {};
      setTranslations(clubTranslations);
      form.setFieldsValue({ translations: clubTranslations });
    }
  }, [selectedLanguage, club]);

  useEffect(() => {
    fetchTranslations();
  }, []);

  useEffect(() => {
    if (selectedLanguage) {
      loadBaseKeys(selectedLanguage);
    }
  }, [selectedLanguage]);

  function getBaseTranslationByKey(key = "") {
    if (!key) {
      console.error("Translation key is required.");
      return null;
    }
    // Fetch the translation for the specific key from the already populated baseTranslations
    return baseTranslations[key] || null; // Return null if the key doesn't exist
  }

  return (
    <Form
      form={form}
      onFinish={saveTranslations}
      onValuesChange={updateFilledTranslations}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      colon={false}
    >
      <Card title="Translation Settings">
        <Form.Item
          name="language"
          label="Select Language"
          rules={[{ required: true, message: "Please select a language" }]}
        >
          <Select
            onChange={handleLanguageChange}
            allowClear
            style={{ maxWidth: 300 }}
          >
            {languages.map((lang) => (
              <Option key={lang} value={lang}>
                {lang.toUpperCase()}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Card>

      <Card
        title="Custom translations"
        extra={
          <Switch
            checked={showBaseKeys}
            onChange={setShowBaseKeys}
            checkedChildren="Show all Keys"
            unCheckedChildren="Show Custom Keys"
          />
        }
      >
        {selectedLanguage && (
          <>
            <Form.Item label="Filter translation" style={{ width: "100%" }}>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search for translation"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
                style={{ maxWidth: 300, marginBottom: 16 }}
                suffix={
                  searchTerm && (
                    <CloseCircleOutlined
                      onClick={() => setSearchTerm("")}
                      style={{ cursor: "pointer" }}
                    />
                  )
                }
              />
            </Form.Item>
            <List
              dataSource={paginatedTranslations}
              renderItem={([key, value]) => (
                <List.Item key={key}>
                  <Form.Item
                    label={getBaseTranslationByKey(key)}
                    name={["translations", key]}
                    style={{ width: "100%" }}
                  >
                    <Input
                      placeholder={value}
                      allowClear={{
                        clearIcon: (
                          <CloseCircleOutlined
                            onClick={() => clearInput(key)}
                          />
                        ),
                      }}
                    />
                  </Form.Item>
                </List.Item>
              )}
            />
            <Pagination
              current={currentPage}
              total={filteredTranslations.length}
              pageSize={pageSize}
              onChange={setCurrentPage}
              style={{ marginTop: 16, textAlign: "right" }}
            />
          </>
        )}
      </Card>

      <Layout.Footer
        style={{
          width: "100%",
          background: "white",
          position: "fixed",
          bottom: "0",
          display: "flex",
          marginLeft: -10,
          alignItems: "center",
          height: "40px",
          borderTop: "1px solid #e8e8e8",
        }}
      >
        <Button
          disabled={loading}
          loading={saving}
          type="primary"
          htmlType="submit"
        >
          {$t("Opslaan")}
        </Button>
      </Layout.Footer>
    </Form>
  );
};
