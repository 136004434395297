import React, { useState } from "react";
import { $t } from "~/i18n.js";

import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { VerwijderButtonConfirm } from "~/components/buttons/VerwijderButtonConfirm.jsx";
import { SelectTagWithAddNew } from "~/components/form/SelectTagWithAddNew.jsx";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";
import { HelpBubble } from "~/components/help/helpBubble.jsx";

export const MatChapterModal = ({
  closeModal,
  chapter,
  saveChapter,
  allTags,
}) => {
  const [form] = Form.useForm();

  const is_public = Form.useWatch("is_public", form);

  const chapterExists = chapter && chapter.id;

  React.useEffect(() => {
    if (chapter) form.setFieldsValue(chapter);
    return () => form.resetFields();
  }, [chapter, form]);

  const [saving, setSaving] = useState(null);

  const deleteChapter = async () => {
    setSaving("delete");
    const result = await saveChapter({ method: "DELETE", id: chapter.id });
    result && closeModal();
    setSaving(null);
  };

  const herstelChapter = async () => {
    setSaving("herstel");
    const result = await saveChapter({
      method: "PUT",
      id: chapter.id,
      is_archived: false,
    });
    result && closeModal();
    setSaving(null);
  };

  const triggerSave = async () => {
    try {
      setSaving("save");
      const values = await form.validateFields();
      const result = await saveChapter({
        method: chapter.id ? "PUT" : "POST",
        ...chapter,
        ...values,
      });
      if (result) {
        closeModal();
        form.resetFields();
      }
      setSaving(null);
    } catch (info) {
      console.log("Validate Failed:", info);
      setSaving(null);
    }
  };

  return (
    <Modal
      title={
        chapterExists ? $t("Pas hoofdstuk aan") : $t("Voeg nieuw hoofdstuk toe")
      }
      open={!!chapter}
      onCancel={closeModal}
      okText={$t("Opslaan")}
      cancelText={$t("Annuleren")}
      footer={[
        chapterExists && (
          <VerwijderButtonConfirm
            buttontext={
              chapter.is_archived ? $t("Verwijderen") : $t("Archiveren")
            }
            key="delete"
            title={$t(
              "Zeker weten dat je alle documenten hieronder wilt ${verwijderen}?",
              {
                verwijderen: chapter.is_archived
                  ? $t("Verwijderen")
                  : $t("Archiveren"),
              }
            )}
            onConfirm={deleteChapter}
          />
        ),
        chapterExists && chapter.is_archived && (
          <Button
            key="herstel"
            onClick={herstelChapter}
            loading={saving === "herstel"}
          >
            {$t("Herstel")}
          </Button>
        ),
        <AnnuleerButton key="annuleren" onClick={closeModal} />,
        <SaveButton
          key="save"
          onClick={triggerSave}
          loading={saving === "save"}
        />,
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="hoofstuk"
        preserve={false}
        requiredMark={false}
        initialValues={{ ...chapter }}
      >
        <Form.Item
          label={$t("Naam")}
          name="name"
          rules={[
            {
              required: true,
              max: 50,
              message: $t("Maximaal ${c} characters", { c: 50 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={$t("Positie")} name="order">
          <Input type="number" prefix="#" />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Cluster")}
              <HelpBubble
                content={$t(
                  "Met de cluster kan je snel filteren bovenin de documenten."
                )}
              />
            </p>
          }
          name="tag"
          rules={[
            {
              required: false,
              max: 20,
              message: $t("Maximaal ${c} characters", { c: 20 }),
            },
          ]}
        >
          <SelectTagWithAddNew allTags={allTags} />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {is_public ? <EyeOutlined /> : <EyeInvisibleOutlined />}{" "}
              {$t("Is openbaar")}{" "}
              <HelpBubble
                content={$t(
                  "Standard zijn documenten alleen zichtbaar voor ingelogde leden, zet dit aan als je wilt dat mensen via de QR code de documenten mogen inzien, zoals boorddocumenten"
                )}
              />
            </p>
          }
          name="is_public"
        >
          <SwitchYesNo />
        </Form.Item>
      </Form>
    </Modal>
  );
};
