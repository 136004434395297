import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Input, Switch, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { $t } from '~/i18n.js'

import { MainLayout } from '~/components/layout/layout.jsx'
import { SearchTags } from '~/components/searchTag/searchTag.jsx'
import { adminActions } from '~/redux/actions.js'

import { selectElementByID, updateIfNeeded } from '~/lib/helpers.js'

import { Chapter } from "./sections/chapterCard.jsx";
import { ChapterModal } from "./sections/chapterModal.jsx";
import { TabModal } from "./sections/tabModal.jsx";

import "./documents.css";

const DocumentsComponent = ({
  history,
  match,
  persist,
  admin,
  getDocuments,
  saveTab,
  saveChapter,
  saveDocument,
}) => {
  const [search, setSearch] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [readOnly, setReadOnlyMode] = useState(true);

  // get tabID from URL
  useEffect(() => {
    if (admin.tabs.length > 0) {
      if (!match.params?.tabID) return setActiveTab(admin.tabs[0]);

      const tabID = parseInt(match.params.tabID);
      let active = selectElementByID(admin.tabs, "id", tabID);
      setActiveTab(active || admin.tabs[0]);
    }
  }, [match.params?.tabID, activeTab, admin.tabs]);

  // get documents
  useEffect(() => {
    updateIfNeeded(
      admin.documentsUpdated,
      getDocuments,
      (tabs) => tabs && tabs.length > 0 && setActiveTab(tabs[0])
    );
  }, [admin.documentsUpdated, getDocuments]);
  useEffect(() => {
    !activeTab &&
      admin.tabs &&
      admin.tabs.length > 0 &&
      setActiveTab(admin.tabs[0]);
  }, []);

  const [openTab, setOpenTab] = useState(null);
  const [openChapter, setOpenChapter] = useState(null);

  const [searchTag, setSearchTag] = useState([]);

  // reduce unique cluster names in tabs
  let allTags = {};
  admin.tabs.forEach((t) => (allTags[t.cluster] = 1));
  allTags = Object.keys(allTags).map((v) => v);

  const tabs = admin.tabs.filter((row) => {
    return searchTag?.length === 0 || searchTag.some((t) => row.cluster === t);
  });

  const items = tabs?.map((tab) => {
    // see if any documents are in tab, otherwise filter out
    const getCount = (total, chapter) =>
      total +
      chapter.documents?.filter(
        (f) => f.name.toLowerCase().indexOf(search) > -1
      )?.length;
    const count = !search ? 0 : tab?.chapters?.reduce(getCount, 0);

    if (search && count === 0) return null;

    return {
      key: tab.id,
      label: tab.name,
      children: (
        <div>
          {!readOnly && (
            <div>
              <Button
                style={{ marginLeft: 4 }}
                icon={<EditOutlined />}
                onClick={() => setOpenTab(activeTab)}
              >
                {$t("Wijzig tab")}
              </Button>
              <Button
                style={{ marginLeft: 4 }}
                icon={<PlusOutlined />}
                onClick={() => setOpenChapter({ name: "", order: 0 })}
              >
                {$t("Voeg hoofstuk toe")}
              </Button>
            </div>
          )}

          <div
            className="row"
            style={{ flexWrap: "wrap", alignItems: "flex-start" }}
          >
            {tab.chapters.map((chapter, key) => (
              <Chapter
                allChapters={tab.chapters}
                key={key}
                readOnly={readOnly}
                chapter={chapter}
                saveDocument={saveDocument}
                search={search}
                editChapter={(c) => setOpenChapter(c)}
              />
            ))}
          </div>
        </div>
      ),
    };
  });

  return (
    <MainLayout history={history} isLoading={admin.isPending}>
      <div
        className="row"
        style={{ flexWrap: "wrap", justifyContent: "space-between" }}
      >
        <Breadcrumb className="row spacer">
          <Breadcrumb.Item>
            <p className="title">{$t("Documenten")}</p>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      {persist.profile.wijzig_documenten && (
        <div className="row" style={{ height: 30 }}>
          <p className="spacer">
            {$t("Wijzig")}:
            <Switch
              size="small"
              style={{ marginLeft: 4 }}
              onChange={(checked) => setReadOnlyMode(!checked)}
              checked={!readOnly}
            />
            {!readOnly && (
              <Button
                style={{ marginLeft: 24 }}
                icon={<PlusOutlined />}
                onClick={() =>
                  setOpenTab({ name: "", order: 0, group_key: "zweefvlieger" })
                }
              >
                {$t("Voeg Tab toe")}
              </Button>
            )}
          </p>
        </div>
      )}

      <div className="row" style={{ gap: 3 }}>
        <Input.Search
          style={{ maxWidth: 250 }}
          allowClear
          size="small"
          placeholder={$t("Zoek op naam")}
          onChange={(event) => setSearch(event.target.value.toLowerCase())}
        />

        {allTags?.length > 1 && (
          <SearchTags
            items={allTags.map((t) => {
              return { name: t, color: "default" };
            })}
            searchTags={searchTag}
            setSearchTags={setSearchTag}
          />
        )}
      </div>

      {openTab && (
        <TabModal
          tab={openTab}
          closeModal={() => setOpenTab(null)}
          saveTab={saveTab}
          allTags={allTags}
        />
      )}

      {openChapter && (
        <ChapterModal
          allTabs={admin.tabs}
          chapter={openChapter}
          tab={activeTab}
          closeModal={() => setOpenChapter(null)}
          saveChapter={saveChapter}
        />
      )}

      {activeTab && (
        <Tabs
          style={{ marginTop: 12 }}
          onChange={(id) => history.push("/documents/" + id)}
          activeKey={activeTab.id}
          items={items}
        />
      )}
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  persist: state.persist,
  admin: state.admin
})

const mapDispatchToProps = (dispatch) => {
  return {
    getDocuments: (d) => dispatch(adminActions.getDocuments(d)),
    saveTab: (d) => dispatch(adminActions.saveTab(d)),
    saveChapter: (d) => dispatch(adminActions.saveChapter(d)),
    saveDocument: (d) => dispatch(adminActions.saveDocument(d))
  }
}

export const DocumentsPagina = connect(mapStateToProps, mapDispatchToProps)(DocumentsComponent)
