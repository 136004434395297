import React from "react";
import { $t } from "~/i18n.js";
import { RecencyRowTd } from "./components/RecencyRow.jsx";
import { RecencyTable } from "./components/RecencyTable.jsx";
import {
  mapRecency,
  minRecencyValidThrough,
  setAlertDate,
} from "./components/helpers.js";
import { RecencyValidThrough } from "./components/RecencyValidThrough.jsx";
import { DateFormat } from "../date/DateFormat.jsx";

export const createSPLRules = (recency) => {
  return [
    {
      label: $t("Trainingsvluchten"),
      value: recency.checks,
      min: 2,
      valid_through: recency.checks_valid_to,
      deadline: 120,
    },
    {
      label: $t("Starts"),
      value: recency.starts,
      min: 15,
      valid_through: recency.starts_valid_to,
      deadline: 120,
    },
    {
      label: $t("Uren"),
      value: parseInt(recency.vliegduur / 6) / 10,
      min: 5,
      valid_through: recency.vliegduur_valid_to,
      deadline: 120,
    },
    {
      label: $t("Lierstart"),
      value: recency.lier,
      min: 5,
      valid_through: recency.lier_valid_to,
      deadline: 120,
    },
    {
      label: $t("Sleepstart"),
      value: recency.sleep,
      min: 5,
      valid_through: recency.sleep_valid_to,
      deadline: 120,
    },
    {
      label: $t("Zelfstart"),
      value: recency.zelfstart,
      min: 5,
      valid_through: recency.zelfstart_valid_to,
      deadline: 120,
    },
    {
      label: $t("Bungeestart"),
      value: recency.bungee,
      min: 2,
      valid_through: recency.bungee_valid_to,
      deadline: 120,
    },
    {
      label: $t("Passagier (90 dagen)"),
      value: recency.pax,
      min: 3,
      valid_through: recency.pax_valid_to,
      deadline: 0,
    },
  ];
};

export const SPLRecencyTable = ({ recency, showOnlyInvalid = false }) => {
  const items = createSPLRules(recency)
    .map(mapRecency)
    .filter((e) => e.isUsed);

  const minValidThrough = minRecencyValidThrough(items);

  const _items = showOnlyInvalid
    ? items.filter((item) => !item.is_valid || item.alert_date)
    : items;

  const limitToOnlyHeader =
    showOnlyInvalid && recency.spl !== "invalid" && _items.length === 0;

  const body = (
    <>
      <tr style={{ width: "100%" }}>
        <td className="col bold">{$t("SPL recency")}</td>
        <td className="col" />
        {RecencyValidThrough({
          is_invalid: recency.spl === "invalid",
          valid_through: minValidThrough,
          alert_date: setAlertDate(120, minValidThrough),
        })}
      </tr>

      {!limitToOnlyHeader && (
        <>
          {recency.spl_profcheck && (
            <tr>
              <td className="col">{$t("Proficiency check (AoC)")}</td>
              <td className="small">
                <DateFormat
                  date={recency.spl_profcheck}
                  options={{ month: "short", day: "2-digit", year: "2-digit" }}
                />
              </td>
              {RecencyValidThrough({
                is_invalid: false,
                valid_through: recency.spl_profcheck_valid_to,
                alert_date: setAlertDate(120, recency.spl_profcheck_valid_to),
              })}
            </tr>
          )}

          {_items.map((item, index) => (
            <tr key={index}>
              <RecencyRowTd item={item} />
            </tr>
          ))}
        </>
      )}
    </>
  );

  if (showOnlyInvalid) return body;
  return (
    <RecencyTable title={$t("SFCL SPL recency, last 24 months")}>
      {body}
    </RecencyTable>
  );
};
