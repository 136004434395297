import React from "react";
import { Link } from "react-router-dom";
import { $t } from "~/i18n.js";
import {
  getUserAvatar,
  getUserAvatarError,
} from "../../profile/selectPilot.jsx";
import { DropdownMenu } from "~/components/menu/DropdownMenu.jsx";

export function ProfileMenu({ persist }) {
  const menuItems = [
    {
      label: (
        <p>
          {$t("Welkom")} {persist.profile.first_name}
        </p>
      ),
    },
    {type: 'divider'},
    {
      label: (
        <Link to="/my_starts">
          {$t("Mijn starts")}
        </Link>
      ),
    },
    {
      label: (
        <Link to="/my_maintenance">
          {$t("Mijn onderhoud")}
        </Link>
      ),
    },
    {
      label: (
        <Link to="/my_news">
          {$t("Mijn nieuws")}
        </Link>
      ),
    },
    {
      label: (
        <Link to="/my_details">
          {$t("Mijn gegevens")}
        </Link>
      ),
    },
    {type: 'divider'},
    {
      label: (
        <Link to="/logout">
          {$t("Uitloggen")}
        </Link>
      ),
    },
  ].filter(f => f);

  return (
    <DropdownMenu trigger={<img
      className="profileLink"
      alt=""
      src={getUserAvatar(persist.profile, true)}
      onError={getUserAvatarError}
      style={{
        height: 35,
        width: 35,
        borderRadius: '50%',
        backgroundColor: 'white',
        
      }}
    />}
    items={menuItems}
    />
  );
}
