import React from 'react'
import { useDispatch } from 'react-redux'
import { saveMaterialTab } from '~/redux/materiaal/materiaal.actions.js'
import { CardGeneral } from './cardGeneral.jsx'
import { CardMembers } from './cardMembers.jsx'
import { CardRegister } from './cardRegister.jsx'
import { DeleteMaterial } from './deleteMaterial.jsx'
import { CardInspection } from './cardInspection.jsx'
import { CardExport } from './cardExport.jsx'

export const MaterialData = ({
  readOnly,
  currentMaterial,
  kist,
  members,
  history,
  hasAccess,
  wijzig_material
}) => {
  const dispatch = useDispatch()
  const saveData = async (payload) => {
    return await dispatch(
      saveMaterialTab({
        activeTab: 'data',
        materialID: currentMaterial.id,
        payload
      })
    )
  }

  return (
    <div>
      <div className="MyData">
        <CardGeneral
          readOnly={readOnly}
          currentMaterial={currentMaterial}
          kist={kist}
          saveData={saveData}
        />

        {kist && (
          <CardRegister readOnly={readOnly} saveData={saveData} kist={kist} />
        )}

        {hasAccess && (
          <CardMembers
            readOnly={readOnly}
            saveData={saveData}
            members={members}
            kist={kist}
            currentMaterial={currentMaterial}
          />
        )}

        {!readOnly && (
          <CardInspection
            saveData={saveData}
            currentMaterial={currentMaterial}
          />
        )}

        {!readOnly && (
          <CardExport
            saveData={saveData}
            currentMaterial={currentMaterial}
          />
        )}
      </div>

      {wijzig_material && (
        <DeleteMaterial
          readOnly={!wijzig_material}
          currentMaterial={currentMaterial}
          saveData={saveData}
          history={history}
        />
      )}
    </div>
  )
}
