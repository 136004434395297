import { DownloadOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Switch } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { HelpBubble } from "~/components/help/helpBubble.jsx";
import { $t } from "~/i18n.js";
import { ExportCluster } from "./ExportCluster.js";
import { SharedDTOgooglelink } from "./ImportDialogDTO.jsx";
import { UpgradeDTO } from "./UpgradeDTO.jsx";

export const ClusterForm = ({ form, clusterData, updateCluster }) => {
  const { profile, club } = useSelector((state) => state.persist);

  return (
    <div>
      <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="cluster"
      preserve={false}
      requiredMark={false}
    >
      <Form.Item
        label={
          <p>
            {$t("Volgorde")}{" "}
            <HelpBubble
              content={$t(
                "Volgorde waarop programma zichtbaar is in het profiel"
              )}
            />
          </p>
        }
        name="order"
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label={$t("Naam")}
        name="name"
        rules={[
          {
            required: true,
            max: 99,
            message: $t("Maximaal ${max} characters", { max: 100 }),
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label={$t("Curriculum versie")}
        name="curriculum_versie"
        rules={[
          {
            required: false,
            max: 299,
            message: $t("Maximaal ${max} characters", { max: 300 }),
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label={$t("Beschrijving")}
        name="description"
        rules={[
          {
            required: false,
            max: 299,
            message: $t("Maximaal ${max} characters", { max: 300 }),
          },
        ]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label={
          <p>
            {$t("Is Training")}{" "}
            <HelpBubble
              content={$t(
                "Indien het een (DTO) training programma is dan kan je hem los activeren per gebruiker, anders is hij voor alle gebruikers zichtbaar"
              )}
            />
          </p>
        }
        valuePropName="checked"
        name="is_dto"
      >
        <Switch checkedChildren={$t("Ja")} unCheckedChildren={$t("Nee")} />
      </Form.Item>

      <Divider />

      <p>
        <b>{$t("Export")}</b>:{" "}
        {$t(
          "Het export bestand (.txt formaat) kan gedeeld worden met andere clubs via de gedeelde drive, via deze link"
        )}
        : <SharedDTOgooglelink />
      </p>

      <Button
        icon={<DownloadOutlined />}
        onClick={() => ExportCluster(clusterData, profile, club)}
      >
        {$t("Exporteer")}
      </Button>

    </Form>
    
    <UpgradeDTO updateCluster={updateCluster} cluster={clusterData} />
    </div>
  );
};
