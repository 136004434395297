import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { MilkdownEditor } from "~/components/richtext/MilkdownEditor.jsx";
import { api } from "~/lib/api.js";

export const EditHomePage = ({ visible, onComplete, homepage_message }) => {
  const [saving, setSaving] = useState(false);

  const [newHomePage_message, setHomePageMessage] = useState();
  useEffect(() => setHomePageMessage(homepage_message), [homepage_message]);

  const dispatch = useDispatch();

  const onSave = async () => {
    setSaving(true);

    const data = await api.post(`admin/club_settings.json`, {
      homepage_message: newHomePage_message,
    });
    if (data) {
      dispatch({ type: "SET_CLUB", club: data });
    }
    setSaving(false);
    onComplete();
  };

  function onChange(content) {
    setHomePageMessage(content);
  }

  return (
    <div>
      <SaveButton
        onClick={onSave}
        loading={saving}
        size="small"
        style={{ position: "absolute", zIndex: 1, top: 10, right: 10 }}
      />

      <MilkdownEditor content={homepage_message} onChange={onChange} />
    </div>
  );
};
