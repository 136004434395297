import { CheckOutlined, SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";

export const SaveButtonWithStatusSaved = ({onClick, ...args}) => {
  
  const [hasSaved, setHasSaved] = useState(false)
  
  const handleClick = async () => {
    await onClick()

    setHasSaved(true)
    setTimeout(() => setHasSaved(false), 4000)
  }

  return (
    
    <Button
      icon={hasSaved ? <CheckOutlined /> : <SaveOutlined />} 
      type={hasSaved ? 'default' : (args && args.type) || 'primary'}
      onClick={handleClick}
      {...args}
    >
    {
      hasSaved ? $t('Opgeslagen') : $t('Opslaan')
    }
    </Button>
  );
};
