import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import {
  Modal,
  Select
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DescriptionRow } from "~/components/descriptionRow/descriptionRow.jsx";
import { getMaterialTab } from "~/redux/materiaal/materiaal.actions.js";

export const DocumentSelectModal = ({ visible, addDocument, title, onClose, documents }) => {
  const [chapter, setChapter] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const dispatch = useDispatch();
  const { currentMaterial } = useSelector((state) => state.materiaal);

  const chapters = currentMaterial?.documents?.chapters || [];

  const [loadingDocs, setLoadingDocs] = useState(true);

  useEffect(() => {
    
    const loadData = async () => {
      if (currentMaterial.documents === null) {
        await dispatch(getMaterialTab({activeTab: "documents",materialID: currentMaterial.id}));
      }
      setLoadingDocs(false);
    };
    if (visible) {
      setLoadingDocs(true);
      setChapter(null);
      setSelectedDocument(null);
      loadData()
    }
  }, [visible]);

  const selectChapter = (chapter_id) => {
    setChapter(chapters.find((c) => c.id === chapter_id));
  };

  const selectDocument = (document_id) => {
    setSelectedDocument(
      chapter?.documents?.find((doc) => doc.id === document_id)
    );
  };

  return (
    <Modal
      loading={loadingDocs}
      title={title}
      open={visible}
      onClose={() => onClose(false)}
      onCancel={() => onClose(false)}
      okButtonProps={{ disabled: !selectedDocument }}
      onOk={() => {
        addDocument(selectedDocument);
        onClose(false);
      }}
    >
      <div className="column" style={{ gap: 12 }}>
        <DescriptionRow label={$t("Hoofdstuk")}>
          <Select
            style={{ width: 250 }}
            value={chapter?.id}
            onChange={selectChapter}
          >
            {chapters.map((chapter) => (
              <Select.Option key={chapter.id} value={chapter.id}>
                {chapter.name}
              </Select.Option>
            ))}
          </Select>
        </DescriptionRow>

        <DescriptionRow label={$t("Document")}>
          <Select
            style={{ width: 250 }}
            value={selectedDocument?.id}
            onChange={selectDocument}
          >
            {chapter?.documents?.map((document) => (
              <Select.Option
                disabled={documents?.find((d) => d.id == document.id)}
                key={document.id}
                value={document.id}
              >
                {document.name}
              </Select.Option>
            ))}
          </Select>
        </DescriptionRow>
      </div>
    </Modal>
  );
};
