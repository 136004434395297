import { Button, Form, Input, Modal, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { $t } from '~/i18n.js'
import { ModalLayout } from '~/components/layout/modalLayout.jsx'
import { config } from '~/config.js'
import { persistActions } from '~/redux/actions.js'
import { colors } from '~/theme/colors.js'

import { Logo } from '~/components/layout/logo.jsx'
import { selectElementByID, updateIfNeeded } from '~/lib/helpers.js'
import { getAllClubs } from '~/redux/persist/persist.actions.js'
import './login.css'

// Club public route are components like login which can only be accessible for club, not with WWW in front or naked
const unknownClub = (schema) => {
  Modal.error({
    content: <div>
      <p><span className='bold'>{schema}</span> {$t('is onbekend')}</p>
      <p className='small' style={{ marginTop: 18 }}>{$t('Navigeer naar de juiste club site. Deze heeft een url als')}: https://<span className='bold'>club</span>.zweef.app</p>
      <p className='small' style={{ marginTop: 18 }}>{$t('Of druk op de Logo van je club op de homepagina.')}</p>
    </div>
  })
}

const LoginPageComponent = ({ login, logout, persist, history }) => {
  const [isSelected, setSelected] = useState(false)

  const { all_clubs, lastUpdatedClubs } = useSelector((state) => state.persist)
  const dispatch = useDispatch()

  useEffect(() => {
    updateIfNeeded(lastUpdatedClubs, () => dispatch(getAllClubs()))
  }, [lastUpdatedClubs, dispatch, getAllClubs])

  useEffect(() => {
    if (all_clubs && all_clubs.length > 0) {
      if (!selectElementByID(all_clubs, 'schema_name', config.tenant)) {
        unknownClub(config.tenant)
      }
    }
  }, [all_clubs])

  const selectedClub = selectElementByID(all_clubs, 'schema_name', config.tenant)

  const [loading, setLoading] = useState(false)
  const onFinish = async (values) => {
    // fire off login function of Redux
    setLoading(true);
    await login({ ...values, history })
    setLoading(false)
  }

  return (
    <ModalLayout width='500px' noPadding>

      <div className='column' style={{ width: '100%', minHeight: 100, backgroundColor: colors.white_off }}>

        {selectedClub ? <Logo height={70} width={100} url={selectedClub.logo} /> : <Skeleton.Button active size='large' shape='circle' block={false} />}

        {config.tenant === 'acvz' && config.mode === 'production' && <Button
          style={{ width: '100%', maxWidth: 300, marginBottom: 16, marginTop: 15 }}
          size='large' type='primary' title='Login met ACvZ' href={`${config.backend_url}/internal_api/auth/acvz_login.json`}
                                                                     >
          <span>Login via je ACvZ account</span>
        </Button>}

      </div>

      <div className='column' style={{ width: '100%' }}>
        <Form
          onFocus={() => setSelected(true)}
          style={{ width: '100%', maxWidth: 340, margin: 'auto' }}
          requiredMark={false}
          name='basic'
          initialValues={{
            remember: false
          }}
          onFinish={onFinish}
        >
          <p className='bold center' style={{ margin: '32px 0 18px' }}>
            {$t('Log in met je ${tenant} zweef.app account.', { tenant: config.tenant.toUpperCase() })}
          </p>

          {config.test_users && config.test_users.map((data, key) => (
            <Button
              key={key} type='small' style={{ margin: 4 }}
              onClick={() => onFinish({ email: `${data[0]}@${data[0] === 'Contact' ? 'zweef.app' : config.tenant + '.com'}`, password: 'test' })}
            >
              {data[0]} {data[1]}
            </Button>
          ))}

          <Form.Item

            autoFocus
            name='email'

            // TODO: Make sure you can login with startwagen@alb (non-existing email with .com behind it)
            rules={[{ required: true, message: $t('Voeg email toe') }]}
          >
            <Input autoComplete='username' type='email' placeholder={$t('Emailadres')} style={{ width: '100%', maxWidth: 300 }} />
          </Form.Item>

          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: $t('Voeg wachtwoord toe!')
              }
            ]}
          >
            <Input.Password
              autoComplete='current-password' name='email' placeholder={$t('Wachtwoord')}
              style={{ width: '100%', maxWidth: 300 }}
            />
          </Form.Item>

          <Form.Item>
            <Button size='large' type={isSelected ? 'primary' : 'default'} htmlType='submit' loading={loading} style={{ width: '100%', maxWidth: 300 }}>
              {$t('Login')}
            </Button>
          </Form.Item>

          <Button type='link' onClick={() => history.push('/forgot')} style={{ width: '100%', maxWidth: 300 }}>
            {$t('Wachtwoord vergeten?')}
          </Button>
        </Form>
      </div>

    </ModalLayout>
  )
}

const mapStateToProps = (state) => ({
  persist: state.persist
})

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(persistActions.login(data)),
    logout: () => dispatch(persistActions.logout())
  }
}

export const LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent)
