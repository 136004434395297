import { api } from '~/lib/api.js'

export const getMaterialData = (tab) => async (dispatch) => {
  dispatch({
    type: 'MATERIAAL_PENDING'
  })

  // defaults to material overview, which in redux contains normalizers
  tab = tab || 'material'
  const data = await api.get(`materiaal/overview_${tab}.json`)
  data && dispatch({
    type: tab === 'material' ? 'MATERIAAL_BASE_DATA' : 'MATERIAAL_SET_OVERVIEW',
    ...data
  })

  data?.inspections && dispatch({
    type: 'SET_INSPECTIONS',
    inspections: data.inspections
  })   
}


export const getDailyInspections = () => async (dispatch) => {
  
  const data = await api.get(`materiaal/daily_inspections.json`)
  data && dispatch({
    type: 'SET_INSPECTIONS',
    inspections: data
  })
}


export const openNewMaterial = ({ materialID }) => async (dispatch) => {
  dispatch({
    type: 'MATERIAAL_OPEN_NEW',
    materialID
  })
}

const _maintenanceURL = (activeTab, materialID, obj_id) => {
  let url = `materiaal/${materialID}/tab_${activeTab}.json`
  if (obj_id) url += `?obj_id=${obj_id}`
  return url
}


export const getMaterialTab = ({ method='GET', activeTab, materialID, obj_id, payload = {}, as_json=true }) => async (dispatch) => {
  method = method || 'GET'
  const {data} = await api._call_method(method, _maintenanceURL(activeTab, materialID, obj_id), payload, true, as_json)
  
  data && dispatch({
    type: 'MATERIAAL_SET_TAB',
    obj_id,
    material: data.material,
    activeTab,
    tab_data: data.data
  })
  return data
}

export const saveMaterialTab = ({ activeTab, materialID, payload }) => async (dispatch) => {
  const res = await api.post(_maintenanceURL(activeTab, materialID), payload)
  res && dispatch({
    type: 'MATERIAAL_SET_TAB',
    obj_id: 'current',
    material: res.material,
    activeTab,
    tab_data: res.data
  })
  return res
}

export const confirmWorkorderTask = (payload) => async (dispatch) => {
  const res = await api.post('materiaal/confirm_workorder_task.json', payload)
  res && dispatch({
    type: 'MATERIAAL_SAVE_WORKORDER_TASK',
    task: res
  })
  return res
}

export const getMelding = (id) => async (dispatch) => {
  dispatch({
    type: 'MATERIAAL_PENDING'
  })
  const data = await api.get(`materiaal/${id}/get_melding.json`)
  data && dispatch({
    type: 'MATERIAAL_OPEN_MELDING',
    activeMelding: data.melding
  })
}

const _upload_files = (bericht_id, files) => {
  if (files && files.length > 0 && bericht_id) {
    // upload all files
    files.forEach((file) => {
      // and don't wait
      api.post_form('materiaal/save_melding_file.json', { bericht_id, file: file.originFileObj })
    })
  }
}

export const addMelding = (melding) => async (dispatch) => {
  const data = await api.post('materiaal/add_melding.json', melding)
  if (!data?.melding) return null

  data.melding && dispatch({
    type: 'ADD_MELDING',
    newMelding: data.melding
  })
  _upload_files(data.bericht_id, melding.files)
  return data.melding
}

export const updateMelding = (melding) => async (dispatch) => {
  const data = await api.post('materiaal/add_melding.json', melding)
  data && dispatch({
    type: 'MATERIAAL_UPDATE_MELDING',
    melding: data.melding
  })
  return data
}

export const addMessage = ({ melding_id, transitie, bericht, files, only_for_technicians }) => async (dispatch) => {
  const data = await api.post('materiaal/add_message.json', { melding_id, transitie, bericht, only_for_technicians })
  data && dispatch({
    type: 'MATERIAAL_UPDATE_MELDING',
    melding: data.melding
  })
  _upload_files(data && data.bericht_id, files)
  return data
}
